export const PlaylistIcon = ({ className }: { className?: string }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M17 20.5V15.5L21 18L17 20.5Z" fill="currentColor" />
            <path
                d="M3 12H21M3 6H21M3 18H12.5M17 15.5V20.5L21 18L17 15.5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PlaylistIcon;
