import { Link, useLocation } from "react-router-dom";

export namespace TabComponent {
    export interface Props {
        children: React.ReactNode;
        icon: React.ReactNode;
        to: string;
    }
}

export function TabComponent({ icon, children, to }: TabComponent.Props) {
    const active = useLocation().pathname === to;

    const backgroundClassName = active
        ? "bg-secondary"
        : "bg-transparent hover:bg-secondary";
    const textColorClassName = active ? "text-neutral-900" : "text-neutral-800";

    return (
        <li data-testid="tab">
            <Link
                className={`flex gap-4 rounded-button p-4 ${backgroundClassName} ${textColorClassName}`}
                to={to}
            >
                {icon}
                {children}
            </Link>
        </li>
    );
}
