import { ShortCodeHits } from "@/components/base/atoms/shortcode-hits/shortcode-hits.component";
import { SearchResults } from "@/components/base/molecules/search-results/search-results";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import { SearchMdIcon, XCloseIcon } from "@/components/base/icons";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ConfigureProps } from "react-instantsearch";
import {
    Configure,
    Index,
    SearchBox,
    useInstantSearch,
} from "react-instantsearch";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "../../atoms";
import { useMediaQuery } from "usehooks-ts";

export const Search = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "search",
    });
    const [message, setMessage] = useState<string | null>(null);

    const {
        searchActive,
        searchInstantiated,
        handleDisableSearchActive,
        setGlobalSearchResults,
        globalSearchResults,
        shortcode,
        shortcodeUrl,
    } = useContext(AlgoliaSearchContext) ?? {};

    const navigate = useNavigate();
    const { results } = useInstantSearch();
    const location = useLocation();
    const previousLocation = useRef(location);
    const mediaQueryDesktop = useMediaQuery("(min-width: 1024px)");

    const noResultsMessage = t("errors.noResults", "Geen zoekresultaten");

    const searchConfigShortcode: ConfigureProps = {
        advancedSyntax: true,
        disableTypoToleranceOnAttributes: ["shortcode"],
        queryType: "prefixNone",
        restrictSearchableAttributes: ["shortcode", "available_to"],
    };

    useEffect(() => {
        if (previousLocation.current !== location) {
            handleDisableSearchActive?.();
            previousLocation.current = location;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (!searchActive) {
            setMessage(null);
        }
    }, [searchActive]);

    const handleGlobalSearch = useCallback(() => {
        if (
            results.query.length >= 2 &&
            results.nbHits === 0 &&
            !shortcodeUrl
        ) {
            setGlobalSearchResults?.([]);
            return null;
        } else if (results.query.length < 2) {
            setGlobalSearchResults?.([]);
            setMessage(null);

            return null;
        }

        if (
            !results ||
            results.index !== import.meta.env.VITE_ALGOLIA_SEARCH_GLOBAL_INDEX
        ) {
            return false;
        }

        setGlobalSearchResults?.(results.hits);
        setMessage(null);
    }, [results, shortcodeUrl, setGlobalSearchResults, setMessage]);

    useEffect(() => {
        const handlePaste = () => {
            handleGlobalSearch();
        };

        document.addEventListener("paste", handlePaste);

        return () => {
            document.removeEventListener("paste", handlePaste);
        };
    }, [handleGlobalSearch, results.hits, setGlobalSearchResults]);

    if (!searchActive) {
        return null;
    }

    const handleNavigateShortcode = () => {
        if (results.query == "") {
            setMessage(t("errors.noQuery", "Voer een zoekterm of snelcode in"));
        }

        if (
            !shortcodeUrl &&
            globalSearchResults?.length === 0 &&
            results.query
        ) {
            setMessage(noResultsMessage);
            return null;
        }

        if (
            !shortcodeUrl &&
            globalSearchResults?.length === 0 &&
            results.query
        ) {
            setMessage(
                t(
                    "errors.noResultsShortCode",
                    "Geen resultaten, gebruik een andere snelcode",
                ),
            );

            return null;
        }

        if (shortcodeUrl && results.query === shortcode) {
            setMessage(null);
            navigate(shortcodeUrl);
            handleDisableSearchActive?.();

            return null;
        }
    };

    return (
        <div className="fixed inset-0 z-[250] transition-all">
            {/* Backdrop */}
            <div
                className="absolute inset-0 z-10 cursor-pointer bg-white lg:bg-neutral-900/70"
                onClick={() => {
                    mediaQueryDesktop && handleDisableSearchActive?.();
                }}
            />
            {/* Search */}
            <div className="absolute z-20 w-full lg:left-1/2 lg:top-1/2 lg:h-[50vh] lg:w-[90vw] lg:max-w-[592px] lg:-translate-x-1/2 lg:-translate-y-1/2">
                <div className="overflow-hidden bg-white lg:rounded-default">
                    {searchInstantiated ? (
                        <div className="lg flex items-center pr-4 lg:pr-0">
                            <SearchBox
                                autoFocus={true}
                                resetIconComponent={() => (
                                    <XCloseIcon className="size-6" />
                                )}
                                submitIconComponent={() => (
                                    <SearchMdIcon className="size-6" />
                                )}
                                placeholder={t(
                                    "search",
                                    "Zoek op snelcode of trefwoord",
                                )}
                                onSubmit={handleNavigateShortcode}
                                onChangeCapture={handleGlobalSearch}
                                classNames={{
                                    root: "p-4 lg:py-0 w-full",
                                    form: "flex gap-2 border-outline-300 border lg:border-none rounded-default lg:rounded-none px-4 lg:px-0",
                                    reset: "order-3",
                                    submit: "order-1 text-neutral-500 hover:text-neutral-900 transition-colors",
                                    input: "order-2 h-11 lg:h-14 border-0 outline-none w-full appearance-none bg-transparent",
                                    loadingIndicator:
                                        "order-4 [&>svg]:size-4 [&>svg]:mt-5",
                                }}
                            />
                            <Typography
                                weight="bold"
                                color="very-dark"
                                onClick={() => {
                                    handleDisableSearchActive?.();
                                }}
                                className="block lg:hidden"
                            >
                                {t("cancel", "Zoek op snelcode of trefwoord")}
                            </Typography>
                        </div>
                    ) : null}
                    {message && (
                        <div className="px-4 py-6">
                            <Typography
                                variant="span"
                                color="dark"
                                weight="medium"
                                size="sm"
                                block
                            >
                                {message}
                            </Typography>
                        </div>
                    )}
                    {results.query !== "" ? (
                        <>
                            {results.hits && results.hits.length > 0 ? (
                                <div className="max-h-[calc(100dvh-78px)] overflow-auto border-t border-neutral-200 p-2 lg:max-h-[calc(50vh-56px)]">
                                    <SearchResults
                                        searchString={results.query}
                                        results={
                                            results.hits ||
                                            globalSearchResults ||
                                            []
                                        }
                                    />
                                </div>
                            ) : null}
                            <Index
                                indexName={
                                    import.meta.env
                                        .VITE_ALGOLIA_SEARCH_SHORTCODE_INDEX
                                }
                            >
                                <Configure {...searchConfigShortcode} />
                                <ShortCodeHits />
                            </Index>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
