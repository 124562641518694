import { z } from "zod";

export const backgroundSchema = z.enum([
    "BRAND_DARK",
    "BRAND_LIGHT",
    "DEFAULT",
    "NEUTRAL_DARK",
    "NEUTRAL_LIGHT",
    "PRIMARY",
    "WHITE",
    "NONE",
]);

export const ModuleBlockType = {
    DIDACTIC_TOOLS: "DIDACTIC_TOOLS",
    VIDEO_BLOCK: "VIDEO_BLOCK",
    TITLE_BLOCK: "TITLE_BLOCK",
} as const;

export type ModuleBlockType = keyof typeof ModuleBlockType;

export const ContentBlockType = {
    CONTENT: "CONTENT",
    LIST: "LIST",
    LINK: "LINK",
    QUOTE: "QUOTE",
} as const;

export type ContentBlockType = keyof typeof ContentBlockType;

export const BlockType = { ...ModuleBlockType, ...ContentBlockType };

export type BlockType = keyof typeof BlockType;

export const baseBlockSchema = z.object({
    id: z.union([z.string(), z.number()]).optional().nullable(),
    order: z.number().optional(),
    type: z.enum(["CONTENT", ...Object.values(ModuleBlockType)]),
    label: z
        .object({
            id: z.string().uuid().optional(),
            label: z.string(),
        })
        .optional()
        .nullable()
        .default({ label: "" }),
    background: backgroundSchema
        .optional()
        .transform((value) => (value === "PRIMARY" ? "BRAND_DARK" : value)),
    textBackground: backgroundSchema.optional(),
});

export type BackgroundType = z.infer<typeof backgroundSchema>;

export function isBlockType(type: string): type is BlockType {
    return Object.values(BlockType).includes(type as BlockType);
}
