import { Typography } from "@/components/base/atoms";

export const highlighString = (content?: string, searchString?: string) => {
    if (!searchString) return content;

    const searchWords = searchString.split(" ");
    let parts = [content]; // Start with the full description as a single part

    searchWords.forEach((word) => {
        const regex = new RegExp(`(${word})`, "gi");

        //@ts-ignore
        parts = parts.flatMap((part: string) =>
            typeof part === "string"
                ? part.split(regex).map((subPart, index) =>
                      regex.test(subPart) ? (
                          <Typography
                              variant="span"
                              weight="bold"
                              key={`${subPart}-${index}`}
                              color="very-dark"
                          >
                              {subPart}
                          </Typography>
                      ) : (
                          subPart
                      ),
                  )
                : part,
        );
    });

    if (parts.length >= 1 && typeof parts[0] === "string") {
        return parts;
    }

    return content;
};

export default highlighString;
