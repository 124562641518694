/* eslint-disable sonarjs/no-duplicate-string */
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import { useContext, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useKey, useOutsideClickRef } from "rooks";
import { useGetAuthenticatedUserQuery } from "@bespeak/apollo";
import { Scope, usePermissionsStore } from "@/store/permissions";
import MobileUserMenu from "@/components/base/molecules/mobile-user-menu/mobile-user-menu";
import Button from "@/components/base/atoms/Button";
import {
    LogOut01,
    Menu03Icon,
    SearchSmIcon,
    XCloseIcon,
} from "@/components/base/icons";
import { useMenuItems } from "@/lib/use-menu-items";

const MobileNav = () => {
    const { t } = useTranslation("common", { keyPrefix: "main-menu" });
    const navigate = useNavigate();
    const {
        tenantHasSearchKey,
        handleToggleSearchActive,
        searchActive,
        handleDisableSearchActive,
    } = useContext(AlgoliaSearchContext) ?? {};
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const { data } = useGetAuthenticatedUserQuery();
    const user = data?.getAuthenticatedUser;

    const { hasPermission } = usePermissionsStore();

    const hasReferenceCardPermissions = hasPermission(
        Scope.REFERENCE_CARDS_READ,
    );

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);

        if (!isMenuOpen && searchActive && handleDisableSearchActive) {
            handleDisableSearchActive();
        }
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    const [mobileNavRef] = useOutsideClickRef(handleCloseMenu);

    const { base: baseMenuItems, personal: personalMenuItems } = useMenuItems();

    useKey("Escape", () => {
        handleCloseMenu();
    });

    const { programId } = useParams<{
        programId: string;
        learningPathId: string;
        moduleId: string;
        didacticToolId: string;
    }>();

    const menuLinks = [
        {
            label: t("home"),
            href: programId ? `/program/${programId}` : "/",
        },
        ...(hasReferenceCardPermissions
            ? [{ label: t("reference"), href: "/naslag" }]
            : []),
    ];

    const userMenus = [[...personalMenuItems], [...baseMenuItems]];

    return (
        <section
            ref={mobileNavRef}
            className="fixed left-1.5 right-1.5 top-1.5 z-[200] max-h-[calc(100vh-0.75rem)] overflow-y-auto overflow-x-hidden rounded-lg bg-neutral-900 text-white theatre:border theatre:border-neutral-800"
        >
            <div className="sticky top-0 flex items-center justify-between bg-neutral-900 px-3 py-2">
                <Button
                    variant="neutralGhostDark"
                    size="small"
                    onClick={handleToggleMenu}
                >
                    <div className="grid [&>svg]:col-start-1 [&>svg]:row-start-1">
                        <Menu03Icon
                            className={clsx(
                                "h-5 w-5 transition-opacity duration-200",
                                {
                                    "opacity-100": !isMenuOpen,
                                    "opacity-0 delay-100": isMenuOpen,
                                },
                            )}
                        />
                        <XCloseIcon
                            className={clsx(
                                "h-5 w-5 transition-opacity duration-200",
                                {
                                    "opacity-0": !isMenuOpen,
                                    "opacity-100 delay-100": isMenuOpen,
                                },
                            )}
                        />
                    </div>
                    <div className="grid [&>span]:col-start-1 [&>span]:row-start-1">
                        <span
                            className={clsx("transition-opacity duration-200", {
                                "opacity-100": !isMenuOpen,
                                "opacity-0 delay-100": isMenuOpen,
                            })}
                        >
                            Menu
                        </span>
                        <span
                            className={clsx("transition-opacity duration-200", {
                                "opacity-0": !isMenuOpen,
                                "opacity-100 delay-100": isMenuOpen,
                            })}
                        >
                            Close
                        </span>
                    </div>
                </Button>
                {tenantHasSearchKey && (
                    <Button
                        variant="neutralGhostDark"
                        size="small"
                        iconOnly
                        onClick={handleToggleSearchActive}
                        className={clsx("transition-opacity duration-200", {
                            "pointer-events-none opacity-0": isMenuOpen,
                            "opacity-100": !isMenuOpen,
                        })}
                    >
                        {searchActive ? (
                            <XCloseIcon className="h-5 w-5" />
                        ) : (
                            <SearchSmIcon className="h-5 w-5" />
                        )}
                    </Button>
                )}
            </div>
            <div
                className={clsx(
                    "grid transition-[grid-template-rows] duration-300",
                    {
                        "grid-rows-[1fr]": isMenuOpen,
                        "grid-rows-[0fr]": !isMenuOpen,
                    },
                )}
            >
                <div className="overflow-hidden">
                    <div className="flex flex-col gap-4 px-3 pb-6 pt-2">
                        {menuLinks.length > 0 && (
                            <div className="flex flex-col gap-1">
                                {menuLinks.map((link) => (
                                    <Button
                                        key={link.label}
                                        variant="neutralGhostDark"
                                        size="small"
                                        className="w-full justify-start"
                                        onClick={() => {
                                            navigate(link.href);
                                            setIsMenuOpen(false);
                                        }}
                                    >
                                        {link.label}
                                    </Button>
                                ))}
                            </div>
                        )}
                        {user && (
                            <>
                                <MobileUserMenu
                                    user={user}
                                    menus={userMenus}
                                    onClick={() => setIsMenuOpen(false)}
                                />
                                <div className="w-full border-t border-neutral-800"></div>
                            </>
                        )}
                        <Button
                            variant="neutralGhostDark"
                            size="small"
                            className="w-full justify-start"
                            onClick={() => {
                                navigate("/logout");
                                setIsMenuOpen(false);
                            }}
                        >
                            <LogOut01 className="h-5 w-5" />
                            <span>{t("user-menu.log-out")}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MobileNav;
