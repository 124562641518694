const ShoppingBag02Icon = (props: React.ComponentProps<"svg">) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.67}
            d="M13.334 7.5V5a3.333 3.333 0 0 0-6.667 0v2.5M2.994 8.627l-.5 5.333c-.143 1.516-.214 2.275.038 2.86a2.5 2.5 0 0 0 1.1 1.208c.56.305 1.321.305 2.844.305h7.048c1.524 0 2.285 0 2.845-.305a2.5 2.5 0 0 0 1.1-1.208c.251-.585.18-1.344.038-2.86l-.5-5.333c-.12-1.28-.18-1.921-.468-2.405a2.5 2.5 0 0 0-1.077-.98C14.954 5 14.312 5 13.024 5H6.976c-1.286 0-1.929 0-2.438.242a2.5 2.5 0 0 0-1.076.98c-.288.484-.348 1.124-.468 2.405Z"
        />
    </svg>
);

export default ShoppingBag02Icon;
