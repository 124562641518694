import { useGetModuleForAppQuery } from "@bespeak/apollo";
export function useGetModuleForApp(moduleId: string) {
    const { data, ...results } = useGetModuleForAppQuery({
        variables: { moduleId: moduleId },
        skip: !moduleId,
    });

    return {
        module: data?.getModuleForApp,
        ...results,
    };
}

export default useGetModuleForApp;
