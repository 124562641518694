import ConvertedHTML from "@bespeak/ui/src/ConvertedHTML";
import { typographyStyles } from "./typography.styles";

export type TypographyColor =
    | "primary"
    | "primary-dim"
    | "white"
    | "neutral"
    | "dark"
    | "very-dark"
    | "almost-black"
    | "success"
    | "warning"
    | "error"
    | "inherit";

export type TypographySize = "xs" | "sm" | "base" | "lg" | "xl" | "2xl" | "3xl";

export type TypographyVariant =
    | "span"
    | "div"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "label"
    | "strong"
    | "i"
    | "li";

export type TypographyWeight = "normal" | "medium" | "bold";

export type TypographyProps = {
    truncate?: boolean;
    size?: TypographySize;
    variant?: TypographyVariant;
    children?: React.ReactNode;
    content?: string | null;
    weight?: TypographyWeight;
    italic?: boolean;
    block?: boolean;
    forElement?: string;
    className?: string;
    color?: TypographyColor;
    fontType?: "title" | "body";
    onClick?: () => void;
};

export const Typography = ({
    truncate,
    size = "base",
    variant = "div",
    children,
    fontType = "body",
    forElement = "",
    className = "",
    content,
    color = "almost-black",
    weight = "normal",
    italic = false,
    block = false,
    onClick,
}: TypographyProps) => {
    const Element = variant;

    const classes = typographyStyles({
        color,
        italic,
        weight,
        size,
        fontType,
        block,
        className,
    });

    const labelFor =
        variant === "label"
            ? {
                  htmlFor: forElement,
              }
            : {};

    return (
        <Element {...labelFor} className={`${classes}`} onClick={onClick}>
            {children ? (
                children
            ) : (
                <ConvertedHTML truncate={truncate} value={content} />
            )}
        </Element>
    );
};

export default Typography;
