import {
    NavigateToSearchResult,
    type QueryType,
} from "@/lib/navigateToSearchResult/navigateToSearchResult";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";
import { isArray } from "@apollo/client/utilities";
import { useContext, useEffect } from "react";
import { type HitsProps, useHits } from "react-instantsearch";
import { useTranslation } from "react-i18next";
import { useGetEntityNameForShortcode } from "@/lib/useGetEntityNameForShortcode/useGetEntityNameForShortcode";
import { Typography } from "@/components/base/atoms";
import { Link } from "react-router-dom";

export const ShortCodeHits = (props: HitsProps<any>) => {
    const { items: hits } = useHits(props);
    const { t } = useTranslation("common", {
        keyPrefix: "search",
    });
    const { setShortcode, setShortcodeUrl, shortcodeUrl } =
        useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);

    const shortcodeResult =
        isArray(hits) && hits.at(0)
            ? NavigateToSearchResult(hits!.at(0) as QueryType)
            : null;

    useEffect(() => {
        if (isArray(hits) && hits.at(0)) {
            setShortcode(hits!.at(0)!.shortcode);
        }

        if (shortcodeResult) {
            setShortcodeUrl(shortcodeResult);
        }

        if (!shortcodeResult) {
            setShortcodeUrl(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortcodeResult, hits]);

    const shortcodeResultName = useGetEntityNameForShortcode({
        educationalProgram: hits[0]?.educational_program,
        educationalPath: hits[0]?.eduational_path,
        module: hits[0]?.module,
        didacticTool: hits[0]?.didactic_tool,
    });

    return shortcodeUrl ? (
        <div className="flex gap-1 border-t border-neutral-200 p-4">
            <Typography color="very-dark">
                {t("shortcode", "Druk op enter te navigeren naar")}
            </Typography>
            <Typography weight="bold" color="very-dark">
                {shortcodeResultName ? (
                    <Link to={shortcodeUrl}>{shortcodeResultName || ""}</Link>
                ) : null}
            </Typography>
        </div>
    ) : null;
};
