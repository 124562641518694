import type { AppModuleDto, EducationalPathDto } from "@bespeak/apollo";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@/components/base/icons";
import clsx from "clsx";

type NavigationBreadcrumbsProps = {
    programId?: string;
    moduleId?: string;
    didacticToolId?: string;
    learningPath?: EducationalPathDto;
    module?: AppModuleDto;
};

const NavigationBreadcrumbs = ({
    programId,
    moduleId,
    didacticToolId,
    learningPath,
    module,
}: NavigationBreadcrumbsProps) => {
    const [breadcrumbsLinks, setBreadcrumbsLinks] = useState<string[]>([]);

    const breadcrumbTypes = useMemo(
        () => [
            {
                ...learningPath,
                type: "learning-path",
                name: learningPath?.name,
                condition: moduleId,
            },
            {
                ...module,
                type: "module",
                name: module?.title, //remap because module uses .title, while the other type uses .name
                condition: didacticToolId,
            },
        ],
        [learningPath, module, didacticToolId, moduleId],
    );

    useEffect(() => {
        const updatedBreadcrumbsLinks: string[] = [];

        let learningPathURL = "";
        let moduleURL = "";

        breadcrumbTypes.forEach((content) => {
            if (content.type === "learning-path" && moduleId) {
                learningPathURL = `/learning-path/${content.id}`;
                updatedBreadcrumbsLinks.push(learningPathURL);
            }
            if (content.type === "module" && didacticToolId) {
                moduleURL = `${learningPathURL}/module/${content.id}`;
                updatedBreadcrumbsLinks.push(moduleURL);
            }
        });

        setBreadcrumbsLinks(updatedBreadcrumbsLinks);
    }, [breadcrumbTypes, didacticToolId, moduleId]);

    return (
        <div className="flex items-center gap-2 overflow-hidden">
            {breadcrumbTypes
                .filter((breadcrumb) => breadcrumb.name !== undefined)
                .map((breadcrumb, index) => {
                    if (!breadcrumbsLinks[index]) return null;
                    return (
                        <Fragment
                            key={`breadcrumb-item-${breadcrumbTypes[index].id}`}
                        >
                            {index > 0 && (
                                <ChevronRightIcon className="h-4 w-4 shrink-0 text-neutral-300 theatre:text-neutral-700" />
                            )}
                            <Link
                                to={`/program/${programId}${breadcrumbsLinks[index]}`}
                                className={clsx(
                                    "truncate rounded-md px-2 py-1 transition-colors duration-300 ease-in-out hover:bg-neutral-100 theatre:bg-opacity-20 theatre:text-white hover:theatre:bg-neutral-200",
                                    {
                                        "min-w-[4ch] flex-1":
                                            index !==
                                            breadcrumbsLinks.length - 1,
                                        "flex-0":
                                            index ===
                                            breadcrumbsLinks.length - 1,
                                    },
                                )}
                            >
                                {breadcrumb?.name || ""}
                            </Link>
                        </Fragment>
                    );
                })}
        </div>
    );
};

export default NavigationBreadcrumbs;
