import { useState, useRef, useEffect } from "react";
import type { ComponentProps } from "react";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";
import classNames from "@/lib/class-names/class-names";

export interface ImgWithIdProps extends ComponentProps<"img"> {
    id: string;
    onLoad?: () => void;
}

export const ImageById = ({ id, className, ...props }: ImgWithIdProps) => {
    const ref = useRef<HTMLImageElement>(null);
    const [loaded, setLoaded] = useState(false);
    const detailedAsset = useDetailedAsset(id || "");

    const signedUrl = useDetailedAsset.getSignedUrl(detailedAsset);

    const imageClasses = classNames(
        "block w-full h-full object-cover transition-opacity duration-300 opacity-0",
        {
            "opacity-100": loaded,
        },
        className,
    );

    useEffect(() => {
        if (!signedUrl) return;

        const imageElement = ref.current;

        function handleLoad() {
            setLoaded(true);
            props.onLoad?.();
        }

        if (imageElement?.complete) {
            setLoaded(true);
        } else {
            imageElement?.addEventListener("load", handleLoad);
        }

        return () => {
            imageElement?.removeEventListener("load", handleLoad);
        };
    }, [props, ref.current?.complete, signedUrl]);

    return signedUrl ? (
        <img
            className={imageClasses}
            src={signedUrl}
            ref={ref}
            data-testid="image-by-id"
            {...props}
        />
    ) : (
        <div className={imageClasses} />
    );
};

export default ImageById;
