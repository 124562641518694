import { useTranslation } from "react-i18next";
import { VerticalTabs } from "@/components/molecules/vertical-tabs";
import { Shopping02BagIcon as ShoppingBagIcon } from "@/components/base/icons";
import { User02Icon as UserIcon } from "@/components/base/icons";

export function Menu() {
    const { t } = useTranslation("translation", {
        keyPrefix: "templates.layout.profile.menu",
    });

    const menuItems = [
        {
            icon: <UserIcon />,
            label: t("personal"),
            href: "/profile/personal",
        },
        {
            icon: <ShoppingBagIcon />,
            label: t("products"),
            href: "/profile/products",
        },
    ];

    return (
        <nav>
            <VerticalTabs>
                {menuItems.map((item) => (
                    <VerticalTabs.Tab
                        key={item.label}
                        to={item.href}
                        icon={item.icon}
                    >
                        {item.label}
                    </VerticalTabs.Tab>
                ))}
            </VerticalTabs>
        </nav>
    );
}

export default Menu;
