import { useMemo } from "react";
import type { ErrorLink } from "@apollo/client/link/error";
import { onError } from "@apollo/client/link/error";
import { GraphQLError, NetworkError } from "@/errors";
import useThrowInHook from "@/lib/use-throw-in-hook";

export function useErrorLink() {
    const throwInHook = useThrowInHook();

    return useMemo(
        () =>
            onError(({ graphQLErrors, response, networkError, operation }) => {
                //
                // When setting bypassErrorLink to true, the error link will not be used.
                // This is useful for testing or when you want to handle errors manually.
                // This will also not trigger any error boundary. The error will be logged to the console.
                //
                // For example:
                //
                //   registerNewUser({
                //     variables: { … },
                //     context: { bypassErrorLink: true },
                //   });
                //
                if (operation.getContext().bypassErrorLink) {
                    !import.meta.env.PROD &&
                        console.warn("useErrorLink: GraphQLError bypassed", {
                            graphQLErrors,
                            response,
                            networkError,
                        });
                    return;
                }

                if (graphQLErrors) {
                    console.error(
                        "errorLink:onError:graphQLErrors",
                        graphQLErrors,
                    );
                    throwInHook(() => {
                        graphQLErrors.forEach((e) => {
                            throw new GraphQLError(e.message);
                        });
                    });
                }
                if (graphQLErrors || response?.data) {
                    throwInHook(() => {
                        throw new NetworkError(graphQLErrors as any);
                    });
                } else if (networkError) {
                    console.error(
                        "errorLink:onError:networkError",
                        networkError,
                    );
                    throwInHook(() => {
                        throw new NetworkError(graphQLErrors as any);
                    });
                }
            }),
        [throwInHook],
    );
}

export { type ErrorLink };

export default useErrorLink;
