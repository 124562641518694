import type { ReactNode } from "react";
import css from "./DialogFooter.module.css";
import clsx from "clsx";

export type DialogFooterProps = {
    /** @deprecated Use children instead */
    onClick?: () => void;
    /** @deprecated Use children instead */
    closeDialog?: () => void;
    /** @deprecated Use children instead */
    buttonLabel?: string;
    children?: ReactNode;
    justify?: "start" | "center" | "end";
    center?: boolean;
    className?: string;
};

export const DialogFooter = ({
    children,
    justify = "end",
    center,
    className,
}: DialogFooterProps) => (
    <div
        className={clsx(
            css.DialogFooter,
            {
                ["justify-start"]: justify === "start",
                ["justify-center"]: justify === "center" || center,
                ["justify-end"]: justify === "end",
            },
            className,
        )}
    >
        {children}
    </div>
);

export default DialogFooter;
