import { Fragment, type ReactNode, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useKey, useOutsideClickRef } from "rooks";
import type { UserDto } from "@bespeak/apollo";
import Avatar from "@/components/base/atoms/avatar";
import ChevronDownIcon from "@/components/base/icons/chevron-down";
import { User02Icon } from "@/components/base/icons";
import { Typography } from "@/components/base/atoms";

type NavigationUserMenuProps = {
    user?: Partial<UserDto>;
    menus?: {
        icon: ReactNode;
        label: string;
        href: string;
    }[][];
};

const NavigationUserMenu = ({ user, menus }: NavigationUserMenuProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleCloseDropdown = () => {
        setDropdownOpen(false);
    };

    const [userDropdownRef] = useOutsideClickRef(handleCloseDropdown);

    useKey("Escape", () => {
        handleCloseDropdown();
    });

    useEffect(() => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            dropdownRef.current.style.maxHeight = `calc(100vh - ${rect.top}px)`;
        }
    }, [dropdownOpen]);

    if (!user) return null;

    return (
        <div className="relative" ref={userDropdownRef}>
            <div
                className="flex cursor-pointer items-center gap-0.5"
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                <Avatar
                    name={`${user.firstName} ${user.lastName}`}
                    size="sm"
                    isPrimaryBackground={false}
                />
                <ChevronDownIcon
                    className={clsx(
                        "h-5 w-5 transition-transform duration-300 ease-in-out will-change-transform theatre:text-white",
                        {
                            "-scale-y-100": dropdownOpen,
                        },
                    )}
                />
            </div>
            <div
                ref={dropdownRef}
                className={clsx(
                    "border-outline-200 absolute right-0 top-full z-10 mt-1.5 flex w-60 flex-col overflow-y-auto overflow-x-hidden rounded-default border bg-white shadow-md transition duration-300 ease-in-out will-change-transform theatre:border-neutral-800 theatre:bg-neutral-900 theatre:shadow-none",
                    {
                        "pointer-events-none translate-y-2 transform opacity-0":
                            !dropdownOpen,
                        "translate-y-0 transform opacity-100": dropdownOpen,
                    },
                )}
            >
                <div className="flex items-center gap-1.5 px-4 py-3">
                    <User02Icon className="h-5 w-6 theatre:text-white" />
                    <Typography weight="bold" className="theatre:text-white">
                        {user.firstName} {user.lastName}
                    </Typography>
                </div>
                {menus?.map(
                    (menu, index) =>
                        menu.length > 0 && (
                            <Fragment key={`user-menu-${index}`}>
                                <div className="border-outline-200 w-full border-t theatre:border-neutral-800"></div>
                                <div className="p-1.5">
                                    {menu.map((item, subindex) => (
                                        <Link
                                            key={`user-menu-${index}-${subindex}`}
                                            to={item.href}
                                            className="flex items-center gap-2 rounded-form p-2 hover:bg-neutral-50 theatre:bg-opacity-20 theatre:text-white hover:theatre:bg-neutral-200"
                                            onClick={() =>
                                                setDropdownOpen(false)
                                            }
                                        >
                                            {item.icon}
                                            <Typography className="theatre:text-white">
                                                {item.label}
                                            </Typography>
                                        </Link>
                                    ))}
                                </div>
                            </Fragment>
                        ),
                )}
            </div>
        </div>
    );
};

export default NavigationUserMenu;
