import css from "./QuestionConnectBuildingBlock.module.scss";
import Stack from "@mui/material/Stack";
import Block from "../../../atoms/Block";
import { useState } from "react";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import clsx from "clsx";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import FeedbackFields from "./common/Feedback/Feedback";
import QuestionFields from "./common/Question/Question";
import QuestionConnectAnswers from "@/components/molecules/QuestionConnectAnswers/QuestionConnectAnswers";
import { QuestionConnectBlockLayout } from "@bespeak/apollo";
import {
    type BuildingBlocksLabel,
    type QuestionConnectBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import SelectLabel from "@/components/organisms/BuildingBlockMapper/blocks/common/SelectLabel";

export interface QuestionConnectBuildingBlockComponentProps
    extends QuestionConnectBuildingBlock {
    availableLabels: BuildingBlocksLabel[];
    onChange?: (buildingBlock: QuestionConnectBuildingBlock) => void;
}

export function QuestionConnectBuildingBlockComponent(
    props: QuestionConnectBuildingBlockComponentProps,
) {
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");

    const [label, setLabel] = useState(props.label || null);

    type Fields = Pick<QuestionConnectBuildingBlock, "background">;

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const questionError = resolveError({
        errors: props.errors,
        path: "question",
    });

    const question = (
        <QuestionFields
            questionValue={props.question}
            introductionValue={props.introduction}
            instructionValue={props.instruction}
            onChange={(value, key) => {
                props.onChange?.({
                    ...props,
                    [key]: value,
                });
            }}
            error={questionError}
        />
    );
    const feedbackFields = (
        <FeedbackFields
            hasHintText={false}
            correctFeedbackValue={props.correctFeedbackText}
            incorrectFeedbackValue={props.incorrectFeedbackText}
            onChange={(value, key) => {
                props.onChange?.({
                    ...props,
                    [key]: value,
                });
            }}
        />
    );

    const answers = <QuestionConnectAnswers {...props} />;

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <SelectBgColor
                        {...registerControl("background")}
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                    />
                    <SelectLabel
                        options={props.availableLabels}
                        onChange={(newValue) => {
                            setLabel(newValue);
                            props.onChange?.({
                                ...props,
                                label: newValue,
                            });
                        }}
                        value={label}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <Stack gap={4} direction="row">
                    {props.layout === QuestionConnectBlockLayout.Centered ? (
                        <Stack gap={4} flexBasis="100%">
                            {question}
                            {answers}
                            {feedbackFields}
                        </Stack>
                    ) : null}
                    {props.layout === QuestionConnectBlockLayout.TwoColumns ? (
                        <>
                            <Stack gap={1} flexBasis="50%">
                                {question}
                            </Stack>
                            <Stack gap={4} flexBasis="50%">
                                {answers}
                                {feedbackFields}
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default QuestionConnectBuildingBlockComponent;
