export namespace VerticalTabsComponent {
    export interface Props {
        children: React.ReactNode;
    }
}

export function VerticalTabsComponent({
    children,
}: VerticalTabsComponent.Props) {
    return (
        <ul data-testid="vertical-tabs" className="flex flex-col gap-1.5">
            {children}
        </ul>
    );
}
