import z from "zod";

const uuidSchema = z.string().uuid();

export const validateUuid = (id: string | undefined | null) => {
    if (!id) return false;
    const result = uuidSchema.safeParse(id);
    return result.success ? id : false;
};

export default validateUuid;
