import type { GroupDto } from "@bespeak/apollo";
import { Autocomplete, Box, TextField } from "@mui/material";
import dashify from "dashify";
import type { ControllerRenderProps, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

const INDENT_CHAR = "↳";
const NON_BREAKING_SPACE = "\u00A0";

export interface GroupAutocompleteProps {
    error?: true;
    field?: ControllerRenderProps<FieldValues, string>;
    filter?: (group: Group) => boolean;
    groups: GroupDto[];
    keyPrefix?: string;
    label?: string;
    multiple?: boolean;
    name: string;
    onChange?: (value: string[]) => void;
    value: string | string[];
}

export type Group = {
    id: string;
    name: string;
    subgroups?: Group[];
    indent?: number;
};

function flattenGroups({
    id,
    name,
    subgroups = [],
    indent = 0,
}: Group): Group[] {
    return [
        { id, name, indent },
        ...subgroups.flatMap((subgroup: any) =>
            flattenGroups({ ...subgroup, indent: indent + 1 }),
        ),
    ];
}

const GroupAutocomplete = ({
    error,
    field,
    filter = defaultFilter,
    groups,
    keyPrefix = "dynamic-form",
    label,
    multiple,
    name,
    onChange,
    value,
}: GroupAutocompleteProps) => {
    const { t } = useTranslation("common", { keyPrefix });

    const options = [
        ...(groups as Group[]).flatMap(flattenGroups).filter(filter),
    ].map((group) => ({
        label: group.name,
        id: group.id,
        indent: group.indent,
    }));

    const selectedValues = multiple
        ? options.filter((opt) => (value as string[])?.includes(opt.id))
        : options.find((opt) => opt.id === value) || null;

    return (
        <Autocomplete
            {...field}
            multiple={multiple}
            options={options}
            value={selectedValues}
            onChange={(_, newValue) => {
                let ids: string[];

                if (multiple) {
                    ids = (newValue as { id: string }[]).map((item) => item.id);
                } else {
                    const singleId = (newValue as { id: string } | null)?.id;
                    ids = singleId ? [singleId] : [];
                }

                onChange?.(ids);
                field?.onChange(multiple ? ids : ids[0] || []);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                    <Indent indent={option.indent} />
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label || t(dashify(name))}
                    error={error}
                    inputProps={{
                        ...params.inputProps,
                        "data-testid": dashify(field?.name || dashify(name)),
                    }}
                />
            )}
        />
    );
};

export default GroupAutocomplete;

const Indent = ({ indent = 0 }: { indent?: number }) => {
    return (
        <Box component="span" sx={{ opacity: 0.4 }}>
            {NON_BREAKING_SPACE.repeat((indent ?? 0) * 2)}
            {indent ? `${INDENT_CHAR} ` : ""}
        </Box>
    );
};

const defaultFilter = () => true;
