import { createContext, type ReactNode } from "react";
import { Loader } from "@bespeak/ui";
import type { AnswerSheetContextType } from "@/contexts/AnswerSheet/AnswerSheet.types";
import { useAnswerSheetHook } from "@/contexts/AnswerSheet/AnswerSheet.hook";
import { useDidacticTool } from "@/contexts/DidacticTool";

//Initialize with placeholder values to prevent throwing of errors on hot reload
export const AnswerSheetContext = createContext<AnswerSheetContextType>({
    loading: false,
    didacticToolId: "",
    checkStoredInputs: () => {},
    countCorrectAnswers: () => 0,
    clear(): void {},
    countAmountOfAnswers: () => 0,
    countAmountOfCheckedAnswers: () => 0,
    getAnswer: () => undefined,
    storeInput: () => {},
    isAnswerInputLoading: () => false,
    clearAnswer: () => {},
    isCorrect: () => false,
} as AnswerSheetContextType);

type AnswerSheetProviderType = {
    children: ReactNode;
    /** @deprecated Use `useDidacticTool` instead. */
    didacticToolId?: string;
};

export const AnswerSheetProvider = (props: AnswerSheetProviderType) => {
    const { didacticToolId: didacticToolIdFromProps, children } = props;
    const { didacticToolId: didacticToolIdFromContext } = useDidacticTool();

    const answerSheet = useAnswerSheetHook({
        didacticToolId: didacticToolIdFromProps || didacticToolIdFromContext,
    });

    if (answerSheet.loading)
        return (
            <div style={{ minHeight: "75vh" }}>
                <Loader />
            </div>
        );

    return (
        <AnswerSheetContext.Provider value={answerSheet}>
            {children}
        </AnswerSheetContext.Provider>
    );
};
