import { z } from "zod";
import { baseBlockSchema } from "./base-block.schema";
import { ObjectFit } from "@bespeak/apollo";

export const objectFitSchema = z.enum([ObjectFit.Boxed, ObjectFit.Full]);

export const baseContentBlockSchema = baseBlockSchema.merge(
    z.object({
        id: z.string().nullable(),
        type: z.literal("CONTENT"),
        contentColumnOne: z.string().nullish(),
        contentColumnTwo: z.string().nullish(),
        objectFit: objectFitSchema.default(ObjectFit.Boxed),
        assetId: z.string().nullable(),
        nested: z.boolean().default(false).optional(),
    }),
);
