import {
    useGetDidacticToolForAppQuery,
    useGetEducationalProgramQuery,
    useGetEducationalPathForAppQuery,
    useGetModuleForAppQuery,
} from "@bespeak/apollo";
import validateUuid from "../validateUuid/validateUuid";

export type UseGetEntityNameForShortcodeProps = {
    educationalProgram?: string;
    educationalPath?: string;
    module?: string;
    didacticTool?: string;
};

export const useGetEntityNameForShortcode = ({
    educationalProgram,
    educationalPath,
    module,
    didacticTool,
}: UseGetEntityNameForShortcodeProps) => {
    const { data: educationalProgramData } = useGetEducationalProgramQuery({
        variables: {
            id: educationalProgram || "",
        },
        skip: !validateUuid(educationalProgram),
    });

    const { data: educationalPathData } = useGetEducationalPathForAppQuery({
        variables: {
            id: educationalPath || "",
        },
        skip: !validateUuid(educationalPath),
    });

    const { data: moduleData } = useGetModuleForAppQuery({
        variables: {
            moduleId: module || "",
        },
        skip: !validateUuid(module),
    });

    const { data: didacticToolData } = useGetDidacticToolForAppQuery({
        variables: {
            id: didacticTool || "",
        },
        skip: !validateUuid(didacticTool),
    });

    const educationalProgramName =
        educationalProgramData?.getEducationalProgram?.name;
    const educationalPathName =
        educationalPathData?.getEducationalPathForApp?.name;
    const moduleName = moduleData?.getModuleForApp?.title;
    const didacticToolName = didacticToolData?.getDidacticToolForApp?.name;

    return (
        didacticToolName ||
        moduleName ||
        educationalPathName ||
        educationalProgramName
    );
};
