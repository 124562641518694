import { z } from "zod";
import { baseBlockSchema } from "./base-block.schema";
import { baseContentListItemSchema } from "./base-content-list-item.schema";

export const baseListBlockLayoutSchema = z.enum([
    "ONE_COLUMN_WITH_IMAGE",
    "TWO_COLUMNS",
    "TWO_COLUMNS_WITH_NUMBERS",
    "TWO_COLUMN_WITH_LARGE_NUMBERS",
]);

export const baseListBlockSchema = baseBlockSchema.extend({
    id: z.union([z.string(), z.number()]).optional().nullable(),
    assetId: z.string().uuid().nullable().optional(),
    list: z.object({
        layout: baseListBlockLayoutSchema.catch("ONE_COLUMN_WITH_IMAGE"),
        items: z.array(baseContentListItemSchema),
    }),
});
