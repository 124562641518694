import { noop, useDialogStack } from "@bespeak/ui";
import { useCallback, useEffect } from "react";
import ReferenceCardDetail, {
    type ReferenceCardDetailProps,
} from "./ReferenceCardDetail";
import { useSearchParams } from "react-router-dom";

export namespace useReferenceCardDialog {
    export type Value = ReferenceCardDetailProps;

    export interface Props {
        value: {
            programId: string;
        };
        onDismiss?: () => void;
    }

    export interface ReturnValue {
        open: (id: string) => void;
        close: () => void;
    }
}

export function useReferenceCardDialog({
    value,
    onDismiss = noop,
}: useReferenceCardDialog.Props): useReferenceCardDialog.ReturnValue {
    const [, setSearchParam] = useSearchParams();
    const dialogStack = useDialogStack();
    const close = useCallback(() => {
        dialogStack.pop();
    }, [dialogStack]);

    const handleOnDismiss = useCallback(() => {
        onDismiss();
        close();
        setSearchParam({});
        document.body.style.overflow = "scroll";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onDismiss, close]);

    useEffect(() => {
        window.addEventListener("popstate", handleOnDismiss);
        return () => {
            window.removeEventListener("popstate", handleOnDismiss);
        };
    }, [handleOnDismiss]);

    function open(id: string) {
        dialogStack.push({
            component: (
                <ReferenceCardDetail
                    value={{
                        programId: value.programId,
                        id: id,
                    }}
                    onClick={handleOnDismiss}
                />
            ),
        });
    }

    return {
        open,
        close,
    };
}
