export type OIDCSettings = {
    url: string;
    realm: string;
    clientId: string;
};

/**
 * Object containing all possible OIDC settings. Some may be re-used across
 * multiple environments (especially in testing).
 */
const configs = {
    testing: {
        makemewitty: {
            url: "https://auth.makemewitty.witty.bespeak.test.test-k8s.tribeagency.nl/",
            realm: "makemewitty",
            clientId: "frontend",
        } satisfies OIDCSettings,

        visavi: {
            url: "https://auth.witty.bespeak.test.test-k8s.tribeagency.nl/",
            realm: "visavi",
            clientId: "frontend",
        } satisfies OIDCSettings,
    },

    acceptance: {
        makemewitty: {
            url: "https://auth.witty.bespeak.acc.test-k8s.tribeagency.nl/",
            realm: "makemewitty",
            clientId: "frontend",
        } satisfies OIDCSettings,

        visavi: {
            url: "https://auth.witty.bespeak.acc.test-k8s.tribeagency.nl/",
            realm: "visavi",
            clientId: "frontend",
        } satisfies OIDCSettings,
    },

    production: {
        makemewitty: {
            url: "https://auth.witty.bespeak.prd.k8s.tribeagency.nl/",
            realm: "makemewitty",
            clientId: "frontend",
        } satisfies OIDCSettings,

        visavi: {
            url: "https://auth.witty.bespeak.prd.k8s.tribeagency.nl/",
            realm: "visavi",
            clientId: "frontend",
        } satisfies OIDCSettings,
    },
} as const;

type MappingType = [string, OIDCSettings];

/**
 * Object tying hostnames to specific OIDC settings.
 */
const mappings: {
    testing: MappingType[];
    acceptance: MappingType[];
    production: MappingType[];
} = {
    testing: [
        ["localhost", configs.testing.makemewitty],
        [
            "makemewitty.witty.bespeak.test.test-k8s.tribeagency.nl",
            configs.testing.makemewitty,
        ],
        [
            "visavionline.witty.bespeak.test.test-k8s.tribeagency.nl",
            configs.testing.visavi,
        ],
        [
            "redesign.witty.bespeak.test.test-k8s.tribeagency.nl",
            configs.testing.makemewitty,
        ],
    ],
    acceptance: [
        [
            "makemewitty.witty.bespeak.acc.test-k8s.tribeagency.nl",
            configs.acceptance.makemewitty,
        ],
        [
            "visavionline.witty.bespeak.acc.test-k8s.tribeagency.nl",
            configs.acceptance.visavi,
        ],
        [
            "redesign.witty.bespeak.acc.test-k8s.tribeagency.nl",
            configs.acceptance.visavi,
        ],
    ],
    production: [
        [
            "makemewitty.witty.bespeak.prd.k8s.tribeagency.nl",
            configs.production.makemewitty,
        ],
        ["visavionline.nl", configs.production.visavi],
    ],
};

/**
 * Combine all hostname mappings into a single map for quick resolving
 */
const hostnameToConfigMap = new Map([
    ...mappings.testing,
    ...mappings.acceptance,
    ...mappings.production,
]);

/**
 * Resolve the correct OIDC settings based on the current hostname.
 *
 * @return the OIDC settings to apply for a specific hostname, or `undefined`
 *  if the current hostname is not associated with any OIDC settings. This
 *  can be overridden by setting the `VITE_APP_WHITELABEL_OVERRIDE` environment
 *  variable.
 */
export function resolveOIDCSettings() {
    const override = import.meta.env.VITE_APP_WHITELABEL_OVERRIDE;
    if (override) return configs.testing[override];
    return hostnameToConfigMap.get(location.hostname);
}
