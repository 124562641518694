import { z } from "zod";

export const baseContentListItemSchema = z.object({
    id: z.union([z.string(), z.number()]).optional().nullable(),
    assetId: z.string().uuid().nullish(),
    content: z.string(),
    sortOrder: z.number(),
    subtitle: z.string(),
    title: z.string(),
});
