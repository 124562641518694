import { z } from "zod";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    BuildingBlockAnswer: { input: any; output: any };
    BuildingBlockInput: { input: any; output: any };
    LocalDateTime: { input: any; output: any };
    Long: { input: number; output: number };
    ModuleBlockInput: { input: any; output: any };
    UUID: { input: string; output: string };
};

export type AnswerCorrectness = {
    buildingBlockId: Scalars["UUID"]["output"];
    correct: Scalars["Boolean"]["output"];
    correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
    hasHelpText: Scalars["Boolean"]["output"];
    incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
    reasoning?: Maybe<Scalars["String"]["output"]>;
    type: Scalars["String"]["output"];
};

export type AnswerDto = MpcAnswer & {
    __typename?: "AnswerDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    content?: Maybe<Scalars["String"]["output"]>;
    correct: Scalars["Boolean"]["output"];
    id: Scalars["UUID"]["output"];
    sortOrder: Scalars["Int"]["output"];
    text?: Maybe<Scalars["String"]["output"]>;
};

export type AnswerInput = {
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    content?: InputMaybe<Scalars["String"]["input"]>;
    correct: Scalars["Boolean"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    sortOrder?: InputMaybe<Scalars["Int"]["input"]>;
    text?: InputMaybe<Scalars["String"]["input"]>;
};

export type AppAnswerDto = MpcAnswer & {
    __typename?: "AppAnswerDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    content?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    text?: Maybe<Scalars["String"]["output"]>;
};

export type AppBuildingBlockDto = {
    background: BuildingBlockBackground;
    id: Scalars["UUID"]["output"];
    label?: Maybe<BuildingBlocksLabelDto>;
    order: Scalars["Long"]["output"];
    type: Scalars["String"]["output"];
};

export type AppBuildingBlocksForDidacticToolDto = {
    __typename?: "AppBuildingBlocksForDidacticToolDto";
    buildingBlocks: Array<AppBuildingBlockDto>;
    didacticToolId: Scalars["UUID"]["output"];
};

export type AppCarouselBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    CarouselBuildingBlock & {
        __typename?: "AppCarouselBuildingBlockDto";
        background: BuildingBlockBackground;
        blocks: Array<AppBuildingBlockDto>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: CarouselBlockLayout;
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppContentBlockListDto = ContentBlockList & {
    __typename?: "AppContentBlockListDto";
    items: Array<AppContentBlockListItemDto>;
    layout: ContentBlockListLayout;
};

export type AppContentBlockListItemDto = ContentListItem & {
    __typename?: "AppContentBlockListItemDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    content: Scalars["String"]["output"];
    id?: Maybe<Scalars["UUID"]["output"]>;
    sortOrder: Scalars["Int"]["output"];
    subtitle: Scalars["String"]["output"];
    title: Scalars["String"]["output"];
};

export type AppContentBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    ContentBuildingBlock & {
        __typename?: "AppContentBuildingBlockDto";
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        contentColumnOne?: Maybe<Scalars["String"]["output"]>;
        contentColumnTwo?: Maybe<Scalars["String"]["output"]>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: ContentBlockLayout;
        list?: Maybe<ContentBlockList>;
        objectFit: ObjectFit;
        order: Scalars["Long"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppConversationBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    ConversationBuildingBlock & {
        __typename?: "AppConversationBuildingBlockDto";
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        interval: Scalars["Int"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: ConversationBlockLayout;
        messages: Array<AppMessageDto>;
        order: Scalars["Long"]["output"];
        persons: Array<AppPersonDto>;
        type: Scalars["String"]["output"];
    };

export type AppDidacticToolDto = {
    __typename?: "AppDidacticToolDto";
    altLabel?: Maybe<Scalars["String"]["output"]>;
    description: Scalars["String"]["output"];
    durationIndication?: Maybe<Scalars["String"]["output"]>;
    headerBackground?: Maybe<DidacticToolBackground>;
    headerImageId?: Maybe<Scalars["UUID"]["output"]>;
    headerIntroText?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    label?: Maybe<DidacticToolLabelType>;
    learningAdviceDescription?: Maybe<Scalars["String"]["output"]>;
    learningAdviceTitle?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    type: DidacticToolType;
};

export type AppDidacticToolProgressDto = {
    __typename?: "AppDidacticToolProgressDto";
    duration?: Maybe<Scalars["Int"]["output"]>;
    endDate?: Maybe<Scalars["LocalDateTime"]["output"]>;
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    progress: Scalars["Int"]["output"];
    score: Scalars["Int"]["output"];
    type: DidacticToolType;
};

export type AppDidacticToolsModuleBlockDto = BaseModuleBlock & {
    __typename?: "AppDidacticToolsModuleBlockDto";
    didacticToolBlocks?: Maybe<Array<AppModuleBlockDidacticToolDto>>;
    id?: Maybe<Scalars["UUID"]["output"]>;
    order: Scalars["Int"]["output"];
    type: Scalars["String"]["output"];
};

export type AppEducationalPathDto = {
    __typename?: "AppEducationalPathDto";
    dashboardImageId?: Maybe<Scalars["UUID"]["output"]>;
    description: Scalars["String"]["output"];
    durationIndication?: Maybe<Scalars["String"]["output"]>;
    headerImageId?: Maybe<Scalars["UUID"]["output"]>;
    headerIntroText?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
};

export type AppEducationalPathProgressDto = {
    __typename?: "AppEducationalPathProgressDto";
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    progress: Scalars["Int"]["output"];
};

export type AppEducationalProgramDto = {
    __typename?: "AppEducationalProgramDto";
    description: Scalars["String"]["output"];
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
};

export type AppEducationalProgramElementDto = {
    __typename?: "AppEducationalProgramElementDto";
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
};

export type AppEducationalProgramElementsDto = {
    __typename?: "AppEducationalProgramElementsDto";
    didacticTools: Array<AppEducationalProgramElementDto>;
    educationalPaths: Array<AppEducationalProgramElementDto>;
    modules: Array<AppEducationalProgramElementDto>;
};

export type AppEducationalProgramElementsFilterInput = {
    /** An empty list indicates no lessons should be included. */
    didacticTools: Array<Scalars["UUID"]["input"]>;
    /** An empty list indicates no educational paths should be included. */
    educationalPaths: Array<Scalars["UUID"]["input"]>;
    /** An empty list indicates no modules should be included. */
    modules: Array<Scalars["UUID"]["input"]>;
};

export type AppGroupDto = {
    __typename?: "AppGroupDto";
    id: Scalars["UUID"]["output"];
    members: Array<AppUserDto>;
    name: Scalars["String"]["output"];
    parentId?: Maybe<Scalars["UUID"]["output"]>;
};

export type AppHotspotBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    HotspotBuildingBlock & {
        __typename?: "AppHotspotBuildingBlockDto";
        assetId: Scalars["UUID"]["output"];
        background: BuildingBlockBackground;
        content?: Maybe<Scalars["String"]["output"]>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: HotspotBlockLayout;
        order: Scalars["Long"]["output"];
        spots: Array<SpotDto>;
        title?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type AppInteractiveQuestionBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    InteractiveQuestionBuildingBlock & {
        __typename?: "AppInteractiveQuestionBuildingBlockDto";
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: InteractiveQuestionBlockLayout;
        order: Scalars["Long"]["output"];
        question: AppBuildingBlockDto;
        sceneWhenWrong: AppSceneBuildingBlockDto;
        type: Scalars["String"]["output"];
    };

export type AppLearningPathProgressDto = {
    __typename?: "AppLearningPathProgressDto";
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    progress: Scalars["Int"]["output"];
};

export type AppLinkBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    LinkBuildingBlock & {
        __typename?: "AppLinkBuildingBlockDto";
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        instruction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: LinkBlockLayout;
        linkLabel?: Maybe<Scalars["String"]["output"]>;
        openInNewWindow: Scalars["Boolean"]["output"];
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
        url: Scalars["String"]["output"];
    };

export type AppMessageDto = Message & {
    __typename?: "AppMessageDto";
    id: Scalars["UUID"]["output"];
    message: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
    personIndex: Scalars["Int"]["output"];
};

export type AppModuleBlockDidacticToolDto = {
    __typename?: "AppModuleBlockDidacticToolDto";
    didacticTool?: Maybe<AppDidacticToolDto>;
    didacticToolId: Scalars["UUID"]["output"];
    order: Scalars["Int"]["output"];
    variant: ModuleBlockVariant;
};

export type AppModuleBlockDto =
    | AppDidacticToolsModuleBlockDto
    | AppTitleModuleBlockDto
    | AppVideoModuleBlockDto;

export type AppModuleBlocksForModuleDto = {
    __typename?: "AppModuleBlocksForModuleDto";
    blocks: Array<AppModuleBlockDto>;
    moduleId: Scalars["UUID"]["output"];
};

export type AppModuleDto = {
    __typename?: "AppModuleDto";
    content?: Maybe<Scalars["String"]["output"]>;
    dashboardImageId?: Maybe<Scalars["UUID"]["output"]>;
    description: Scalars["String"]["output"];
    durationIndication?: Maybe<Scalars["String"]["output"]>;
    headerImageId?: Maybe<Scalars["UUID"]["output"]>;
    id: Scalars["UUID"]["output"];
    shortTitle?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
};

export type AppModuleProgressDto = {
    __typename?: "AppModuleProgressDto";
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    progress: Scalars["Int"]["output"];
};

export type AppMoodboardBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    MoodboardBuildingBlock & {
        __typename?: "AppMoodboardBuildingBlockDto";
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        images: Array<MoodboardImageDto>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: MoodboardBlockLayout;
        order: Scalars["Long"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppMpcBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    MpcBuildingBlock & {
        __typename?: "AppMpcBuildingBlockDto";
        answers: Array<AppAnswerDto>;
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        choiceType: MpcChoiceType;
        id: Scalars["UUID"]["output"];
        instruction?: Maybe<Scalars["String"]["output"]>;
        introduction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: MpcBlockLayout;
        objectFit: ObjectFit;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        sorting: Sorting;
        type: Scalars["String"]["output"];
    };

export type AppPersonDto = Person & {
    __typename?: "AppPersonDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
};

export type AppPollBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    PollBuildingBlock & {
        __typename?: "AppPollBuildingBlockDto";
        answers: Array<PollAnswerDto>;
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        instruction: Scalars["String"]["output"];
        introduction: Scalars["String"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: PollBlockLayout;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
        voteOption: PollVoteOption;
    };

export type AppQuestionConnectBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    QuestionConnectBuildingBlock & {
        __typename?: "AppQuestionConnectBuildingBlockDto";
        answers: Array<QuestionConnectItemAnswerDto>;
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        instruction: Scalars["String"]["output"];
        introduction?: Maybe<Scalars["String"]["output"]>;
        items: Array<AppQuestionConnectItemDto>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: QuestionConnectBlockLayout;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppQuestionConnectItemDto = QuestionConnectItem & {
    __typename?: "AppQuestionConnectItemDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    buildingBlockId?: Maybe<Scalars["UUID"]["output"]>;
    content?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["UUID"]["output"]>;
};

export type AppQuoteBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    QuoteBuildingBlock & {
        __typename?: "AppQuoteBuildingBlockDto";
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: QuoteBlockLayout;
        name?: Maybe<Scalars["String"]["output"]>;
        order: Scalars["Long"]["output"];
        quote: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppReferenceCardCarouselBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    ReferenceCardsCarouselBuildingBlock & {
        __typename?: "AppReferenceCardCarouselBuildingBlockDto";
        background: BuildingBlockBackground;
        cards: Array<ReferenceCardDto>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: ReferenceCardsCarouselBlockLayout;
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppReferenceCardsFilterOptionsDto = {
    __typename?: "AppReferenceCardsFilterOptionsDto";
    categories: Array<ReferenceCardCategoryDto>;
    leads: Array<ReferenceCardLeadDto>;
};

export type AppSceneBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    SceneBuildingBlock & {
        __typename?: "AppSceneBuildingBlockDto";
        assetId: Scalars["UUID"]["output"];
        background: BuildingBlockBackground;
        description?: Maybe<Scalars["String"]["output"]>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: SceneBlockLayout;
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppSortQuestionAnswerDto = SortQuestionAnswer & {
    __typename?: "AppSortQuestionAnswerDto";
    id: Scalars["UUID"]["output"];
    text: Scalars["String"]["output"];
};

export type AppSortQuestionBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    SortQuestionBuildingBlock & {
        __typename?: "AppSortQuestionBuildingBlockDto";
        answers: Array<AppSortQuestionAnswerDto>;
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        instruction?: Maybe<Scalars["String"]["output"]>;
        introduction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: SortQuestionBlockLayout;
        objectFit: ObjectFit;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type AppStandBuildingBlockDto = AppBuildingBlockDto &
    BaseBuildingBlock &
    StandBuildingBlock & {
        __typename?: "AppStandBuildingBlockDto";
        agreeText: Scalars["String"]["output"];
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        disagreeText: Scalars["String"]["output"];
        id: Scalars["UUID"]["output"];
        instruction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: StandBlockLayout;
        order: Scalars["Long"]["output"];
        stand: Scalars["String"]["output"];
        text?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type AppTitleModuleBlockDto = BaseModuleBlock &
    TitleModuleBlock & {
        __typename?: "AppTitleModuleBlockDto";
        description?: Maybe<Scalars["String"]["output"]>;
        id?: Maybe<Scalars["UUID"]["output"]>;
        order: Scalars["Int"]["output"];
        title?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type AppUserDto = {
    __typename?: "AppUserDto";
    educationalPathsProgress: Array<AppEducationalPathProgressDto>;
    emailAddress: Scalars["String"]["output"];
    firstName: Scalars["String"]["output"];
    id: Scalars["UUID"]["output"];
    lastName: Scalars["String"]["output"];
};

export type AppUserProgressDto = {
    __typename?: "AppUserProgressDto";
    didacticTools: Array<AppDidacticToolProgressDto>;
    emailAddress: Scalars["String"]["output"];
    firstName: Scalars["String"]["output"];
    id: Scalars["UUID"]["output"];
    lastName: Scalars["String"]["output"];
    learningPaths: Array<AppLearningPathProgressDto>;
    modules: Array<AppModuleProgressDto>;
};

export type AppUsersProgressDto = {
    __typename?: "AppUsersProgressDto";
    pagination: Pagination;
    rows: Array<AppUserProgressDto>;
};

export type AppVideoModuleBlockDto = BaseModuleBlock &
    VideoModuleBlock & {
        __typename?: "AppVideoModuleBlockDto";
        assetId: Scalars["UUID"]["output"];
        durationIndication?: Maybe<Scalars["String"]["output"]>;
        id?: Maybe<Scalars["UUID"]["output"]>;
        order: Scalars["Int"]["output"];
        title?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type AssetDto = {
    createdAt: Scalars["LocalDateTime"]["output"];
    id: Scalars["UUID"]["output"];
};

export type BaseBuildingBlock = {
    background: BuildingBlockBackground;
    id?: Maybe<Scalars["UUID"]["output"]>;
    order: Scalars["Long"]["output"];
    type: Scalars["String"]["output"];
};

export type BaseModuleBlock = {
    id?: Maybe<Scalars["UUID"]["output"]>;
    order: Scalars["Int"]["output"];
    type: Scalars["String"]["output"];
};

export enum BorderRadius {
    BigRadius = "BIG_RADIUS",
    None = "NONE",
    SmallRadius = "SMALL_RADIUS",
}

export enum BuildingBlockBackground {
    BrandDark = "BRAND_DARK",
    BrandLight = "BRAND_LIGHT",
    /** @deprecated Legacy color, will be removed in the near future */
    Default = "DEFAULT",
    NeutralDark = "NEUTRAL_DARK",
    NeutralLight = "NEUTRAL_LIGHT",
    /** @deprecated Legacy color, will be removed in the near future */
    Primary = "PRIMARY",
    White = "WHITE",
}

export type BuildingBlockDto = {
    background: BuildingBlockBackground;
    id: Scalars["UUID"]["output"];
    label?: Maybe<BuildingBlocksLabelDto>;
    order: Scalars["Long"]["output"];
    type: Scalars["String"]["output"];
};

export type BuildingBlocksForDidacticToolDto = {
    __typename?: "BuildingBlocksForDidacticToolDto";
    buildingBlocks: Array<BuildingBlockDto>;
    didacticToolId: Scalars["UUID"]["output"];
};

export type BuildingBlocksLabel = {
    id?: Maybe<Scalars["UUID"]["output"]>;
    label: Scalars["String"]["output"];
};

export type BuildingBlocksLabelDto = BuildingBlocksLabel & {
    __typename?: "BuildingBlocksLabelDto";
    id: Scalars["UUID"]["output"];
    label: Scalars["String"]["output"];
};

export type BuildingBlocksLabelInput = {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label: Scalars["String"]["input"];
};

export type BulkActionResult = {
    __typename?: "BulkActionResult";
    failed: Array<Scalars["UUID"]["output"]>;
    successful: Array<Scalars["UUID"]["output"]>;
};

export enum CarouselBlockLayout {
    Default = "DEFAULT",
}

export type CarouselBuildingBlock = {
    blocks: Array<BaseBuildingBlock>;
    layout: CarouselBlockLayout;
    title: Scalars["String"]["output"];
};

export type CarouselBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    CarouselBuildingBlock & {
        __typename?: "CarouselBuildingBlockDto";
        background: BuildingBlockBackground;
        blocks: Array<BuildingBlockDto>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: CarouselBlockLayout;
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type CarouselBuildingBlockInput = {
    background: BuildingBlockBackground;
    blocks: Array<Scalars["BuildingBlockInput"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: CarouselBlockLayout;
    order: Scalars["Long"]["input"];
    title: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
};

export type CheckAnswersForDidacticToolInput = {
    answers: Array<Scalars["BuildingBlockAnswer"]["input"]>;
    didacticToolId: Scalars["UUID"]["input"];
};

export enum ContentBlockLayout {
    ImageLeft = "IMAGE_LEFT",
    ImageRight = "IMAGE_RIGHT",
    TextCentered = "TEXT_CENTERED",
    TwoColumns = "TWO_COLUMNS",
}

export type ContentBlockList = {
    items: Array<ContentListItem>;
    layout: ContentBlockListLayout;
};

export type ContentBlockListDto = ContentBlockList & {
    __typename?: "ContentBlockListDto";
    items: Array<ContentBlockListItemDto>;
    layout: ContentBlockListLayout;
};

export type ContentBlockListInput = {
    items: Array<ContentBlockListItemInput>;
    layout: ContentBlockListLayout;
};

export type ContentBlockListItemDto = ContentListItem & {
    __typename?: "ContentBlockListItemDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    content: Scalars["String"]["output"];
    id?: Maybe<Scalars["UUID"]["output"]>;
    sortOrder: Scalars["Int"]["output"];
    subtitle: Scalars["String"]["output"];
    title: Scalars["String"]["output"];
};

export type ContentBlockListItemInput = {
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    content: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    sortOrder: Scalars["Int"]["input"];
    subtitle: Scalars["String"]["input"];
    title: Scalars["String"]["input"];
};

export enum ContentBlockListLayout {
    OneColumnNumbered = "ONE_COLUMN_NUMBERED",
    OneColumnWithImage = "ONE_COLUMN_WITH_IMAGE",
    ThreeColumns = "THREE_COLUMNS",
    ThreeColumnsWithNumbers = "THREE_COLUMNS_WITH_NUMBERS",
    TwoColumns = "TWO_COLUMNS",
    TwoColumnsWithImage = "TWO_COLUMNS_WITH_IMAGE",
    TwoColumnsWithNumbers = "TWO_COLUMNS_WITH_NUMBERS",
    TwoColumnWithLargeNumbers = "TWO_COLUMN_WITH_LARGE_NUMBERS",
}

export type ContentBuildingBlock = {
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    contentColumnOne?: Maybe<Scalars["String"]["output"]>;
    contentColumnTwo?: Maybe<Scalars["String"]["output"]>;
    layout: ContentBlockLayout;
    list?: Maybe<ContentBlockList>;
    objectFit: ObjectFit;
};

export type ContentBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    ContentBuildingBlock & {
        __typename?: "ContentBuildingBlockDto";
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        contentColumnOne?: Maybe<Scalars["String"]["output"]>;
        contentColumnTwo?: Maybe<Scalars["String"]["output"]>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: ContentBlockLayout;
        list?: Maybe<ContentBlockListDto>;
        objectFit: ObjectFit;
        order: Scalars["Long"]["output"];
        type: Scalars["String"]["output"];
    };

export type ContentBuildingBlockInput = {
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    background: BuildingBlockBackground;
    contentColumnOne?: InputMaybe<Scalars["String"]["input"]>;
    contentColumnTwo?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: ContentBlockLayout;
    list?: InputMaybe<ContentBlockListInput>;
    objectFit: ObjectFit;
    order: Scalars["Long"]["input"];
    type: Scalars["String"]["input"];
};

export type ContentListItem = {
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    content?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["UUID"]["output"]>;
    sortOrder: Scalars["Int"]["output"];
    subtitle?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
};

export enum ConversationBlockLayout {
    Default = "DEFAULT",
}

export type ConversationBuildingBlock = {
    interval: Scalars["Int"]["output"];
    layout: ConversationBlockLayout;
    messages: Array<Message>;
    persons: Array<Person>;
};

export type ConversationBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    ConversationBuildingBlock & {
        __typename?: "ConversationBuildingBlockDto";
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        interval: Scalars["Int"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: ConversationBlockLayout;
        messages: Array<MessageDto>;
        order: Scalars["Long"]["output"];
        persons: Array<PersonDto>;
        type: Scalars["String"]["output"];
    };

export type ConversationBuildingBlockInput = {
    background: BuildingBlockBackground;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    interval: Scalars["Int"]["input"];
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: ConversationBlockLayout;
    messages: Array<MessageInput>;
    order: Scalars["Long"]["input"];
    persons: Array<PersonInput>;
    type: Scalars["String"]["input"];
};

export type CreateChildGroupInput = {
    memberIds: Array<Scalars["UUID"]["input"]>;
    name: Scalars["String"]["input"];
    parentId: Scalars["UUID"]["input"];
};

export type CreateDidacticToolInput = {
    altLabel?: InputMaybe<Scalars["String"]["input"]>;
    description: Scalars["String"]["input"];
    durationIndication?: InputMaybe<Scalars["String"]["input"]>;
    headerImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    headerIntroText?: InputMaybe<Scalars["String"]["input"]>;
    label?: InputMaybe<DidacticToolLabelType>;
    learningAdviceDescription?: InputMaybe<Scalars["String"]["input"]>;
    learningAdviceTitle?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    type: DidacticToolType;
};

export type CreateEducationalPathInput = {
    dashboardImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    description: Scalars["String"]["input"];
    durationIndication?: InputMaybe<Scalars["String"]["input"]>;
    headerImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    headerIntroText?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
};

export type CreateEducationalProgramInput = {
    description: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
};

export type CreateGroupInput = {
    externalCode?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    parentId?: InputMaybe<Scalars["UUID"]["input"]>;
    productIds: Array<Scalars["UUID"]["input"]>;
    showGroupDuringRegistration: Scalars["Boolean"]["input"];
};

export type CreateLicensesInput = {
    productIds: Array<Scalars["UUID"]["input"]>;
    users: Array<Scalars["UUID"]["input"]>;
};

export type CreateModuleInput = {
    content?: InputMaybe<Scalars["String"]["input"]>;
    dashboardImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    description: Scalars["String"]["input"];
    durationIndication?: InputMaybe<Scalars["String"]["input"]>;
    headerImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    shortTitle?: InputMaybe<Scalars["String"]["input"]>;
    title: Scalars["String"]["input"];
};

export type CreateProductInput = {
    educationalProgramIds: Array<Scalars["UUID"]["input"]>;
    externalIdentifier?: InputMaybe<Scalars["String"]["input"]>;
    isAvailable: Scalars["Boolean"]["input"];
    name: Scalars["String"]["input"];
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    validityPeriod?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreateRoleInput = {
    name: Scalars["String"]["input"];
    permissions: Array<Scalars["UUID"]["input"]>;
};

export type CreateShortcodeInput = {
    didacticToolId?: InputMaybe<Scalars["UUID"]["input"]>;
    educationalPathId?: InputMaybe<Scalars["UUID"]["input"]>;
    educationalProgramId: Scalars["UUID"]["input"];
    moduleId?: InputMaybe<Scalars["UUID"]["input"]>;
    shortCode: Scalars["String"]["input"];
};

export type CreateUserInput = {
    dateOfBirth?: InputMaybe<Scalars["LocalDateTime"]["input"]>;
    emailAddress: Scalars["String"]["input"];
    firstName: Scalars["String"]["input"];
    groupIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
    lastName: Scalars["String"]["input"];
    roleId: Scalars["UUID"]["input"];
    sendInviteImmediately: Scalars["Boolean"]["input"];
    tenantIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

export enum DidacticToolBackground {
    BrandDark = "BRAND_DARK",
    BrandLight = "BRAND_LIGHT",
    NeutralLight = "NEUTRAL_LIGHT",
}

export type DidacticToolDto = {
    __typename?: "DidacticToolDto";
    altLabel?: Maybe<Scalars["String"]["output"]>;
    description: Scalars["String"]["output"];
    durationIndication?: Maybe<Scalars["String"]["output"]>;
    headerBackground?: Maybe<DidacticToolBackground>;
    headerImageId?: Maybe<Scalars["UUID"]["output"]>;
    headerIntroText?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    label?: Maybe<DidacticToolLabelType>;
    learningAdviceDescription?: Maybe<Scalars["String"]["output"]>;
    learningAdviceTitle?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    type: DidacticToolType;
};

export type DidacticToolForModuleDto = {
    __typename?: "DidacticToolForModuleDto";
    id: Scalars["UUID"]["output"];
    label?: Maybe<DidacticToolLabelType>;
    name: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
    type: DidacticToolType;
};

export enum DidacticToolLabelType {
    BookCheck = "BOOK_CHECK",
    EntryTest = "ENTRY_TEST",
    Quiz = "QUIZ",
    TestYourself = "TEST_YOURSELF",
    Trainer = "TRAINER",
}

export type DidacticToolModuleBlockInput = {
    didacticToolId: Scalars["UUID"]["input"];
    order: Scalars["Int"]["input"];
    variant: ModuleBlockVariant;
};

export type DidacticToolProgressDto = ProgressDto & {
    __typename?: "DidacticToolProgressDto";
    id: Scalars["UUID"]["output"];
    progressPercentage: Scalars["Int"]["output"];
};

export enum DidacticToolType {
    Assignment = "ASSIGNMENT",
    Book = "BOOK",
    Case = "CASE",
    Exam = "EXAM",
    Lesson = "LESSON",
    LinearVideo = "LINEAR_VIDEO",
    Quiz = "QUIZ",
    VideoInteractive = "VIDEO_INTERACTIVE",
}

export type DidacticToolsDto = {
    __typename?: "DidacticToolsDto";
    pagination: Pagination;
    rows: Array<DidacticToolDto>;
};

export enum DidacticToolsFieldSelector {
    /** Selector to search on the name of the didactic tools */
    Name = "Name",
}

/** Filter for didactic tools */
export type DidacticToolsFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: DidacticToolsFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type DidacticToolsForModuleDto = {
    __typename?: "DidacticToolsForModuleDto";
    didacticTools: Array<DidacticToolForModuleDto>;
    moduleId: Scalars["UUID"]["output"];
};

export type DidacticToolsModuleBlockDto = BaseModuleBlock & {
    __typename?: "DidacticToolsModuleBlockDto";
    didacticToolBlocks?: Maybe<Array<ModuleBlockDidacticToolDto>>;
    id?: Maybe<Scalars["UUID"]["output"]>;
    order: Scalars["Int"]["output"];
    type: Scalars["String"]["output"];
};

export type DidacticToolsModuleBlockInput = {
    didacticTools: Array<DidacticToolModuleBlockInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    order: Scalars["Int"]["input"];
    type: Scalars["String"]["input"];
};

export enum Direction {
    Asc = "ASC",
    Desc = "DESC",
}

export type EducationalPathDto = {
    __typename?: "EducationalPathDto";
    dashboardImageId?: Maybe<Scalars["UUID"]["output"]>;
    description: Scalars["String"]["output"];
    durationIndication?: Maybe<Scalars["String"]["output"]>;
    headerImageId?: Maybe<Scalars["UUID"]["output"]>;
    headerIntroText?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
};

export type EducationalPathForProgramDto = {
    __typename?: "EducationalPathForProgramDto";
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
};

export type EducationalPathProgressDto = ProgressDto & {
    __typename?: "EducationalPathProgressDto";
    id: Scalars["UUID"]["output"];
    progressPercentage: Scalars["Int"]["output"];
};

export type EducationalPathsDto = {
    __typename?: "EducationalPathsDto";
    pagination: Pagination;
    rows: Array<EducationalPathDto>;
};

export enum EducationalPathsFieldSelector {
    /** Selector to search on the name of the educational paths */
    Name = "Name",
}

/** Filter for modules */
export type EducationalPathsFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: EducationalPathsFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type EducationalPathsForProgramDto = {
    __typename?: "EducationalPathsForProgramDto";
    educationalPaths: Array<EducationalPathForProgramDto>;
    programId: Scalars["UUID"]["output"];
};

export type EducationalProgramDto = {
    __typename?: "EducationalProgramDto";
    description: Scalars["String"]["output"];
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
};

export type EducationalProgramsDto = {
    __typename?: "EducationalProgramsDto";
    pagination: Pagination;
    rows: Array<EducationalProgramDto>;
};

export enum EducationalProgramsFieldSelector {
    /** Selector to search on the name of the educational program */
    Name = "Name",
}

/** Filter for educational programs */
export type EducationalProgramsFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: EducationalProgramsFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type ElementFiltersInput = {
    /** When `null`, NO lessons are included. An empty list indicates all lessons should be included. */
    didacticTools?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
    /** When `null`, NO educational paths are included. An empty list indicates all educational paths should be included. */
    educationalPaths?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
    /** When `null`, NO modules are included. An empty list indicates all modules should be included. */
    modules?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

export type ExportActionDto = {
    __typename?: "ExportActionDto";
    id: Scalars["UUID"]["output"];
    status: Status;
    type: Type;
};

export type ExternalVideoAssetDto = AssetDto & {
    __typename?: "ExternalVideoAssetDto";
    autoplay: Scalars["Boolean"]["output"];
    createdAt: Scalars["LocalDateTime"]["output"];
    id: Scalars["UUID"]["output"];
    loop: Scalars["Boolean"]["output"];
    videoUrl: Scalars["String"]["output"];
};

export type FileAssetDto = AssetDto & {
    __typename?: "FileAssetDto";
    createdAt: Scalars["LocalDateTime"]["output"];
    fileName: Scalars["String"]["output"];
    id: Scalars["UUID"]["output"];
    mediaType: Scalars["String"]["output"];
    signedUrl?: Maybe<Scalars["String"]["output"]>;
};

export enum FontSet {
    AtkinsonLexend = "ATKINSON_LEXEND",
    RobotoRobotoslab = "ROBOTO_ROBOTOSLAB",
}

export type GroupDetailDto = {
    __typename?: "GroupDetailDto";
    externalCode?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    parentId?: Maybe<Scalars["UUID"]["output"]>;
    productIds: Array<Scalars["UUID"]["output"]>;
    showGroupDuringRegistration: Scalars["Boolean"]["output"];
};

export type GroupDto = {
    __typename?: "GroupDto";
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    parentId?: Maybe<Scalars["UUID"]["output"]>;
    showGroupDuringRegistration: Scalars["Boolean"]["output"];
    subgroups: Array<GroupDto>;
};

export enum GroupSortSelector {
    Id = "Id",
    Name = "Name",
}

export type GroupsDto = {
    __typename?: "GroupsDto";
    pagination: Pagination;
    rows: Array<GroupDto>;
};

export enum GroupsFieldSelector {
    /** Selector to search on the name of the educational program */
    Name = "Name",
    /** Selector to search on the parent id of the group */
    ParentId = "ParentId",
}

/** Filter for groups */
export type GroupsFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: GroupsFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type GroupsSortingInput = {
    direction: Direction;
    selector: GroupSortSelector;
};

export enum HotspotBlockLayout {
    Centered = "CENTERED",
    ImageLeft = "IMAGE_LEFT",
    ImageRight = "IMAGE_RIGHT",
}

export type HotspotBuildingBlock = {
    assetId: Scalars["UUID"]["output"];
    content?: Maybe<Scalars["String"]["output"]>;
    layout: HotspotBlockLayout;
    spots: Array<Spot>;
    title?: Maybe<Scalars["String"]["output"]>;
};

export type HotspotBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    HotspotBuildingBlock & {
        __typename?: "HotspotBuildingBlockDto";
        assetId: Scalars["UUID"]["output"];
        background: BuildingBlockBackground;
        content?: Maybe<Scalars["String"]["output"]>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: HotspotBlockLayout;
        order: Scalars["Long"]["output"];
        spots: Array<SpotDto>;
        title?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type HotspotBuildingBlockInput = {
    assetId: Scalars["UUID"]["input"];
    background: BuildingBlockBackground;
    content?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: HotspotBlockLayout;
    order: Scalars["Long"]["input"];
    spots: Array<SpotDtoInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    type: Scalars["String"]["input"];
};

export type InteractiveBuildingBlock = {
    correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
    hasHelpText: Scalars["Boolean"]["output"];
    incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
};

export type InteractiveBuildingBlockWithHelpText = {
    correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
    hasHelpText: Scalars["Boolean"]["output"];
    helpText?: Maybe<Scalars["String"]["output"]>;
    incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
};

export enum InteractiveQuestionBlockLayout {
    Default = "DEFAULT",
}

export type InteractiveQuestionBuildingBlock = {
    layout: InteractiveQuestionBlockLayout;
    question: BaseBuildingBlock;
    sceneWhenWrong: SceneBuildingBlock;
};

export type InteractiveQuestionBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    InteractiveQuestionBuildingBlock & {
        __typename?: "InteractiveQuestionBuildingBlockDto";
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: InteractiveQuestionBlockLayout;
        order: Scalars["Long"]["output"];
        question: BuildingBlockDto;
        sceneWhenWrong: SceneBuildingBlockDto;
        type: Scalars["String"]["output"];
    };

export type InteractiveQuestionBuildingBlockInput = {
    background: BuildingBlockBackground;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: InteractiveQuestionBlockLayout;
    order: Scalars["Long"]["input"];
    question: Scalars["BuildingBlockInput"]["input"];
    sceneWhenWrong: SceneBuildingBlockInput;
    type: Scalars["String"]["input"];
};

export enum Language {
    En = "EN",
    Nl = "NL",
}

export type LastViewedModuleDto = {
    __typename?: "LastViewedModuleDto";
    educationalPathId: Scalars["UUID"]["output"];
    module: AppModuleDto;
    programId: Scalars["UUID"]["output"];
};

export type LicenseDto = {
    __typename?: "LicenseDto";
    id: Scalars["UUID"]["output"];
    isActive: Scalars["Boolean"]["output"];
    product: ProductDto;
    startAt: Scalars["LocalDateTime"]["output"];
    user: UserDto;
    userExpirationSeenAt?: Maybe<Scalars["LocalDateTime"]["output"]>;
    validUntil?: Maybe<Scalars["LocalDateTime"]["output"]>;
};

export type LicensesDto = {
    __typename?: "LicensesDto";
    pagination: Pagination;
    rows: Array<LicenseDto>;
};

export enum LicensesFieldSelector {
    /** Selector to filter on the deleted at of the licenses */
    DeletedAt = "DeletedAt",
    /** Selector to search on the user id of the licenses */
    UserId = "UserId",
}

/** Filter for licenses */
export type LicensesFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: LicensesFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export enum LicensesSortSelector {
    ProductName = "ProductName",
}

export type LicensesSortingInput = {
    direction: Direction;
    selector: LicensesSortSelector;
};

export enum LinkBlockLayout {
    Default = "DEFAULT",
}

export type LinkBuildingBlock = {
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    instruction?: Maybe<Scalars["String"]["output"]>;
    layout: LinkBlockLayout;
    linkLabel?: Maybe<Scalars["String"]["output"]>;
    openInNewWindow: Scalars["Boolean"]["output"];
    title: Scalars["String"]["output"];
    url: Scalars["String"]["output"];
};

export type LinkBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    LinkBuildingBlock & {
        __typename?: "LinkBuildingBlockDto";
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        instruction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: LinkBlockLayout;
        linkLabel?: Maybe<Scalars["String"]["output"]>;
        openInNewWindow: Scalars["Boolean"]["output"];
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
        url: Scalars["String"]["output"];
    };

export type LinkBuildingBlockInput = {
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    background: BuildingBlockBackground;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    instruction?: InputMaybe<Scalars["String"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: LinkBlockLayout;
    linkLabel?: InputMaybe<Scalars["String"]["input"]>;
    openInNewWindow: Scalars["Boolean"]["input"];
    order: Scalars["Long"]["input"];
    title: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
    url: Scalars["String"]["input"];
};

export type Message = {
    message: Scalars["String"]["output"];
    personIndex: Scalars["Int"]["output"];
};

export type MessageDto = Message & {
    __typename?: "MessageDto";
    id: Scalars["UUID"]["output"];
    message: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
    personIndex: Scalars["Int"]["output"];
};

export type MessageInput = {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    message: Scalars["String"]["input"];
    order: Scalars["Int"]["input"];
    personIndex: Scalars["Int"]["input"];
};

export type ModuleBlockDidacticToolDto = {
    __typename?: "ModuleBlockDidacticToolDto";
    didacticTool?: Maybe<DidacticToolDto>;
    didacticToolId: Scalars["UUID"]["output"];
    order: Scalars["Int"]["output"];
    variant: ModuleBlockVariant;
};

export type ModuleBlockDto =
    | DidacticToolsModuleBlockDto
    | TitleModuleBlockDto
    | VideoModuleBlockDto;

export enum ModuleBlockVariant {
    Full = "FULL",
    FullLarge = "FULL_LARGE",
    Half = "HALF",
}

export type ModuleDto = {
    __typename?: "ModuleDto";
    blocks?: Maybe<Array<ModuleBlockDto>>;
    content?: Maybe<Scalars["String"]["output"]>;
    dashboardImageId?: Maybe<Scalars["UUID"]["output"]>;
    description: Scalars["String"]["output"];
    durationIndication?: Maybe<Scalars["String"]["output"]>;
    headerImageId?: Maybe<Scalars["UUID"]["output"]>;
    id: Scalars["UUID"]["output"];
    shortTitle?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
};

export type ModuleForEducationalPathDto = {
    __typename?: "ModuleForEducationalPathDto";
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
    shortTitle: Scalars["String"]["output"];
};

export type ModuleProgressDto = ProgressDto & {
    __typename?: "ModuleProgressDto";
    id: Scalars["UUID"]["output"];
    progressPercentage: Scalars["Int"]["output"];
};

export type ModulesDto = {
    __typename?: "ModulesDto";
    pagination: Pagination;
    rows: Array<ModuleDto>;
};

export enum ModulesFieldSelector {
    /** Selector to search on the title of the modules */
    Title = "Title",
}

/** Filter for modules */
export type ModulesFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: ModulesFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModulesForEducationalPathDto = {
    __typename?: "ModulesForEducationalPathDto";
    educationalPathId: Scalars["UUID"]["output"];
    modules: Array<ModuleForEducationalPathDto>;
};

export enum MoodboardBlockLayout {
    FullOverlay = "FULL_OVERLAY",
    SmallOverlay = "SMALL_OVERLAY",
}

export type MoodboardBuildingBlock = {
    images: Array<MoodboardImage>;
    layout: MoodboardBlockLayout;
};

export type MoodboardBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    MoodboardBuildingBlock & {
        __typename?: "MoodboardBuildingBlockDto";
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        images: Array<MoodboardImageDto>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: MoodboardBlockLayout;
        order: Scalars["Long"]["output"];
        type: Scalars["String"]["output"];
    };

export type MoodboardBuildingBlockInput = {
    background: BuildingBlockBackground;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    images: Array<MoodboardImageInput>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: MoodboardBlockLayout;
    order: Scalars["Long"]["input"];
    type: Scalars["String"]["input"];
};

export type MoodboardImage = {
    assetId: Scalars["UUID"]["output"];
    sortOrder: Scalars["Int"]["output"];
    text?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
};

export type MoodboardImageDto = MoodboardImage & {
    __typename?: "MoodboardImageDto";
    assetId: Scalars["UUID"]["output"];
    id: Scalars["UUID"]["output"];
    sortOrder: Scalars["Int"]["output"];
    text?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
};

export type MoodboardImageInput = {
    assetId: Scalars["UUID"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    sortOrder: Scalars["Int"]["input"];
    text?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MpcAnswer = {
    text?: Maybe<Scalars["String"]["output"]>;
};

export type MpcAnswerCorrectness = AnswerCorrectness &
    InteractiveBuildingBlock &
    InteractiveBuildingBlockWithHelpText & {
        __typename?: "MpcAnswerCorrectness";
        buildingBlockId: Scalars["UUID"]["output"];
        correct: Scalars["Boolean"]["output"];
        correctAnswers: Array<Scalars["UUID"]["output"]>;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        givenAnswers: Array<Scalars["UUID"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        helpText?: Maybe<Scalars["String"]["output"]>;
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        reasoning?: Maybe<Scalars["String"]["output"]>;
        remainingAttempts: Scalars["Int"]["output"];
        type: Scalars["String"]["output"];
    };

export enum MpcBlockLayout {
    ImageLeft = "IMAGE_LEFT",
    ImageRight = "IMAGE_RIGHT",
    TextCentered = "TEXT_CENTERED",
    TextCenteredThreeColumnGrid = "TEXT_CENTERED_THREE_COLUMN_GRID",
    TextCenteredTwoColumnGrid = "TEXT_CENTERED_TWO_COLUMN_GRID",
    TwoColumns = "TWO_COLUMNS",
}

export type MpcBuildingBlock = {
    answers: Array<MpcAnswer>;
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    instruction?: Maybe<Scalars["String"]["output"]>;
    introduction?: Maybe<Scalars["String"]["output"]>;
    layout: MpcBlockLayout;
    objectFit: ObjectFit;
    question: Scalars["String"]["output"];
    sorting: Sorting;
};

export type MpcBuildingBlockAnswerInput = {
    answerIds: Array<Scalars["UUID"]["input"]>;
    buildingBlockId: Scalars["UUID"]["input"];
    type: Scalars["String"]["input"];
};

export type MpcBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    InteractiveBuildingBlock &
    InteractiveBuildingBlockWithHelpText &
    MpcBuildingBlock & {
        __typename?: "MpcBuildingBlockDto";
        answers: Array<AnswerDto>;
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        helpText?: Maybe<Scalars["String"]["output"]>;
        id: Scalars["UUID"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        instruction?: Maybe<Scalars["String"]["output"]>;
        introduction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: MpcBlockLayout;
        objectFit: ObjectFit;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        sorting: Sorting;
        type: Scalars["String"]["output"];
    };

export type MpcBuildingBlockInput = {
    answers: Array<AnswerInput>;
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    background: BuildingBlockBackground;
    correctFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    helpText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    incorrectFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    instruction?: InputMaybe<Scalars["String"]["input"]>;
    introduction?: InputMaybe<Scalars["String"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: MpcBlockLayout;
    objectFit: ObjectFit;
    order: Scalars["Long"]["input"];
    question: Scalars["String"]["input"];
    sorting: Sorting;
    type: Scalars["String"]["input"];
};

export enum MpcChoiceType {
    Multiple = "MULTIPLE",
    Single = "SINGLE",
}

export type Mutation = {
    __typename?: "Mutation";
    /** Create a child group under a parent */
    createChildGroup: GroupDto;
    /** Create a new didactic tool */
    createDidacticTool?: Maybe<DidacticToolDto>;
    /** Create a new educational path */
    createEducationalPath?: Maybe<EducationalPathDto>;
    /** Create a new educational program */
    createEducationalProgram?: Maybe<EducationalProgramDto>;
    /** Create a new group */
    createGroup: GroupDetailDto;
    /** Create licenses for each given product for given users */
    createLicenses: Array<LicenseDto>;
    /** Create a new module */
    createModule: ModuleDto;
    /** Create a new product */
    createProduct: ProductDetailDto;
    /** Create a new reference card */
    createReferenceCard: ReferenceCardDto;
    /** Create a new role */
    createRole?: Maybe<RoleDetailsDto>;
    /** Create a new shortcode */
    createShortcode: ShortcodeDto;
    /** Create a new user */
    createUser?: Maybe<UserDetailDto>;
    /** Batch delete didactic tools */
    deleteDidacticTools: BulkActionResult;
    /** Batch delete educational paths */
    deleteEducationalPaths: BulkActionResult;
    /** Delete a group by its ID */
    deleteGroup: Scalars["Boolean"]["output"];
    /** Batch delete groups */
    deleteGroups: BulkActionResult;
    /** Batch delete licenses */
    deleteLicenses: BulkActionResult;
    /** Delete all existing licenses for the given product(s) and user(s) */
    deleteLicensesForProductAndUsers: BulkActionResult;
    /** Batch delete modules */
    deleteModules: BulkActionResult;
    /** Batch delete products */
    deleteProducts: BulkActionResult;
    /** Batch delete reference cards */
    deleteReferenceCards: BulkActionResult;
    /** Batch delete roles */
    deleteRoles: BulkActionResult;
    /** Delete an existing shortcode */
    deleteShortcode: Scalars["Boolean"]["output"];
    /** Delete users */
    deleteUsers: BulkActionResult;
    /** Stop the didactic tool to track the time the user has spent. */
    endDidacticTool: Scalars["Long"]["output"];
    /** Enroll the authenticated user in the group with the given group ID */
    enrollGroup: GroupDto;
    /** Issue a new tenant token for the tenant that has given [tenant] as it's name, but only if the authenticated user has access to that tenant. */
    issueTenantToken: TenantTokenDto;
    /** Mark the license expiration as seen by the user. */
    licenseExpirationSeen: LicenseDto;
    /** Register a new user */
    registerNewUser: AppUserDto;
    /** Request password resets for one or more users */
    requestPasswordResets: BulkActionResult;
    /** Start the didactic tool to track the time the user has spent. */
    startDidacticTool: Scalars["Boolean"]["output"];
    /** Start an async user export with progress for the selected filters. Use `checkExportAction` to check the status. */
    startUsersWithProgressExport: ExportActionDto;
    /** Verify the given JWT, create the user accordingly and return true. The client should then redirect the user onwards. */
    toegangOrgJwt: Scalars["Boolean"]["output"];
    /** Update the set of building blocks for a specific didactic tool */
    updateBuildingBlocksForDidacticTool: BuildingBlocksForDidacticToolDto;
    /** Update an existing child group */
    updateChildGroup: GroupDto;
    /** Update an existing didactic tool */
    updateDidacticTool?: Maybe<DidacticToolDto>;
    /** Create a new educational path */
    updateEducationalPath?: Maybe<EducationalPathDto>;
    /** Update the ordered list of educational paths for a program */
    updateEducationalPathsForProgram: EducationalPathsForProgramDto;
    /** Update the educational program with the id in given [educationalProgram] ([UpdateEducationalProgramInput.id]) with the details from the given [educationalProgram]. */
    updateEducationalProgram: EducationalProgramDto;
    /** Update a group */
    updateGroup: GroupDetailDto;
    /** Updates the specified licenses. Currently, only the 'isActive' field can be updated. This mutation returns the updated licenses. */
    updateLicenses: Array<LicenseDto>;
    /** Update a module */
    updateModule?: Maybe<ModuleDto>;
    /** Update the set of module blocks for a specific module */
    updateModuleBlocksForModule: Array<ModuleBlockDto>;
    /** Update the ordered list of modules for an educational path */
    updateModulesForEducationalPath: ModulesForEducationalPathDto;
    /** Update the user with the id in given input with the details from the given input. */
    updateProduct: ProductDetailDto;
    /** Update an existing reference card */
    updateReferenceCard: ReferenceCardDto;
    /** Update a role */
    updateRole?: Maybe<RoleDetailsDto>;
    /** Update an existing shortcode */
    updateShortcode: ShortcodeDto;
    /** Update the tenant with the id in given [tenant] ([UpdateTenantInput.id]) with the details from the given [tenant]. */
    updateTenant: TenantDto;
    /** Update the theme for an educational program */
    updateThemeForEducationalProgram: ProgramThemeDto;
    /** Update the user with the id in given [user] ([UpdateUserInput.id]) with the details from the given [user]. This call can be used for users to update themselves, or, given the correct permissions, to update other users as well. */
    updateUser: UserDetailDto;
};

export type MutationCreateChildGroupArgs = {
    input: CreateChildGroupInput;
};

export type MutationCreateDidacticToolArgs = {
    input: CreateDidacticToolInput;
};

export type MutationCreateEducationalPathArgs = {
    input: CreateEducationalPathInput;
};

export type MutationCreateEducationalProgramArgs = {
    input: CreateEducationalProgramInput;
};

export type MutationCreateGroupArgs = {
    input: CreateGroupInput;
};

export type MutationCreateLicensesArgs = {
    input: CreateLicensesInput;
};

export type MutationCreateModuleArgs = {
    input: CreateModuleInput;
};

export type MutationCreateProductArgs = {
    input: CreateProductInput;
};

export type MutationCreateReferenceCardArgs = {
    input: ReferenceCardInput;
};

export type MutationCreateRoleArgs = {
    input: CreateRoleInput;
};

export type MutationCreateShortcodeArgs = {
    input: CreateShortcodeInput;
};

export type MutationCreateUserArgs = {
    input: CreateUserInput;
};

export type MutationDeleteDidacticToolsArgs = {
    didacticTools: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteEducationalPathsArgs = {
    educationalPaths: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteGroupArgs = {
    groupId: Scalars["UUID"]["input"];
};

export type MutationDeleteGroupsArgs = {
    groups: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteLicensesArgs = {
    licenses: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteLicensesForProductAndUsersArgs = {
    productIds: Array<Scalars["UUID"]["input"]>;
    userIds: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteModulesArgs = {
    modules: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteProductsArgs = {
    products: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteReferenceCardsArgs = {
    ids: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteRolesArgs = {
    roles: Array<Scalars["UUID"]["input"]>;
};

export type MutationDeleteShortcodeArgs = {
    id: Scalars["UUID"]["input"];
};

export type MutationDeleteUsersArgs = {
    users: Array<Scalars["UUID"]["input"]>;
};

export type MutationEndDidacticToolArgs = {
    didacticToolId: Scalars["UUID"]["input"];
};

export type MutationEnrollGroupArgs = {
    groupId: Scalars["UUID"]["input"];
};

export type MutationIssueTenantTokenArgs = {
    tenant: Scalars["String"]["input"];
};

export type MutationLicenseExpirationSeenArgs = {
    id: Scalars["UUID"]["input"];
};

export type MutationRegisterNewUserArgs = {
    input: RegisterNewUserInput;
};

export type MutationRequestPasswordResetsArgs = {
    ids: Array<Scalars["UUID"]["input"]>;
};

export type MutationStartDidacticToolArgs = {
    didacticToolId: Scalars["UUID"]["input"];
};

export type MutationStartUsersWithProgressExportArgs = {
    filters: UserProgressFilterInput;
    language?: InputMaybe<Language>;
};

export type MutationToegangOrgJwtArgs = {
    jwt: Scalars["String"]["input"];
};

export type MutationUpdateBuildingBlocksForDidacticToolArgs = {
    input: UpdateBuildingBlocksForDidacticToolInput;
};

export type MutationUpdateChildGroupArgs = {
    input: UpdateChildGroupInput;
};

export type MutationUpdateDidacticToolArgs = {
    input: UpdateDidacticToolInput;
};

export type MutationUpdateEducationalPathArgs = {
    input: UpdateEducationalPathInput;
};

export type MutationUpdateEducationalPathsForProgramArgs = {
    input: UpdateEducationalPathsForProgramInput;
};

export type MutationUpdateEducationalProgramArgs = {
    input: UpdateEducationalProgramInput;
};

export type MutationUpdateGroupArgs = {
    input: UpdateGroupInput;
};

export type MutationUpdateLicensesArgs = {
    input: Array<UpdateLicenseInput>;
};

export type MutationUpdateModuleArgs = {
    input: UpdateModuleInput;
};

export type MutationUpdateModuleBlocksForModuleArgs = {
    input: UpdateModuleBlocksForModuleInput;
};

export type MutationUpdateModulesForEducationalPathArgs = {
    input: UpdateModulesForEducationalPathInput;
};

export type MutationUpdateProductArgs = {
    input: UpdateProductInput;
};

export type MutationUpdateReferenceCardArgs = {
    input: ReferenceCardInput;
};

export type MutationUpdateRoleArgs = {
    input: UpdateRoleInput;
};

export type MutationUpdateShortcodeArgs = {
    input: UpdateShortcodeInput;
};

export type MutationUpdateTenantArgs = {
    input: UpdateTenantInput;
};

export type MutationUpdateThemeForEducationalProgramArgs = {
    input: UpdateThemeForEducationalProgramInput;
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
};

export enum NeutralColorVariant {
    Cold = "COLD",
    Warm = "WARM",
}

export enum ObjectFit {
    Boxed = "BOXED",
    Full = "FULL",
}

export enum Operator {
    Equals = "EQUALS",
    GreaterOrEqual = "GREATER_OR_EQUAL",
    GreaterThan = "GREATER_THAN",
    In = "IN",
    IsNotNull = "IS_NOT_NULL",
    IsNull = "IS_NULL",
    LessOrEqual = "LESS_OR_EQUAL",
    LessThan = "LESS_THAN",
    Like = "LIKE",
    LikeIgnoreCase = "LIKE_IGNORE_CASE",
    NotEquals = "NOT_EQUALS",
}

export type Pagination = {
    __typename?: "Pagination";
    page: Scalars["Int"]["output"];
    pageSize: Scalars["Int"]["output"];
    totalCount: Scalars["Int"]["output"];
};

export type PaginationInput = {
    page: Scalars["Int"]["input"];
    pageSize: Scalars["Int"]["input"];
};

export type PermissionDto = {
    __typename?: "PermissionDto";
    enabled: Scalars["Boolean"]["output"];
    id: Scalars["UUID"]["output"];
    key: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
};

export type PermissionsDto = {
    __typename?: "PermissionsDto";
    pagination: Pagination;
    rows: Array<PermissionDto>;
};

export type Person = {
    name: Scalars["String"]["output"];
};

export type PersonDto = Person & {
    __typename?: "PersonDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    id: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    order: Scalars["Int"]["output"];
};

export type PersonInput = {
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    name: Scalars["String"]["input"];
    order: Scalars["Int"]["input"];
};

export type PollAnswer = {
    sortOrder: Scalars["Int"]["output"];
    text: Scalars["String"]["output"];
};

export type PollAnswerCorrectness = AnswerCorrectness &
    InteractiveBuildingBlock & {
        __typename?: "PollAnswerCorrectness";
        buildingBlockId: Scalars["UUID"]["output"];
        correct: Scalars["Boolean"]["output"];
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        givenAnswers: Array<Scalars["UUID"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        reasoning?: Maybe<Scalars["String"]["output"]>;
        remainingAttempts: Scalars["Int"]["output"];
        results: Array<PollAnswerResult>;
        type: Scalars["String"]["output"];
    };

export type PollAnswerDto = PollAnswer & {
    __typename?: "PollAnswerDto";
    id: Scalars["UUID"]["output"];
    sortOrder: Scalars["Int"]["output"];
    text: Scalars["String"]["output"];
};

export type PollAnswerInput = {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    sortOrder: Scalars["Int"]["input"];
    text: Scalars["String"]["input"];
};

export type PollAnswerResult = {
    __typename?: "PollAnswerResult";
    answerId: Scalars["UUID"]["output"];
    percentage: Scalars["Int"]["output"];
};

export enum PollBlockLayout {
    Default = "DEFAULT",
}

export type PollBuildingBlock = {
    answers: Array<PollAnswer>;
    instruction: Scalars["String"]["output"];
    introduction: Scalars["String"]["output"];
    layout: PollBlockLayout;
    question: Scalars["String"]["output"];
    voteOption: PollVoteOption;
};

export type PollBuildingBlockAnswerInput = {
    answers: Array<Scalars["UUID"]["input"]>;
    buildingBlockId: Scalars["UUID"]["input"];
    type: Scalars["String"]["input"];
};

export type PollBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    InteractiveBuildingBlock &
    PollBuildingBlock & {
        __typename?: "PollBuildingBlockDto";
        answers: Array<PollAnswerDto>;
        background: BuildingBlockBackground;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        id: Scalars["UUID"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        instruction: Scalars["String"]["output"];
        introduction: Scalars["String"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: PollBlockLayout;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
        voteOption: PollVoteOption;
    };

export type PollBuildingBlockInput = {
    answers: Array<PollAnswerInput>;
    background: BuildingBlockBackground;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    instruction: Scalars["String"]["input"];
    introduction: Scalars["String"]["input"];
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: PollBlockLayout;
    order: Scalars["Long"]["input"];
    question: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
    voteOption: PollVoteOption;
};

export enum PollVoteOption {
    Multiple = "MULTIPLE",
    Single = "SINGLE",
}

export type ProductDetailDto = {
    __typename?: "ProductDetailDto";
    educationalProgramIds: Array<Scalars["UUID"]["output"]>;
    externalIdentifier?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    isAvailable: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    prefix?: Maybe<Scalars["String"]["output"]>;
    validityPeriod?: Maybe<Scalars["Int"]["output"]>;
};

export type ProductDto = {
    __typename?: "ProductDto";
    educationalPrograms: Array<EducationalProgramDto>;
    externalIdentifier?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["UUID"]["output"];
    isAvailable: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    prefix?: Maybe<Scalars["String"]["output"]>;
    statistics?: Maybe<ProductStatisticsDto>;
    validityPeriod?: Maybe<Scalars["Int"]["output"]>;
};

export type ProductStatisticsDto = {
    __typename?: "ProductStatisticsDto";
    expired: Scalars["Int"]["output"];
    inactive: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
    valid: Scalars["Int"]["output"];
};

export type ProductsDto = {
    __typename?: "ProductsDto";
    pagination: Pagination;
    rows: Array<ProductDto>;
};

export enum ProductsFieldSelector {
    /** Id of the product */
    Id = "Id",
    /** Selector to search on the availability of the products */
    IsAvailable = "IsAvailable",
    /** Selector to search on the name of the products */
    Name = "Name",
    /** Selector to search on the program id of the products */
    ProgramId = "ProgramId",
}

/** Filter for modules */
export type ProductsFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: ProductsFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

/** Used in the CMS to allow setting null-values for various fields if no override is applicable */
export type ProgramThemeDto = {
    __typename?: "ProgramThemeDto";
    /** A set of CSS directives to configure the brand colors for the program, if any */
    brandColors?: Maybe<Scalars["String"]["output"]>;
    /** The set of font families for the program, if any */
    fonts?: Maybe<FontSet>;
    /** The variant of neutral (gray-tone) colors  for the program, if any */
    neutralColorsVariant?: Maybe<NeutralColorVariant>;
    programId: Scalars["UUID"]["output"];
    /** The border radiuses for the program, if any */
    radiuses?: Maybe<BorderRadius>;
};

export type ProgressDto = {
    progressPercentage: Scalars["Int"]["output"];
};

export type Query = {
    __typename?: "Query";
    /** Check the correctness of given list of answers for the didactic tool with given ID */
    checkBuildingBlockAnswers: Array<AnswerCorrectness>;
    /** Check the status of an export action with given ID */
    checkExportAction: ExportActionDto;
    /** Get the Algolia search key for the authenticated user */
    getAlgoliaSearchKeyForAuthenticatedUser?: Maybe<
        Scalars["String"]["output"]
    >;
    /** Get an asset by its ID. */
    getAssetById: AssetDto;
    /** Get the authenticated user (if any). This includes an ID that should be used for administering the user. */
    getAuthenticatedUser?: Maybe<UserDto>;
    /** Get the list of applicable authorities for the authenticated user */
    getAuthoritiesForAuthenticatedUser: Array<Scalars["String"]["output"]>;
    /** Check the correctness of given list of answers for the didactic tool with given ID */
    getBuildingBlockAnswersForDidacticTool: Array<AnswerCorrectness>;
    /** Get the list of building blocks (ordered) for the didactic tool with given ID */
    getBuildingBlocksForDidacticTool: BuildingBlocksForDidacticToolDto;
    /** Get the list of building blocks (ordered) for the didactic tool with given ID */
    getBuildingBlocksForDidacticToolForApp: AppBuildingBlocksForDidacticToolDto;
    /** Get a list of child groups for group with given ID, as long as the authenticated user has access to that given group */
    getChildGroupsForGroup: Array<GroupDto>;
    /** Get child groups for a group marked for registration (see also getGroupsMarkedForRegistration) */
    getChildGroupsForRegistration: Array<GroupDto>;
    /** Get a didactic tool by id */
    getDidacticTool?: Maybe<DidacticToolDto>;
    /** Get the duration of the didactic tool with given id for the current user. */
    getDidacticToolDuration?: Maybe<Scalars["Long"]["output"]>;
    /** Get the didactic tool with given id, as long as the user has access to that path. */
    getDidacticToolForApp: AppDidacticToolDto;
    /** Get the authenticated user's progress for a list of didactic tools with given IDs */
    getDidacticToolProgress: Array<DidacticToolProgressDto>;
    /** Get all didactic tools */
    getDidacticTools: DidacticToolsDto;
    /** Get the didactic tools with given moduleId, as long as the user has access to that path. */
    getDidacticToolsForApp: Array<AppDidacticToolDto>;
    /** Get the ordered list of didactic tools for the module with given id */
    getDidacticToolsForModule: DidacticToolsForModuleDto;
    /** Get an educational program by id */
    getEducationalPath?: Maybe<EducationalPathDto>;
    /** Get the educational path with given id, as long as the user has access to that path. */
    getEducationalPathForApp: AppEducationalPathDto;
    /** Get the authenticated user's progress for a list of educational paths with given IDs */
    getEducationalPathProgress: Array<EducationalPathProgressDto>;
    /** Get all educational paths */
    getEducationalPaths: EducationalPathsDto;
    /** Get a list of educational paths for a specific educational program which is applicable for the authenticated user. */
    getEducationalPathsForApp: Array<AppEducationalPathDto>;
    /** Get the ordered list of educational paths for the program with given ID */
    getEducationalPathsForProgram: EducationalPathsForProgramDto;
    /** Get an educational program by id */
    getEducationalProgram?: Maybe<EducationalProgramDto>;
    /** Get a specific educational program for a group */
    getEducationalProgramForGroup: AppEducationalProgramDto;
    /** Get all educational programs */
    getEducationalPrograms: EducationalProgramsDto;
    /** Get a list of educational programs that are applicable for the authenticated users. */
    getEducationalProgramsForApp: Array<AppEducationalProgramDto>;
    /** Get a list of educational programs that belongs to a group or the parent group. */
    getEducationalProgramsForGroup: Array<AppEducationalProgramDto>;
    /** Get the elements for a program. */
    getElementsForProgram: AppEducationalProgramElementsDto;
    /** Get a group by id */
    getGroup?: Maybe<GroupDetailDto>;
    /** Get a group by it's ID, so it can be managed */
    getGroupForApp: AppGroupDto;
    /** Get all groups */
    getGroups: GroupsDto;
    /** Get all groups for the authenticated user */
    getGroupsForAuthenticatedUser: Array<GroupDto>;
    /** Get groups marked for registration, without child groups (see also getChildGroupsForRegistration) */
    getGroupsMarkedForRegistration: Array<GroupDto>;
    /** Get the last viewed module for a given program id */
    getLastViewedModule?: Maybe<LastViewedModuleDto>;
    /** Get licenses */
    getLicenses: LicensesDto;
    /** Get a module by id */
    getModule: ModuleDto;
    /** Get the module blocks with the given moduleId, as long as the user has access to that path. */
    getModuleBlocksForApp: AppModuleBlocksForModuleDto;
    /** Get a module for a given module id */
    getModuleForApp: AppModuleDto;
    /** Get the authenticated user's progress for a list of modules with given IDs */
    getModuleProgress: Array<ModuleProgressDto>;
    /** Get all modules */
    getModules: ModulesDto;
    /** Get the ordered list of modules for the educational path with given id */
    getModulesForEducationalPath: ModulesForEducationalPathDto;
    /** Get a list of modules that are applicable for the authenticated users for a given educational path. */
    getModulesForEducationalPathForApp: Array<AppModuleDto>;
    /** Get all permissions */
    getPermissions: PermissionsDto;
    /**
     * Get a product by id
     * @deprecated Use getProducts instead
     */
    getProduct?: Maybe<ProductDetailDto>;
    /** Get all products */
    getProducts: ProductsDto;
    /** Get progress overview */
    getProgressOverview: AppUsersProgressDto;
    /** Get a reference card by its ID */
    getReferenceCard: ReferenceCardDto;
    /** Get all reference card categories */
    getReferenceCardCategories: Array<ReferenceCardCategoryDto>;
    /** Get all reference card leads */
    getReferenceCardLeads: Array<ReferenceCardLeadDto>;
    /** Get a page of reference cards */
    getReferenceCards: ReferenceCardsDto;
    /** Get reference cards filter options for the app */
    getReferenceCardsFilterOptionsForApp: AppReferenceCardsFilterOptionsDto;
    /** Get reference cards for a given program id */
    getReferenceCardsForApp: ReferenceCardsDto;
    /** Get a role by id */
    getRole?: Maybe<RoleDetailsDto>;
    /** Get all roles */
    getRoles: RolesDto;
    /** Get a single shortcode by it's id */
    getShortcode: ShortcodeDto;
    /** Get all shortcodes */
    getShortcodes: ShortcodesDto;
    /** Get a signed URL for the asset with given ID, as long as the user has access to that asset. */
    getSignedAssetUrl: Scalars["String"]["output"];
    /** Get a single tenant by it's name */
    getTenantByName: TenantDto;
    /** Get list of all tenants */
    getTenants: Array<TenantDto>;
    /** Get list of tenants available for the current user */
    getTenantsForUser: Array<TenantDto>;
    /** Get the theme for the given program ID, or fall back to the tenant theme if the program has no theme/the ID is omitted */
    getTheme: ThemeDto;
    /** Get the theme for an educational program */
    getThemeForEducationalProgram: ProgramThemeDto;
    /** Get a user by id */
    getUser: UserDetailDto;
    /** Get the user configuration */
    getUserConfiguration: UserConfigurationDto;
    /** Get all users */
    getUsers: UsersDto;
    /** Get all users applicable to become member of a child group of the specified parent group. */
    getUsersApplicableForChildGroup: Array<AppUserDto>;
    /** Get all users for the specified group. When userIds is provided, only the users with the specified IDs are returned. */
    getUsersForGroup: Array<AppUserDto>;
    /** Very simple ping-pong call to test basic GraphQL functionality */
    ping: Scalars["String"]["output"];
};

export type QueryCheckBuildingBlockAnswersArgs = {
    input: CheckAnswersForDidacticToolInput;
};

export type QueryCheckExportActionArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetAssetByIdArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetBuildingBlockAnswersForDidacticToolArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetBuildingBlocksForDidacticToolArgs = {
    didacticToolId: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetBuildingBlocksForDidacticToolForAppArgs = {
    didacticToolId: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetChildGroupsForGroupArgs = {
    groupId: Scalars["UUID"]["input"];
};

export type QueryGetChildGroupsForRegistrationArgs = {
    groupId: Scalars["UUID"]["input"];
};

export type QueryGetDidacticToolArgs = {
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetDidacticToolDurationArgs = {
    didacticToolId: Scalars["UUID"]["input"];
};

export type QueryGetDidacticToolForAppArgs = {
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetDidacticToolProgressArgs = {
    ids: Array<Scalars["UUID"]["input"]>;
};

export type QueryGetDidacticToolsArgs = {
    filters?: InputMaybe<Array<DidacticToolsFilterInput>>;
    paginationInput: PaginationInput;
};

export type QueryGetDidacticToolsForAppArgs = {
    educationalPathId?: InputMaybe<Scalars["UUID"]["input"]>;
    language?: InputMaybe<Language>;
    moduleId: Scalars["UUID"]["input"];
    programId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type QueryGetDidacticToolsForModuleArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetEducationalPathArgs = {
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetEducationalPathForAppArgs = {
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetEducationalPathProgressArgs = {
    ids: Array<Scalars["UUID"]["input"]>;
};

export type QueryGetEducationalPathsArgs = {
    filters?: InputMaybe<Array<EducationalPathsFilterInput>>;
    paginationInput: PaginationInput;
};

export type QueryGetEducationalPathsForAppArgs = {
    educationalPathIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
    educationalProgramId: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetEducationalPathsForProgramArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetEducationalProgramArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetEducationalProgramForGroupArgs = {
    groupId: Scalars["UUID"]["input"];
    programId: Scalars["UUID"]["input"];
};

export type QueryGetEducationalProgramsArgs = {
    filters?: InputMaybe<Array<EducationalProgramsFilterInput>>;
    paginationInput: PaginationInput;
};

export type QueryGetEducationalProgramsForGroupArgs = {
    groupId: Scalars["UUID"]["input"];
};

export type QueryGetElementsForProgramArgs = {
    filters?: InputMaybe<AppEducationalProgramElementsFilterInput>;
    programId: Scalars["UUID"]["input"];
};

export type QueryGetGroupArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetGroupForAppArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetGroupsArgs = {
    filters?: InputMaybe<Array<GroupsFilterInput>>;
    paginationInput: PaginationInput;
    sorting?: InputMaybe<Array<GroupsSortingInput>>;
};

export type QueryGetLastViewedModuleArgs = {
    programId: Scalars["UUID"]["input"];
};

export type QueryGetLicensesArgs = {
    filters?: InputMaybe<Array<LicensesFilterInput>>;
    paginationInput: PaginationInput;
};

export type QueryGetModuleArgs = {
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetModuleBlocksForAppArgs = {
    language?: InputMaybe<Language>;
    moduleId: Scalars["UUID"]["input"];
};

export type QueryGetModuleForAppArgs = {
    language?: InputMaybe<Language>;
    moduleId: Scalars["UUID"]["input"];
};

export type QueryGetModuleProgressArgs = {
    ids: Array<Scalars["UUID"]["input"]>;
};

export type QueryGetModulesArgs = {
    filters?: InputMaybe<Array<ModulesFilterInput>>;
    paginationInput: PaginationInput;
};

export type QueryGetModulesForEducationalPathArgs = {
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetModulesForEducationalPathForAppArgs = {
    educationalPathId: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetPermissionsArgs = {
    paginationInput: PaginationInput;
};

export type QueryGetProductArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetProductsArgs = {
    filters?: InputMaybe<Array<ProductsFilterInput>>;
    paginationInput: PaginationInput;
};

export type QueryGetProgressOverviewArgs = {
    filters: UserProgressFilterInput;
    paginationInput: PaginationInput;
    sorting?: InputMaybe<Array<UsersSortingInput>>;
};

export type QueryGetReferenceCardArgs = {
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type QueryGetReferenceCardCategoriesArgs = {
    language?: InputMaybe<Language>;
};

export type QueryGetReferenceCardLeadsArgs = {
    language?: InputMaybe<Language>;
};

export type QueryGetReferenceCardsArgs = {
    filters?: InputMaybe<Array<ReferenceCardsFilterInput>>;
    language?: InputMaybe<Language>;
    pagination: PaginationInput;
};

export type QueryGetReferenceCardsFilterOptionsForAppArgs = {
    programId: Scalars["UUID"]["input"];
};

export type QueryGetReferenceCardsForAppArgs = {
    filters?: InputMaybe<Array<ReferenceCardsFilterInput>>;
    pagination: PaginationInput;
    programId: Scalars["UUID"]["input"];
};

export type QueryGetRoleArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetRolesArgs = {
    filters?: InputMaybe<Array<RolesFilterInput>>;
    paginationInput: PaginationInput;
};

export type QueryGetShortcodeArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetShortcodesArgs = {
    paginationInput: PaginationInput;
};

export type QueryGetSignedAssetUrlArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetTenantByNameArgs = {
    name: Scalars["String"]["input"];
};

export type QueryGetThemeArgs = {
    programId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type QueryGetThemeForEducationalProgramArgs = {
    programId: Scalars["UUID"]["input"];
};

export type QueryGetUserArgs = {
    id: Scalars["UUID"]["input"];
};

export type QueryGetUserConfigurationArgs = {
    licensesSorting?: InputMaybe<Array<LicensesSortingInput>>;
};

export type QueryGetUsersArgs = {
    filters?: InputMaybe<Array<UsersFilterInput>>;
    paginationInput: PaginationInput;
    sorting?: InputMaybe<Array<UsersSortingInput>>;
};

export type QueryGetUsersApplicableForChildGroupArgs = {
    parentGroupId: Scalars["UUID"]["input"];
};

export type QueryGetUsersForGroupArgs = {
    groupId: Scalars["UUID"]["input"];
    userIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

export type QuestionConnectAnswerCorrectness = AnswerCorrectness &
    InteractiveBuildingBlock & {
        __typename?: "QuestionConnectAnswerCorrectness";
        buildingBlockId: Scalars["UUID"]["output"];
        correct: Scalars["Boolean"]["output"];
        correctAnswers: Array<Maybe<Scalars["UUID"]["output"]>>;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        givenAnswers: Array<Scalars["UUID"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        reasoning?: Maybe<Scalars["String"]["output"]>;
        remainingAttempts: Scalars["Int"]["output"];
        type: Scalars["String"]["output"];
    };

export enum QuestionConnectBlockLayout {
    Centered = "CENTERED",
    TwoColumns = "TWO_COLUMNS",
}

export type QuestionConnectBuildingBlock = {
    instruction: Scalars["String"]["output"];
    introduction?: Maybe<Scalars["String"]["output"]>;
    items: Array<QuestionConnectItem>;
    layout: QuestionConnectBlockLayout;
    question: Scalars["String"]["output"];
};

export type QuestionConnectBuildingBlockAnswerInput = {
    answerIds: Array<Scalars["UUID"]["input"]>;
    buildingBlockId: Scalars["UUID"]["input"];
    type: Scalars["String"]["input"];
};

export type QuestionConnectBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    InteractiveBuildingBlock &
    QuestionConnectBuildingBlock & {
        __typename?: "QuestionConnectBuildingBlockDto";
        background: BuildingBlockBackground;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        id: Scalars["UUID"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        instruction: Scalars["String"]["output"];
        introduction?: Maybe<Scalars["String"]["output"]>;
        items: Array<QuestionConnectItemDto>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: QuestionConnectBlockLayout;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type QuestionConnectBuildingBlockInput = {
    background: BuildingBlockBackground;
    correctFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    incorrectFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    instruction: Scalars["String"]["input"];
    introduction?: InputMaybe<Scalars["String"]["input"]>;
    items: Array<QuestionConnectItemInput>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: QuestionConnectBlockLayout;
    order: Scalars["Long"]["input"];
    question: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
};

export type QuestionConnectCorrectAnswerInput = {
    content: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type QuestionConnectItem = {
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    buildingBlockId?: Maybe<Scalars["UUID"]["output"]>;
    content?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["UUID"]["output"]>;
};

export type QuestionConnectItemAnswerDto = QuestionConnectItem & {
    __typename?: "QuestionConnectItemAnswerDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    buildingBlockId?: Maybe<Scalars["UUID"]["output"]>;
    content?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["UUID"]["output"]>;
};

export type QuestionConnectItemDto = QuestionConnectItem & {
    __typename?: "QuestionConnectItemDto";
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    buildingBlockId?: Maybe<Scalars["UUID"]["output"]>;
    content?: Maybe<Scalars["String"]["output"]>;
    correctAnswer: QuestionConnectItemAnswerDto;
    id?: Maybe<Scalars["UUID"]["output"]>;
};

export type QuestionConnectItemInput = {
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    buildingBlockId?: InputMaybe<Scalars["UUID"]["input"]>;
    content?: InputMaybe<Scalars["String"]["input"]>;
    correctAnswer?: InputMaybe<QuestionConnectCorrectAnswerInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export enum QuoteBlockLayout {
    ImageLeft = "IMAGE_LEFT",
    ImageRight = "IMAGE_RIGHT",
}

export type QuoteBuildingBlock = {
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    layout: QuoteBlockLayout;
    name?: Maybe<Scalars["String"]["output"]>;
    quote: Scalars["String"]["output"];
};

export type QuoteBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    QuoteBuildingBlock & {
        __typename?: "QuoteBuildingBlockDto";
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: QuoteBlockLayout;
        name?: Maybe<Scalars["String"]["output"]>;
        order: Scalars["Long"]["output"];
        quote: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type QuoteBuildingBlockInput = {
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    background: BuildingBlockBackground;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: QuoteBlockLayout;
    name?: InputMaybe<Scalars["String"]["input"]>;
    order: Scalars["Long"]["input"];
    quote: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
};

export type ReferenceCardCategoryDto = ReferenceCardLabelDto & {
    __typename?: "ReferenceCardCategoryDto";
    id: Scalars["UUID"]["output"];
    label: Scalars["String"]["output"];
};

export type ReferenceCardCategoryInput = {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label: Scalars["String"]["input"];
    language?: InputMaybe<Language>;
};

export type ReferenceCardDto = {
    __typename?: "ReferenceCardDto";
    /** @deprecated The content of reference cards should be configured in building blocks */
    body?: Maybe<Scalars["String"]["output"]>;
    buildingBlocks: Array<BuildingBlockDto>;
    categories: Array<ReferenceCardCategoryDto>;
    description?: Maybe<Scalars["String"]["output"]>;
    didacticTools: Array<DidacticToolDto>;
    headerImage?: Maybe<Scalars["UUID"]["output"]>;
    id: Scalars["UUID"]["output"];
    lead?: Maybe<ReferenceCardLeadDto>;
    programs: Array<EducationalProgramDto>;
    thumbnail: Scalars["UUID"]["output"];
    title: Scalars["String"]["output"];
};

export type ReferenceCardInput = {
    buildingBlocks: Array<Scalars["BuildingBlockInput"]["input"]>;
    categories: Array<ReferenceCardCategoryInput>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    headerImage: Scalars["UUID"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    language?: InputMaybe<Language>;
    lead?: InputMaybe<ReferenceCardLeadInput>;
    programs: Array<Scalars["UUID"]["input"]>;
    thumbnail: Scalars["UUID"]["input"];
    title: Scalars["String"]["input"];
};

export type ReferenceCardLabelDto = {
    id: Scalars["UUID"]["output"];
    label: Scalars["String"]["output"];
};

export type ReferenceCardLeadDto = ReferenceCardLabelDto & {
    __typename?: "ReferenceCardLeadDto";
    id: Scalars["UUID"]["output"];
    label: Scalars["String"]["output"];
};

export type ReferenceCardLeadInput = {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label: Scalars["String"]["input"];
    language?: InputMaybe<Language>;
};

export enum ReferenceCardsCarouselBlockLayout {
    Default = "DEFAULT",
}

export type ReferenceCardsCarouselBuildingBlock = {
    layout: ReferenceCardsCarouselBlockLayout;
    title: Scalars["String"]["output"];
};

export type ReferenceCardsCarouselBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    ReferenceCardsCarouselBuildingBlock & {
        __typename?: "ReferenceCardsCarouselBuildingBlockDto";
        background: BuildingBlockBackground;
        cards: Array<ReferenceCardDto>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: ReferenceCardsCarouselBlockLayout;
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type ReferenceCardsCarouselBuildingBlockInput = {
    background: BuildingBlockBackground;
    cards: Array<SortableReferenceCardIdInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: ReferenceCardsCarouselBlockLayout;
    order: Scalars["Long"]["input"];
    title: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
};

export type ReferenceCardsDto = {
    __typename?: "ReferenceCardsDto";
    pagination: Pagination;
    rows: Array<ReferenceCardDto>;
};

export enum ReferenceCardsFieldSelector {
    /** Selector to filter on the category id of the reference card */
    Category = "Category",
    /** Selector to filter if the reference card is deleted. This one is enabled by default. */
    Deleted = "Deleted",
    /** Selector to filter on the educational program id of the reference card */
    EducationalProgram = "EducationalProgram",
    /** Selector to filter on the id of the reference card */
    Id = "Id",
    /** Selector to filter on the leads of the reference cards */
    Lead = "Lead",
    /** Selector to search on title of the reference card */
    Title = "Title",
}

/** Filter for reference cards */
export type ReferenceCardsFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: ReferenceCardsFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type RegisterNewUserInput = {
    dateOfBirth?: InputMaybe<Scalars["LocalDateTime"]["input"]>;
    emailAddress: Scalars["String"]["input"];
    firstName: Scalars["String"]["input"];
    groupId: Scalars["UUID"]["input"];
    lastName: Scalars["String"]["input"];
};

export type RoleDetailsDto = {
    __typename?: "RoleDetailsDto";
    id: Scalars["UUID"]["output"];
    keycloakId: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
    permissions: Array<Scalars["UUID"]["output"]>;
};

export type RoleDto = {
    __typename?: "RoleDto";
    id: Scalars["UUID"]["output"];
    keycloakId: Scalars["UUID"]["output"];
    name: Scalars["String"]["output"];
};

export type RolesDto = {
    __typename?: "RolesDto";
    pagination: Pagination;
    rows: Array<RoleDto>;
};

export enum RolesFieldSelector {
    /** Selector to filter on the keycloak id of the role */
    KeycloakId = "KeycloakId",
    /** Selector to filter on the name of the role */
    Name = "Name",
}

/** Filter for roles */
export type RolesFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: RolesFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SceneBlockLayout {
    Default = "DEFAULT",
}

export type SceneBuildingBlock = {
    assetId: Scalars["UUID"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    layout: SceneBlockLayout;
    title: Scalars["String"]["output"];
};

export type SceneBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    SceneBuildingBlock & {
        __typename?: "SceneBuildingBlockDto";
        assetId: Scalars["UUID"]["output"];
        background: BuildingBlockBackground;
        description?: Maybe<Scalars["String"]["output"]>;
        id: Scalars["UUID"]["output"];
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: SceneBlockLayout;
        order: Scalars["Long"]["output"];
        title: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type SceneBuildingBlockInput = {
    assetId: Scalars["UUID"]["input"];
    background: BuildingBlockBackground;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: SceneBlockLayout;
    order: Scalars["Long"]["input"];
    title: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
};

export type ShortcodeDto = {
    __typename?: "ShortcodeDto";
    didacticTool?: Maybe<DidacticToolDto>;
    educationalPath?: Maybe<EducationalPathDto>;
    educationalProgram: EducationalProgramDto;
    id: Scalars["UUID"]["output"];
    module?: Maybe<ModuleDto>;
    shortcode: Scalars["String"]["output"];
};

export type ShortcodesDto = {
    __typename?: "ShortcodesDto";
    pagination: Pagination;
    rows: Array<ShortcodeDto>;
};

export type SortQuestionAnswer = {
    text: Scalars["String"]["output"];
};

export type SortQuestionAnswerCorrectness = AnswerCorrectness &
    InteractiveBuildingBlock & {
        __typename?: "SortQuestionAnswerCorrectness";
        buildingBlockId: Scalars["UUID"]["output"];
        correct: Scalars["Boolean"]["output"];
        correctAnswers: Array<Scalars["UUID"]["output"]>;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        givenAnswers: Array<Scalars["UUID"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        reasoning?: Maybe<Scalars["String"]["output"]>;
        remainingAttempts: Scalars["Int"]["output"];
        type: Scalars["String"]["output"];
    };

export type SortQuestionAnswerDto = SortQuestionAnswer & {
    __typename?: "SortQuestionAnswerDto";
    id: Scalars["UUID"]["output"];
    order?: Maybe<Scalars["Int"]["output"]>;
    text: Scalars["String"]["output"];
};

export type SortQuestionAnswerInput = {
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    order?: InputMaybe<Scalars["Int"]["input"]>;
    text: Scalars["String"]["input"];
};

export enum SortQuestionBlockLayout {
    ImageLeft = "IMAGE_LEFT",
    ImageRight = "IMAGE_RIGHT",
    TextCentered = "TEXT_CENTERED",
    TwoColumns = "TWO_COLUMNS",
}

export type SortQuestionBuildingBlock = {
    answers: Array<SortQuestionAnswer>;
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    instruction?: Maybe<Scalars["String"]["output"]>;
    introduction?: Maybe<Scalars["String"]["output"]>;
    layout: SortQuestionBlockLayout;
    objectFit: ObjectFit;
    question: Scalars["String"]["output"];
};

export type SortQuestionBuildingBlockAnswerInput = {
    answerIds: Array<Scalars["UUID"]["input"]>;
    buildingBlockId: Scalars["UUID"]["input"];
    type: Scalars["String"]["input"];
};

export type SortQuestionBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    InteractiveBuildingBlock &
    SortQuestionBuildingBlock & {
        __typename?: "SortQuestionBuildingBlockDto";
        answers: Array<SortQuestionAnswerDto>;
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        hasHelpText: Scalars["Boolean"]["output"];
        id: Scalars["UUID"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        instruction?: Maybe<Scalars["String"]["output"]>;
        introduction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: SortQuestionBlockLayout;
        objectFit: ObjectFit;
        order: Scalars["Long"]["output"];
        question: Scalars["String"]["output"];
        type: Scalars["String"]["output"];
    };

export type SortQuestionBuildingBlockInput = {
    answers: Array<SortQuestionAnswerInput>;
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    background: BuildingBlockBackground;
    correctFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    incorrectFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    instruction?: InputMaybe<Scalars["String"]["input"]>;
    introduction?: InputMaybe<Scalars["String"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: SortQuestionBlockLayout;
    objectFit: ObjectFit;
    order: Scalars["Long"]["input"];
    question: Scalars["String"]["input"];
    type: Scalars["String"]["input"];
};

export type SortableEntryInput = {
    id: Scalars["UUID"]["input"];
    order: Scalars["Int"]["input"];
};

export type SortableReferenceCardIdInput = {
    id: Scalars["UUID"]["input"];
    order: Scalars["Int"]["input"];
};

export enum Sorting {
    AsPresented = "AS_PRESENTED",
    Randomized = "RANDOMIZED",
}

export type Spot = {
    content?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
    x: Scalars["Float"]["output"];
    y: Scalars["Float"]["output"];
};

export type SpotDto = Spot & {
    __typename?: "SpotDto";
    content?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["UUID"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
    x: Scalars["Float"]["output"];
    y: Scalars["Float"]["output"];
};

export type SpotDtoInput = {
    content?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    x: Scalars["Float"]["input"];
    y: Scalars["Float"]["input"];
};

export enum Stand {
    Agree = "AGREE",
    Disagree = "DISAGREE",
}

export type StandAnswerCorrectness = AnswerCorrectness &
    InteractiveBuildingBlock & {
        __typename?: "StandAnswerCorrectness";
        buildingBlockId: Scalars["UUID"]["output"];
        correct: Scalars["Boolean"]["output"];
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        correctOption: Stand;
        givenAnswer: Stand;
        hasHelpText: Scalars["Boolean"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        reasoning?: Maybe<Scalars["String"]["output"]>;
        remainingAttempts: Scalars["Int"]["output"];
        type: Scalars["String"]["output"];
    };

export enum StandBlockLayout {
    ImageLeft = "IMAGE_LEFT",
    ImageRight = "IMAGE_RIGHT",
    TextCentered = "TEXT_CENTERED",
    TextLeft = "TEXT_LEFT",
}

export type StandBuildingBlock = {
    agreeText: Scalars["String"]["output"];
    assetId?: Maybe<Scalars["UUID"]["output"]>;
    disagreeText: Scalars["String"]["output"];
    instruction?: Maybe<Scalars["String"]["output"]>;
    layout: StandBlockLayout;
    stand: Scalars["String"]["output"];
    text?: Maybe<Scalars["String"]["output"]>;
};

export type StandBuildingBlockAnswerInput = {
    answer: Stand;
    buildingBlockId: Scalars["UUID"]["input"];
    type: Scalars["String"]["input"];
};

export type StandBuildingBlockDto = BaseBuildingBlock &
    BuildingBlockDto &
    InteractiveBuildingBlock &
    StandBuildingBlock & {
        __typename?: "StandBuildingBlockDto";
        agreeText: Scalars["String"]["output"];
        assetId?: Maybe<Scalars["UUID"]["output"]>;
        background: BuildingBlockBackground;
        correctFeedbackText?: Maybe<Scalars["String"]["output"]>;
        correctOption: Stand;
        disagreeText: Scalars["String"]["output"];
        hasHelpText: Scalars["Boolean"]["output"];
        id: Scalars["UUID"]["output"];
        incorrectFeedbackText?: Maybe<Scalars["String"]["output"]>;
        instruction?: Maybe<Scalars["String"]["output"]>;
        label?: Maybe<BuildingBlocksLabelDto>;
        layout: StandBlockLayout;
        order: Scalars["Long"]["output"];
        stand: Scalars["String"]["output"];
        text?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type StandBuildingBlockInput = {
    agreeText: Scalars["String"]["input"];
    assetId?: InputMaybe<Scalars["UUID"]["input"]>;
    background: BuildingBlockBackground;
    correctFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    correctOption: Stand;
    disagreeText: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    incorrectFeedbackText?: InputMaybe<Scalars["String"]["input"]>;
    instruction?: InputMaybe<Scalars["String"]["input"]>;
    label?: InputMaybe<BuildingBlocksLabelInput>;
    layout: StandBlockLayout;
    order: Scalars["Long"]["input"];
    stand: Scalars["String"]["input"];
    text?: InputMaybe<Scalars["String"]["input"]>;
    type: Scalars["String"]["input"];
};

export enum Status {
    Completed = "COMPLETED",
    InProgress = "IN_PROGRESS",
    NoLongerAvailable = "NO_LONGER_AVAILABLE",
}

export type TenantDto = {
    __typename?: "TenantDto";
    allowRegistration: Scalars["Boolean"]["output"];
    defaultGroupId?: Maybe<Scalars["UUID"]["output"]>;
    defaultProductId?: Maybe<Scalars["UUID"]["output"]>;
    defaultRoleId: Scalars["UUID"]["output"];
    id: Scalars["UUID"]["output"];
    languages: Array<Language>;
    name: Scalars["String"]["output"];
    primaryLanguage: Language;
    theme: ThemeDto;
};

export type TenantTokenDto = {
    __typename?: "TenantTokenDto";
    tenant: TenantDto;
    token: Scalars["String"]["output"];
};

export type ThemeDto = {
    __typename?: "ThemeDto";
    /** A set of CSS directives to configure the brand colors */
    brandColors: Scalars["String"]["output"];
    /** The set of font families used throughout the application */
    fonts: FontSet;
    /** The variant of neutral (gray-tone) colors used throughout the application */
    neutralColorsVariant: NeutralColorVariant;
    /** The border radiuses used throughout the application */
    radiuses: BorderRadius;
};

export type ThemeDtoInput = {
    /** A set of CSS directives to configure the brand colors */
    brandColors: Scalars["String"]["input"];
    /** The set of font families used throughout the application */
    fonts: FontSet;
    /** The variant of neutral (gray-tone) colors used throughout the application */
    neutralColorsVariant: NeutralColorVariant;
    /** The border radiuses used throughout the application */
    radiuses: BorderRadius;
};

export type TitleModuleBlock = {
    description?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
};

export type TitleModuleBlockDto = BaseModuleBlock &
    TitleModuleBlock & {
        __typename?: "TitleModuleBlockDto";
        description?: Maybe<Scalars["String"]["output"]>;
        id?: Maybe<Scalars["UUID"]["output"]>;
        order: Scalars["Int"]["output"];
        title?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type TitleModuleBlockInput = {
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    order: Scalars["Int"]["input"];
    title?: InputMaybe<Scalars["String"]["input"]>;
    type: Scalars["String"]["input"];
};

export enum Type {
    AppUsersWithProgress = "APP_USERS_WITH_PROGRESS",
}

export type UpdateBuildingBlocksForDidacticToolInput = {
    buildingBlocks: Array<Scalars["BuildingBlockInput"]["input"]>;
    didacticToolId: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
};

export type UpdateChildGroupInput = {
    id: Scalars["UUID"]["input"];
    memberIds: Array<Scalars["UUID"]["input"]>;
    name: Scalars["String"]["input"];
};

export type UpdateDidacticToolInput = {
    altLabel?: InputMaybe<Scalars["String"]["input"]>;
    description: Scalars["String"]["input"];
    durationIndication?: InputMaybe<Scalars["String"]["input"]>;
    headerBackground?: InputMaybe<DidacticToolBackground>;
    headerImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    headerIntroText?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    label?: InputMaybe<DidacticToolLabelType>;
    language?: InputMaybe<Language>;
    learningAdviceDescription?: InputMaybe<Scalars["String"]["input"]>;
    learningAdviceTitle?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    type: DidacticToolType;
};

export type UpdateEducationalPathInput = {
    dashboardImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    description: Scalars["String"]["input"];
    durationIndication?: InputMaybe<Scalars["String"]["input"]>;
    headerImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    headerIntroText?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
    name: Scalars["String"]["input"];
};

export type UpdateEducationalPathsForProgramInput = {
    educationalPaths: Array<SortableEntryInput>;
    programId: Scalars["UUID"]["input"];
};

export type UpdateEducationalProgramInput = {
    description: Scalars["String"]["input"];
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
};

export type UpdateGroupInput = {
    externalCode?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    parentId?: InputMaybe<Scalars["UUID"]["input"]>;
    productIds: Array<Scalars["UUID"]["input"]>;
    showGroupDuringRegistration: Scalars["Boolean"]["input"];
};

export type UpdateLicenseInput = {
    id: Scalars["UUID"]["input"];
    isActive: Scalars["Boolean"]["input"];
};

export type UpdateModuleBlocksForModuleInput = {
    language?: InputMaybe<Language>;
    moduleBlocks: Array<Scalars["ModuleBlockInput"]["input"]>;
    moduleId: Scalars["UUID"]["input"];
};

export type UpdateModuleInput = {
    content?: InputMaybe<Scalars["String"]["input"]>;
    dashboardImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    description: Scalars["String"]["input"];
    durationIndication?: InputMaybe<Scalars["String"]["input"]>;
    headerImageId?: InputMaybe<Scalars["UUID"]["input"]>;
    id: Scalars["UUID"]["input"];
    language?: InputMaybe<Language>;
    shortTitle?: InputMaybe<Scalars["String"]["input"]>;
    title: Scalars["String"]["input"];
};

export type UpdateModulesForEducationalPathInput = {
    educationalPathId: Scalars["UUID"]["input"];
    modules: Array<SortableEntryInput>;
};

export type UpdateProductInput = {
    educationalProgramIds: Array<Scalars["UUID"]["input"]>;
    externalIdentifier?: InputMaybe<Scalars["String"]["input"]>;
    id: Scalars["UUID"]["input"];
    isAvailable: Scalars["Boolean"]["input"];
    name: Scalars["String"]["input"];
    prefix?: InputMaybe<Scalars["String"]["input"]>;
    validityPeriod?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateRoleInput = {
    id: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    permissions: Array<Scalars["UUID"]["input"]>;
};

export type UpdateShortcodeInput = {
    didacticToolId?: InputMaybe<Scalars["UUID"]["input"]>;
    educationalPathId?: InputMaybe<Scalars["UUID"]["input"]>;
    educationalProgramId: Scalars["UUID"]["input"];
    id: Scalars["UUID"]["input"];
    moduleId?: InputMaybe<Scalars["UUID"]["input"]>;
    shortCode: Scalars["String"]["input"];
};

export type UpdateTenantInput = {
    allowRegistration: Scalars["Boolean"]["input"];
    defaultGroupId: Scalars["UUID"]["input"];
    defaultProductId?: InputMaybe<Scalars["UUID"]["input"]>;
    defaultRoleId: Scalars["UUID"]["input"];
    name: Scalars["String"]["input"];
    theme: ThemeDtoInput;
};

export type UpdateThemeForEducationalProgramInput = {
    brandColors?: InputMaybe<Scalars["String"]["input"]>;
    fonts?: InputMaybe<FontSet>;
    neutralColorsVariant?: InputMaybe<NeutralColorVariant>;
    programId: Scalars["UUID"]["input"];
    radiuses?: InputMaybe<BorderRadius>;
};

export type UpdateUserInput = {
    emailAddress: Scalars["String"]["input"];
    firstName: Scalars["String"]["input"];
    groupIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
    id: Scalars["UUID"]["input"];
    isActive: Scalars["Boolean"]["input"];
    lastName: Scalars["String"]["input"];
    roleId: Scalars["UUID"]["input"];
    tenantIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

export type UserConfigurationDto = {
    __typename?: "UserConfigurationDto";
    algoliaSearchKey?: Maybe<Scalars["String"]["output"]>;
    authorities: Array<Scalars["String"]["output"]>;
    licenses: Array<LicenseDto>;
    user: UserDto;
};

export type UserDetailDto = {
    __typename?: "UserDetailDto";
    createdAt: Scalars["LocalDateTime"]["output"];
    emailAddress: Scalars["String"]["output"];
    firstName: Scalars["String"]["output"];
    groupIds: Array<Scalars["UUID"]["output"]>;
    id: Scalars["UUID"]["output"];
    isActive: Scalars["Boolean"]["output"];
    keycloakId: Scalars["UUID"]["output"];
    lastName: Scalars["String"]["output"];
    modifiedAt?: Maybe<Scalars["LocalDateTime"]["output"]>;
    modifiedBy?: Maybe<Scalars["String"]["output"]>;
    roleId: Scalars["UUID"]["output"];
    tenantIds: Array<Scalars["UUID"]["output"]>;
};

export type UserDto = {
    __typename?: "UserDto";
    createdAt: Scalars["LocalDateTime"]["output"];
    emailAddress: Scalars["String"]["output"];
    firstName: Scalars["String"]["output"];
    id: Scalars["UUID"]["output"];
    isActive: Scalars["Boolean"]["output"];
    lastName: Scalars["String"]["output"];
};

export type UserProgressFilterInput = {
    /** Filter for specific educational paths, modules and lessons. */
    elements: ElementFiltersInput;
    group: Scalars["UUID"]["input"];
    program: Scalars["UUID"]["input"];
    /** An empty list indicates all users for the group should be included. */
    users: Array<Scalars["UUID"]["input"]>;
};

export enum UserSortSelector {
    CreatedAt = "createdAt",
    EmailAddress = "emailAddress",
    FirstName = "firstName",
    LastName = "lastName",
}

export type UsersDto = {
    __typename?: "UsersDto";
    pagination: Pagination;
    rows: Array<UserDto>;
};

export enum UsersFieldSelector {
    /** Selector to filter on the is active field of the user */
    Active = "Active",
    /** Selector to filter on the deleted field of the user */
    Deleted = "Deleted",
    /** Selector to filter on multiple fields (firstName+lastName or email) of the user */
    FirstNameLastNameOrEmailAddress = "FirstNameLastNameOrEmailAddress",
    /** Selector to filter on the group id field of the user */
    GroupId = "GroupId",
    /** Selector to filter on the id field of the user */
    Id = "Id",
    /** Selector to filter on the role id field of the user */
    RoleId = "RoleId",
}

/** Filter for users */
export type UsersFilterInput = {
    operator: Operator;
    orNull?: InputMaybe<Scalars["Boolean"]["input"]>;
    selector: UsersFieldSelector;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type UsersSortingInput = {
    direction: Direction;
    selector: UserSortSelector;
};

export type VideoModuleBlock = {
    assetId: Scalars["UUID"]["output"];
    durationIndication?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
};

export type VideoModuleBlockDto = BaseModuleBlock &
    VideoModuleBlock & {
        __typename?: "VideoModuleBlockDto";
        assetId: Scalars["UUID"]["output"];
        durationIndication?: Maybe<Scalars["String"]["output"]>;
        id?: Maybe<Scalars["UUID"]["output"]>;
        order: Scalars["Int"]["output"];
        title?: Maybe<Scalars["String"]["output"]>;
        type: Scalars["String"]["output"];
    };

export type VideoModuleBlockInput = {
    assetId: Scalars["UUID"]["input"];
    durationIndication?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    order: Scalars["Int"]["input"];
    title?: InputMaybe<Scalars["String"]["input"]>;
    type: Scalars["String"]["input"];
};

export type GroupFieldsFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    parentId?: string | null;
    showGroupDuringRegistration: boolean;
};

export type SubgroupFieldsFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    parentId?: string | null;
    showGroupDuringRegistration: boolean;
};

export type SubgroupsRecursiveFragment = {
    __typename?: "GroupDto";
    subgroups: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        parentId?: string | null;
        showGroupDuringRegistration: boolean;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            parentId?: string | null;
            showGroupDuringRegistration: boolean;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                parentId?: string | null;
                showGroupDuringRegistration: boolean;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    parentId?: string | null;
                    showGroupDuringRegistration: boolean;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        parentId?: string | null;
                        showGroupDuringRegistration: boolean;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                            parentId?: string | null;
                            showGroupDuringRegistration: boolean;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type CheckBuildingBlockAnswersQueryVariables = Exact<{
    input: CheckAnswersForDidacticToolInput;
}>;

export type CheckBuildingBlockAnswersQuery = {
    __typename?: "Query";
    checkBuildingBlockAnswers: Array<
        | {
              __typename?: "MpcAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctAnswers: Array<string>;
              givenAnswers: Array<string>;
              remainingAttempts: number;
              hasHelpText: boolean;
              helpText?: string | null;
              incorrectFeedbackText?: string | null;
              correctFeedbackText?: string | null;
          }
        | {
              __typename?: "PollAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
          }
        | {
              __typename?: "QuestionConnectAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctFeedbackText?: string | null;
              givenAnswers: Array<string>;
              hasHelpText: boolean;
              incorrectFeedbackText?: string | null;
              remainingAttempts: number;
              correctAnswersForQuestionConnect: Array<string | null>;
          }
        | {
              __typename?: "SortQuestionAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctFeedbackText?: string | null;
              givenAnswers: Array<string>;
              hasHelpText: boolean;
              incorrectFeedbackText?: string | null;
              remainingAttempts: number;
              correctAnswersForSortQuestion: Array<string>;
          }
        | {
              __typename?: "StandAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctOption: Stand;
              givenAnswer: Stand;
              hasHelpText: boolean;
              incorrectFeedbackText?: string | null;
              correctFeedbackText?: string | null;
          }
    >;
};

export type CheckExportActionQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type CheckExportActionQuery = {
    __typename?: "Query";
    checkExportAction: {
        __typename: "ExportActionDto";
        id: string;
        status: Status;
    };
};

export type CreateChildGroupMutationVariables = Exact<{
    input: CreateChildGroupInput;
}>;

export type CreateChildGroupMutation = {
    __typename?: "Mutation";
    createChildGroup: {
        __typename?: "GroupDto";
        id: string;
        name: string;
        parentId?: string | null;
    };
};

export type CreateDidacticToolMutationVariables = Exact<{
    input: CreateDidacticToolInput;
}>;

export type CreateDidacticToolMutation = {
    __typename?: "Mutation";
    createDidacticTool?: {
        __typename?: "DidacticToolDto";
        description: string;
        headerImageId?: string | null;
        headerIntroText?: string | null;
        learningAdviceTitle?: string | null;
        learningAdviceDescription?: string | null;
        id: string;
        name: string;
        type: DidacticToolType;
        altLabel?: string | null;
    } | null;
};

export type CreateEducationalPathMutationVariables = Exact<{
    input: CreateEducationalPathInput;
}>;

export type CreateEducationalPathMutation = {
    __typename?: "Mutation";
    createEducationalPath?: {
        __typename?: "EducationalPathDto";
        id: string;
        description: string;
        headerIntroText?: string | null;
        name: string;
    } | null;
};

export type CreateEducationalProgramMutationVariables = Exact<{
    input: CreateEducationalProgramInput;
}>;

export type CreateEducationalProgramMutation = {
    __typename?: "Mutation";
    createEducationalProgram?: {
        __typename?: "EducationalProgramDto";
        id: string;
        description: string;
        name: string;
    } | null;
};

export type CreateGroupMutationVariables = Exact<{
    input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
    __typename?: "Mutation";
    createGroup: {
        __typename?: "GroupDetailDto";
        id: string;
        name: string;
        showGroupDuringRegistration: boolean;
        externalCode?: string | null;
    };
};

export type CreateLicensesMutationVariables = Exact<{
    input: CreateLicensesInput;
}>;

export type CreateLicensesMutation = {
    __typename?: "Mutation";
    createLicenses: Array<{
        __typename?: "LicenseDto";
        validUntil?: any | null;
        startAt: any;
        user: { __typename?: "UserDto"; firstName: string; lastName: string };
    }>;
};

export type CreateModuleMutationVariables = Exact<{
    input: CreateModuleInput;
}>;

export type CreateModuleMutation = {
    __typename?: "Mutation";
    createModule: {
        __typename?: "ModuleDto";
        id: string;
        content?: string | null;
        dashboardImageId?: string | null;
        description: string;
        durationIndication?: string | null;
        headerImageId?: string | null;
        shortTitle?: string | null;
        title: string;
    };
};

export type CreateProductMutationVariables = Exact<{
    input: CreateProductInput;
}>;

export type CreateProductMutation = {
    __typename?: "Mutation";
    createProduct: {
        __typename?: "ProductDetailDto";
        id: string;
        educationalProgramIds: Array<string>;
        name: string;
        isAvailable: boolean;
        externalIdentifier?: string | null;
        validityPeriod?: number | null;
        prefix?: string | null;
    };
};

export type CreateReferenceCardMutationVariables = Exact<{
    data: ReferenceCardInput;
}>;

export type CreateReferenceCardMutation = {
    __typename?: "Mutation";
    createReferenceCard: {
        __typename: "ReferenceCardDto";
        id: string;
        headerImage?: string | null;
        thumbnail: string;
        title: string;
        description?: string | null;
        body?: string | null;
    };
};

export type CreateRoleMutationVariables = Exact<{
    input: CreateRoleInput;
}>;

export type CreateRoleMutation = {
    __typename?: "Mutation";
    createRole?: {
        __typename?: "RoleDetailsDto";
        id: string;
        keycloakId: string;
        name: string;
    } | null;
};

export type CreateShortcodeMutationVariables = Exact<{
    input: CreateShortcodeInput;
}>;

export type CreateShortcodeMutation = {
    __typename?: "Mutation";
    createShortcode: {
        __typename?: "ShortcodeDto";
        id: string;
        shortcode: string;
        educationalProgram: {
            __typename?: "EducationalProgramDto";
            id: string;
        };
        educationalPath?: {
            __typename?: "EducationalPathDto";
            id: string;
        } | null;
        module?: { __typename?: "ModuleDto"; id: string } | null;
        didacticTool?: { __typename?: "DidacticToolDto"; id: string } | null;
    };
};

export type CreateUserMutationVariables = Exact<{
    input: CreateUserInput;
}>;

export type CreateUserMutation = {
    __typename?: "Mutation";
    createUser?: {
        __typename?: "UserDetailDto";
        id: string;
        firstName: string;
        lastName: string;
        emailAddress: string;
    } | null;
};

export type DeleteDidacticToolsMutationVariables = Exact<{
    didacticTools: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteDidacticToolsMutation = {
    __typename?: "Mutation";
    deleteDidacticTools: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type DeleteEducationalPathsMutationVariables = Exact<{
    educationalPaths:
        | Array<Scalars["UUID"]["input"]>
        | Scalars["UUID"]["input"];
}>;

export type DeleteEducationalPathsMutation = {
    __typename?: "Mutation";
    deleteEducationalPaths: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type DeleteGroupMutationVariables = Exact<{
    groupId: Scalars["UUID"]["input"];
}>;

export type DeleteGroupMutation = {
    __typename?: "Mutation";
    deleteGroup: boolean;
};

export type DeleteGroupsMutationVariables = Exact<{
    groups: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteGroupsMutation = {
    __typename?: "Mutation";
    deleteGroups: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type DeleteLicensesForProductAndUsersMutationVariables = Exact<{
    productIds: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
    userIds: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteLicensesForProductAndUsersMutation = {
    __typename?: "Mutation";
    deleteLicensesForProductAndUsers: {
        __typename?: "BulkActionResult";
        successful: Array<string>;
        failed: Array<string>;
    };
};

export type DeleteLicensesForUserInCmsMutationVariables = Exact<{
    license: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteLicensesForUserInCmsMutation = {
    __typename?: "Mutation";
    deleteLicenses: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type DeleteModulesMutationVariables = Exact<{
    modules: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteModulesMutation = {
    __typename?: "Mutation";
    deleteModules: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type DeleteProductsMutationVariables = Exact<{
    products: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteProductsMutation = {
    __typename?: "Mutation";
    deleteProducts: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type DeleteReferenceCardsMutationVariables = Exact<{
    ids: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteReferenceCardsMutation = {
    __typename?: "Mutation";
    deleteReferenceCards: {
        __typename?: "BulkActionResult";
        successful: Array<string>;
        failed: Array<string>;
    };
};

export type DeleteRolesMutationVariables = Exact<{
    roles: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteRolesMutation = {
    __typename?: "Mutation";
    deleteRoles: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type DeleteShortcodeMutationVariables = Exact<{
    shortcode: Scalars["UUID"]["input"];
}>;

export type DeleteShortcodeMutation = {
    __typename?: "Mutation";
    deleteShortcode: boolean;
};

export type DeleteUsersMutationVariables = Exact<{
    users: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type DeleteUsersMutation = {
    __typename?: "Mutation";
    deleteUsers: {
        __typename?: "BulkActionResult";
        failed: Array<string>;
        successful: Array<string>;
    };
};

export type EndDidacticToolMutationVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type EndDidacticToolMutation = {
    __typename?: "Mutation";
    endDidacticTool: number;
};

export type EnrollGroupMutationVariables = Exact<{
    groupId: Scalars["UUID"]["input"];
}>;

export type EnrollGroupMutation = {
    __typename?: "Mutation";
    enrollGroup: { __typename?: "GroupDto"; name: string };
};

export type GetAlgoliaKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GetAlgoliaKeyQuery = {
    __typename?: "Query";
    getAlgoliaSearchKeyForAuthenticatedUser?: string | null;
};

export type GetAssetByIdQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetAssetByIdQuery = {
    __typename?: "Query";
    getAssetById:
        | {
              __typename: "ExternalVideoAssetDto";
              videoUrl: string;
              autoplay: boolean;
              loop: boolean;
              id: string;
              createdAt: any;
          }
        | {
              __typename: "FileAssetDto";
              fileName: string;
              mediaType: string;
              signedUrl?: string | null;
              id: string;
              createdAt: any;
          };
};

export type GetAuthenticatedUserQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetAuthenticatedUserQuery = {
    __typename?: "Query";
    getAuthenticatedUser?: {
        __typename?: "UserDto";
        id: string;
        firstName: string;
        lastName: string;
        emailAddress: string;
    } | null;
};

export type GetAuthenticatedUserAuthoritiesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetAuthenticatedUserAuthoritiesQuery = {
    __typename?: "Query";
    getAuthoritiesForAuthenticatedUser: Array<string>;
};

export type GetAuthenticatedUserForCreateGroupQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetAuthenticatedUserForCreateGroupQuery = {
    __typename?: "Query";
    getAuthenticatedUser?: {
        __typename?: "UserDto";
        id: string;
        firstName: string;
        lastName: string;
    } | null;
};

export type MpcAnswerCorrectnessFragment = {
    __typename?: "MpcAnswerCorrectness";
    buildingBlockId: string;
    correct: boolean;
    correctAnswers: Array<string>;
    givenAnswers: Array<string>;
    remainingAttempts: number;
    reasoning?: string | null;
    type: string;
    hasHelpText: boolean;
    helpText?: string | null;
    incorrectFeedbackText?: string | null;
    correctFeedbackText?: string | null;
};

export type StandAnswerCorrectnessFragment = {
    __typename?: "StandAnswerCorrectness";
    correctOption: Stand;
    givenAnswer: Stand;
    buildingBlockId: string;
    correct: boolean;
    reasoning?: string | null;
    type: string;
    hasHelpText: boolean;
    incorrectFeedbackText?: string | null;
    correctFeedbackText?: string | null;
};

export type QuestionConnectAnswerCorrectnessFragment = {
    __typename?: "QuestionConnectAnswerCorrectness";
    buildingBlockId: string;
    correct: boolean;
    correctFeedbackText?: string | null;
    givenAnswers: Array<string>;
    hasHelpText: boolean;
    incorrectFeedbackText?: string | null;
    reasoning?: string | null;
    remainingAttempts: number;
    type: string;
    correctAnswersForQuestionConnect: Array<string | null>;
};

export type SortQuestionAnswerCorrectnessFragment = {
    __typename?: "SortQuestionAnswerCorrectness";
    buildingBlockId: string;
    correct: boolean;
    correctFeedbackText?: string | null;
    givenAnswers: Array<string>;
    hasHelpText: boolean;
    incorrectFeedbackText?: string | null;
    reasoning?: string | null;
    remainingAttempts: number;
    type: string;
    correctAnswersForSortQuestion: Array<string>;
};

export type PollQuestionAnswerCorrectnessFragment = {
    __typename?: "PollAnswerCorrectness";
    buildingBlockId: string;
    correct: boolean;
    correctFeedbackText?: string | null;
    givenAnswers: Array<string>;
    hasHelpText: boolean;
    incorrectFeedbackText?: string | null;
    reasoning?: string | null;
    remainingAttempts: number;
    type: string;
    results: Array<{
        __typename?: "PollAnswerResult";
        answerId: string;
        percentage: number;
    }>;
};

export type GetBuildingBlockAnswersForDidacticToolQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetBuildingBlockAnswersForDidacticToolQuery = {
    __typename?: "Query";
    getBuildingBlockAnswersForDidacticTool: Array<
        | {
              __typename?: "MpcAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctAnswers: Array<string>;
              givenAnswers: Array<string>;
              remainingAttempts: number;
              hasHelpText: boolean;
              helpText?: string | null;
              incorrectFeedbackText?: string | null;
              correctFeedbackText?: string | null;
          }
        | {
              __typename?: "PollAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctFeedbackText?: string | null;
              givenAnswers: Array<string>;
              hasHelpText: boolean;
              incorrectFeedbackText?: string | null;
              remainingAttempts: number;
              results: Array<{
                  __typename?: "PollAnswerResult";
                  answerId: string;
                  percentage: number;
              }>;
          }
        | {
              __typename?: "QuestionConnectAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctFeedbackText?: string | null;
              givenAnswers: Array<string>;
              hasHelpText: boolean;
              incorrectFeedbackText?: string | null;
              remainingAttempts: number;
              correctAnswersForQuestionConnect: Array<string | null>;
          }
        | {
              __typename?: "SortQuestionAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctFeedbackText?: string | null;
              givenAnswers: Array<string>;
              hasHelpText: boolean;
              incorrectFeedbackText?: string | null;
              remainingAttempts: number;
              correctAnswersForSortQuestion: Array<string>;
          }
        | {
              __typename?: "StandAnswerCorrectness";
              buildingBlockId: string;
              correct: boolean;
              reasoning?: string | null;
              type: string;
              correctOption: Stand;
              givenAnswer: Stand;
              hasHelpText: boolean;
              incorrectFeedbackText?: string | null;
              correctFeedbackText?: string | null;
          }
    >;
};

export type ContentBuildingBlockFragment = {
    __typename?: "ContentBuildingBlockDto";
    id: string;
    type: string;
    background: BuildingBlockBackground;
    order: number;
    assetId?: string | null;
    contentColumnOne?: string | null;
    contentColumnTwo?: string | null;
    objectFit: ObjectFit;
    textLayout: ContentBlockLayout;
    textBackground: BuildingBlockBackground;
    list?: {
        __typename?: "ContentBlockListDto";
        layout: ContentBlockListLayout;
        items: Array<{
            __typename?: "ContentBlockListItemDto";
            id?: string | null;
            assetId?: string | null;
            content: string;
            subtitle: string;
            title: string;
            sortOrder: number;
        }>;
    } | null;
};

export type MpcBuildingBlockFragment = {
    __typename?: "MpcBuildingBlockDto";
    id: string;
    assetId?: string | null;
    question: string;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    introduction?: string | null;
    helpText?: string | null;
    correctFeedbackText?: string | null;
    incorrectFeedbackText?: string | null;
    sorting: Sorting;
    objectFit: ObjectFit;
    mpcInstruction?: string | null;
    mpcLayout: MpcBlockLayout;
    answers: Array<{
        __typename?: "AnswerDto";
        id: string;
        correct: boolean;
        text?: string | null;
        assetId?: string | null;
        content?: string | null;
        sortOrder: number;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type StandBuildingBlockFragment = {
    __typename?: "StandBuildingBlockDto";
    agreeText: string;
    assetId?: string | null;
    correctOption: Stand;
    disagreeText: string;
    stand: string;
    id: string;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    correctFeedbackText?: string | null;
    incorrectFeedbackText?: string | null;
    standText?: string | null;
    standInstruction?: string | null;
    standLayout: StandBlockLayout;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type MoodboardBuildingBlockFragment = {
    __typename?: "MoodboardBuildingBlockDto";
    id: string;
    type: string;
    order: number;
    background: BuildingBlockBackground;
    moodboardLayout: MoodboardBlockLayout;
    images: Array<{
        __typename?: "MoodboardImageDto";
        id: string;
        assetId: string;
        title?: string | null;
        text?: string | null;
    }>;
};

export type ConversationBuildingBlockFragment = {
    __typename?: "ConversationBuildingBlockDto";
    id: string;
    interval: number;
    background: BuildingBlockBackground;
    order: number;
    type: string;
    conversationLayout: ConversationBlockLayout;
    messages: Array<{
        __typename?: "MessageDto";
        id: string;
        message: string;
        order: number;
        personIndex: number;
    }>;
    persons: Array<{
        __typename?: "PersonDto";
        assetId?: string | null;
        id: string;
        name: string;
        order: number;
    }>;
};

export type QuoteBuildingBlockFragment = {
    __typename?: "QuoteBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    quote: string;
    name?: string | null;
    assetId?: string | null;
    quoteLayout: QuoteBlockLayout;
};

export type SortQuestionBuildingBlockFragment = {
    __typename?: "SortQuestionBuildingBlockDto";
    id: string;
    type: string;
    order: number;
    background: BuildingBlockBackground;
    introduction?: string | null;
    question: string;
    correctFeedbackText?: string | null;
    incorrectFeedbackText?: string | null;
    assetId?: string | null;
    objectFit: ObjectFit;
    sortQuestionInstruction?: string | null;
    sortQuestionLayout: SortQuestionBlockLayout;
    answers: Array<{
        __typename?: "SortQuestionAnswerDto";
        id: string;
        order?: number | null;
        sortAnswerText: string;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type QuestionConnectBuildingBlockFragment = {
    __typename?: "QuestionConnectBuildingBlockDto";
    id: string;
    instruction: string;
    correctFeedbackText?: string | null;
    incorrectFeedbackText?: string | null;
    introduction?: string | null;
    layout: QuestionConnectBlockLayout;
    question: string;
    background: BuildingBlockBackground;
    order: number;
    type: string;
    items: Array<{
        __typename?: "QuestionConnectItemDto";
        id?: string | null;
        assetId?: string | null;
        buildingBlockId?: string | null;
        content?: string | null;
        correctAnswer: {
            __typename?: "QuestionConnectItemAnswerDto";
            id?: string | null;
            assetId?: string | null;
            buildingBlockId?: string | null;
            content?: string | null;
        };
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type CarouselBuildingBlockFragment = {
    __typename?: "CarouselBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    title: string;
    carouselLayout: CarouselBlockLayout;
    blocks: Array<
        | {
              __typename?: "CarouselBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "ContentBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              background: BuildingBlockBackground;
              assetId?: string | null;
              contentColumnOne?: string | null;
              contentColumnTwo?: string | null;
              objectFit: ObjectFit;
              textLayout: ContentBlockLayout;
              textBackground: BuildingBlockBackground;
              list?: {
                  __typename?: "ContentBlockListDto";
                  layout: ContentBlockListLayout;
                  items: Array<{
                      __typename?: "ContentBlockListItemDto";
                      id?: string | null;
                      assetId?: string | null;
                      content: string;
                      subtitle: string;
                      title: string;
                      sortOrder: number;
                  }>;
              } | null;
          }
        | {
              __typename?: "ConversationBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              interval: number;
              background: BuildingBlockBackground;
              conversationLayout: ConversationBlockLayout;
              messages: Array<{
                  __typename?: "MessageDto";
                  id: string;
                  message: string;
                  order: number;
                  personIndex: number;
              }>;
              persons: Array<{
                  __typename?: "PersonDto";
                  assetId?: string | null;
                  id: string;
                  name: string;
                  order: number;
              }>;
          }
        | {
              __typename?: "HotspotBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "InteractiveQuestionBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "LinkBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "MoodboardBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              background: BuildingBlockBackground;
              moodboardLayout: MoodboardBlockLayout;
              images: Array<{
                  __typename?: "MoodboardImageDto";
                  id: string;
                  assetId: string;
                  title?: string | null;
                  text?: string | null;
              }>;
          }
        | {
              __typename?: "MpcBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              assetId?: string | null;
              question: string;
              background: BuildingBlockBackground;
              introduction?: string | null;
              helpText?: string | null;
              correctFeedbackText?: string | null;
              incorrectFeedbackText?: string | null;
              sorting: Sorting;
              objectFit: ObjectFit;
              mpcInstruction?: string | null;
              mpcLayout: MpcBlockLayout;
              answers: Array<{
                  __typename?: "AnswerDto";
                  id: string;
                  correct: boolean;
                  text?: string | null;
                  assetId?: string | null;
                  content?: string | null;
                  sortOrder: number;
              }>;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | {
              __typename?: "PollBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "QuestionConnectBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              instruction: string;
              correctFeedbackText?: string | null;
              incorrectFeedbackText?: string | null;
              introduction?: string | null;
              layout: QuestionConnectBlockLayout;
              question: string;
              background: BuildingBlockBackground;
              items: Array<{
                  __typename?: "QuestionConnectItemDto";
                  id?: string | null;
                  assetId?: string | null;
                  buildingBlockId?: string | null;
                  content?: string | null;
                  correctAnswer: {
                      __typename?: "QuestionConnectItemAnswerDto";
                      id?: string | null;
                      assetId?: string | null;
                      buildingBlockId?: string | null;
                      content?: string | null;
                  };
              }>;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | {
              __typename?: "QuoteBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "ReferenceCardsCarouselBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "SceneBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "SortQuestionBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "StandBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              agreeText: string;
              assetId?: string | null;
              correctOption: Stand;
              disagreeText: string;
              stand: string;
              background: BuildingBlockBackground;
              correctFeedbackText?: string | null;
              incorrectFeedbackText?: string | null;
              standText?: string | null;
              standInstruction?: string | null;
              standLayout: StandBlockLayout;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
    >;
};

export type HotspotBuildingBlockFragment = {
    __typename?: "HotspotBuildingBlockDto";
    id: string;
    content?: string | null;
    background: BuildingBlockBackground;
    order: number;
    type: string;
    hotspotBuildingBlockAssetId: string;
    hotspotLayout: HotspotBlockLayout;
    hotspotTitle?: string | null;
    spots: Array<{
        __typename?: "SpotDto";
        content?: string | null;
        id?: string | null;
        title?: string | null;
        x: number;
        y: number;
    }>;
};

export type ReferenceCardBuildingBlockFragment = {
    __typename?: "ReferenceCardsCarouselBuildingBlockDto";
    id: string;
    type: string;
    background: BuildingBlockBackground;
    order: number;
    title: string;
    referenceCardlayout: ReferenceCardsCarouselBlockLayout;
    cards: Array<{
        __typename?: "ReferenceCardDto";
        headerImage?: string | null;
        thumbnail: string;
        id: string;
        title: string;
        description?: string | null;
    }>;
};

export type SceneBuildingBlockFragment = {
    __typename?: "SceneBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    title: string;
    description?: string | null;
    sceneAssetId: string;
};

export type LinkBuildingBlockFragment = {
    __typename?: "LinkBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    title: string;
    url: string;
    linkLabel?: string | null;
    openInNewWindow: boolean;
    background: BuildingBlockBackground;
    linkInstruction?: string | null;
    linkAssetId?: string | null;
};

export type PollBuildingBlockFragment = {
    __typename?: "PollBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    voteOption: PollVoteOption;
    pollInstruction: string;
    pollIntroduction: string;
    pollQuestion: string;
    pollAnswers: Array<{
        __typename?: "PollAnswerDto";
        id: string;
        text: string;
        sortOrder: number;
    }>;
};

export type InteractiveQuestionBuildingBlockFragment = {
    __typename?: "InteractiveQuestionBuildingBlockDto";
    background: BuildingBlockBackground;
    id: string;
    type: string;
    order: number;
    interactiveQuestionLayout: InteractiveQuestionBlockLayout;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
    interactiveQuestionQuestion:
        | { __typename?: "CarouselBuildingBlockDto" }
        | { __typename?: "ContentBuildingBlockDto" }
        | { __typename?: "ConversationBuildingBlockDto" }
        | { __typename?: "HotspotBuildingBlockDto" }
        | { __typename?: "InteractiveQuestionBuildingBlockDto" }
        | { __typename?: "LinkBuildingBlockDto" }
        | { __typename?: "MoodboardBuildingBlockDto" }
        | {
              __typename?: "MpcBuildingBlockDto";
              id: string;
              assetId?: string | null;
              question: string;
              order: number;
              type: string;
              background: BuildingBlockBackground;
              introduction?: string | null;
              helpText?: string | null;
              correctFeedbackText?: string | null;
              incorrectFeedbackText?: string | null;
              sorting: Sorting;
              objectFit: ObjectFit;
              mpcInstruction?: string | null;
              mpcLayout: MpcBlockLayout;
              answers: Array<{
                  __typename?: "AnswerDto";
                  id: string;
                  correct: boolean;
                  text?: string | null;
                  assetId?: string | null;
                  content?: string | null;
                  sortOrder: number;
              }>;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | { __typename?: "PollBuildingBlockDto" }
        | { __typename?: "QuestionConnectBuildingBlockDto" }
        | { __typename?: "QuoteBuildingBlockDto" }
        | { __typename?: "ReferenceCardsCarouselBuildingBlockDto" }
        | { __typename?: "SceneBuildingBlockDto" }
        | { __typename?: "SortQuestionBuildingBlockDto" }
        | {
              __typename?: "StandBuildingBlockDto";
              agreeText: string;
              assetId?: string | null;
              correctOption: Stand;
              disagreeText: string;
              stand: string;
              id: string;
              order: number;
              type: string;
              background: BuildingBlockBackground;
              correctFeedbackText?: string | null;
              incorrectFeedbackText?: string | null;
              standText?: string | null;
              standInstruction?: string | null;
              standLayout: StandBlockLayout;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          };
    sceneWhenWrong: {
        __typename?: "SceneBuildingBlockDto";
        id: string;
        order: number;
        type: string;
        title: string;
        description?: string | null;
        sceneAssetId: string;
    };
};

export type GetBuildingBlocksForDidacticToolQueryVariables = Exact<{
    didacticToolId: Scalars["UUID"]["input"];
}>;

export type GetBuildingBlocksForDidacticToolQuery = {
    __typename?: "Query";
    getBuildingBlocksForDidacticTool: {
        __typename?: "BuildingBlocksForDidacticToolDto";
        buildingBlocks: Array<
            | {
                  __typename?: "CarouselBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  title: string;
                  carouselLayout: CarouselBlockLayout;
                  blocks: Array<
                      | {
                            __typename?: "CarouselBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "ContentBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            background: BuildingBlockBackground;
                            assetId?: string | null;
                            contentColumnOne?: string | null;
                            contentColumnTwo?: string | null;
                            objectFit: ObjectFit;
                            textLayout: ContentBlockLayout;
                            textBackground: BuildingBlockBackground;
                            list?: {
                                __typename?: "ContentBlockListDto";
                                layout: ContentBlockListLayout;
                                items: Array<{
                                    __typename?: "ContentBlockListItemDto";
                                    id?: string | null;
                                    assetId?: string | null;
                                    content: string;
                                    subtitle: string;
                                    title: string;
                                    sortOrder: number;
                                }>;
                            } | null;
                        }
                      | {
                            __typename?: "ConversationBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            interval: number;
                            background: BuildingBlockBackground;
                            conversationLayout: ConversationBlockLayout;
                            messages: Array<{
                                __typename?: "MessageDto";
                                id: string;
                                message: string;
                                order: number;
                                personIndex: number;
                            }>;
                            persons: Array<{
                                __typename?: "PersonDto";
                                assetId?: string | null;
                                id: string;
                                name: string;
                                order: number;
                            }>;
                        }
                      | {
                            __typename?: "HotspotBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "InteractiveQuestionBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "LinkBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "MoodboardBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            background: BuildingBlockBackground;
                            moodboardLayout: MoodboardBlockLayout;
                            images: Array<{
                                __typename?: "MoodboardImageDto";
                                id: string;
                                assetId: string;
                                title?: string | null;
                                text?: string | null;
                            }>;
                        }
                      | {
                            __typename?: "MpcBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            assetId?: string | null;
                            question: string;
                            background: BuildingBlockBackground;
                            introduction?: string | null;
                            helpText?: string | null;
                            correctFeedbackText?: string | null;
                            incorrectFeedbackText?: string | null;
                            sorting: Sorting;
                            objectFit: ObjectFit;
                            mpcInstruction?: string | null;
                            mpcLayout: MpcBlockLayout;
                            answers: Array<{
                                __typename?: "AnswerDto";
                                id: string;
                                correct: boolean;
                                text?: string | null;
                                assetId?: string | null;
                                content?: string | null;
                                sortOrder: number;
                            }>;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | {
                            __typename?: "PollBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "QuestionConnectBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            instruction: string;
                            correctFeedbackText?: string | null;
                            incorrectFeedbackText?: string | null;
                            introduction?: string | null;
                            layout: QuestionConnectBlockLayout;
                            question: string;
                            background: BuildingBlockBackground;
                            items: Array<{
                                __typename?: "QuestionConnectItemDto";
                                id?: string | null;
                                assetId?: string | null;
                                buildingBlockId?: string | null;
                                content?: string | null;
                                correctAnswer: {
                                    __typename?: "QuestionConnectItemAnswerDto";
                                    id?: string | null;
                                    assetId?: string | null;
                                    buildingBlockId?: string | null;
                                    content?: string | null;
                                };
                            }>;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | {
                            __typename?: "QuoteBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "ReferenceCardsCarouselBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "SceneBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "SortQuestionBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "StandBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            agreeText: string;
                            assetId?: string | null;
                            correctOption: Stand;
                            disagreeText: string;
                            stand: string;
                            background: BuildingBlockBackground;
                            correctFeedbackText?: string | null;
                            incorrectFeedbackText?: string | null;
                            standText?: string | null;
                            standInstruction?: string | null;
                            standLayout: StandBlockLayout;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                  >;
              }
            | {
                  __typename?: "ContentBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  assetId?: string | null;
                  contentColumnOne?: string | null;
                  contentColumnTwo?: string | null;
                  objectFit: ObjectFit;
                  textLayout: ContentBlockLayout;
                  textBackground: BuildingBlockBackground;
                  list?: {
                      __typename?: "ContentBlockListDto";
                      layout: ContentBlockListLayout;
                      items: Array<{
                          __typename?: "ContentBlockListItemDto";
                          id?: string | null;
                          assetId?: string | null;
                          content: string;
                          subtitle: string;
                          title: string;
                          sortOrder: number;
                      }>;
                  } | null;
              }
            | {
                  __typename?: "ConversationBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  interval: number;
                  background: BuildingBlockBackground;
                  conversationLayout: ConversationBlockLayout;
                  messages: Array<{
                      __typename?: "MessageDto";
                      id: string;
                      message: string;
                      order: number;
                      personIndex: number;
                  }>;
                  persons: Array<{
                      __typename?: "PersonDto";
                      assetId?: string | null;
                      id: string;
                      name: string;
                      order: number;
                  }>;
              }
            | {
                  __typename?: "HotspotBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  content?: string | null;
                  background: BuildingBlockBackground;
                  hotspotBuildingBlockAssetId: string;
                  hotspotLayout: HotspotBlockLayout;
                  hotspotTitle?: string | null;
                  spots: Array<{
                      __typename?: "SpotDto";
                      content?: string | null;
                      id?: string | null;
                      title?: string | null;
                      x: number;
                      y: number;
                  }>;
              }
            | {
                  __typename?: "InteractiveQuestionBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  interactiveQuestionLayout: InteractiveQuestionBlockLayout;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
                  interactiveQuestionQuestion:
                      | { __typename?: "CarouselBuildingBlockDto" }
                      | { __typename?: "ContentBuildingBlockDto" }
                      | { __typename?: "ConversationBuildingBlockDto" }
                      | { __typename?: "HotspotBuildingBlockDto" }
                      | { __typename?: "InteractiveQuestionBuildingBlockDto" }
                      | { __typename?: "LinkBuildingBlockDto" }
                      | { __typename?: "MoodboardBuildingBlockDto" }
                      | {
                            __typename?: "MpcBuildingBlockDto";
                            id: string;
                            assetId?: string | null;
                            question: string;
                            order: number;
                            type: string;
                            background: BuildingBlockBackground;
                            introduction?: string | null;
                            helpText?: string | null;
                            correctFeedbackText?: string | null;
                            incorrectFeedbackText?: string | null;
                            sorting: Sorting;
                            objectFit: ObjectFit;
                            mpcInstruction?: string | null;
                            mpcLayout: MpcBlockLayout;
                            answers: Array<{
                                __typename?: "AnswerDto";
                                id: string;
                                correct: boolean;
                                text?: string | null;
                                assetId?: string | null;
                                content?: string | null;
                                sortOrder: number;
                            }>;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | { __typename?: "PollBuildingBlockDto" }
                      | { __typename?: "QuestionConnectBuildingBlockDto" }
                      | { __typename?: "QuoteBuildingBlockDto" }
                      | {
                            __typename?: "ReferenceCardsCarouselBuildingBlockDto";
                        }
                      | { __typename?: "SceneBuildingBlockDto" }
                      | { __typename?: "SortQuestionBuildingBlockDto" }
                      | {
                            __typename?: "StandBuildingBlockDto";
                            agreeText: string;
                            assetId?: string | null;
                            correctOption: Stand;
                            disagreeText: string;
                            stand: string;
                            id: string;
                            order: number;
                            type: string;
                            background: BuildingBlockBackground;
                            correctFeedbackText?: string | null;
                            incorrectFeedbackText?: string | null;
                            standText?: string | null;
                            standInstruction?: string | null;
                            standLayout: StandBlockLayout;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        };
                  sceneWhenWrong: {
                      __typename?: "SceneBuildingBlockDto";
                      id: string;
                      order: number;
                      type: string;
                      title: string;
                      description?: string | null;
                      sceneAssetId: string;
                  };
              }
            | {
                  __typename?: "LinkBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  title: string;
                  url: string;
                  linkLabel?: string | null;
                  openInNewWindow: boolean;
                  background: BuildingBlockBackground;
                  linkInstruction?: string | null;
                  linkAssetId?: string | null;
              }
            | {
                  __typename?: "MoodboardBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  moodboardLayout: MoodboardBlockLayout;
                  images: Array<{
                      __typename?: "MoodboardImageDto";
                      id: string;
                      assetId: string;
                      title?: string | null;
                      text?: string | null;
                  }>;
              }
            | {
                  __typename?: "MpcBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  assetId?: string | null;
                  question: string;
                  background: BuildingBlockBackground;
                  introduction?: string | null;
                  helpText?: string | null;
                  correctFeedbackText?: string | null;
                  incorrectFeedbackText?: string | null;
                  sorting: Sorting;
                  objectFit: ObjectFit;
                  mpcInstruction?: string | null;
                  mpcLayout: MpcBlockLayout;
                  answers: Array<{
                      __typename?: "AnswerDto";
                      id: string;
                      correct: boolean;
                      text?: string | null;
                      assetId?: string | null;
                      content?: string | null;
                      sortOrder: number;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "PollBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  voteOption: PollVoteOption;
                  pollInstruction: string;
                  pollIntroduction: string;
                  pollQuestion: string;
                  pollAnswers: Array<{
                      __typename?: "PollAnswerDto";
                      id: string;
                      text: string;
                      sortOrder: number;
                  }>;
              }
            | {
                  __typename?: "QuestionConnectBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  instruction: string;
                  correctFeedbackText?: string | null;
                  incorrectFeedbackText?: string | null;
                  introduction?: string | null;
                  layout: QuestionConnectBlockLayout;
                  question: string;
                  background: BuildingBlockBackground;
                  items: Array<{
                      __typename?: "QuestionConnectItemDto";
                      id?: string | null;
                      assetId?: string | null;
                      buildingBlockId?: string | null;
                      content?: string | null;
                      correctAnswer: {
                          __typename?: "QuestionConnectItemAnswerDto";
                          id?: string | null;
                          assetId?: string | null;
                          buildingBlockId?: string | null;
                          content?: string | null;
                      };
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "QuoteBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  quote: string;
                  name?: string | null;
                  assetId?: string | null;
                  quoteLayout: QuoteBlockLayout;
              }
            | {
                  __typename?: "ReferenceCardsCarouselBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  title: string;
                  referenceCardlayout: ReferenceCardsCarouselBlockLayout;
                  cards: Array<{
                      __typename?: "ReferenceCardDto";
                      headerImage?: string | null;
                      thumbnail: string;
                      id: string;
                      title: string;
                      description?: string | null;
                  }>;
              }
            | {
                  __typename?: "SceneBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  title: string;
                  description?: string | null;
                  sceneAssetId: string;
              }
            | {
                  __typename?: "SortQuestionBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  introduction?: string | null;
                  question: string;
                  correctFeedbackText?: string | null;
                  incorrectFeedbackText?: string | null;
                  assetId?: string | null;
                  objectFit: ObjectFit;
                  sortQuestionInstruction?: string | null;
                  sortQuestionLayout: SortQuestionBlockLayout;
                  answers: Array<{
                      __typename?: "SortQuestionAnswerDto";
                      id: string;
                      order?: number | null;
                      sortAnswerText: string;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "StandBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  agreeText: string;
                  assetId?: string | null;
                  correctOption: Stand;
                  disagreeText: string;
                  stand: string;
                  background: BuildingBlockBackground;
                  correctFeedbackText?: string | null;
                  incorrectFeedbackText?: string | null;
                  standText?: string | null;
                  standInstruction?: string | null;
                  standLayout: StandBlockLayout;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
        >;
    };
};

export type AppContentBuildingBlockFragment = {
    __typename?: "AppContentBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    assetId?: string | null;
    background: BuildingBlockBackground;
    contentColumnOne?: string | null;
    contentColumnTwo?: string | null;
    objectFit: ObjectFit;
    textLayout: ContentBlockLayout;
    list?:
        | {
              __typename?: "AppContentBlockListDto";
              layout: ContentBlockListLayout;
              items: Array<{
                  __typename?: "AppContentBlockListItemDto";
                  id?: string | null;
                  assetId?: string | null;
                  content: string;
                  subtitle: string;
                  title: string;
                  sortOrder: number;
              }>;
          }
        | {
              __typename?: "ContentBlockListDto";
              layout: ContentBlockListLayout;
              items: Array<{
                  __typename?: "ContentBlockListItemDto";
                  id?: string | null;
                  assetId?: string | null;
                  content: string;
                  subtitle: string;
                  title: string;
                  sortOrder: number;
              }>;
          }
        | null;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppStandBuildingBlockFragment = {
    __typename?: "AppStandBuildingBlockDto";
    agreeText: string;
    assetId?: string | null;
    disagreeText: string;
    stand: string;
    id: string;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    standInstruction?: string | null;
    standText?: string | null;
    standLayout: StandBlockLayout;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppPollBuildingBlockFragment = {
    __typename?: "AppPollBuildingBlockDto";
    question: string;
    instruction: string;
    voteOption: PollVoteOption;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    id: string;
    pollIntroduction: string;
    pollLayout: PollBlockLayout;
    pollAnswers: Array<{
        __typename?: "PollAnswerDto";
        id: string;
        sortOrder: number;
        text: string;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppMpcBuildingBlockFragment = {
    __typename?: "AppMpcBuildingBlockDto";
    assetId?: string | null;
    question: string;
    id: string;
    order: number;
    type: string;
    choiceType: MpcChoiceType;
    background: BuildingBlockBackground;
    objectFit: ObjectFit;
    sorting: Sorting;
    introduction?: string | null;
    mpcInstruction?: string | null;
    mpcLayout: MpcBlockLayout;
    answers: Array<{
        __typename?: "AppAnswerDto";
        id: string;
        text?: string | null;
        assetId?: string | null;
        content?: string | null;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppMoodboardBuildingBlockFragment = {
    __typename?: "AppMoodboardBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    layout: MoodboardBlockLayout;
    images: Array<{
        __typename?: "MoodboardImageDto";
        id: string;
        assetId: string;
        sortOrder: number;
        text?: string | null;
        title?: string | null;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppQuoteBuildingBlockFragment = {
    __typename?: "AppQuoteBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    quote: string;
    name?: string | null;
    background: BuildingBlockBackground;
    quoteLayout: QuoteBlockLayout;
    asset?: string | null;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppConversationBuildingBlockFragment = {
    __typename?: "AppConversationBuildingBlockDto";
    id: string;
    interval: number;
    background: BuildingBlockBackground;
    order: number;
    type: string;
    conversationLayout: ConversationBlockLayout;
    messages: Array<{
        __typename?: "AppMessageDto";
        id: string;
        message: string;
        order: number;
        personIndex: number;
    }>;
    persons: Array<{
        __typename?: "AppPersonDto";
        assetId?: string | null;
        id: string;
        name: string;
        order: number;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppQuestionConnectBuildingBlockFragment = {
    __typename?: "AppQuestionConnectBuildingBlockDto";
    id: string;
    instruction: string;
    introduction?: string | null;
    question: string;
    background: BuildingBlockBackground;
    order: number;
    type: string;
    questionConnectLayout: QuestionConnectBlockLayout;
    answers: Array<{
        __typename?: "QuestionConnectItemAnswerDto";
        content?: string | null;
        answerId?: string | null;
    }>;
    items: Array<{
        __typename?: "AppQuestionConnectItemDto";
        id?: string | null;
        assetId?: string | null;
        content?: string | null;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppCarouselBuildingBlockFragment = {
    __typename?: "AppCarouselBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    background: BuildingBlockBackground;
    title: string;
    carouselLayout: CarouselBlockLayout;
    blocks: Array<
        | {
              __typename?: "AppCarouselBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppContentBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              assetId?: string | null;
              background: BuildingBlockBackground;
              contentColumnOne?: string | null;
              contentColumnTwo?: string | null;
              objectFit: ObjectFit;
              textLayout: ContentBlockLayout;
              list?:
                  | {
                        __typename?: "AppContentBlockListDto";
                        layout: ContentBlockListLayout;
                        items: Array<{
                            __typename?: "AppContentBlockListItemDto";
                            id?: string | null;
                            assetId?: string | null;
                            content: string;
                            subtitle: string;
                            title: string;
                            sortOrder: number;
                        }>;
                    }
                  | {
                        __typename?: "ContentBlockListDto";
                        layout: ContentBlockListLayout;
                        items: Array<{
                            __typename?: "ContentBlockListItemDto";
                            id?: string | null;
                            assetId?: string | null;
                            content: string;
                            subtitle: string;
                            title: string;
                            sortOrder: number;
                        }>;
                    }
                  | null;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | {
              __typename?: "AppConversationBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              interval: number;
              background: BuildingBlockBackground;
              conversationLayout: ConversationBlockLayout;
              messages: Array<{
                  __typename?: "AppMessageDto";
                  id: string;
                  message: string;
                  order: number;
                  personIndex: number;
              }>;
              persons: Array<{
                  __typename?: "AppPersonDto";
                  assetId?: string | null;
                  id: string;
                  name: string;
                  order: number;
              }>;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | {
              __typename?: "AppHotspotBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppInteractiveQuestionBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppLinkBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppMoodboardBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              background: BuildingBlockBackground;
              layout: MoodboardBlockLayout;
              images: Array<{
                  __typename?: "MoodboardImageDto";
                  id: string;
                  assetId: string;
                  sortOrder: number;
                  text?: string | null;
                  title?: string | null;
              }>;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | {
              __typename?: "AppMpcBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              assetId?: string | null;
              question: string;
              choiceType: MpcChoiceType;
              background: BuildingBlockBackground;
              objectFit: ObjectFit;
              sorting: Sorting;
              introduction?: string | null;
              mpcInstruction?: string | null;
              mpcLayout: MpcBlockLayout;
              answers: Array<{
                  __typename?: "AppAnswerDto";
                  id: string;
                  text?: string | null;
                  assetId?: string | null;
                  content?: string | null;
              }>;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | {
              __typename?: "AppPollBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppQuestionConnectBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppQuoteBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppReferenceCardCarouselBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppSceneBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppSortQuestionBuildingBlockDto";
              id: string;
              order: number;
              type: string;
          }
        | {
              __typename?: "AppStandBuildingBlockDto";
              id: string;
              order: number;
              type: string;
              agreeText: string;
              assetId?: string | null;
              disagreeText: string;
              stand: string;
              background: BuildingBlockBackground;
              standInstruction?: string | null;
              standText?: string | null;
              standLayout: StandBlockLayout;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
    >;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppHotspotBuildingBlockFragment = {
    __typename?: "AppHotspotBuildingBlockDto";
    id: string;
    content?: string | null;
    background: BuildingBlockBackground;
    order: number;
    type: string;
    hotspotBuildingBlockAssetId: string;
    hotspotLayout: HotspotBlockLayout;
    hotspotTitle?: string | null;
    spots: Array<{
        __typename?: "SpotDto";
        content?: string | null;
        id?: string | null;
        title?: string | null;
        x: number;
        y: number;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppSortQuestionBuildingBlockFragment = {
    __typename?: "AppSortQuestionBuildingBlockDto";
    assetId?: string | null;
    background: BuildingBlockBackground;
    id: string;
    introduction?: string | null;
    objectFit: ObjectFit;
    question: string;
    order: number;
    type: string;
    sortQuestionInstruction?: string | null;
    sortQuestionLayout: SortQuestionBlockLayout;
    answers: Array<{
        __typename?: "AppSortQuestionAnswerDto";
        id: string;
        sortAnswerText: string;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppReferenceCardCarouselBuildingBlockFragment = {
    __typename?: "AppReferenceCardCarouselBuildingBlockDto";
    id: string;
    type: string;
    order: number;
    background: BuildingBlockBackground;
    referenceCardCarouselLayout: ReferenceCardsCarouselBlockLayout;
    referenceCardCarouselTitle: string;
    cards: Array<{
        __typename: "ReferenceCardDto";
        id: string;
        headerImage?: string | null;
        thumbnail: string;
        title: string;
        description?: string | null;
        body?: string | null;
        lead?: {
            __typename?: "ReferenceCardLeadDto";
            id: string;
            label: string;
        } | null;
        categories: Array<{
            __typename?: "ReferenceCardCategoryDto";
            id: string;
            label: string;
        }>;
    }>;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppSceneBuildingBlockFragment = {
    __typename?: "AppSceneBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    title: string;
    description?: string | null;
    sceneAssetId: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type AppLinkBuildingBlockFragment = {
    __typename?: "AppLinkBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    title: string;
    url: string;
    linkLabel?: string | null;
    openInNewWindow: boolean;
    background: BuildingBlockBackground;
    linkInstruction?: string | null;
    linkAssetId?: string | null;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type BlockLabelFragment = {
    __typename?: "BuildingBlocksLabelDto";
    id: string;
    label: string;
};

type BaseBlockProps_AppCarouselBuildingBlockDto_Fragment = {
    __typename?: "AppCarouselBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppContentBuildingBlockDto_Fragment = {
    __typename?: "AppContentBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppConversationBuildingBlockDto_Fragment = {
    __typename?: "AppConversationBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppHotspotBuildingBlockDto_Fragment = {
    __typename?: "AppHotspotBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppInteractiveQuestionBuildingBlockDto_Fragment = {
    __typename?: "AppInteractiveQuestionBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppLinkBuildingBlockDto_Fragment = {
    __typename?: "AppLinkBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppMoodboardBuildingBlockDto_Fragment = {
    __typename?: "AppMoodboardBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppMpcBuildingBlockDto_Fragment = {
    __typename?: "AppMpcBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppPollBuildingBlockDto_Fragment = {
    __typename?: "AppPollBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppQuestionConnectBuildingBlockDto_Fragment = {
    __typename?: "AppQuestionConnectBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppQuoteBuildingBlockDto_Fragment = {
    __typename?: "AppQuoteBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppReferenceCardCarouselBuildingBlockDto_Fragment = {
    __typename?: "AppReferenceCardCarouselBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppSceneBuildingBlockDto_Fragment = {
    __typename?: "AppSceneBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppSortQuestionBuildingBlockDto_Fragment = {
    __typename?: "AppSortQuestionBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

type BaseBlockProps_AppStandBuildingBlockDto_Fragment = {
    __typename?: "AppStandBuildingBlockDto";
    id: string;
    order: number;
    type: string;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
};

export type BaseBlockPropsFragment =
    | BaseBlockProps_AppCarouselBuildingBlockDto_Fragment
    | BaseBlockProps_AppContentBuildingBlockDto_Fragment
    | BaseBlockProps_AppConversationBuildingBlockDto_Fragment
    | BaseBlockProps_AppHotspotBuildingBlockDto_Fragment
    | BaseBlockProps_AppInteractiveQuestionBuildingBlockDto_Fragment
    | BaseBlockProps_AppLinkBuildingBlockDto_Fragment
    | BaseBlockProps_AppMoodboardBuildingBlockDto_Fragment
    | BaseBlockProps_AppMpcBuildingBlockDto_Fragment
    | BaseBlockProps_AppPollBuildingBlockDto_Fragment
    | BaseBlockProps_AppQuestionConnectBuildingBlockDto_Fragment
    | BaseBlockProps_AppQuoteBuildingBlockDto_Fragment
    | BaseBlockProps_AppReferenceCardCarouselBuildingBlockDto_Fragment
    | BaseBlockProps_AppSceneBuildingBlockDto_Fragment
    | BaseBlockProps_AppSortQuestionBuildingBlockDto_Fragment
    | BaseBlockProps_AppStandBuildingBlockDto_Fragment;

export type AppInteractiveQuestionBlockFragment = {
    __typename?: "AppInteractiveQuestionBuildingBlockDto";
    background: BuildingBlockBackground;
    id: string;
    type: string;
    order: number;
    interactiveQuestionLayout: InteractiveQuestionBlockLayout;
    label?: {
        __typename?: "BuildingBlocksLabelDto";
        id: string;
        label: string;
    } | null;
    interactiveQuestionQuestion:
        | { __typename?: "AppCarouselBuildingBlockDto" }
        | { __typename?: "AppContentBuildingBlockDto" }
        | { __typename?: "AppConversationBuildingBlockDto" }
        | { __typename?: "AppHotspotBuildingBlockDto" }
        | { __typename?: "AppInteractiveQuestionBuildingBlockDto" }
        | { __typename?: "AppLinkBuildingBlockDto" }
        | { __typename?: "AppMoodboardBuildingBlockDto" }
        | {
              __typename?: "AppMpcBuildingBlockDto";
              assetId?: string | null;
              question: string;
              id: string;
              order: number;
              type: string;
              choiceType: MpcChoiceType;
              background: BuildingBlockBackground;
              objectFit: ObjectFit;
              sorting: Sorting;
              introduction?: string | null;
              mpcInstruction?: string | null;
              mpcLayout: MpcBlockLayout;
              answers: Array<{
                  __typename?: "AppAnswerDto";
                  id: string;
                  text?: string | null;
                  assetId?: string | null;
                  content?: string | null;
              }>;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          }
        | { __typename?: "AppPollBuildingBlockDto" }
        | { __typename?: "AppQuestionConnectBuildingBlockDto" }
        | { __typename?: "AppQuoteBuildingBlockDto" }
        | { __typename?: "AppReferenceCardCarouselBuildingBlockDto" }
        | { __typename?: "AppSceneBuildingBlockDto" }
        | { __typename?: "AppSortQuestionBuildingBlockDto" }
        | {
              __typename?: "AppStandBuildingBlockDto";
              agreeText: string;
              assetId?: string | null;
              disagreeText: string;
              stand: string;
              id: string;
              order: number;
              type: string;
              background: BuildingBlockBackground;
              standInstruction?: string | null;
              standText?: string | null;
              standLayout: StandBlockLayout;
              label?: {
                  __typename?: "BuildingBlocksLabelDto";
                  id: string;
                  label: string;
              } | null;
          };
    sceneWhenWrong: {
        __typename?: "AppSceneBuildingBlockDto";
        assetId: string;
        layout: SceneBlockLayout;
        title: string;
        description?: string | null;
        type: string;
    };
};

export type GetBuildingBlocksForDidacticToolForAppQueryVariables = Exact<{
    didacticToolId: Scalars["UUID"]["input"];
}>;

export type GetBuildingBlocksForDidacticToolForAppQuery = {
    __typename?: "Query";
    getBuildingBlocksForDidacticToolForApp: {
        __typename?: "AppBuildingBlocksForDidacticToolDto";
        didacticToolId: string;
        buildingBlocks: Array<
            | {
                  __typename?: "AppCarouselBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  title: string;
                  carouselLayout: CarouselBlockLayout;
                  blocks: Array<
                      | {
                            __typename?: "AppCarouselBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppContentBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            assetId?: string | null;
                            background: BuildingBlockBackground;
                            contentColumnOne?: string | null;
                            contentColumnTwo?: string | null;
                            objectFit: ObjectFit;
                            textLayout: ContentBlockLayout;
                            list?:
                                | {
                                      __typename?: "AppContentBlockListDto";
                                      layout: ContentBlockListLayout;
                                      items: Array<{
                                          __typename?: "AppContentBlockListItemDto";
                                          id?: string | null;
                                          assetId?: string | null;
                                          content: string;
                                          subtitle: string;
                                          title: string;
                                          sortOrder: number;
                                      }>;
                                  }
                                | {
                                      __typename?: "ContentBlockListDto";
                                      layout: ContentBlockListLayout;
                                      items: Array<{
                                          __typename?: "ContentBlockListItemDto";
                                          id?: string | null;
                                          assetId?: string | null;
                                          content: string;
                                          subtitle: string;
                                          title: string;
                                          sortOrder: number;
                                      }>;
                                  }
                                | null;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | {
                            __typename?: "AppConversationBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            interval: number;
                            background: BuildingBlockBackground;
                            conversationLayout: ConversationBlockLayout;
                            messages: Array<{
                                __typename?: "AppMessageDto";
                                id: string;
                                message: string;
                                order: number;
                                personIndex: number;
                            }>;
                            persons: Array<{
                                __typename?: "AppPersonDto";
                                assetId?: string | null;
                                id: string;
                                name: string;
                                order: number;
                            }>;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | {
                            __typename?: "AppHotspotBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppInteractiveQuestionBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppLinkBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppMoodboardBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            background: BuildingBlockBackground;
                            layout: MoodboardBlockLayout;
                            images: Array<{
                                __typename?: "MoodboardImageDto";
                                id: string;
                                assetId: string;
                                sortOrder: number;
                                text?: string | null;
                                title?: string | null;
                            }>;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | {
                            __typename?: "AppMpcBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            assetId?: string | null;
                            question: string;
                            choiceType: MpcChoiceType;
                            background: BuildingBlockBackground;
                            objectFit: ObjectFit;
                            sorting: Sorting;
                            introduction?: string | null;
                            mpcInstruction?: string | null;
                            mpcLayout: MpcBlockLayout;
                            answers: Array<{
                                __typename?: "AppAnswerDto";
                                id: string;
                                text?: string | null;
                                assetId?: string | null;
                                content?: string | null;
                            }>;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | {
                            __typename?: "AppPollBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppQuestionConnectBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppQuoteBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppReferenceCardCarouselBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppSceneBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppSortQuestionBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                        }
                      | {
                            __typename?: "AppStandBuildingBlockDto";
                            id: string;
                            order: number;
                            type: string;
                            agreeText: string;
                            assetId?: string | null;
                            disagreeText: string;
                            stand: string;
                            background: BuildingBlockBackground;
                            standInstruction?: string | null;
                            standText?: string | null;
                            standLayout: StandBlockLayout;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                  >;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppContentBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  assetId?: string | null;
                  background: BuildingBlockBackground;
                  contentColumnOne?: string | null;
                  contentColumnTwo?: string | null;
                  objectFit: ObjectFit;
                  textLayout: ContentBlockLayout;
                  list?:
                      | {
                            __typename?: "AppContentBlockListDto";
                            layout: ContentBlockListLayout;
                            items: Array<{
                                __typename?: "AppContentBlockListItemDto";
                                id?: string | null;
                                assetId?: string | null;
                                content: string;
                                subtitle: string;
                                title: string;
                                sortOrder: number;
                            }>;
                        }
                      | {
                            __typename?: "ContentBlockListDto";
                            layout: ContentBlockListLayout;
                            items: Array<{
                                __typename?: "ContentBlockListItemDto";
                                id?: string | null;
                                assetId?: string | null;
                                content: string;
                                subtitle: string;
                                title: string;
                                sortOrder: number;
                            }>;
                        }
                      | null;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppConversationBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  interval: number;
                  background: BuildingBlockBackground;
                  conversationLayout: ConversationBlockLayout;
                  messages: Array<{
                      __typename?: "AppMessageDto";
                      id: string;
                      message: string;
                      order: number;
                      personIndex: number;
                  }>;
                  persons: Array<{
                      __typename?: "AppPersonDto";
                      assetId?: string | null;
                      id: string;
                      name: string;
                      order: number;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppHotspotBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  content?: string | null;
                  background: BuildingBlockBackground;
                  hotspotBuildingBlockAssetId: string;
                  hotspotLayout: HotspotBlockLayout;
                  hotspotTitle?: string | null;
                  spots: Array<{
                      __typename?: "SpotDto";
                      content?: string | null;
                      id?: string | null;
                      title?: string | null;
                      x: number;
                      y: number;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppInteractiveQuestionBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  interactiveQuestionLayout: InteractiveQuestionBlockLayout;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
                  interactiveQuestionQuestion:
                      | { __typename?: "AppCarouselBuildingBlockDto" }
                      | { __typename?: "AppContentBuildingBlockDto" }
                      | { __typename?: "AppConversationBuildingBlockDto" }
                      | { __typename?: "AppHotspotBuildingBlockDto" }
                      | {
                            __typename?: "AppInteractiveQuestionBuildingBlockDto";
                        }
                      | { __typename?: "AppLinkBuildingBlockDto" }
                      | { __typename?: "AppMoodboardBuildingBlockDto" }
                      | {
                            __typename?: "AppMpcBuildingBlockDto";
                            assetId?: string | null;
                            question: string;
                            id: string;
                            order: number;
                            type: string;
                            choiceType: MpcChoiceType;
                            background: BuildingBlockBackground;
                            objectFit: ObjectFit;
                            sorting: Sorting;
                            introduction?: string | null;
                            mpcInstruction?: string | null;
                            mpcLayout: MpcBlockLayout;
                            answers: Array<{
                                __typename?: "AppAnswerDto";
                                id: string;
                                text?: string | null;
                                assetId?: string | null;
                                content?: string | null;
                            }>;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        }
                      | { __typename?: "AppPollBuildingBlockDto" }
                      | { __typename?: "AppQuestionConnectBuildingBlockDto" }
                      | { __typename?: "AppQuoteBuildingBlockDto" }
                      | {
                            __typename?: "AppReferenceCardCarouselBuildingBlockDto";
                        }
                      | { __typename?: "AppSceneBuildingBlockDto" }
                      | { __typename?: "AppSortQuestionBuildingBlockDto" }
                      | {
                            __typename?: "AppStandBuildingBlockDto";
                            agreeText: string;
                            assetId?: string | null;
                            disagreeText: string;
                            stand: string;
                            id: string;
                            order: number;
                            type: string;
                            background: BuildingBlockBackground;
                            standInstruction?: string | null;
                            standText?: string | null;
                            standLayout: StandBlockLayout;
                            label?: {
                                __typename?: "BuildingBlocksLabelDto";
                                id: string;
                                label: string;
                            } | null;
                        };
                  sceneWhenWrong: {
                      __typename?: "AppSceneBuildingBlockDto";
                      assetId: string;
                      layout: SceneBlockLayout;
                      title: string;
                      description?: string | null;
                      type: string;
                  };
              }
            | {
                  __typename?: "AppLinkBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  title: string;
                  url: string;
                  linkLabel?: string | null;
                  openInNewWindow: boolean;
                  background: BuildingBlockBackground;
                  linkInstruction?: string | null;
                  linkAssetId?: string | null;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppMoodboardBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  layout: MoodboardBlockLayout;
                  images: Array<{
                      __typename?: "MoodboardImageDto";
                      id: string;
                      assetId: string;
                      sortOrder: number;
                      text?: string | null;
                      title?: string | null;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppMpcBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  assetId?: string | null;
                  question: string;
                  choiceType: MpcChoiceType;
                  background: BuildingBlockBackground;
                  objectFit: ObjectFit;
                  sorting: Sorting;
                  introduction?: string | null;
                  mpcInstruction?: string | null;
                  mpcLayout: MpcBlockLayout;
                  answers: Array<{
                      __typename?: "AppAnswerDto";
                      id: string;
                      text?: string | null;
                      assetId?: string | null;
                      content?: string | null;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppPollBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  question: string;
                  instruction: string;
                  voteOption: PollVoteOption;
                  background: BuildingBlockBackground;
                  pollIntroduction: string;
                  pollLayout: PollBlockLayout;
                  pollAnswers: Array<{
                      __typename?: "PollAnswerDto";
                      id: string;
                      sortOrder: number;
                      text: string;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppQuestionConnectBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  instruction: string;
                  introduction?: string | null;
                  question: string;
                  background: BuildingBlockBackground;
                  questionConnectLayout: QuestionConnectBlockLayout;
                  answers: Array<{
                      __typename?: "QuestionConnectItemAnswerDto";
                      content?: string | null;
                      answerId?: string | null;
                  }>;
                  items: Array<{
                      __typename?: "AppQuestionConnectItemDto";
                      id?: string | null;
                      assetId?: string | null;
                      content?: string | null;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppQuoteBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  quote: string;
                  name?: string | null;
                  background: BuildingBlockBackground;
                  quoteLayout: QuoteBlockLayout;
                  asset?: string | null;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppReferenceCardCarouselBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  background: BuildingBlockBackground;
                  referenceCardCarouselLayout: ReferenceCardsCarouselBlockLayout;
                  referenceCardCarouselTitle: string;
                  cards: Array<{
                      __typename: "ReferenceCardDto";
                      id: string;
                      headerImage?: string | null;
                      thumbnail: string;
                      title: string;
                      description?: string | null;
                      body?: string | null;
                      lead?: {
                          __typename?: "ReferenceCardLeadDto";
                          id: string;
                          label: string;
                      } | null;
                      categories: Array<{
                          __typename?: "ReferenceCardCategoryDto";
                          id: string;
                          label: string;
                      }>;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppSceneBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  title: string;
                  description?: string | null;
                  sceneAssetId: string;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppSortQuestionBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  assetId?: string | null;
                  background: BuildingBlockBackground;
                  introduction?: string | null;
                  objectFit: ObjectFit;
                  question: string;
                  sortQuestionInstruction?: string | null;
                  sortQuestionLayout: SortQuestionBlockLayout;
                  answers: Array<{
                      __typename?: "AppSortQuestionAnswerDto";
                      id: string;
                      sortAnswerText: string;
                  }>;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
            | {
                  __typename?: "AppStandBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
                  agreeText: string;
                  assetId?: string | null;
                  disagreeText: string;
                  stand: string;
                  background: BuildingBlockBackground;
                  standInstruction?: string | null;
                  standText?: string | null;
                  standLayout: StandBlockLayout;
                  label?: {
                      __typename?: "BuildingBlocksLabelDto";
                      id: string;
                      label: string;
                  } | null;
              }
        >;
    };
};

export type GetChildGroupsForGroupQueryVariables = Exact<{
    groupId: Scalars["UUID"]["input"];
}>;

export type GetChildGroupsForGroupQuery = {
    __typename?: "Query";
    getChildGroupsForGroup: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        parentId?: string | null;
    }>;
};

export type GetDidacticToolQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetDidacticToolQuery = {
    __typename?: "Query";
    getDidacticTool?: {
        __typename?: "DidacticToolDto";
        description: string;
        headerImageId?: string | null;
        headerIntroText?: string | null;
        headerBackground?: DidacticToolBackground | null;
        durationIndication?: string | null;
        learningAdviceTitle?: string | null;
        learningAdviceDescription?: string | null;
        id: string;
        name: string;
        type: DidacticToolType;
        label?: DidacticToolLabelType | null;
        altLabel?: string | null;
    } | null;
};

export type GetDidacticToolForAppQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetDidacticToolForAppQuery = {
    __typename?: "Query";
    getDidacticToolForApp: {
        __typename?: "AppDidacticToolDto";
        description: string;
        headerImageId?: string | null;
        headerIntroText?: string | null;
        headerBackground?: DidacticToolBackground | null;
        durationIndication?: string | null;
        id: string;
        name: string;
        type: DidacticToolType;
        altLabel?: string | null;
        label?: DidacticToolLabelType | null;
        learningAdviceTitle?: string | null;
        learningAdviceDescription?: string | null;
    };
};

export type GetDidacticToolProgressQueryVariables = Exact<{
    ids: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type GetDidacticToolProgressQuery = {
    __typename?: "Query";
    getDidacticToolProgress: Array<{
        __typename?: "DidacticToolProgressDto";
        id: string;
        progressPercentage: number;
    }>;
};

export type GetDidacticToolsForAppQueryVariables = Exact<{
    moduleId: Scalars["UUID"]["input"];
    programId: Scalars["UUID"]["input"];
    educationalPathId: Scalars["UUID"]["input"];
}>;

export type GetDidacticToolsForAppQuery = {
    __typename?: "Query";
    getDidacticToolsForApp: Array<{
        __typename?: "AppDidacticToolDto";
        description: string;
        headerImageId?: string | null;
        headerIntroText?: string | null;
        id: string;
        name: string;
        type: DidacticToolType;
        label?: DidacticToolLabelType | null;
        altLabel?: string | null;
    }>;
};

export type GetDidacticToolsForAppProgressTableFilterQueryVariables = Exact<{
    moduleId: Scalars["UUID"]["input"];
}>;

export type GetDidacticToolsForAppProgressTableFilterQuery = {
    __typename?: "Query";
    didacticTools: Array<{
        __typename?: "AppDidacticToolDto";
        id: string;
        name: string;
    }>;
};

export type GetDidacticToolsForModuleQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetDidacticToolsForModuleQuery = {
    __typename?: "Query";
    getDidacticToolsForModule: {
        __typename?: "DidacticToolsForModuleDto";
        moduleId: string;
        didacticTools: Array<{
            __typename?: "DidacticToolForModuleDto";
            id: string;
            name: string;
            order: number;
            type: DidacticToolType;
            label?: DidacticToolLabelType | null;
        }>;
    };
};

export type GetEducationalPathQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetEducationalPathQuery = {
    __typename?: "Query";
    getEducationalPath?: {
        __typename?: "EducationalPathDto";
        id: string;
        name: string;
        description: string;
        dashboardImageId?: string | null;
        durationIndication?: string | null;
        headerImageId?: string | null;
        headerIntroText?: string | null;
    } | null;
};

export type GetEducationalPathForAppQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetEducationalPathForAppQuery = {
    __typename?: "Query";
    getEducationalPathForApp: {
        __typename?: "AppEducationalPathDto";
        id: string;
        name: string;
        headerIntroText?: string | null;
        headerImageId?: string | null;
        description: string;
        dashboardImageId?: string | null;
        durationIndication?: string | null;
    };
};

export type GetEducationalPathProgressQueryVariables = Exact<{
    ids: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type GetEducationalPathProgressQuery = {
    __typename?: "Query";
    getEducationalPathProgress: Array<{
        __typename?: "EducationalPathProgressDto";
        id: string;
        progressPercentage: number;
    }>;
};

export type GetEducationalPathsForAppQueryVariables = Exact<{
    educationalProgramId: Scalars["UUID"]["input"];
}>;

export type GetEducationalPathsForAppQuery = {
    __typename?: "Query";
    getEducationalPathsForApp: Array<{
        __typename?: "AppEducationalPathDto";
        id: string;
        name: string;
        description: string;
        dashboardImageId?: string | null;
        headerImageId?: string | null;
        headerIntroText?: string | null;
    }>;
};

export type GetEducationalPathsForAppProgressTableFilterDialogQueryVariables =
    Exact<{
        educationalProgramId: Scalars["UUID"]["input"];
    }>;

export type GetEducationalPathsForAppProgressTableFilterDialogQuery = {
    __typename?: "Query";
    educationalPaths: Array<{
        __typename?: "AppEducationalPathDto";
        id: string;
        name: string;
    }>;
};

export type GetEducationalPathsForProgramQueryVariables = Exact<{
    programId: Scalars["UUID"]["input"];
}>;

export type GetEducationalPathsForProgramQuery = {
    __typename?: "Query";
    getEducationalPathsForProgram: {
        __typename?: "EducationalPathsForProgramDto";
        programId: string;
        educationalPaths: Array<{
            __typename?: "EducationalPathForProgramDto";
            id: string;
            name: string;
            order: number;
        }>;
    };
};

export type GetEducationalProgramQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetEducationalProgramQuery = {
    __typename?: "Query";
    getEducationalProgram?: {
        __typename?: "EducationalProgramDto";
        id: string;
        name: string;
        description: string;
    } | null;
};

export type GetEducationalProgramForGroupQueryVariables = Exact<{
    groupId: Scalars["UUID"]["input"];
    programId: Scalars["UUID"]["input"];
}>;

export type GetEducationalProgramForGroupQuery = {
    __typename?: "Query";
    educationalProgram: {
        __typename?: "AppEducationalProgramDto";
        id: string;
        name: string;
        description: string;
    };
};

export type GetEducationalProgramsForAppQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetEducationalProgramsForAppQuery = {
    __typename?: "Query";
    getEducationalProgramsForApp: Array<{
        __typename?: "AppEducationalProgramDto";
        id: string;
        description: string;
        name: string;
    }>;
};

export type GetEducationalProgramsForProgressTableQueryVariables = Exact<{
    groupId: Scalars["UUID"]["input"];
}>;

export type GetEducationalProgramsForProgressTableQuery = {
    __typename?: "Query";
    programs: Array<{
        __typename?: "AppEducationalProgramDto";
        id: string;
        name: string;
    }>;
};

export type GetElementsForProgramQueryVariables = Exact<{
    programId: Scalars["UUID"]["input"];
    filters: AppEducationalProgramElementsFilterInput;
}>;

export type GetElementsForProgramQuery = {
    __typename?: "Query";
    elements: {
        __typename?: "AppEducationalProgramElementsDto";
        educationalPaths: Array<{
            __typename?: "AppEducationalProgramElementDto";
            id: string;
            name: string;
        }>;
        modules: Array<{
            __typename?: "AppEducationalProgramElementDto";
            id: string;
            name: string;
        }>;
        didacticTools: Array<{
            __typename?: "AppEducationalProgramElementDto";
            id: string;
            name: string;
        }>;
    };
};

export type GetFiltersForReferenceCardQueryVariables = Exact<{
    programId: Scalars["UUID"]["input"];
}>;

export type GetFiltersForReferenceCardQuery = {
    __typename?: "Query";
    filters: {
        __typename?: "AppReferenceCardsFilterOptionsDto";
        categories: Array<{
            __typename?: "ReferenceCardCategoryDto";
            id: string;
            label: string;
        }>;
        leads: Array<{
            __typename?: "ReferenceCardLeadDto";
            id: string;
            label: string;
        }>;
    };
};

export type GetGroupQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetGroupQuery = {
    __typename?: "Query";
    getGroup?: {
        __typename?: "GroupDetailDto";
        id: string;
        name: string;
        productIds: Array<string>;
        showGroupDuringRegistration: boolean;
        parentId?: string | null;
        externalCode?: string | null;
    } | null;
};

export type AppGroupFragment = {
    __typename?: "AppGroupDto";
    id: string;
    name: string;
    parentId?: string | null;
};

export type AppGroupMemberFragment = {
    __typename?: "AppUserDto";
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
};

export type GetGroupForAppQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetGroupForAppQuery = {
    __typename?: "Query";
    getGroupForApp: {
        __typename?: "AppGroupDto";
        id: string;
        name: string;
        parentId?: string | null;
        members: Array<{
            __typename?: "AppUserDto";
            id: string;
            firstName: string;
            lastName: string;
            emailAddress: string;
        }>;
    };
};

export type GetGroupsForAuthenticatedUserQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetGroupsForAuthenticatedUserQuery = {
    __typename?: "Query";
    getGroupsForAuthenticatedUser: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        parentId?: string | null;
        showGroupDuringRegistration: boolean;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            parentId?: string | null;
            showGroupDuringRegistration: boolean;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                parentId?: string | null;
                showGroupDuringRegistration: boolean;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    parentId?: string | null;
                    showGroupDuringRegistration: boolean;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        parentId?: string | null;
                        showGroupDuringRegistration: boolean;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                            parentId?: string | null;
                            showGroupDuringRegistration: boolean;
                            subgroups: Array<{
                                __typename?: "GroupDto";
                                id: string;
                                name: string;
                                parentId?: string | null;
                                showGroupDuringRegistration: boolean;
                            }>;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type GetGroupsForProgressTableQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetGroupsForProgressTableQuery = {
    __typename?: "Query";
    getGroupsForAuthenticatedUser: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        parentId?: string | null;
        showGroupDuringRegistration: boolean;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            parentId?: string | null;
            showGroupDuringRegistration: boolean;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                parentId?: string | null;
                showGroupDuringRegistration: boolean;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    parentId?: string | null;
                    showGroupDuringRegistration: boolean;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        parentId?: string | null;
                        showGroupDuringRegistration: boolean;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                            parentId?: string | null;
                            showGroupDuringRegistration: boolean;
                            subgroups: Array<{
                                __typename?: "GroupDto";
                                id: string;
                                name: string;
                                parentId?: string | null;
                                showGroupDuringRegistration: boolean;
                            }>;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type GetGroupsMarkedForRegistrationQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetGroupsMarkedForRegistrationQuery = {
    __typename?: "Query";
    getGroupsMarkedForRegistration: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        parentId?: string | null;
        showGroupDuringRegistration: boolean;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            parentId?: string | null;
            showGroupDuringRegistration: boolean;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                parentId?: string | null;
                showGroupDuringRegistration: boolean;
            }>;
        }>;
    }>;
};

export type GetLastViewedModuleQueryVariables = Exact<{
    programId: Scalars["UUID"]["input"];
}>;

export type GetLastViewedModuleQuery = {
    __typename?: "Query";
    getLastViewedModule?: {
        __typename?: "LastViewedModuleDto";
        educationalPathId: string;
        programId: string;
        module: {
            __typename?: "AppModuleDto";
            id: string;
            content?: string | null;
            dashboardImageId?: string | null;
            description: string;
            headerImageId?: string | null;
            shortTitle?: string | null;
            title: string;
        };
    } | null;
};

export type GetLicensesForUserInCmsQueryVariables = Exact<{
    userId: Scalars["String"]["input"];
}>;

export type GetLicensesForUserInCmsQuery = {
    __typename?: "Query";
    getLicenses: {
        __typename?: "LicensesDto";
        rows: Array<{
            __typename?: "LicenseDto";
            id: string;
            validUntil?: any | null;
            startAt: any;
            isActive: boolean;
            product: { __typename?: "ProductDto"; id: string; name: string };
        }>;
    };
};

type BaseModuleBlock_AppDidacticToolsModuleBlockDto_Fragment = {
    __typename?: "AppDidacticToolsModuleBlockDto";
    id?: string | null;
    order: number;
    type: string;
};

type BaseModuleBlock_AppTitleModuleBlockDto_Fragment = {
    __typename?: "AppTitleModuleBlockDto";
    id?: string | null;
    order: number;
    type: string;
};

type BaseModuleBlock_AppVideoModuleBlockDto_Fragment = {
    __typename?: "AppVideoModuleBlockDto";
    id?: string | null;
    order: number;
    type: string;
};

type BaseModuleBlock_DidacticToolsModuleBlockDto_Fragment = {
    __typename?: "DidacticToolsModuleBlockDto";
    id?: string | null;
    order: number;
    type: string;
};

type BaseModuleBlock_TitleModuleBlockDto_Fragment = {
    __typename?: "TitleModuleBlockDto";
    id?: string | null;
    order: number;
    type: string;
};

type BaseModuleBlock_VideoModuleBlockDto_Fragment = {
    __typename?: "VideoModuleBlockDto";
    id?: string | null;
    order: number;
    type: string;
};

export type BaseModuleBlockFragment =
    | BaseModuleBlock_AppDidacticToolsModuleBlockDto_Fragment
    | BaseModuleBlock_AppTitleModuleBlockDto_Fragment
    | BaseModuleBlock_AppVideoModuleBlockDto_Fragment
    | BaseModuleBlock_DidacticToolsModuleBlockDto_Fragment
    | BaseModuleBlock_TitleModuleBlockDto_Fragment
    | BaseModuleBlock_VideoModuleBlockDto_Fragment;

export type DidacticToolsModuleBlockFragment = {
    __typename?: "DidacticToolsModuleBlockDto";
    didacticToolBlocks?: Array<{
        __typename?: "ModuleBlockDidacticToolDto";
        didacticToolId: string;
        order: number;
        variant: ModuleBlockVariant;
        didacticTool?: {
            __typename?: "DidacticToolDto";
            id: string;
            name: string;
        } | null;
    }> | null;
};

export type TitleModuleBlockFragment = {
    __typename?: "TitleModuleBlockDto";
    title?: string | null;
    description?: string | null;
};

export type VideoModuleBlockFragment = {
    __typename?: "VideoModuleBlockDto";
    assetId: string;
    title?: string | null;
    durationIndication?: string | null;
};

export type GetModuleQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetModuleQuery = {
    __typename?: "Query";
    getModule: {
        __typename?: "ModuleDto";
        id: string;
        content?: string | null;
        dashboardImageId?: string | null;
        durationIndication?: string | null;
        description: string;
        headerImageId?: string | null;
        shortTitle?: string | null;
        title: string;
        moduleBlocks?: Array<
            | {
                  __typename?: "DidacticToolsModuleBlockDto";
                  id?: string | null;
                  order: number;
                  type: string;
                  didacticToolBlocks?: Array<{
                      __typename?: "ModuleBlockDidacticToolDto";
                      didacticToolId: string;
                      order: number;
                      variant: ModuleBlockVariant;
                      didacticTool?: {
                          __typename?: "DidacticToolDto";
                          id: string;
                          name: string;
                      } | null;
                  }> | null;
              }
            | {
                  __typename?: "TitleModuleBlockDto";
                  id?: string | null;
                  order: number;
                  type: string;
                  title?: string | null;
                  description?: string | null;
              }
            | {
                  __typename?: "VideoModuleBlockDto";
                  id?: string | null;
                  order: number;
                  type: string;
                  assetId: string;
                  title?: string | null;
                  durationIndication?: string | null;
              }
        > | null;
    };
};

export type GetModuleBlocksForAppQueryVariables = Exact<{
    moduleId: Scalars["UUID"]["input"];
}>;

export type GetModuleBlocksForAppQuery = {
    __typename?: "Query";
    getModuleBlocksForApp: {
        __typename?: "AppModuleBlocksForModuleDto";
        moduleId: string;
        blocks: Array<
            | {
                  __typename?: "AppDidacticToolsModuleBlockDto";
                  id?: string | null;
                  didacticToolBlocks?: Array<{
                      __typename?: "AppModuleBlockDidacticToolDto";
                      didacticToolId: string;
                      order: number;
                      variant: ModuleBlockVariant;
                      didacticTool?: {
                          __typename?: "AppDidacticToolDto";
                          altLabel?: string | null;
                          description: string;
                          durationIndication?: string | null;
                          headerBackground?: DidacticToolBackground | null;
                          headerImageId?: string | null;
                          headerIntroText?: string | null;
                          id: string;
                          label?: DidacticToolLabelType | null;
                          learningAdviceDescription?: string | null;
                          learningAdviceTitle?: string | null;
                          name: string;
                          type: DidacticToolType;
                      } | null;
                  }> | null;
              }
            | {
                  __typename?: "AppTitleModuleBlockDto";
                  id?: string | null;
                  title?: string | null;
                  description?: string | null;
                  order: number;
              }
            | {
                  __typename?: "AppVideoModuleBlockDto";
                  assetId: string;
                  durationIndication?: string | null;
                  title?: string | null;
                  id?: string | null;
                  order: number;
                  type: string;
              }
        >;
    };
};

export type GetModuleForAppQueryVariables = Exact<{
    moduleId: Scalars["UUID"]["input"];
}>;

export type GetModuleForAppQuery = {
    __typename?: "Query";
    getModuleForApp: {
        __typename?: "AppModuleDto";
        id: string;
        content?: string | null;
        dashboardImageId?: string | null;
        description: string;
        durationIndication?: string | null;
        headerImageId?: string | null;
        shortTitle?: string | null;
        title: string;
    };
};

export type GetModuleProgressQueryVariables = Exact<{
    ids: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type GetModuleProgressQuery = {
    __typename?: "Query";
    getModuleProgress: Array<{
        __typename?: "ModuleProgressDto";
        id: string;
        progressPercentage: number;
    }>;
};

export type GetModulesForEducationalPathForAppQueryVariables = Exact<{
    educationalPathId: Scalars["UUID"]["input"];
}>;

export type GetModulesForEducationalPathForAppQuery = {
    __typename?: "Query";
    getModulesForEducationalPathForApp: Array<{
        __typename?: "AppModuleDto";
        content?: string | null;
        dashboardImageId?: string | null;
        description: string;
        id: string;
        headerImageId?: string | null;
        title: string;
        shortTitle?: string | null;
    }>;
};

export type GetModulesForAppProgressTableFilterDialogQueryVariables = Exact<{
    educationalPathId: Scalars["UUID"]["input"];
}>;

export type GetModulesForAppProgressTableFilterDialogQuery = {
    __typename?: "Query";
    modules: Array<{ __typename?: "AppModuleDto"; id: string; name: string }>;
};

export type GetModulesForEducationalPathQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetModulesForEducationalPathQuery = {
    __typename?: "Query";
    getModulesForEducationalPath: {
        __typename?: "ModulesForEducationalPathDto";
        educationalPathId: string;
        modules: Array<{
            __typename?: "ModuleForEducationalPathDto";
            id: string;
            name: string;
            order: number;
        }>;
    };
};

export type GetParentGroupsForCreateChildGroupQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetParentGroupsForCreateChildGroupQuery = {
    __typename?: "Query";
    getGroupsForAuthenticatedUser: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                            subgroups: Array<{
                                __typename?: "GroupDto";
                                id: string;
                                name: string;
                            }>;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type GetProductQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetProductQuery = {
    __typename?: "Query";
    getProduct?: {
        __typename?: "ProductDetailDto";
        id: string;
        educationalProgramIds: Array<string>;
        name: string;
        isAvailable: boolean;
        externalIdentifier?: string | null;
        validityPeriod?: number | null;
        prefix?: string | null;
    } | null;
};

export type GetProductsForLicenseSelectionQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetProductsForLicenseSelectionQuery = {
    __typename?: "Query";
    products: {
        __typename?: "ProductsDto";
        rows: Array<{
            __typename?: "ProductDto";
            id: string;
            name: string;
            validityPeriod?: number | null;
        }>;
    };
};

export type GetReferenceCardQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetReferenceCardQuery = {
    __typename?: "Query";
    getReferenceCard: {
        __typename: "ReferenceCardDto";
        id: string;
        headerImage?: string | null;
        thumbnail: string;
        title: string;
        description?: string | null;
        lead?: {
            __typename?: "ReferenceCardLeadDto";
            id: string;
            label: string;
        } | null;
        didacticTools: Array<{
            __typename?: "DidacticToolDto";
            id: string;
            name: string;
        }>;
        programs: Array<{
            __typename?: "EducationalProgramDto";
            id: string;
            label: string;
        }>;
        categories: Array<{
            __typename?: "ReferenceCardCategoryDto";
            id: string;
            label: string;
        }>;
        buildingBlocks: Array<
            | {
                  __typename?: "CarouselBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "ContentBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
                  type: string;
                  assetId?: string | null;
                  contentColumnOne?: string | null;
                  contentColumnTwo?: string | null;
                  objectFit: ObjectFit;
                  textLayout: ContentBlockLayout;
                  textBackground: BuildingBlockBackground;
                  list?: {
                      __typename?: "ContentBlockListDto";
                      layout: ContentBlockListLayout;
                      items: Array<{
                          __typename?: "ContentBlockListItemDto";
                          id?: string | null;
                          assetId?: string | null;
                          content: string;
                          subtitle: string;
                          title: string;
                          sortOrder: number;
                      }>;
                  } | null;
              }
            | {
                  __typename?: "ConversationBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "HotspotBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "InteractiveQuestionBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "LinkBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "MoodboardBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "MpcBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "PollBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "QuestionConnectBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "QuoteBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "ReferenceCardsCarouselBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "SceneBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "SortQuestionBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "StandBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
        >;
    };
};

export type GetReferenceCardCategoriesQueryVariables = Exact<{
    language?: InputMaybe<Language>;
}>;

export type GetReferenceCardCategoriesQuery = {
    __typename?: "Query";
    getReferenceCardCategories: Array<{
        __typename?: "ReferenceCardCategoryDto";
        id: string;
        label: string;
    }>;
};

export type GetReferenceCardLeadsQueryVariables = Exact<{
    language?: InputMaybe<Language>;
}>;

export type GetReferenceCardLeadsQuery = {
    __typename?: "Query";
    getReferenceCardLeads: Array<{
        __typename?: "ReferenceCardLeadDto";
        id: string;
        label: string;
    }>;
};

export type GetReferenceCardsForAppQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<
        Array<ReferenceCardsFilterInput> | ReferenceCardsFilterInput
    >;
    programId: Scalars["UUID"]["input"];
}>;

export type GetReferenceCardsForAppQuery = {
    __typename?: "Query";
    getReferenceCardsForApp: {
        __typename?: "ReferenceCardsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "ReferenceCardDto";
            id: string;
            title: string;
            description?: string | null;
            thumbnail: string;
            headerImage?: string | null;
            buildingBlocks: Array<
                | { __typename?: "CarouselBuildingBlockDto"; id: string }
                | {
                      __typename?: "ContentBuildingBlockDto";
                      id: string;
                      type: string;
                      background: BuildingBlockBackground;
                      order: number;
                      assetId?: string | null;
                      contentColumnOne?: string | null;
                      contentColumnTwo?: string | null;
                      objectFit: ObjectFit;
                      textLayout: ContentBlockLayout;
                      textBackground: BuildingBlockBackground;
                      list?: {
                          __typename?: "ContentBlockListDto";
                          layout: ContentBlockListLayout;
                          items: Array<{
                              __typename?: "ContentBlockListItemDto";
                              id?: string | null;
                              assetId?: string | null;
                              content: string;
                              subtitle: string;
                              title: string;
                              sortOrder: number;
                          }>;
                      } | null;
                  }
                | { __typename?: "ConversationBuildingBlockDto"; id: string }
                | { __typename?: "HotspotBuildingBlockDto"; id: string }
                | {
                      __typename?: "InteractiveQuestionBuildingBlockDto";
                      id: string;
                  }
                | { __typename?: "LinkBuildingBlockDto"; id: string }
                | { __typename?: "MoodboardBuildingBlockDto"; id: string }
                | { __typename?: "MpcBuildingBlockDto"; id: string }
                | { __typename?: "PollBuildingBlockDto"; id: string }
                | { __typename?: "QuestionConnectBuildingBlockDto"; id: string }
                | { __typename?: "QuoteBuildingBlockDto"; id: string }
                | {
                      __typename?: "ReferenceCardsCarouselBuildingBlockDto";
                      id: string;
                  }
                | { __typename?: "SceneBuildingBlockDto"; id: string }
                | { __typename?: "SortQuestionBuildingBlockDto"; id: string }
                | { __typename?: "StandBuildingBlockDto"; id: string }
            >;
            lead?: {
                __typename?: "ReferenceCardLeadDto";
                id: string;
                label: string;
            } | null;
        }>;
    };
};

export type GetRoleQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetRoleQuery = {
    __typename?: "Query";
    getRole?: {
        __typename?: "RoleDetailsDto";
        id: string;
        keycloakId: string;
        name: string;
        permissions: Array<string>;
    } | null;
};

export type GetShortcodeQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetShortcodeQuery = {
    __typename?: "Query";
    getShortcode: {
        __typename?: "ShortcodeDto";
        id: string;
        shortcode: string;
        educationalProgram: {
            __typename?: "EducationalProgramDto";
            id: string;
            name: string;
        };
        educationalPath?: {
            __typename?: "EducationalPathDto";
            id: string;
            name: string;
        } | null;
        module?: { __typename?: "ModuleDto"; id: string; title: string } | null;
        didacticTool?: {
            __typename?: "DidacticToolDto";
            id: string;
            name: string;
        } | null;
    };
};

export type GetSignedAssetUrlQueryVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type GetSignedAssetUrlQuery = {
    __typename?: "Query";
    getSignedAssetUrl: string;
};

export type GetStudentsForProgressTableQueryVariables = Exact<{
    groupId: Scalars["UUID"]["input"];
    userIds?: InputMaybe<
        Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"]
    >;
}>;

export type GetStudentsForProgressTableQuery = {
    __typename?: "Query";
    getUsersForGroup: Array<{
        __typename?: "AppUserDto";
        id: string;
        emailAddress: string;
        firstName: string;
        lastName: string;
    }>;
};

export type GetStudentsWithProgressOverviewQueryVariables = Exact<{
    filters: UserProgressFilterInput;
    sorting?: InputMaybe<Array<UsersSortingInput> | UsersSortingInput>;
    pagination: PaginationInput;
}>;

export type GetStudentsWithProgressOverviewQuery = {
    __typename?: "Query";
    getProgressOverview: {
        __typename?: "AppUsersProgressDto";
        rows: Array<{
            __typename?: "AppUserProgressDto";
            id: string;
            firstName: string;
            lastName: string;
            emailAddress: string;
            learningPaths: Array<{
                __typename?: "AppLearningPathProgressDto";
                id: string;
                name: string;
                progress: number;
            }>;
            modules: Array<{
                __typename?: "AppModuleProgressDto";
                id: string;
                name: string;
                progress: number;
            }>;
            didacticTools: Array<{
                __typename?: "AppDidacticToolProgressDto";
                type: DidacticToolType;
                id: string;
                name: string;
                progress: number;
                score: number;
                duration?: number | null;
            }>;
        }>;
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
    };
};

export type GetTenantByNameQueryVariables = Exact<{
    name: Scalars["String"]["input"];
}>;

export type GetTenantByNameQuery = {
    __typename?: "Query";
    getTenantByName: {
        __typename?: "TenantDto";
        name: string;
        defaultGroupId?: string | null;
        defaultRoleId: string;
        defaultProductId?: string | null;
        allowRegistration: boolean;
        theme: {
            __typename?: "ThemeDto";
            brandColors: string;
            fonts: FontSet;
            neutralColorsVariant: NeutralColorVariant;
            radiuses: BorderRadius;
        };
    };
};

export type GetTenantsForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantsForUserQuery = {
    __typename?: "Query";
    getTenantsForUser: Array<{
        __typename?: "TenantDto";
        id: string;
        name: string;
        defaultGroupId?: string | null;
        defaultRoleId: string;
    }>;
};

export type GetThemeQueryVariables = Exact<{
    programId?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type GetThemeQuery = {
    __typename?: "Query";
    theme: {
        __typename?: "ThemeDto";
        brandColors: string;
        fonts: FontSet;
        neutralColorsVariant: NeutralColorVariant;
        radiuses: BorderRadius;
    };
};

export type GetThemeForEducationalProgramQueryVariables = Exact<{
    programId: Scalars["UUID"]["input"];
}>;

export type GetThemeForEducationalProgramQuery = {
    __typename?: "Query";
    theme: {
        __typename?: "ProgramThemeDto";
        brandColors?: string | null;
        fonts?: FontSet | null;
        neutralColorsVariant?: NeutralColorVariant | null;
        programId: string;
        radiuses?: BorderRadius | null;
    };
};

export type GetUserQueryVariables = Exact<{
    getUserId: Scalars["UUID"]["input"];
}>;

export type GetUserQuery = {
    __typename?: "Query";
    getUser: {
        __typename?: "UserDetailDto";
        emailAddress: string;
        firstName: string;
        id: string;
        isActive: boolean;
        lastName: string;
        roleId: string;
        groupIds: Array<string>;
        tenantIds: Array<string>;
        modifiedAt?: any | null;
        modifiedBy?: string | null;
        createdAt: any;
    };
};

export type UserApplicableForChildGroupFragment = {
    __typename?: "AppUserDto";
    id: string;
    firstName: string;
    lastName: string;
};

export type GetUsersApplicableForChildGroupQueryVariables = Exact<{
    parentGroupId: Scalars["UUID"]["input"];
}>;

export type GetUsersApplicableForChildGroupQuery = {
    __typename?: "Query";
    getUsersApplicableForChildGroup: Array<{
        __typename?: "AppUserDto";
        id: string;
        firstName: string;
        lastName: string;
    }>;
};

export type IssueTenantTokenMutationVariables = Exact<{
    tenant: Scalars["String"]["input"];
}>;

export type IssueTenantTokenMutation = {
    __typename?: "Mutation";
    issueTenantToken: {
        __typename?: "TenantTokenDto";
        token: string;
        tenant: { __typename?: "TenantDto"; name: string };
    };
};

export type ListAppUsersQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<Array<UsersFilterInput> | UsersFilterInput>;
    sorting?: InputMaybe<Array<UsersSortingInput> | UsersSortingInput>;
}>;

export type ListAppUsersQuery = {
    __typename?: "Query";
    getUsers: {
        __typename?: "UsersDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "UserDto";
            emailAddress: string;
            firstName: string;
            id: string;
            isActive: boolean;
            lastName: string;
            createdAt: any;
        }>;
    };
};

export type GetDidacticToolsQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<
        Array<DidacticToolsFilterInput> | DidacticToolsFilterInput
    >;
}>;

export type GetDidacticToolsQuery = {
    __typename?: "Query";
    getDidacticTools: {
        __typename?: "DidacticToolsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "DidacticToolDto";
            description: string;
            headerImageId?: string | null;
            headerIntroText?: string | null;
            id: string;
            name: string;
            type: DidacticToolType;
        }>;
    };
};

export type GetEducationalPathsQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<
        Array<EducationalPathsFilterInput> | EducationalPathsFilterInput
    >;
}>;

export type GetEducationalPathsQuery = {
    __typename?: "Query";
    getEducationalPaths: {
        __typename?: "EducationalPathsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "EducationalPathDto";
            id: string;
            name: string;
            description: string;
        }>;
    };
};

export type GetEducationalProgramsQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<
        Array<EducationalProgramsFilterInput> | EducationalProgramsFilterInput
    >;
}>;

export type GetEducationalProgramsQuery = {
    __typename?: "Query";
    getEducationalPrograms: {
        __typename?: "EducationalProgramsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "EducationalProgramDto";
            id: string;
            name: string;
            description: string;
        }>;
    };
};

export type GetGroupsQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<Array<GroupsFilterInput> | GroupsFilterInput>;
}>;

export type GetGroupsQuery = {
    __typename?: "Query";
    getGroups: {
        __typename?: "GroupsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            parentId?: string | null;
            showGroupDuringRegistration: boolean;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                parentId?: string | null;
                showGroupDuringRegistration: boolean;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    parentId?: string | null;
                    showGroupDuringRegistration: boolean;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        parentId?: string | null;
                        showGroupDuringRegistration: boolean;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                            parentId?: string | null;
                            showGroupDuringRegistration: boolean;
                            subgroups: Array<{
                                __typename?: "GroupDto";
                                id: string;
                                name: string;
                                parentId?: string | null;
                                showGroupDuringRegistration: boolean;
                                subgroups: Array<{
                                    __typename?: "GroupDto";
                                    id: string;
                                    name: string;
                                    parentId?: string | null;
                                    showGroupDuringRegistration: boolean;
                                }>;
                            }>;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    };
};

export type GetModulesQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<Array<ModulesFilterInput> | ModulesFilterInput>;
}>;

export type GetModulesQuery = {
    __typename?: "Query";
    getModules: {
        __typename?: "ModulesDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "ModuleDto";
            id: string;
            content?: string | null;
            dashboardImageId?: string | null;
            description: string;
            headerImageId?: string | null;
            shortTitle?: string | null;
            title: string;
        }>;
    };
};

export type GetProductsQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<Array<ProductsFilterInput> | ProductsFilterInput>;
}>;

export type GetProductsQuery = {
    __typename?: "Query";
    getProducts: {
        __typename?: "ProductsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "ProductDto";
            id: string;
            name: string;
            educationalPrograms: Array<{
                __typename?: "EducationalProgramDto";
                id: string;
                name: string;
                description: string;
            }>;
            statistics?: {
                __typename?: "ProductStatisticsDto";
                total: number;
                valid: number;
                expired: number;
                inactive: number;
            } | null;
        }>;
    };
};

export type GetReferenceCardsQueryVariables = Exact<{
    pagination: PaginationInput;
    filters?: InputMaybe<
        Array<ReferenceCardsFilterInput> | ReferenceCardsFilterInput
    >;
}>;

export type GetReferenceCardsQuery = {
    __typename?: "Query";
    getReferenceCards: {
        __typename?: "ReferenceCardsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "ReferenceCardDto";
            id: string;
            title: string;
            description?: string | null;
            body?: string | null;
            thumbnail: string;
            headerImage?: string | null;
            programs: Array<{
                __typename?: "EducationalProgramDto";
                id: string;
                name: string;
            }>;
            didacticTools: Array<{
                __typename?: "DidacticToolDto";
                id: string;
                name: string;
            }>;
            categories: Array<{
                __typename?: "ReferenceCardCategoryDto";
                id: string;
                label: string;
            }>;
            lead?: {
                __typename?: "ReferenceCardLeadDto";
                id: string;
                label: string;
            } | null;
        }>;
    };
};

export type ListRolesQueryVariables = Exact<{
    paginationInput: PaginationInput;
    filters?: InputMaybe<Array<RolesFilterInput> | RolesFilterInput>;
}>;

export type ListRolesQuery = {
    __typename?: "Query";
    getRoles: {
        __typename?: "RolesDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "RoleDto";
            id: string;
            keycloakId: string;
            name: string;
        }>;
    };
};

export type GetShortcodesQueryVariables = Exact<{
    pagination: PaginationInput;
}>;

export type GetShortcodesQuery = {
    __typename?: "Query";
    getShortcodes: {
        __typename?: "ShortcodesDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "ShortcodeDto";
            id: string;
            shortcode: string;
            educationalProgram: {
                __typename?: "EducationalProgramDto";
                id: string;
                name: string;
            };
            educationalPath?: {
                __typename?: "EducationalPathDto";
                id: string;
                name: string;
            } | null;
            module?: {
                __typename?: "ModuleDto";
                id: string;
                title: string;
            } | null;
            didacticTool?: {
                __typename?: "DidacticToolDto";
                id: string;
                name: string;
            } | null;
        }>;
    };
};

export type GetPermissionsQueryVariables = Exact<{
    pagination: PaginationInput;
}>;

export type GetPermissionsQuery = {
    __typename?: "Query";
    getPermissions: {
        __typename?: "PermissionsDto";
        pagination: {
            __typename?: "Pagination";
            page: number;
            pageSize: number;
            totalCount: number;
        };
        rows: Array<{
            __typename?: "PermissionDto";
            enabled: boolean;
            id: string;
            key: string;
            name: string;
        }>;
    };
};

export type RegisterNewUserMutationVariables = Exact<{
    input: RegisterNewUserInput;
}>;

export type RegisterNewUserMutation = {
    __typename?: "Mutation";
    registerNewUser: {
        __typename?: "AppUserDto";
        emailAddress: string;
        firstName: string;
        id: string;
        lastName: string;
        educationalPathsProgress: Array<{
            __typename?: "AppEducationalPathProgressDto";
            id: string;
            name: string;
            progress: number;
        }>;
    };
};

export type RequestPasswordResetsMutationVariables = Exact<{
    ids: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type RequestPasswordResetsMutation = {
    __typename?: "Mutation";
    requestPasswordResets: {
        __typename?: "BulkActionResult";
        successful: Array<string>;
        failed: Array<string>;
    };
};

export type StartDidacticToolMutationVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type StartDidacticToolMutation = {
    __typename?: "Mutation";
    startDidacticTool: boolean;
};

export type StartUsersWithProgressExportMutationVariables = Exact<{
    filters: UserProgressFilterInput;
}>;

export type StartUsersWithProgressExportMutation = {
    __typename?: "Mutation";
    startUsersWithProgressExport: {
        __typename: "ExportActionDto";
        id: string;
        status: Status;
    };
};

export type ToegangOrgJwtMutationVariables = Exact<{
    jwt: Scalars["String"]["input"];
}>;

export type ToegangOrgJwtMutation = {
    __typename?: "Mutation";
    toegangOrgJwt: boolean;
};

export type UpdateBuildingBlocksForDidacticToolMutationVariables = Exact<{
    input: UpdateBuildingBlocksForDidacticToolInput;
}>;

export type UpdateBuildingBlocksForDidacticToolMutation = {
    __typename?: "Mutation";
    updateBuildingBlocksForDidacticTool: {
        __typename?: "BuildingBlocksForDidacticToolDto";
        buildingBlocks: Array<
            | {
                  __typename?: "CarouselBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "ContentBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "ConversationBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "HotspotBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "InteractiveQuestionBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "LinkBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "MoodboardBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "MpcBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "PollBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "QuestionConnectBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "QuoteBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "ReferenceCardsCarouselBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "SceneBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "SortQuestionBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
            | {
                  __typename?: "StandBuildingBlockDto";
                  id: string;
                  order: number;
                  type: string;
              }
        >;
    };
};

export type UpdateChildGroupMutationVariables = Exact<{
    input: UpdateChildGroupInput;
}>;

export type UpdateChildGroupMutation = {
    __typename?: "Mutation";
    updateChildGroup: {
        __typename?: "GroupDto";
        id: string;
        name: string;
        parentId?: string | null;
    };
};

export type UpdateDidacticToolMutationVariables = Exact<{
    input: UpdateDidacticToolInput;
}>;

export type UpdateDidacticToolMutation = {
    __typename?: "Mutation";
    updateDidacticTool?: {
        __typename?: "DidacticToolDto";
        description: string;
        headerImageId?: string | null;
        headerIntroText?: string | null;
        durationIndication?: string | null;
        learningAdviceTitle?: string | null;
        learningAdviceDescription?: string | null;
        altLabel?: string | null;
        id: string;
        name: string;
        type: DidacticToolType;
    } | null;
};

export type UpdateEducationalPathMutationVariables = Exact<{
    input: UpdateEducationalPathInput;
}>;

export type UpdateEducationalPathMutation = {
    __typename?: "Mutation";
    updateEducationalPath?: {
        __typename?: "EducationalPathDto";
        id: string;
        name: string;
        description: string;
        durationIndication?: string | null;
        headerIntroText?: string | null;
    } | null;
};

export type UpdateEducationalPathsForProgramMutationVariables = Exact<{
    input: UpdateEducationalPathsForProgramInput;
}>;

export type UpdateEducationalPathsForProgramMutation = {
    __typename?: "Mutation";
    updateEducationalPathsForProgram: {
        __typename?: "EducationalPathsForProgramDto";
        programId: string;
        educationalPaths: Array<{
            __typename?: "EducationalPathForProgramDto";
            id: string;
            name: string;
            order: number;
        }>;
    };
};

export type UpdateEducationalProgramMutationVariables = Exact<{
    input: UpdateEducationalProgramInput;
}>;

export type UpdateEducationalProgramMutation = {
    __typename?: "Mutation";
    updateEducationalProgram: {
        __typename?: "EducationalProgramDto";
        id: string;
        name: string;
        description: string;
    };
};

export type UpdateGroupMutationVariables = Exact<{
    input: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
    __typename?: "Mutation";
    updateGroup: {
        __typename?: "GroupDetailDto";
        id: string;
        name: string;
        productIds: Array<string>;
        showGroupDuringRegistration: boolean;
        parentId?: string | null;
        externalCode?: string | null;
    };
};

export type UpdateModuleMutationVariables = Exact<{
    input: UpdateModuleInput;
}>;

export type UpdateModuleMutation = {
    __typename?: "Mutation";
    updateModule?: {
        __typename?: "ModuleDto";
        id: string;
        content?: string | null;
        dashboardImageId?: string | null;
        description: string;
        durationIndication?: string | null;
        headerImageId?: string | null;
        shortTitle?: string | null;
        title: string;
    } | null;
};

export type UpdateModuleBlocksForModuleMutationVariables = Exact<{
    input: UpdateModuleBlocksForModuleInput;
}>;

export type UpdateModuleBlocksForModuleMutation = {
    __typename?: "Mutation";
    updateModuleBlocksForModule: Array<
        | {
              __typename?: "DidacticToolsModuleBlockDto";
              id?: string | null;
              order: number;
              type: string;
              didacticToolBlocks?: Array<{
                  __typename?: "ModuleBlockDidacticToolDto";
                  didacticToolId: string;
                  didacticTool?: {
                      __typename?: "DidacticToolDto";
                      id: string;
                      name: string;
                  } | null;
              }> | null;
          }
        | {
              __typename?: "TitleModuleBlockDto";
              id?: string | null;
              order: number;
              type: string;
              title?: string | null;
              description?: string | null;
          }
        | {
              __typename?: "VideoModuleBlockDto";
              id?: string | null;
              order: number;
              type: string;
              assetId: string;
              title?: string | null;
              durationIndication?: string | null;
          }
    >;
};

export type UpdateModulesForEducationalPathMutationVariables = Exact<{
    input: UpdateModulesForEducationalPathInput;
}>;

export type UpdateModulesForEducationalPathMutation = {
    __typename?: "Mutation";
    updateModulesForEducationalPath: {
        __typename?: "ModulesForEducationalPathDto";
        educationalPathId: string;
        modules: Array<{
            __typename?: "ModuleForEducationalPathDto";
            id: string;
            name: string;
            order: number;
        }>;
    };
};

export type UpdateProductMutationVariables = Exact<{
    input: UpdateProductInput;
}>;

export type UpdateProductMutation = {
    __typename?: "Mutation";
    updateProduct: {
        __typename?: "ProductDetailDto";
        id: string;
        educationalProgramIds: Array<string>;
        name: string;
    };
};

export type UpdateReferenceCardMutationVariables = Exact<{
    data: ReferenceCardInput;
}>;

export type UpdateReferenceCardMutation = {
    __typename?: "Mutation";
    updateReferenceCard: {
        __typename: "ReferenceCardDto";
        id: string;
        headerImage?: string | null;
        thumbnail: string;
        title: string;
        description?: string | null;
        lead?: {
            __typename?: "ReferenceCardLeadDto";
            id: string;
            label: string;
        } | null;
        didacticTools: Array<{
            __typename?: "DidacticToolDto";
            id: string;
            name: string;
        }>;
        programs: Array<{
            __typename?: "EducationalProgramDto";
            id: string;
            label: string;
        }>;
        categories: Array<{
            __typename?: "ReferenceCardCategoryDto";
            id: string;
            label: string;
        }>;
        buildingBlocks: Array<
            | {
                  __typename?: "CarouselBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "ContentBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
                  type: string;
                  assetId?: string | null;
                  contentColumnOne?: string | null;
                  contentColumnTwo?: string | null;
                  objectFit: ObjectFit;
                  textLayout: ContentBlockLayout;
                  textBackground: BuildingBlockBackground;
                  list?: {
                      __typename?: "ContentBlockListDto";
                      layout: ContentBlockListLayout;
                      items: Array<{
                          __typename?: "ContentBlockListItemDto";
                          id?: string | null;
                          assetId?: string | null;
                          content: string;
                          subtitle: string;
                          title: string;
                          sortOrder: number;
                      }>;
                  } | null;
              }
            | {
                  __typename?: "ConversationBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "HotspotBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "InteractiveQuestionBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "LinkBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "MoodboardBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "MpcBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "PollBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "QuestionConnectBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "QuoteBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "ReferenceCardsCarouselBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "SceneBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "SortQuestionBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
            | {
                  __typename?: "StandBuildingBlockDto";
                  id: string;
                  background: BuildingBlockBackground;
                  order: number;
              }
        >;
    };
};

export type UpdateRoleMutationVariables = Exact<{
    input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = {
    __typename?: "Mutation";
    updateRole?: {
        __typename?: "RoleDetailsDto";
        id: string;
        keycloakId: string;
        name: string;
        permissions: Array<string>;
    } | null;
};

export type UpdateShortcodeMutationVariables = Exact<{
    input: UpdateShortcodeInput;
}>;

export type UpdateShortcodeMutation = {
    __typename?: "Mutation";
    updateShortcode: {
        __typename?: "ShortcodeDto";
        id: string;
        shortcode: string;
        educationalProgram: {
            __typename?: "EducationalProgramDto";
            id: string;
        };
        educationalPath?: {
            __typename?: "EducationalPathDto";
            id: string;
        } | null;
        module?: { __typename?: "ModuleDto"; id: string } | null;
        didacticTool?: { __typename?: "DidacticToolDto"; id: string } | null;
    };
};

export type UpdateTenantMutationVariables = Exact<{
    input: UpdateTenantInput;
}>;

export type UpdateTenantMutation = {
    __typename?: "Mutation";
    updateTenant: {
        __typename?: "TenantDto";
        name: string;
        defaultGroupId?: string | null;
        defaultRoleId: string;
        allowRegistration: boolean;
        defaultProductId?: string | null;
        theme: {
            __typename?: "ThemeDto";
            brandColors: string;
            fonts: FontSet;
            neutralColorsVariant: NeutralColorVariant;
            radiuses: BorderRadius;
        };
    };
};

export type UpdateThemeForEducationalProgramMutationVariables = Exact<{
    input: UpdateThemeForEducationalProgramInput;
}>;

export type UpdateThemeForEducationalProgramMutation = {
    __typename?: "Mutation";
    theme: {
        __typename?: "ProgramThemeDto";
        brandColors?: string | null;
        fonts?: FontSet | null;
        neutralColorsVariant?: NeutralColorVariant | null;
        programId: string;
        radiuses?: BorderRadius | null;
    };
};

export type UpdateUserMutationVariables = Exact<{
    user: UpdateUserInput;
}>;

export type UpdateUserMutation = {
    __typename?: "Mutation";
    updateUser: {
        __typename?: "UserDetailDto";
        id: string;
        emailAddress: string;
        firstName: string;
        isActive: boolean;
        lastName: string;
        roleId: string;
        modifiedAt?: any | null;
        modifiedBy?: string | null;
    };
};

export type SubGroupLeveLSevenFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
};

export type SubGroupLeveLSixFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    subgroups: Array<{ __typename?: "GroupDto"; id: string; name: string }>;
};

export type SubGroupLeveLFiveFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    subgroups: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{ __typename?: "GroupDto"; id: string; name: string }>;
    }>;
};

export type SubGroupLeveLFourFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    subgroups: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
            }>;
        }>;
    }>;
};

export type SubGroupLeveLThreeFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    subgroups: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                }>;
            }>;
        }>;
    }>;
};

export type SubGroupLeveLTwoFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    subgroups: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type SubGroupLeveLOneFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    subgroups: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type RootGroupFragment = {
    __typename?: "GroupDto";
    id: string;
    name: string;
    subgroups: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                            subgroups: Array<{
                                __typename?: "GroupDto";
                                id: string;
                                name: string;
                            }>;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type GetGroupsForRegistrationQueryVariables = Exact<{
    groupId: Scalars["UUID"]["input"];
}>;

export type GetGroupsForRegistrationQuery = {
    __typename?: "Query";
    getChildGroupsForRegistration: Array<{
        __typename?: "GroupDto";
        id: string;
        name: string;
        subgroups: Array<{
            __typename?: "GroupDto";
            id: string;
            name: string;
            subgroups: Array<{
                __typename?: "GroupDto";
                id: string;
                name: string;
                subgroups: Array<{
                    __typename?: "GroupDto";
                    id: string;
                    name: string;
                    subgroups: Array<{
                        __typename?: "GroupDto";
                        id: string;
                        name: string;
                        subgroups: Array<{
                            __typename?: "GroupDto";
                            id: string;
                            name: string;
                            subgroups: Array<{
                                __typename?: "GroupDto";
                                id: string;
                                name: string;
                                subgroups: Array<{
                                    __typename?: "GroupDto";
                                    id: string;
                                    name: string;
                                }>;
                            }>;
                        }>;
                    }>;
                }>;
            }>;
        }>;
    }>;
};

export type GetUserConfigurationQueryVariables = Exact<{
    licenseSorting?: InputMaybe<
        Array<LicensesSortingInput> | LicensesSortingInput
    >;
}>;

export type GetUserConfigurationQuery = {
    __typename?: "Query";
    getUserConfiguration: {
        __typename?: "UserConfigurationDto";
        authorities: Array<string>;
        user: {
            __typename?: "UserDto";
            id: string;
            firstName: string;
            lastName: string;
            emailAddress: string;
        };
        licenses: Array<{
            __typename?: "LicenseDto";
            id: string;
            validUntil?: any | null;
            startAt: any;
            userExpirationSeenAt?: any | null;
            isActive: boolean;
            product: { __typename?: "ProductDto"; id: string; name: string };
        }>;
    };
};

export type LicenseExpirationSeenMutationVariables = Exact<{
    id: Scalars["UUID"]["input"];
}>;

export type LicenseExpirationSeenMutation = {
    __typename?: "Mutation";
    licenseExpirationSeen: {
        __typename?: "LicenseDto";
        id: string;
        validUntil?: any | null;
        startAt: any;
        userExpirationSeenAt?: any | null;
        product: { __typename?: "ProductDto"; name: string };
        user: { __typename?: "UserDto"; firstName: string; lastName: string };
    };
};

export type PingQueryVariables = Exact<{ [key: string]: never }>;

export type PingQuery = { __typename?: "Query"; ping: string };

export type GetTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantsQuery = {
    __typename?: "Query";
    getTenants: Array<{
        __typename?: "TenantDto";
        id: string;
        name: string;
        defaultGroupId?: string | null;
        defaultRoleId: string;
    }>;
};

export type UpdateLicensesMutationVariables = Exact<{
    input: Array<UpdateLicenseInput> | UpdateLicenseInput;
}>;

export type UpdateLicensesMutation = {
    __typename?: "Mutation";
    updateLicenses: Array<{
        __typename?: "LicenseDto";
        validUntil?: any | null;
        startAt: any;
        isActive: boolean;
        user: { __typename?: "UserDto"; firstName: string; lastName: string };
    }>;
};

export const GroupFieldsFragmentDoc = gql`
    fragment GroupFields on GroupDto {
        id
        name
        parentId
        showGroupDuringRegistration
    }
`;
export const SubgroupFieldsFragmentDoc = gql`
    fragment SubgroupFields on GroupDto {
        ...GroupFields
    }
    ${GroupFieldsFragmentDoc}
`;
export const SubgroupsRecursiveFragmentDoc = gql`
    fragment SubgroupsRecursive on GroupDto {
        subgroups {
            ...SubgroupFields
            subgroups {
                ...SubgroupFields
                subgroups {
                    ...SubgroupFields
                    subgroups {
                        ...SubgroupFields
                        subgroups {
                            ...SubgroupFields
                            subgroups {
                                ...SubgroupFields
                            }
                        }
                    }
                }
            }
        }
    }
    ${SubgroupFieldsFragmentDoc}
`;
export const MpcAnswerCorrectnessFragmentDoc = gql`
    fragment MpcAnswerCorrectness on MpcAnswerCorrectness {
        buildingBlockId
        correct
        correctAnswers
        givenAnswers
        remainingAttempts
        reasoning
        type
        hasHelpText
        helpText
        incorrectFeedbackText
        correctFeedbackText
    }
`;
export const StandAnswerCorrectnessFragmentDoc = gql`
    fragment StandAnswerCorrectness on StandAnswerCorrectness {
        correctOption
        givenAnswer
        buildingBlockId
        correct
        reasoning
        type
        hasHelpText
        incorrectFeedbackText
        correctFeedbackText
    }
`;
export const QuestionConnectAnswerCorrectnessFragmentDoc = gql`
    fragment QuestionConnectAnswerCorrectness on QuestionConnectAnswerCorrectness {
        buildingBlockId
        correct
        correctAnswersForQuestionConnect: correctAnswers
        correctFeedbackText
        givenAnswers
        hasHelpText
        incorrectFeedbackText
        reasoning
        remainingAttempts
        type
    }
`;
export const SortQuestionAnswerCorrectnessFragmentDoc = gql`
    fragment SortQuestionAnswerCorrectness on SortQuestionAnswerCorrectness {
        buildingBlockId
        correct
        correctAnswersForSortQuestion: correctAnswers
        correctFeedbackText
        givenAnswers
        hasHelpText
        incorrectFeedbackText
        reasoning
        remainingAttempts
        type
    }
`;
export const PollQuestionAnswerCorrectnessFragmentDoc = gql`
    fragment PollQuestionAnswerCorrectness on PollAnswerCorrectness {
        buildingBlockId
        correct
        correctFeedbackText
        givenAnswers
        hasHelpText
        incorrectFeedbackText
        reasoning
        remainingAttempts
        results {
            answerId
            percentage
        }
        type
    }
`;
export const QuoteBuildingBlockFragmentDoc = gql`
    fragment QuoteBuildingBlock on QuoteBuildingBlockDto {
        id
        order
        type
        background
        quote
        name
        assetId
        quoteLayout: layout
    }
`;
export const SortQuestionBuildingBlockFragmentDoc = gql`
    fragment SortQuestionBuildingBlock on SortQuestionBuildingBlockDto {
        id
        type
        order
        background
        introduction
        question
        sortQuestionInstruction: instruction
        sortQuestionLayout: layout
        correctFeedbackText
        incorrectFeedbackText
        assetId
        objectFit
        answers {
            id
            sortAnswerText: text
            order
        }
        label {
            id
            label
        }
    }
`;
export const ContentBuildingBlockFragmentDoc = gql`
    fragment ContentBuildingBlock on ContentBuildingBlockDto {
        id
        type
        background
        order
        assetId
        contentColumnOne
        contentColumnTwo
        objectFit
        textLayout: layout
        textBackground: background
        list {
            layout
            items {
                id
                assetId
                content
                subtitle
                title
                sortOrder
            }
        }
    }
`;
export const MpcBuildingBlockFragmentDoc = gql`
    fragment MpcBuildingBlock on MpcBuildingBlockDto {
        id
        answers {
            id
            correct
            text
            assetId
            content
            sortOrder
        }
        assetId
        question
        mpcInstruction: instruction
        order
        type
        background
        mpcLayout: layout
        introduction
        helpText
        correctFeedbackText
        incorrectFeedbackText
        sorting
        objectFit
        label {
            id
            label
        }
    }
`;
export const StandBuildingBlockFragmentDoc = gql`
    fragment StandBuildingBlock on StandBuildingBlockDto {
        agreeText
        assetId
        correctOption
        disagreeText
        stand
        id
        standText: text
        standInstruction: instruction
        order
        type
        background
        standLayout: layout
        correctFeedbackText
        incorrectFeedbackText
        label {
            id
            label
        }
    }
`;
export const MoodboardBuildingBlockFragmentDoc = gql`
    fragment MoodboardBuildingBlock on MoodboardBuildingBlockDto {
        id
        type
        order
        background
        images {
            id
            assetId
            title
            text
        }
        moodboardLayout: layout
    }
`;
export const ConversationBuildingBlockFragmentDoc = gql`
    fragment ConversationBuildingBlock on ConversationBuildingBlockDto {
        id
        interval
        conversationLayout: layout
        messages {
            id
            message
            order
            personIndex
        }
        persons {
            assetId
            id
            name
            order
        }
        background
        order
        type
    }
`;
export const QuestionConnectBuildingBlockFragmentDoc = gql`
    fragment QuestionConnectBuildingBlock on QuestionConnectBuildingBlockDto {
        id
        instruction
        correctFeedbackText
        incorrectFeedbackText
        introduction
        items {
            id
            correctAnswer {
                id
                assetId
                buildingBlockId
                content
            }
            assetId
            buildingBlockId
            content
        }
        layout
        question
        background
        order
        type
        label {
            id
            label
        }
    }
`;
export const CarouselBuildingBlockFragmentDoc = gql`
    fragment CarouselBuildingBlock on CarouselBuildingBlockDto {
        id
        order
        type
        background
        carouselLayout: layout
        title
        blocks {
            id
            order
            type
            ...ContentBuildingBlock
            ...MpcBuildingBlock
            ...StandBuildingBlock
            ...MoodboardBuildingBlock
            ...ConversationBuildingBlock
            ...QuestionConnectBuildingBlock
        }
    }
    ${ContentBuildingBlockFragmentDoc}
    ${MpcBuildingBlockFragmentDoc}
    ${StandBuildingBlockFragmentDoc}
    ${MoodboardBuildingBlockFragmentDoc}
    ${ConversationBuildingBlockFragmentDoc}
    ${QuestionConnectBuildingBlockFragmentDoc}
`;
export const HotspotBuildingBlockFragmentDoc = gql`
    fragment HotspotBuildingBlock on HotspotBuildingBlockDto {
        id
        hotspotBuildingBlockAssetId: assetId
        content
        hotspotLayout: layout
        spots {
            content
            id
            title
            x
            y
        }
        hotspotTitle: title
        background
        id
        order
        type
    }
`;
export const ReferenceCardBuildingBlockFragmentDoc = gql`
    fragment ReferenceCardBuildingBlock on ReferenceCardsCarouselBuildingBlockDto {
        id
        type
        background
        order
        referenceCardlayout: layout
        title
        cards {
            headerImage
            thumbnail
            id
            title
            description
        }
    }
`;
export const LinkBuildingBlockFragmentDoc = gql`
    fragment LinkBuildingBlock on LinkBuildingBlockDto {
        id
        order
        type
        title
        linkInstruction: instruction
        url
        linkLabel
        openInNewWindow
        linkAssetId: assetId
        background
    }
`;
export const PollBuildingBlockFragmentDoc = gql`
    fragment PollBuildingBlock on PollBuildingBlockDto {
        id
        order
        type
        voteOption
        pollInstruction: instruction
        pollIntroduction: introduction
        pollQuestion: question
        pollAnswers: answers {
            id
            text
            sortOrder
        }
    }
`;
export const SceneBuildingBlockFragmentDoc = gql`
    fragment SceneBuildingBlock on SceneBuildingBlockDto {
        id
        order
        type
        sceneAssetId: assetId
        title
        description
    }
`;
export const InteractiveQuestionBuildingBlockFragmentDoc = gql`
    fragment InteractiveQuestionBuildingBlock on InteractiveQuestionBuildingBlockDto {
        background
        id
        type
        label {
            id
            label
        }
        interactiveQuestionLayout: layout
        order
        interactiveQuestionQuestion: question {
            ...MpcBuildingBlock
            ...StandBuildingBlock
        }
        sceneWhenWrong {
            ...SceneBuildingBlock
        }
    }
    ${MpcBuildingBlockFragmentDoc}
    ${StandBuildingBlockFragmentDoc}
    ${SceneBuildingBlockFragmentDoc}
`;
export const BlockLabelFragmentDoc = gql`
    fragment BlockLabel on BuildingBlocksLabelDto {
        id
        label
    }
`;
export const BaseBlockPropsFragmentDoc = gql`
    fragment BaseBlockProps on AppBuildingBlockDto {
        id
        order
        type
        label {
            ...BlockLabel
        }
    }
    ${BlockLabelFragmentDoc}
`;
export const AppPollBuildingBlockFragmentDoc = gql`
    fragment AppPollBuildingBlock on AppPollBuildingBlockDto {
        ...BaseBlockProps
        question
        pollAnswers: answers {
            id
            sortOrder
            text
        }
        instruction
        voteOption
        pollIntroduction: introduction
        pollLayout: layout
        order
        type
        background
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppQuoteBuildingBlockFragmentDoc = gql`
    fragment AppQuoteBuildingBlock on AppQuoteBuildingBlockDto {
        ...BaseBlockProps
        id
        order
        type
        quoteLayout: layout
        asset: assetId
        quote
        name
        background
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppQuestionConnectBuildingBlockFragmentDoc = gql`
    fragment AppQuestionConnectBuildingBlock on AppQuestionConnectBuildingBlockDto {
        ...BaseBlockProps
        id
        answers {
            answerId: id
            content
        }
        instruction
        introduction
        items {
            id
            assetId
            content
        }
        questionConnectLayout: layout
        question
        background
        order
        type
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppContentBuildingBlockFragmentDoc = gql`
    fragment AppContentBuildingBlock on AppContentBuildingBlockDto {
        ...BaseBlockProps
        id
        order
        type
        assetId
        background
        textLayout: layout
        contentColumnOne
        contentColumnTwo
        objectFit
        list {
            layout
            items {
                id
                assetId
                content
                subtitle
                title
                sortOrder
            }
        }
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppMpcBuildingBlockFragmentDoc = gql`
    fragment AppMpcBuildingBlock on AppMpcBuildingBlockDto {
        ...BaseBlockProps
        answers {
            id
            text
            assetId
            content
        }
        assetId
        question
        mpcInstruction: instruction
        id
        order
        type
        choiceType
        background
        objectFit
        mpcLayout: layout
        sorting
        introduction
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppStandBuildingBlockFragmentDoc = gql`
    fragment AppStandBuildingBlock on AppStandBuildingBlockDto {
        ...BaseBlockProps
        agreeText
        assetId
        disagreeText
        stand
        standInstruction: instruction
        id
        standText: text
        order
        type
        background
        standLayout: layout
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppMoodboardBuildingBlockFragmentDoc = gql`
    fragment AppMoodboardBuildingBlock on AppMoodboardBuildingBlockDto {
        ...BaseBlockProps
        id
        order
        type
        background
        images {
            id
            assetId
            sortOrder
            text
            title
        }
        layout
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppConversationBuildingBlockFragmentDoc = gql`
    fragment AppConversationBuildingBlock on AppConversationBuildingBlockDto {
        ...BaseBlockProps
        id
        interval
        conversationLayout: layout
        messages {
            id
            message
            order
            personIndex
        }
        persons {
            assetId
            id
            name
            order
        }
        background
        order
        type
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppCarouselBuildingBlockFragmentDoc = gql`
    fragment AppCarouselBuildingBlock on AppCarouselBuildingBlockDto {
        ...BaseBlockProps
        id
        order
        type
        background
        carouselLayout: layout
        title
        blocks {
            id
            order
            type
            ...AppContentBuildingBlock
            ...AppMpcBuildingBlock
            ...AppStandBuildingBlock
            ...AppMoodboardBuildingBlock
            ...AppConversationBuildingBlock
        }
    }
    ${BaseBlockPropsFragmentDoc}
    ${AppContentBuildingBlockFragmentDoc}
    ${AppMpcBuildingBlockFragmentDoc}
    ${AppStandBuildingBlockFragmentDoc}
    ${AppMoodboardBuildingBlockFragmentDoc}
    ${AppConversationBuildingBlockFragmentDoc}
`;
export const AppHotspotBuildingBlockFragmentDoc = gql`
    fragment AppHotspotBuildingBlock on AppHotspotBuildingBlockDto {
        ...BaseBlockProps
        id
        hotspotBuildingBlockAssetId: assetId
        content
        hotspotLayout: layout
        spots {
            content
            id
            title
            x
            y
        }
        hotspotTitle: title
        background
        id
        order
        type
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppSortQuestionBuildingBlockFragmentDoc = gql`
    fragment AppSortQuestionBuildingBlock on AppSortQuestionBuildingBlockDto {
        ...BaseBlockProps
        answers {
            id
            sortAnswerText: text
        }
        assetId
        background
        id
        sortQuestionInstruction: instruction
        introduction
        objectFit
        sortQuestionInstruction: instruction
        sortQuestionLayout: layout
        question
        order
        type
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppReferenceCardCarouselBuildingBlockFragmentDoc = gql`
    fragment AppReferenceCardCarouselBuildingBlock on AppReferenceCardCarouselBuildingBlockDto {
        ...BaseBlockProps
        id
        type
        order
        background
        referenceCardCarouselLayout: layout
        referenceCardCarouselTitle: title
        cards {
            id
            __typename
            headerImage
            thumbnail
            lead {
                id
                label
            }
            title
            description
            body
            categories {
                id
                label
            }
        }
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppSceneBuildingBlockFragmentDoc = gql`
    fragment AppSceneBuildingBlock on AppSceneBuildingBlockDto {
        ...BaseBlockProps
        id
        order
        type
        sceneAssetId: assetId
        title
        description
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppLinkBuildingBlockFragmentDoc = gql`
    fragment AppLinkBuildingBlock on AppLinkBuildingBlockDto {
        ...BaseBlockProps
        id
        order
        type
        title
        linkInstruction: instruction
        url
        linkLabel
        openInNewWindow
        linkAssetId: assetId
        background
    }
    ${BaseBlockPropsFragmentDoc}
`;
export const AppInteractiveQuestionBlockFragmentDoc = gql`
    fragment AppInteractiveQuestionBlock on AppInteractiveQuestionBuildingBlockDto {
        background
        id
        type
        label {
            id
            label
        }
        interactiveQuestionLayout: layout
        order
        interactiveQuestionQuestion: question {
            ...AppMpcBuildingBlock
            ...AppStandBuildingBlock
        }
        sceneWhenWrong {
            assetId
            layout
            title
            description
            type
        }
    }
    ${AppMpcBuildingBlockFragmentDoc}
    ${AppStandBuildingBlockFragmentDoc}
`;
export const AppGroupFragmentDoc = gql`
    fragment AppGroup on AppGroupDto {
        id
        name
        parentId
    }
`;
export const AppGroupMemberFragmentDoc = gql`
    fragment AppGroupMember on AppUserDto {
        id
        firstName
        lastName
        emailAddress
    }
`;
export const BaseModuleBlockFragmentDoc = gql`
    fragment BaseModuleBlock on BaseModuleBlock {
        id
        order
        type
    }
`;
export const DidacticToolsModuleBlockFragmentDoc = gql`
    fragment DidacticToolsModuleBlock on DidacticToolsModuleBlockDto {
        didacticToolBlocks {
            didacticToolId
            order
            variant
            didacticTool {
                id
                name
            }
        }
    }
`;
export const TitleModuleBlockFragmentDoc = gql`
    fragment TitleModuleBlock on TitleModuleBlockDto {
        title
        description
    }
`;
export const VideoModuleBlockFragmentDoc = gql`
    fragment VideoModuleBlock on VideoModuleBlockDto {
        assetId
        title
        durationIndication
    }
`;
export const UserApplicableForChildGroupFragmentDoc = gql`
    fragment UserApplicableForChildGroup on AppUserDto {
        id
        firstName
        lastName
    }
`;
export const SubGroupLeveLSevenFragmentDoc = gql`
    fragment SubGroupLeveLSeven on GroupDto {
        id
        name
    }
`;
export const SubGroupLeveLSixFragmentDoc = gql`
    fragment SubGroupLeveLSix on GroupDto {
        id
        name
        subgroups {
            ...SubGroupLeveLSeven
        }
    }
    ${SubGroupLeveLSevenFragmentDoc}
`;
export const SubGroupLeveLFiveFragmentDoc = gql`
    fragment SubGroupLeveLFive on GroupDto {
        id
        name
        subgroups {
            ...SubGroupLeveLSix
        }
    }
    ${SubGroupLeveLSixFragmentDoc}
`;
export const SubGroupLeveLFourFragmentDoc = gql`
    fragment SubGroupLeveLFour on GroupDto {
        id
        name
        subgroups {
            ...SubGroupLeveLFive
        }
    }
    ${SubGroupLeveLFiveFragmentDoc}
`;
export const SubGroupLeveLThreeFragmentDoc = gql`
    fragment SubGroupLeveLThree on GroupDto {
        id
        name
        subgroups {
            ...SubGroupLeveLFour
        }
    }
    ${SubGroupLeveLFourFragmentDoc}
`;
export const SubGroupLeveLTwoFragmentDoc = gql`
    fragment SubGroupLeveLTwo on GroupDto {
        id
        name
        subgroups {
            ...SubGroupLeveLThree
        }
    }
    ${SubGroupLeveLThreeFragmentDoc}
`;
export const SubGroupLeveLOneFragmentDoc = gql`
    fragment SubGroupLeveLOne on GroupDto {
        id
        name
        subgroups {
            ...SubGroupLeveLTwo
        }
    }
    ${SubGroupLeveLTwoFragmentDoc}
`;
export const RootGroupFragmentDoc = gql`
    fragment RootGroup on GroupDto {
        id
        name
        subgroups {
            ...SubGroupLeveLOne
        }
    }
    ${SubGroupLeveLOneFragmentDoc}
`;
export const CheckBuildingBlockAnswersDocument = gql`
    query CheckBuildingBlockAnswers($input: CheckAnswersForDidacticToolInput!) {
        checkBuildingBlockAnswers(input: $input) {
            buildingBlockId
            correct
            reasoning
            type
            ...StandAnswerCorrectness
            ...MpcAnswerCorrectness
            ...QuestionConnectAnswerCorrectness
            ...SortQuestionAnswerCorrectness
        }
    }
    ${StandAnswerCorrectnessFragmentDoc}
    ${MpcAnswerCorrectnessFragmentDoc}
    ${QuestionConnectAnswerCorrectnessFragmentDoc}
    ${SortQuestionAnswerCorrectnessFragmentDoc}
`;

/**
 * __useCheckBuildingBlockAnswersQuery__
 *
 * To run a query within a React component, call `useCheckBuildingBlockAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBuildingBlockAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBuildingBlockAnswersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckBuildingBlockAnswersQuery(
    baseOptions: Apollo.QueryHookOptions<
        CheckBuildingBlockAnswersQuery,
        CheckBuildingBlockAnswersQueryVariables
    > &
        (
            | {
                  variables: CheckBuildingBlockAnswersQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CheckBuildingBlockAnswersQuery,
        CheckBuildingBlockAnswersQueryVariables
    >(CheckBuildingBlockAnswersDocument, options);
}
export function useCheckBuildingBlockAnswersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CheckBuildingBlockAnswersQuery,
        CheckBuildingBlockAnswersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CheckBuildingBlockAnswersQuery,
        CheckBuildingBlockAnswersQueryVariables
    >(CheckBuildingBlockAnswersDocument, options);
}
export function useCheckBuildingBlockAnswersSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              CheckBuildingBlockAnswersQuery,
              CheckBuildingBlockAnswersQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        CheckBuildingBlockAnswersQuery,
        CheckBuildingBlockAnswersQueryVariables
    >(CheckBuildingBlockAnswersDocument, options);
}
export type CheckBuildingBlockAnswersQueryHookResult = ReturnType<
    typeof useCheckBuildingBlockAnswersQuery
>;
export type CheckBuildingBlockAnswersLazyQueryHookResult = ReturnType<
    typeof useCheckBuildingBlockAnswersLazyQuery
>;
export type CheckBuildingBlockAnswersSuspenseQueryHookResult = ReturnType<
    typeof useCheckBuildingBlockAnswersSuspenseQuery
>;
export type CheckBuildingBlockAnswersQueryResult = Apollo.QueryResult<
    CheckBuildingBlockAnswersQuery,
    CheckBuildingBlockAnswersQueryVariables
>;
export const CheckExportActionDocument = gql`
    query CheckExportAction($id: UUID!) {
        checkExportAction(id: $id) {
            id
            status
            __typename
        }
    }
`;

/**
 * __useCheckExportActionQuery__
 *
 * To run a query within a React component, call `useCheckExportActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExportActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExportActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckExportActionQuery(
    baseOptions: Apollo.QueryHookOptions<
        CheckExportActionQuery,
        CheckExportActionQueryVariables
    > &
        (
            | { variables: CheckExportActionQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CheckExportActionQuery,
        CheckExportActionQueryVariables
    >(CheckExportActionDocument, options);
}
export function useCheckExportActionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CheckExportActionQuery,
        CheckExportActionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CheckExportActionQuery,
        CheckExportActionQueryVariables
    >(CheckExportActionDocument, options);
}
export function useCheckExportActionSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              CheckExportActionQuery,
              CheckExportActionQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        CheckExportActionQuery,
        CheckExportActionQueryVariables
    >(CheckExportActionDocument, options);
}
export type CheckExportActionQueryHookResult = ReturnType<
    typeof useCheckExportActionQuery
>;
export type CheckExportActionLazyQueryHookResult = ReturnType<
    typeof useCheckExportActionLazyQuery
>;
export type CheckExportActionSuspenseQueryHookResult = ReturnType<
    typeof useCheckExportActionSuspenseQuery
>;
export type CheckExportActionQueryResult = Apollo.QueryResult<
    CheckExportActionQuery,
    CheckExportActionQueryVariables
>;
export const CreateChildGroupDocument = gql`
    mutation CreateChildGroup($input: CreateChildGroupInput!) {
        createChildGroup(input: $input) {
            id
            name
            parentId
        }
    }
`;
export type CreateChildGroupMutationFn = Apollo.MutationFunction<
    CreateChildGroupMutation,
    CreateChildGroupMutationVariables
>;

/**
 * __useCreateChildGroupMutation__
 *
 * To run a mutation, you first call `useCreateChildGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChildGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChildGroupMutation, { data, loading, error }] = useCreateChildGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChildGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateChildGroupMutation,
        CreateChildGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateChildGroupMutation,
        CreateChildGroupMutationVariables
    >(CreateChildGroupDocument, options);
}
export type CreateChildGroupMutationHookResult = ReturnType<
    typeof useCreateChildGroupMutation
>;
export type CreateChildGroupMutationResult =
    Apollo.MutationResult<CreateChildGroupMutation>;
export type CreateChildGroupMutationOptions = Apollo.BaseMutationOptions<
    CreateChildGroupMutation,
    CreateChildGroupMutationVariables
>;
export const CreateDidacticToolDocument = gql`
    mutation CreateDidacticTool($input: CreateDidacticToolInput!) {
        createDidacticTool(input: $input) {
            description
            headerImageId
            headerIntroText
            learningAdviceTitle
            learningAdviceDescription
            id
            name
            type
            altLabel
        }
    }
`;
export type CreateDidacticToolMutationFn = Apollo.MutationFunction<
    CreateDidacticToolMutation,
    CreateDidacticToolMutationVariables
>;

/**
 * __useCreateDidacticToolMutation__
 *
 * To run a mutation, you first call `useCreateDidacticToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDidacticToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDidacticToolMutation, { data, loading, error }] = useCreateDidacticToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDidacticToolMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDidacticToolMutation,
        CreateDidacticToolMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateDidacticToolMutation,
        CreateDidacticToolMutationVariables
    >(CreateDidacticToolDocument, options);
}
export type CreateDidacticToolMutationHookResult = ReturnType<
    typeof useCreateDidacticToolMutation
>;
export type CreateDidacticToolMutationResult =
    Apollo.MutationResult<CreateDidacticToolMutation>;
export type CreateDidacticToolMutationOptions = Apollo.BaseMutationOptions<
    CreateDidacticToolMutation,
    CreateDidacticToolMutationVariables
>;
export const CreateEducationalPathDocument = gql`
    mutation CreateEducationalPath($input: CreateEducationalPathInput!) {
        createEducationalPath(input: $input) {
            id
            description
            headerIntroText
            name
        }
    }
`;
export type CreateEducationalPathMutationFn = Apollo.MutationFunction<
    CreateEducationalPathMutation,
    CreateEducationalPathMutationVariables
>;

/**
 * __useCreateEducationalPathMutation__
 *
 * To run a mutation, you first call `useCreateEducationalPathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationalPathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationalPathMutation, { data, loading, error }] = useCreateEducationalPathMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEducationalPathMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateEducationalPathMutation,
        CreateEducationalPathMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateEducationalPathMutation,
        CreateEducationalPathMutationVariables
    >(CreateEducationalPathDocument, options);
}
export type CreateEducationalPathMutationHookResult = ReturnType<
    typeof useCreateEducationalPathMutation
>;
export type CreateEducationalPathMutationResult =
    Apollo.MutationResult<CreateEducationalPathMutation>;
export type CreateEducationalPathMutationOptions = Apollo.BaseMutationOptions<
    CreateEducationalPathMutation,
    CreateEducationalPathMutationVariables
>;
export const CreateEducationalProgramDocument = gql`
    mutation CreateEducationalProgram($input: CreateEducationalProgramInput!) {
        createEducationalProgram(input: $input) {
            id
            description
            name
        }
    }
`;
export type CreateEducationalProgramMutationFn = Apollo.MutationFunction<
    CreateEducationalProgramMutation,
    CreateEducationalProgramMutationVariables
>;

/**
 * __useCreateEducationalProgramMutation__
 *
 * To run a mutation, you first call `useCreateEducationalProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationalProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationalProgramMutation, { data, loading, error }] = useCreateEducationalProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEducationalProgramMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateEducationalProgramMutation,
        CreateEducationalProgramMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateEducationalProgramMutation,
        CreateEducationalProgramMutationVariables
    >(CreateEducationalProgramDocument, options);
}
export type CreateEducationalProgramMutationHookResult = ReturnType<
    typeof useCreateEducationalProgramMutation
>;
export type CreateEducationalProgramMutationResult =
    Apollo.MutationResult<CreateEducationalProgramMutation>;
export type CreateEducationalProgramMutationOptions =
    Apollo.BaseMutationOptions<
        CreateEducationalProgramMutation,
        CreateEducationalProgramMutationVariables
    >;
export const CreateGroupDocument = gql`
    mutation CreateGroup($input: CreateGroupInput!) {
        createGroup(input: $input) {
            id
            name
            showGroupDuringRegistration
            externalCode
        }
    }
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
    CreateGroupMutation,
    CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateGroupMutation,
        CreateGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateGroupMutation,
        CreateGroupMutationVariables
    >(CreateGroupDocument, options);
}
export type CreateGroupMutationHookResult = ReturnType<
    typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
    Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
>;
export const CreateLicensesDocument = gql`
    mutation CreateLicenses($input: CreateLicensesInput!) {
        createLicenses(input: $input) {
            user {
                firstName
                lastName
            }
            validUntil
            startAt
        }
    }
`;
export type CreateLicensesMutationFn = Apollo.MutationFunction<
    CreateLicensesMutation,
    CreateLicensesMutationVariables
>;

/**
 * __useCreateLicensesMutation__
 *
 * To run a mutation, you first call `useCreateLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicensesMutation, { data, loading, error }] = useCreateLicensesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLicensesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateLicensesMutation,
        CreateLicensesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateLicensesMutation,
        CreateLicensesMutationVariables
    >(CreateLicensesDocument, options);
}
export type CreateLicensesMutationHookResult = ReturnType<
    typeof useCreateLicensesMutation
>;
export type CreateLicensesMutationResult =
    Apollo.MutationResult<CreateLicensesMutation>;
export type CreateLicensesMutationOptions = Apollo.BaseMutationOptions<
    CreateLicensesMutation,
    CreateLicensesMutationVariables
>;
export const CreateModuleDocument = gql`
    mutation CreateModule($input: CreateModuleInput!) {
        createModule(input: $input) {
            id
            content
            dashboardImageId
            description
            durationIndication
            headerImageId
            shortTitle
            title
        }
    }
`;
export type CreateModuleMutationFn = Apollo.MutationFunction<
    CreateModuleMutation,
    CreateModuleMutationVariables
>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateModuleMutation,
        CreateModuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateModuleMutation,
        CreateModuleMutationVariables
    >(CreateModuleDocument, options);
}
export type CreateModuleMutationHookResult = ReturnType<
    typeof useCreateModuleMutation
>;
export type CreateModuleMutationResult =
    Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<
    CreateModuleMutation,
    CreateModuleMutationVariables
>;
export const CreateProductDocument = gql`
    mutation CreateProduct($input: CreateProductInput!) {
        createProduct(input: $input) {
            id
            educationalProgramIds
            name
            isAvailable
            externalIdentifier
            validityPeriod
            prefix
        }
    }
`;
export type CreateProductMutationFn = Apollo.MutationFunction<
    CreateProductMutation,
    CreateProductMutationVariables
>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateProductMutation,
        CreateProductMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateProductMutation,
        CreateProductMutationVariables
    >(CreateProductDocument, options);
}
export type CreateProductMutationHookResult = ReturnType<
    typeof useCreateProductMutation
>;
export type CreateProductMutationResult =
    Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
    CreateProductMutation,
    CreateProductMutationVariables
>;
export const CreateReferenceCardDocument = gql`
    mutation CreateReferenceCard($data: ReferenceCardInput!) {
        createReferenceCard(input: $data) {
            __typename
            id
            headerImage
            thumbnail
            title
            description
            body
        }
    }
`;
export type CreateReferenceCardMutationFn = Apollo.MutationFunction<
    CreateReferenceCardMutation,
    CreateReferenceCardMutationVariables
>;

/**
 * __useCreateReferenceCardMutation__
 *
 * To run a mutation, you first call `useCreateReferenceCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceCardMutation, { data, loading, error }] = useCreateReferenceCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReferenceCardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateReferenceCardMutation,
        CreateReferenceCardMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateReferenceCardMutation,
        CreateReferenceCardMutationVariables
    >(CreateReferenceCardDocument, options);
}
export type CreateReferenceCardMutationHookResult = ReturnType<
    typeof useCreateReferenceCardMutation
>;
export type CreateReferenceCardMutationResult =
    Apollo.MutationResult<CreateReferenceCardMutation>;
export type CreateReferenceCardMutationOptions = Apollo.BaseMutationOptions<
    CreateReferenceCardMutation,
    CreateReferenceCardMutationVariables
>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
        createRole(input: $input) {
            id
            keycloakId
            name
        }
    }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
    CreateRoleMutation,
    CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateRoleMutation,
        CreateRoleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
        CreateRoleDocument,
        options,
    );
}
export type CreateRoleMutationHookResult = ReturnType<
    typeof useCreateRoleMutation
>;
export type CreateRoleMutationResult =
    Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
    CreateRoleMutation,
    CreateRoleMutationVariables
>;
export const CreateShortcodeDocument = gql`
    mutation createShortcode($input: CreateShortcodeInput!) {
        createShortcode(input: $input) {
            id
            shortcode
            educationalProgram {
                id
            }
            educationalPath {
                id
            }
            module {
                id
            }
            didacticTool {
                id
            }
        }
    }
`;
export type CreateShortcodeMutationFn = Apollo.MutationFunction<
    CreateShortcodeMutation,
    CreateShortcodeMutationVariables
>;

/**
 * __useCreateShortcodeMutation__
 *
 * To run a mutation, you first call `useCreateShortcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShortcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShortcodeMutation, { data, loading, error }] = useCreateShortcodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShortcodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateShortcodeMutation,
        CreateShortcodeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateShortcodeMutation,
        CreateShortcodeMutationVariables
    >(CreateShortcodeDocument, options);
}
export type CreateShortcodeMutationHookResult = ReturnType<
    typeof useCreateShortcodeMutation
>;
export type CreateShortcodeMutationResult =
    Apollo.MutationResult<CreateShortcodeMutation>;
export type CreateShortcodeMutationOptions = Apollo.BaseMutationOptions<
    CreateShortcodeMutation,
    CreateShortcodeMutationVariables
>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
        createUser(input: $input) {
            id
            firstName
            lastName
            emailAddress
        }
    }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
    CreateUserMutation,
    CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateUserMutation,
        CreateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        options,
    );
}
export type CreateUserMutationHookResult = ReturnType<
    typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
    Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
    CreateUserMutation,
    CreateUserMutationVariables
>;
export const DeleteDidacticToolsDocument = gql`
    mutation DeleteDidacticTools($didacticTools: [UUID!]!) {
        deleteDidacticTools(didacticTools: $didacticTools) {
            failed
            successful
        }
    }
`;
export type DeleteDidacticToolsMutationFn = Apollo.MutationFunction<
    DeleteDidacticToolsMutation,
    DeleteDidacticToolsMutationVariables
>;

/**
 * __useDeleteDidacticToolsMutation__
 *
 * To run a mutation, you first call `useDeleteDidacticToolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDidacticToolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDidacticToolsMutation, { data, loading, error }] = useDeleteDidacticToolsMutation({
 *   variables: {
 *      didacticTools: // value for 'didacticTools'
 *   },
 * });
 */
export function useDeleteDidacticToolsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDidacticToolsMutation,
        DeleteDidacticToolsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteDidacticToolsMutation,
        DeleteDidacticToolsMutationVariables
    >(DeleteDidacticToolsDocument, options);
}
export type DeleteDidacticToolsMutationHookResult = ReturnType<
    typeof useDeleteDidacticToolsMutation
>;
export type DeleteDidacticToolsMutationResult =
    Apollo.MutationResult<DeleteDidacticToolsMutation>;
export type DeleteDidacticToolsMutationOptions = Apollo.BaseMutationOptions<
    DeleteDidacticToolsMutation,
    DeleteDidacticToolsMutationVariables
>;
export const DeleteEducationalPathsDocument = gql`
    mutation DeleteEducationalPaths($educationalPaths: [UUID!]!) {
        deleteEducationalPaths(educationalPaths: $educationalPaths) {
            failed
            successful
        }
    }
`;
export type DeleteEducationalPathsMutationFn = Apollo.MutationFunction<
    DeleteEducationalPathsMutation,
    DeleteEducationalPathsMutationVariables
>;

/**
 * __useDeleteEducationalPathsMutation__
 *
 * To run a mutation, you first call `useDeleteEducationalPathsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationalPathsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationalPathsMutation, { data, loading, error }] = useDeleteEducationalPathsMutation({
 *   variables: {
 *      educationalPaths: // value for 'educationalPaths'
 *   },
 * });
 */
export function useDeleteEducationalPathsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteEducationalPathsMutation,
        DeleteEducationalPathsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteEducationalPathsMutation,
        DeleteEducationalPathsMutationVariables
    >(DeleteEducationalPathsDocument, options);
}
export type DeleteEducationalPathsMutationHookResult = ReturnType<
    typeof useDeleteEducationalPathsMutation
>;
export type DeleteEducationalPathsMutationResult =
    Apollo.MutationResult<DeleteEducationalPathsMutation>;
export type DeleteEducationalPathsMutationOptions = Apollo.BaseMutationOptions<
    DeleteEducationalPathsMutation,
    DeleteEducationalPathsMutationVariables
>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($groupId: UUID!) {
        deleteGroup(groupId: $groupId)
    }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteGroupMutation,
        DeleteGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteGroupMutation,
        DeleteGroupMutationVariables
    >(DeleteGroupDocument, options);
}
export type DeleteGroupMutationHookResult = ReturnType<
    typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
    Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
>;
export const DeleteGroupsDocument = gql`
    mutation DeleteGroups($groups: [UUID!]!) {
        deleteGroups(groups: $groups) {
            failed
            successful
        }
    }
`;
export type DeleteGroupsMutationFn = Apollo.MutationFunction<
    DeleteGroupsMutation,
    DeleteGroupsMutationVariables
>;

/**
 * __useDeleteGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupsMutation, { data, loading, error }] = useDeleteGroupsMutation({
 *   variables: {
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useDeleteGroupsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteGroupsMutation,
        DeleteGroupsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteGroupsMutation,
        DeleteGroupsMutationVariables
    >(DeleteGroupsDocument, options);
}
export type DeleteGroupsMutationHookResult = ReturnType<
    typeof useDeleteGroupsMutation
>;
export type DeleteGroupsMutationResult =
    Apollo.MutationResult<DeleteGroupsMutation>;
export type DeleteGroupsMutationOptions = Apollo.BaseMutationOptions<
    DeleteGroupsMutation,
    DeleteGroupsMutationVariables
>;
export const DeleteLicensesForProductAndUsersDocument = gql`
    mutation DeleteLicensesForProductAndUsers(
        $productIds: [UUID!]!
        $userIds: [UUID!]!
    ) {
        deleteLicensesForProductAndUsers(
            productIds: $productIds
            userIds: $userIds
        ) {
            successful
            failed
        }
    }
`;
export type DeleteLicensesForProductAndUsersMutationFn =
    Apollo.MutationFunction<
        DeleteLicensesForProductAndUsersMutation,
        DeleteLicensesForProductAndUsersMutationVariables
    >;

/**
 * __useDeleteLicensesForProductAndUsersMutation__
 *
 * To run a mutation, you first call `useDeleteLicensesForProductAndUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicensesForProductAndUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicensesForProductAndUsersMutation, { data, loading, error }] = useDeleteLicensesForProductAndUsersMutation({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useDeleteLicensesForProductAndUsersMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteLicensesForProductAndUsersMutation,
        DeleteLicensesForProductAndUsersMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteLicensesForProductAndUsersMutation,
        DeleteLicensesForProductAndUsersMutationVariables
    >(DeleteLicensesForProductAndUsersDocument, options);
}
export type DeleteLicensesForProductAndUsersMutationHookResult = ReturnType<
    typeof useDeleteLicensesForProductAndUsersMutation
>;
export type DeleteLicensesForProductAndUsersMutationResult =
    Apollo.MutationResult<DeleteLicensesForProductAndUsersMutation>;
export type DeleteLicensesForProductAndUsersMutationOptions =
    Apollo.BaseMutationOptions<
        DeleteLicensesForProductAndUsersMutation,
        DeleteLicensesForProductAndUsersMutationVariables
    >;
export const DeleteLicensesForUserInCmsDocument = gql`
    mutation DeleteLicensesForUserInCms($license: [UUID!]!) {
        deleteLicenses(licenses: $license) {
            failed
            successful
        }
    }
`;
export type DeleteLicensesForUserInCmsMutationFn = Apollo.MutationFunction<
    DeleteLicensesForUserInCmsMutation,
    DeleteLicensesForUserInCmsMutationVariables
>;

/**
 * __useDeleteLicensesForUserInCmsMutation__
 *
 * To run a mutation, you first call `useDeleteLicensesForUserInCmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicensesForUserInCmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicensesForUserInCmsMutation, { data, loading, error }] = useDeleteLicensesForUserInCmsMutation({
 *   variables: {
 *      license: // value for 'license'
 *   },
 * });
 */
export function useDeleteLicensesForUserInCmsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteLicensesForUserInCmsMutation,
        DeleteLicensesForUserInCmsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteLicensesForUserInCmsMutation,
        DeleteLicensesForUserInCmsMutationVariables
    >(DeleteLicensesForUserInCmsDocument, options);
}
export type DeleteLicensesForUserInCmsMutationHookResult = ReturnType<
    typeof useDeleteLicensesForUserInCmsMutation
>;
export type DeleteLicensesForUserInCmsMutationResult =
    Apollo.MutationResult<DeleteLicensesForUserInCmsMutation>;
export type DeleteLicensesForUserInCmsMutationOptions =
    Apollo.BaseMutationOptions<
        DeleteLicensesForUserInCmsMutation,
        DeleteLicensesForUserInCmsMutationVariables
    >;
export const DeleteModulesDocument = gql`
    mutation DeleteModules($modules: [UUID!]!) {
        deleteModules(modules: $modules) {
            failed
            successful
        }
    }
`;
export type DeleteModulesMutationFn = Apollo.MutationFunction<
    DeleteModulesMutation,
    DeleteModulesMutationVariables
>;

/**
 * __useDeleteModulesMutation__
 *
 * To run a mutation, you first call `useDeleteModulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModulesMutation, { data, loading, error }] = useDeleteModulesMutation({
 *   variables: {
 *      modules: // value for 'modules'
 *   },
 * });
 */
export function useDeleteModulesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteModulesMutation,
        DeleteModulesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteModulesMutation,
        DeleteModulesMutationVariables
    >(DeleteModulesDocument, options);
}
export type DeleteModulesMutationHookResult = ReturnType<
    typeof useDeleteModulesMutation
>;
export type DeleteModulesMutationResult =
    Apollo.MutationResult<DeleteModulesMutation>;
export type DeleteModulesMutationOptions = Apollo.BaseMutationOptions<
    DeleteModulesMutation,
    DeleteModulesMutationVariables
>;
export const DeleteProductsDocument = gql`
    mutation DeleteProducts($products: [UUID!]!) {
        deleteProducts(products: $products) {
            failed
            successful
        }
    }
`;
export type DeleteProductsMutationFn = Apollo.MutationFunction<
    DeleteProductsMutation,
    DeleteProductsMutationVariables
>;

/**
 * __useDeleteProductsMutation__
 *
 * To run a mutation, you first call `useDeleteProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductsMutation, { data, loading, error }] = useDeleteProductsMutation({
 *   variables: {
 *      products: // value for 'products'
 *   },
 * });
 */
export function useDeleteProductsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteProductsMutation,
        DeleteProductsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteProductsMutation,
        DeleteProductsMutationVariables
    >(DeleteProductsDocument, options);
}
export type DeleteProductsMutationHookResult = ReturnType<
    typeof useDeleteProductsMutation
>;
export type DeleteProductsMutationResult =
    Apollo.MutationResult<DeleteProductsMutation>;
export type DeleteProductsMutationOptions = Apollo.BaseMutationOptions<
    DeleteProductsMutation,
    DeleteProductsMutationVariables
>;
export const DeleteReferenceCardsDocument = gql`
    mutation DeleteReferenceCards($ids: [UUID!]!) {
        deleteReferenceCards(ids: $ids) {
            successful
            failed
        }
    }
`;
export type DeleteReferenceCardsMutationFn = Apollo.MutationFunction<
    DeleteReferenceCardsMutation,
    DeleteReferenceCardsMutationVariables
>;

/**
 * __useDeleteReferenceCardsMutation__
 *
 * To run a mutation, you first call `useDeleteReferenceCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferenceCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferenceCardsMutation, { data, loading, error }] = useDeleteReferenceCardsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteReferenceCardsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteReferenceCardsMutation,
        DeleteReferenceCardsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteReferenceCardsMutation,
        DeleteReferenceCardsMutationVariables
    >(DeleteReferenceCardsDocument, options);
}
export type DeleteReferenceCardsMutationHookResult = ReturnType<
    typeof useDeleteReferenceCardsMutation
>;
export type DeleteReferenceCardsMutationResult =
    Apollo.MutationResult<DeleteReferenceCardsMutation>;
export type DeleteReferenceCardsMutationOptions = Apollo.BaseMutationOptions<
    DeleteReferenceCardsMutation,
    DeleteReferenceCardsMutationVariables
>;
export const DeleteRolesDocument = gql`
    mutation DeleteRoles($roles: [UUID!]!) {
        deleteRoles(roles: $roles) {
            failed
            successful
        }
    }
`;
export type DeleteRolesMutationFn = Apollo.MutationFunction<
    DeleteRolesMutation,
    DeleteRolesMutationVariables
>;

/**
 * __useDeleteRolesMutation__
 *
 * To run a mutation, you first call `useDeleteRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolesMutation, { data, loading, error }] = useDeleteRolesMutation({
 *   variables: {
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useDeleteRolesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteRolesMutation,
        DeleteRolesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteRolesMutation,
        DeleteRolesMutationVariables
    >(DeleteRolesDocument, options);
}
export type DeleteRolesMutationHookResult = ReturnType<
    typeof useDeleteRolesMutation
>;
export type DeleteRolesMutationResult =
    Apollo.MutationResult<DeleteRolesMutation>;
export type DeleteRolesMutationOptions = Apollo.BaseMutationOptions<
    DeleteRolesMutation,
    DeleteRolesMutationVariables
>;
export const DeleteShortcodeDocument = gql`
    mutation DeleteShortcode($shortcode: UUID!) {
        deleteShortcode(id: $shortcode)
    }
`;
export type DeleteShortcodeMutationFn = Apollo.MutationFunction<
    DeleteShortcodeMutation,
    DeleteShortcodeMutationVariables
>;

/**
 * __useDeleteShortcodeMutation__
 *
 * To run a mutation, you first call `useDeleteShortcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShortcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShortcodeMutation, { data, loading, error }] = useDeleteShortcodeMutation({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useDeleteShortcodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteShortcodeMutation,
        DeleteShortcodeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteShortcodeMutation,
        DeleteShortcodeMutationVariables
    >(DeleteShortcodeDocument, options);
}
export type DeleteShortcodeMutationHookResult = ReturnType<
    typeof useDeleteShortcodeMutation
>;
export type DeleteShortcodeMutationResult =
    Apollo.MutationResult<DeleteShortcodeMutation>;
export type DeleteShortcodeMutationOptions = Apollo.BaseMutationOptions<
    DeleteShortcodeMutation,
    DeleteShortcodeMutationVariables
>;
export const DeleteUsersDocument = gql`
    mutation DeleteUsers($users: [UUID!]!) {
        deleteUsers(users: $users) {
            failed
            successful
        }
    }
`;
export type DeleteUsersMutationFn = Apollo.MutationFunction<
    DeleteUsersMutation,
    DeleteUsersMutationVariables
>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useDeleteUsersMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteUsersMutation,
        DeleteUsersMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteUsersMutation,
        DeleteUsersMutationVariables
    >(DeleteUsersDocument, options);
}
export type DeleteUsersMutationHookResult = ReturnType<
    typeof useDeleteUsersMutation
>;
export type DeleteUsersMutationResult =
    Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<
    DeleteUsersMutation,
    DeleteUsersMutationVariables
>;
export const EndDidacticToolDocument = gql`
    mutation endDidacticTool($id: UUID!) {
        endDidacticTool(didacticToolId: $id)
    }
`;
export type EndDidacticToolMutationFn = Apollo.MutationFunction<
    EndDidacticToolMutation,
    EndDidacticToolMutationVariables
>;

/**
 * __useEndDidacticToolMutation__
 *
 * To run a mutation, you first call `useEndDidacticToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndDidacticToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endDidacticToolMutation, { data, loading, error }] = useEndDidacticToolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEndDidacticToolMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EndDidacticToolMutation,
        EndDidacticToolMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        EndDidacticToolMutation,
        EndDidacticToolMutationVariables
    >(EndDidacticToolDocument, options);
}
export type EndDidacticToolMutationHookResult = ReturnType<
    typeof useEndDidacticToolMutation
>;
export type EndDidacticToolMutationResult =
    Apollo.MutationResult<EndDidacticToolMutation>;
export type EndDidacticToolMutationOptions = Apollo.BaseMutationOptions<
    EndDidacticToolMutation,
    EndDidacticToolMutationVariables
>;
export const EnrollGroupDocument = gql`
    mutation EnrollGroup($groupId: UUID!) {
        enrollGroup(groupId: $groupId) {
            name
        }
    }
`;
export type EnrollGroupMutationFn = Apollo.MutationFunction<
    EnrollGroupMutation,
    EnrollGroupMutationVariables
>;

/**
 * __useEnrollGroupMutation__
 *
 * To run a mutation, you first call `useEnrollGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollGroupMutation, { data, loading, error }] = useEnrollGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useEnrollGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        EnrollGroupMutation,
        EnrollGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        EnrollGroupMutation,
        EnrollGroupMutationVariables
    >(EnrollGroupDocument, options);
}
export type EnrollGroupMutationHookResult = ReturnType<
    typeof useEnrollGroupMutation
>;
export type EnrollGroupMutationResult =
    Apollo.MutationResult<EnrollGroupMutation>;
export type EnrollGroupMutationOptions = Apollo.BaseMutationOptions<
    EnrollGroupMutation,
    EnrollGroupMutationVariables
>;
export const GetAlgoliaKeyDocument = gql`
    query getAlgoliaKey {
        getAlgoliaSearchKeyForAuthenticatedUser
    }
`;

/**
 * __useGetAlgoliaKeyQuery__
 *
 * To run a query within a React component, call `useGetAlgoliaKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlgoliaKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlgoliaKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAlgoliaKeyQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAlgoliaKeyQuery,
        GetAlgoliaKeyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAlgoliaKeyQuery, GetAlgoliaKeyQueryVariables>(
        GetAlgoliaKeyDocument,
        options,
    );
}
export function useGetAlgoliaKeyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAlgoliaKeyQuery,
        GetAlgoliaKeyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAlgoliaKeyQuery, GetAlgoliaKeyQueryVariables>(
        GetAlgoliaKeyDocument,
        options,
    );
}
export function useGetAlgoliaKeySuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetAlgoliaKeyQuery,
              GetAlgoliaKeyQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAlgoliaKeyQuery,
        GetAlgoliaKeyQueryVariables
    >(GetAlgoliaKeyDocument, options);
}
export type GetAlgoliaKeyQueryHookResult = ReturnType<
    typeof useGetAlgoliaKeyQuery
>;
export type GetAlgoliaKeyLazyQueryHookResult = ReturnType<
    typeof useGetAlgoliaKeyLazyQuery
>;
export type GetAlgoliaKeySuspenseQueryHookResult = ReturnType<
    typeof useGetAlgoliaKeySuspenseQuery
>;
export type GetAlgoliaKeyQueryResult = Apollo.QueryResult<
    GetAlgoliaKeyQuery,
    GetAlgoliaKeyQueryVariables
>;
export const GetAssetByIdDocument = gql`
    query GetAssetById($id: UUID!) {
        getAssetById(id: $id) {
            __typename
            id
            createdAt
            ... on FileAssetDto {
                fileName
                mediaType
                signedUrl
            }
            ... on ExternalVideoAssetDto {
                videoUrl
                autoplay
                loop
            }
        }
    }
`;

/**
 * __useGetAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetByIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAssetByIdQuery,
        GetAssetByIdQueryVariables
    > &
        (
            | { variables: GetAssetByIdQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
        GetAssetByIdDocument,
        options,
    );
}
export function useGetAssetByIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAssetByIdQuery,
        GetAssetByIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
        GetAssetByIdDocument,
        options,
    );
}
export function useGetAssetByIdSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetAssetByIdQuery,
              GetAssetByIdQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAssetByIdQuery,
        GetAssetByIdQueryVariables
    >(GetAssetByIdDocument, options);
}
export type GetAssetByIdQueryHookResult = ReturnType<
    typeof useGetAssetByIdQuery
>;
export type GetAssetByIdLazyQueryHookResult = ReturnType<
    typeof useGetAssetByIdLazyQuery
>;
export type GetAssetByIdSuspenseQueryHookResult = ReturnType<
    typeof useGetAssetByIdSuspenseQuery
>;
export type GetAssetByIdQueryResult = Apollo.QueryResult<
    GetAssetByIdQuery,
    GetAssetByIdQueryVariables
>;
export const GetAuthenticatedUserDocument = gql`
    query GetAuthenticatedUser {
        getAuthenticatedUser {
            id
            firstName
            lastName
            emailAddress
        }
    }
`;

/**
 * __useGetAuthenticatedUserQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedUserQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAuthenticatedUserQuery,
        GetAuthenticatedUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAuthenticatedUserQuery,
        GetAuthenticatedUserQueryVariables
    >(GetAuthenticatedUserDocument, options);
}
export function useGetAuthenticatedUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAuthenticatedUserQuery,
        GetAuthenticatedUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAuthenticatedUserQuery,
        GetAuthenticatedUserQueryVariables
    >(GetAuthenticatedUserDocument, options);
}
export function useGetAuthenticatedUserSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetAuthenticatedUserQuery,
              GetAuthenticatedUserQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAuthenticatedUserQuery,
        GetAuthenticatedUserQueryVariables
    >(GetAuthenticatedUserDocument, options);
}
export type GetAuthenticatedUserQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserQuery
>;
export type GetAuthenticatedUserLazyQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserLazyQuery
>;
export type GetAuthenticatedUserSuspenseQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserSuspenseQuery
>;
export type GetAuthenticatedUserQueryResult = Apollo.QueryResult<
    GetAuthenticatedUserQuery,
    GetAuthenticatedUserQueryVariables
>;
export const GetAuthenticatedUserAuthoritiesDocument = gql`
    query GetAuthenticatedUserAuthorities {
        getAuthoritiesForAuthenticatedUser
    }
`;

/**
 * __useGetAuthenticatedUserAuthoritiesQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedUserAuthoritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedUserAuthoritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedUserAuthoritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedUserAuthoritiesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAuthenticatedUserAuthoritiesQuery,
        GetAuthenticatedUserAuthoritiesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAuthenticatedUserAuthoritiesQuery,
        GetAuthenticatedUserAuthoritiesQueryVariables
    >(GetAuthenticatedUserAuthoritiesDocument, options);
}
export function useGetAuthenticatedUserAuthoritiesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAuthenticatedUserAuthoritiesQuery,
        GetAuthenticatedUserAuthoritiesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAuthenticatedUserAuthoritiesQuery,
        GetAuthenticatedUserAuthoritiesQueryVariables
    >(GetAuthenticatedUserAuthoritiesDocument, options);
}
export function useGetAuthenticatedUserAuthoritiesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetAuthenticatedUserAuthoritiesQuery,
              GetAuthenticatedUserAuthoritiesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAuthenticatedUserAuthoritiesQuery,
        GetAuthenticatedUserAuthoritiesQueryVariables
    >(GetAuthenticatedUserAuthoritiesDocument, options);
}
export type GetAuthenticatedUserAuthoritiesQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserAuthoritiesQuery
>;
export type GetAuthenticatedUserAuthoritiesLazyQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserAuthoritiesLazyQuery
>;
export type GetAuthenticatedUserAuthoritiesSuspenseQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserAuthoritiesSuspenseQuery
>;
export type GetAuthenticatedUserAuthoritiesQueryResult = Apollo.QueryResult<
    GetAuthenticatedUserAuthoritiesQuery,
    GetAuthenticatedUserAuthoritiesQueryVariables
>;
export const GetAuthenticatedUserForCreateGroupDocument = gql`
    query GetAuthenticatedUserForCreateGroup {
        getAuthenticatedUser {
            id
            firstName
            lastName
        }
    }
`;

/**
 * __useGetAuthenticatedUserForCreateGroupQuery__
 *
 * To run a query within a React component, call `useGetAuthenticatedUserForCreateGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticatedUserForCreateGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticatedUserForCreateGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticatedUserForCreateGroupQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetAuthenticatedUserForCreateGroupQuery,
        GetAuthenticatedUserForCreateGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetAuthenticatedUserForCreateGroupQuery,
        GetAuthenticatedUserForCreateGroupQueryVariables
    >(GetAuthenticatedUserForCreateGroupDocument, options);
}
export function useGetAuthenticatedUserForCreateGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAuthenticatedUserForCreateGroupQuery,
        GetAuthenticatedUserForCreateGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetAuthenticatedUserForCreateGroupQuery,
        GetAuthenticatedUserForCreateGroupQueryVariables
    >(GetAuthenticatedUserForCreateGroupDocument, options);
}
export function useGetAuthenticatedUserForCreateGroupSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetAuthenticatedUserForCreateGroupQuery,
              GetAuthenticatedUserForCreateGroupQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetAuthenticatedUserForCreateGroupQuery,
        GetAuthenticatedUserForCreateGroupQueryVariables
    >(GetAuthenticatedUserForCreateGroupDocument, options);
}
export type GetAuthenticatedUserForCreateGroupQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserForCreateGroupQuery
>;
export type GetAuthenticatedUserForCreateGroupLazyQueryHookResult = ReturnType<
    typeof useGetAuthenticatedUserForCreateGroupLazyQuery
>;
export type GetAuthenticatedUserForCreateGroupSuspenseQueryHookResult =
    ReturnType<typeof useGetAuthenticatedUserForCreateGroupSuspenseQuery>;
export type GetAuthenticatedUserForCreateGroupQueryResult = Apollo.QueryResult<
    GetAuthenticatedUserForCreateGroupQuery,
    GetAuthenticatedUserForCreateGroupQueryVariables
>;
export const GetBuildingBlockAnswersForDidacticToolDocument = gql`
    query GetBuildingBlockAnswersForDidacticTool($id: UUID!) {
        getBuildingBlockAnswersForDidacticTool(id: $id) {
            buildingBlockId
            correct
            reasoning
            type
            ...StandAnswerCorrectness
            ...MpcAnswerCorrectness
            ...QuestionConnectAnswerCorrectness
            ...SortQuestionAnswerCorrectness
            ...PollQuestionAnswerCorrectness
        }
    }
    ${StandAnswerCorrectnessFragmentDoc}
    ${MpcAnswerCorrectnessFragmentDoc}
    ${QuestionConnectAnswerCorrectnessFragmentDoc}
    ${SortQuestionAnswerCorrectnessFragmentDoc}
    ${PollQuestionAnswerCorrectnessFragmentDoc}
`;

/**
 * __useGetBuildingBlockAnswersForDidacticToolQuery__
 *
 * To run a query within a React component, call `useGetBuildingBlockAnswersForDidacticToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingBlockAnswersForDidacticToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingBlockAnswersForDidacticToolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingBlockAnswersForDidacticToolQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetBuildingBlockAnswersForDidacticToolQuery,
        GetBuildingBlockAnswersForDidacticToolQueryVariables
    > &
        (
            | {
                  variables: GetBuildingBlockAnswersForDidacticToolQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetBuildingBlockAnswersForDidacticToolQuery,
        GetBuildingBlockAnswersForDidacticToolQueryVariables
    >(GetBuildingBlockAnswersForDidacticToolDocument, options);
}
export function useGetBuildingBlockAnswersForDidacticToolLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetBuildingBlockAnswersForDidacticToolQuery,
        GetBuildingBlockAnswersForDidacticToolQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetBuildingBlockAnswersForDidacticToolQuery,
        GetBuildingBlockAnswersForDidacticToolQueryVariables
    >(GetBuildingBlockAnswersForDidacticToolDocument, options);
}
export function useGetBuildingBlockAnswersForDidacticToolSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetBuildingBlockAnswersForDidacticToolQuery,
              GetBuildingBlockAnswersForDidacticToolQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetBuildingBlockAnswersForDidacticToolQuery,
        GetBuildingBlockAnswersForDidacticToolQueryVariables
    >(GetBuildingBlockAnswersForDidacticToolDocument, options);
}
export type GetBuildingBlockAnswersForDidacticToolQueryHookResult = ReturnType<
    typeof useGetBuildingBlockAnswersForDidacticToolQuery
>;
export type GetBuildingBlockAnswersForDidacticToolLazyQueryHookResult =
    ReturnType<typeof useGetBuildingBlockAnswersForDidacticToolLazyQuery>;
export type GetBuildingBlockAnswersForDidacticToolSuspenseQueryHookResult =
    ReturnType<typeof useGetBuildingBlockAnswersForDidacticToolSuspenseQuery>;
export type GetBuildingBlockAnswersForDidacticToolQueryResult =
    Apollo.QueryResult<
        GetBuildingBlockAnswersForDidacticToolQuery,
        GetBuildingBlockAnswersForDidacticToolQueryVariables
    >;
export const GetBuildingBlocksForDidacticToolDocument = gql`
    query GetBuildingBlocksForDidacticTool($didacticToolId: UUID!) {
        getBuildingBlocksForDidacticTool(
            didacticToolId: $didacticToolId
            language: null
        ) {
            buildingBlocks {
                id
                order
                type
                ...ContentBuildingBlock
                ...MpcBuildingBlock
                ...StandBuildingBlock
                ...MoodboardBuildingBlock
                ...ConversationBuildingBlock
                ...SortQuestionBuildingBlock
                ...QuestionConnectBuildingBlock
                ...CarouselBuildingBlock
                ...QuoteBuildingBlock
                ...InteractiveQuestionBuildingBlock
                ...HotspotBuildingBlock
                ...ReferenceCardBuildingBlock
                ...SceneBuildingBlock
                ...LinkBuildingBlock
                ...PollBuildingBlock
            }
        }
    }
    ${ContentBuildingBlockFragmentDoc}
    ${MpcBuildingBlockFragmentDoc}
    ${StandBuildingBlockFragmentDoc}
    ${MoodboardBuildingBlockFragmentDoc}
    ${ConversationBuildingBlockFragmentDoc}
    ${SortQuestionBuildingBlockFragmentDoc}
    ${QuestionConnectBuildingBlockFragmentDoc}
    ${CarouselBuildingBlockFragmentDoc}
    ${QuoteBuildingBlockFragmentDoc}
    ${InteractiveQuestionBuildingBlockFragmentDoc}
    ${HotspotBuildingBlockFragmentDoc}
    ${ReferenceCardBuildingBlockFragmentDoc}
    ${SceneBuildingBlockFragmentDoc}
    ${LinkBuildingBlockFragmentDoc}
    ${PollBuildingBlockFragmentDoc}
`;

/**
 * __useGetBuildingBlocksForDidacticToolQuery__
 *
 * To run a query within a React component, call `useGetBuildingBlocksForDidacticToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingBlocksForDidacticToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingBlocksForDidacticToolQuery({
 *   variables: {
 *      didacticToolId: // value for 'didacticToolId'
 *   },
 * });
 */
export function useGetBuildingBlocksForDidacticToolQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetBuildingBlocksForDidacticToolQuery,
        GetBuildingBlocksForDidacticToolQueryVariables
    > &
        (
            | {
                  variables: GetBuildingBlocksForDidacticToolQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetBuildingBlocksForDidacticToolQuery,
        GetBuildingBlocksForDidacticToolQueryVariables
    >(GetBuildingBlocksForDidacticToolDocument, options);
}
export function useGetBuildingBlocksForDidacticToolLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetBuildingBlocksForDidacticToolQuery,
        GetBuildingBlocksForDidacticToolQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetBuildingBlocksForDidacticToolQuery,
        GetBuildingBlocksForDidacticToolQueryVariables
    >(GetBuildingBlocksForDidacticToolDocument, options);
}
export function useGetBuildingBlocksForDidacticToolSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetBuildingBlocksForDidacticToolQuery,
              GetBuildingBlocksForDidacticToolQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetBuildingBlocksForDidacticToolQuery,
        GetBuildingBlocksForDidacticToolQueryVariables
    >(GetBuildingBlocksForDidacticToolDocument, options);
}
export type GetBuildingBlocksForDidacticToolQueryHookResult = ReturnType<
    typeof useGetBuildingBlocksForDidacticToolQuery
>;
export type GetBuildingBlocksForDidacticToolLazyQueryHookResult = ReturnType<
    typeof useGetBuildingBlocksForDidacticToolLazyQuery
>;
export type GetBuildingBlocksForDidacticToolSuspenseQueryHookResult =
    ReturnType<typeof useGetBuildingBlocksForDidacticToolSuspenseQuery>;
export type GetBuildingBlocksForDidacticToolQueryResult = Apollo.QueryResult<
    GetBuildingBlocksForDidacticToolQuery,
    GetBuildingBlocksForDidacticToolQueryVariables
>;
export const GetBuildingBlocksForDidacticToolForAppDocument = gql`
    query GetBuildingBlocksForDidacticToolForApp($didacticToolId: UUID!) {
        getBuildingBlocksForDidacticToolForApp(
            didacticToolId: $didacticToolId
        ) {
            didacticToolId
            buildingBlocks {
                id
                order
                type
                ...AppInteractiveQuestionBlock
                ...AppContentBuildingBlock
                ...AppMpcBuildingBlock
                ...AppStandBuildingBlock
                ...AppMoodboardBuildingBlock
                ...AppQuestionConnectBuildingBlock
                ...AppConversationBuildingBlock
                ...AppCarouselBuildingBlock
                ...AppHotspotBuildingBlock
                ...AppSortQuestionBuildingBlock
                ...AppQuoteBuildingBlock
                ...AppReferenceCardCarouselBuildingBlock
                ...AppSceneBuildingBlock
                ...AppLinkBuildingBlock
                ...AppPollBuildingBlock
            }
        }
    }
    ${AppInteractiveQuestionBlockFragmentDoc}
    ${AppContentBuildingBlockFragmentDoc}
    ${AppMpcBuildingBlockFragmentDoc}
    ${AppStandBuildingBlockFragmentDoc}
    ${AppMoodboardBuildingBlockFragmentDoc}
    ${AppQuestionConnectBuildingBlockFragmentDoc}
    ${AppConversationBuildingBlockFragmentDoc}
    ${AppCarouselBuildingBlockFragmentDoc}
    ${AppHotspotBuildingBlockFragmentDoc}
    ${AppSortQuestionBuildingBlockFragmentDoc}
    ${AppQuoteBuildingBlockFragmentDoc}
    ${AppReferenceCardCarouselBuildingBlockFragmentDoc}
    ${AppSceneBuildingBlockFragmentDoc}
    ${AppLinkBuildingBlockFragmentDoc}
    ${AppPollBuildingBlockFragmentDoc}
`;

/**
 * __useGetBuildingBlocksForDidacticToolForAppQuery__
 *
 * To run a query within a React component, call `useGetBuildingBlocksForDidacticToolForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingBlocksForDidacticToolForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingBlocksForDidacticToolForAppQuery({
 *   variables: {
 *      didacticToolId: // value for 'didacticToolId'
 *   },
 * });
 */
export function useGetBuildingBlocksForDidacticToolForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetBuildingBlocksForDidacticToolForAppQuery,
        GetBuildingBlocksForDidacticToolForAppQueryVariables
    > &
        (
            | {
                  variables: GetBuildingBlocksForDidacticToolForAppQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetBuildingBlocksForDidacticToolForAppQuery,
        GetBuildingBlocksForDidacticToolForAppQueryVariables
    >(GetBuildingBlocksForDidacticToolForAppDocument, options);
}
export function useGetBuildingBlocksForDidacticToolForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetBuildingBlocksForDidacticToolForAppQuery,
        GetBuildingBlocksForDidacticToolForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetBuildingBlocksForDidacticToolForAppQuery,
        GetBuildingBlocksForDidacticToolForAppQueryVariables
    >(GetBuildingBlocksForDidacticToolForAppDocument, options);
}
export function useGetBuildingBlocksForDidacticToolForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetBuildingBlocksForDidacticToolForAppQuery,
              GetBuildingBlocksForDidacticToolForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetBuildingBlocksForDidacticToolForAppQuery,
        GetBuildingBlocksForDidacticToolForAppQueryVariables
    >(GetBuildingBlocksForDidacticToolForAppDocument, options);
}
export type GetBuildingBlocksForDidacticToolForAppQueryHookResult = ReturnType<
    typeof useGetBuildingBlocksForDidacticToolForAppQuery
>;
export type GetBuildingBlocksForDidacticToolForAppLazyQueryHookResult =
    ReturnType<typeof useGetBuildingBlocksForDidacticToolForAppLazyQuery>;
export type GetBuildingBlocksForDidacticToolForAppSuspenseQueryHookResult =
    ReturnType<typeof useGetBuildingBlocksForDidacticToolForAppSuspenseQuery>;
export type GetBuildingBlocksForDidacticToolForAppQueryResult =
    Apollo.QueryResult<
        GetBuildingBlocksForDidacticToolForAppQuery,
        GetBuildingBlocksForDidacticToolForAppQueryVariables
    >;
export const GetChildGroupsForGroupDocument = gql`
    query GetChildGroupsForGroup($groupId: UUID!) {
        getChildGroupsForGroup(groupId: $groupId) {
            id
            name
            parentId
        }
    }
`;

/**
 * __useGetChildGroupsForGroupQuery__
 *
 * To run a query within a React component, call `useGetChildGroupsForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildGroupsForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildGroupsForGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetChildGroupsForGroupQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetChildGroupsForGroupQuery,
        GetChildGroupsForGroupQueryVariables
    > &
        (
            | {
                  variables: GetChildGroupsForGroupQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetChildGroupsForGroupQuery,
        GetChildGroupsForGroupQueryVariables
    >(GetChildGroupsForGroupDocument, options);
}
export function useGetChildGroupsForGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetChildGroupsForGroupQuery,
        GetChildGroupsForGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetChildGroupsForGroupQuery,
        GetChildGroupsForGroupQueryVariables
    >(GetChildGroupsForGroupDocument, options);
}
export function useGetChildGroupsForGroupSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetChildGroupsForGroupQuery,
              GetChildGroupsForGroupQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetChildGroupsForGroupQuery,
        GetChildGroupsForGroupQueryVariables
    >(GetChildGroupsForGroupDocument, options);
}
export type GetChildGroupsForGroupQueryHookResult = ReturnType<
    typeof useGetChildGroupsForGroupQuery
>;
export type GetChildGroupsForGroupLazyQueryHookResult = ReturnType<
    typeof useGetChildGroupsForGroupLazyQuery
>;
export type GetChildGroupsForGroupSuspenseQueryHookResult = ReturnType<
    typeof useGetChildGroupsForGroupSuspenseQuery
>;
export type GetChildGroupsForGroupQueryResult = Apollo.QueryResult<
    GetChildGroupsForGroupQuery,
    GetChildGroupsForGroupQueryVariables
>;
export const GetDidacticToolDocument = gql`
    query GetDidacticTool($id: UUID!) {
        getDidacticTool(id: $id) {
            description
            headerImageId
            headerIntroText
            headerBackground
            durationIndication
            learningAdviceTitle
            learningAdviceDescription
            id
            name
            type
            label
            altLabel
        }
    }
`;

/**
 * __useGetDidacticToolQuery__
 *
 * To run a query within a React component, call `useGetDidacticToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDidacticToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDidacticToolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDidacticToolQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDidacticToolQuery,
        GetDidacticToolQueryVariables
    > &
        (
            | { variables: GetDidacticToolQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetDidacticToolQuery, GetDidacticToolQueryVariables>(
        GetDidacticToolDocument,
        options,
    );
}
export function useGetDidacticToolLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDidacticToolQuery,
        GetDidacticToolQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDidacticToolQuery,
        GetDidacticToolQueryVariables
    >(GetDidacticToolDocument, options);
}
export function useGetDidacticToolSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetDidacticToolQuery,
              GetDidacticToolQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDidacticToolQuery,
        GetDidacticToolQueryVariables
    >(GetDidacticToolDocument, options);
}
export type GetDidacticToolQueryHookResult = ReturnType<
    typeof useGetDidacticToolQuery
>;
export type GetDidacticToolLazyQueryHookResult = ReturnType<
    typeof useGetDidacticToolLazyQuery
>;
export type GetDidacticToolSuspenseQueryHookResult = ReturnType<
    typeof useGetDidacticToolSuspenseQuery
>;
export type GetDidacticToolQueryResult = Apollo.QueryResult<
    GetDidacticToolQuery,
    GetDidacticToolQueryVariables
>;
export const GetDidacticToolForAppDocument = gql`
    query GetDidacticToolForApp($id: UUID!) {
        getDidacticToolForApp(id: $id) {
            description
            headerImageId
            headerIntroText
            headerBackground
            durationIndication
            id
            name
            type
            altLabel
            label
            learningAdviceTitle
            learningAdviceDescription
        }
    }
`;

/**
 * __useGetDidacticToolForAppQuery__
 *
 * To run a query within a React component, call `useGetDidacticToolForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDidacticToolForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDidacticToolForAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDidacticToolForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDidacticToolForAppQuery,
        GetDidacticToolForAppQueryVariables
    > &
        (
            | { variables: GetDidacticToolForAppQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetDidacticToolForAppQuery,
        GetDidacticToolForAppQueryVariables
    >(GetDidacticToolForAppDocument, options);
}
export function useGetDidacticToolForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDidacticToolForAppQuery,
        GetDidacticToolForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDidacticToolForAppQuery,
        GetDidacticToolForAppQueryVariables
    >(GetDidacticToolForAppDocument, options);
}
export function useGetDidacticToolForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetDidacticToolForAppQuery,
              GetDidacticToolForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDidacticToolForAppQuery,
        GetDidacticToolForAppQueryVariables
    >(GetDidacticToolForAppDocument, options);
}
export type GetDidacticToolForAppQueryHookResult = ReturnType<
    typeof useGetDidacticToolForAppQuery
>;
export type GetDidacticToolForAppLazyQueryHookResult = ReturnType<
    typeof useGetDidacticToolForAppLazyQuery
>;
export type GetDidacticToolForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetDidacticToolForAppSuspenseQuery
>;
export type GetDidacticToolForAppQueryResult = Apollo.QueryResult<
    GetDidacticToolForAppQuery,
    GetDidacticToolForAppQueryVariables
>;
export const GetDidacticToolProgressDocument = gql`
    query GetDidacticToolProgress($ids: [UUID!]!) {
        getDidacticToolProgress(ids: $ids) {
            id
            progressPercentage
        }
    }
`;

/**
 * __useGetDidacticToolProgressQuery__
 *
 * To run a query within a React component, call `useGetDidacticToolProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDidacticToolProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDidacticToolProgressQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetDidacticToolProgressQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDidacticToolProgressQuery,
        GetDidacticToolProgressQueryVariables
    > &
        (
            | {
                  variables: GetDidacticToolProgressQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetDidacticToolProgressQuery,
        GetDidacticToolProgressQueryVariables
    >(GetDidacticToolProgressDocument, options);
}
export function useGetDidacticToolProgressLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDidacticToolProgressQuery,
        GetDidacticToolProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDidacticToolProgressQuery,
        GetDidacticToolProgressQueryVariables
    >(GetDidacticToolProgressDocument, options);
}
export function useGetDidacticToolProgressSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetDidacticToolProgressQuery,
              GetDidacticToolProgressQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDidacticToolProgressQuery,
        GetDidacticToolProgressQueryVariables
    >(GetDidacticToolProgressDocument, options);
}
export type GetDidacticToolProgressQueryHookResult = ReturnType<
    typeof useGetDidacticToolProgressQuery
>;
export type GetDidacticToolProgressLazyQueryHookResult = ReturnType<
    typeof useGetDidacticToolProgressLazyQuery
>;
export type GetDidacticToolProgressSuspenseQueryHookResult = ReturnType<
    typeof useGetDidacticToolProgressSuspenseQuery
>;
export type GetDidacticToolProgressQueryResult = Apollo.QueryResult<
    GetDidacticToolProgressQuery,
    GetDidacticToolProgressQueryVariables
>;
export const GetDidacticToolsForAppDocument = gql`
    query GetDidacticToolsForApp(
        $moduleId: UUID!
        $programId: UUID!
        $educationalPathId: UUID!
    ) {
        getDidacticToolsForApp(
            moduleId: $moduleId
            programId: $programId
            educationalPathId: $educationalPathId
        ) {
            description
            headerImageId
            headerIntroText
            id
            name
            type
            label
            altLabel
        }
    }
`;

/**
 * __useGetDidacticToolsForAppQuery__
 *
 * To run a query within a React component, call `useGetDidacticToolsForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDidacticToolsForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDidacticToolsForAppQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      programId: // value for 'programId'
 *      educationalPathId: // value for 'educationalPathId'
 *   },
 * });
 */
export function useGetDidacticToolsForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDidacticToolsForAppQuery,
        GetDidacticToolsForAppQueryVariables
    > &
        (
            | {
                  variables: GetDidacticToolsForAppQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetDidacticToolsForAppQuery,
        GetDidacticToolsForAppQueryVariables
    >(GetDidacticToolsForAppDocument, options);
}
export function useGetDidacticToolsForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDidacticToolsForAppQuery,
        GetDidacticToolsForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDidacticToolsForAppQuery,
        GetDidacticToolsForAppQueryVariables
    >(GetDidacticToolsForAppDocument, options);
}
export function useGetDidacticToolsForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetDidacticToolsForAppQuery,
              GetDidacticToolsForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDidacticToolsForAppQuery,
        GetDidacticToolsForAppQueryVariables
    >(GetDidacticToolsForAppDocument, options);
}
export type GetDidacticToolsForAppQueryHookResult = ReturnType<
    typeof useGetDidacticToolsForAppQuery
>;
export type GetDidacticToolsForAppLazyQueryHookResult = ReturnType<
    typeof useGetDidacticToolsForAppLazyQuery
>;
export type GetDidacticToolsForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetDidacticToolsForAppSuspenseQuery
>;
export type GetDidacticToolsForAppQueryResult = Apollo.QueryResult<
    GetDidacticToolsForAppQuery,
    GetDidacticToolsForAppQueryVariables
>;
export const GetDidacticToolsForAppProgressTableFilterDocument = gql`
    query GetDidacticToolsForAppProgressTableFilter($moduleId: UUID!) {
        didacticTools: getDidacticToolsForApp(moduleId: $moduleId) {
            id
            name
        }
    }
`;

/**
 * __useGetDidacticToolsForAppProgressTableFilterQuery__
 *
 * To run a query within a React component, call `useGetDidacticToolsForAppProgressTableFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDidacticToolsForAppProgressTableFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDidacticToolsForAppProgressTableFilterQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetDidacticToolsForAppProgressTableFilterQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDidacticToolsForAppProgressTableFilterQuery,
        GetDidacticToolsForAppProgressTableFilterQueryVariables
    > &
        (
            | {
                  variables: GetDidacticToolsForAppProgressTableFilterQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetDidacticToolsForAppProgressTableFilterQuery,
        GetDidacticToolsForAppProgressTableFilterQueryVariables
    >(GetDidacticToolsForAppProgressTableFilterDocument, options);
}
export function useGetDidacticToolsForAppProgressTableFilterLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDidacticToolsForAppProgressTableFilterQuery,
        GetDidacticToolsForAppProgressTableFilterQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDidacticToolsForAppProgressTableFilterQuery,
        GetDidacticToolsForAppProgressTableFilterQueryVariables
    >(GetDidacticToolsForAppProgressTableFilterDocument, options);
}
export function useGetDidacticToolsForAppProgressTableFilterSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetDidacticToolsForAppProgressTableFilterQuery,
              GetDidacticToolsForAppProgressTableFilterQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDidacticToolsForAppProgressTableFilterQuery,
        GetDidacticToolsForAppProgressTableFilterQueryVariables
    >(GetDidacticToolsForAppProgressTableFilterDocument, options);
}
export type GetDidacticToolsForAppProgressTableFilterQueryHookResult =
    ReturnType<typeof useGetDidacticToolsForAppProgressTableFilterQuery>;
export type GetDidacticToolsForAppProgressTableFilterLazyQueryHookResult =
    ReturnType<typeof useGetDidacticToolsForAppProgressTableFilterLazyQuery>;
export type GetDidacticToolsForAppProgressTableFilterSuspenseQueryHookResult =
    ReturnType<
        typeof useGetDidacticToolsForAppProgressTableFilterSuspenseQuery
    >;
export type GetDidacticToolsForAppProgressTableFilterQueryResult =
    Apollo.QueryResult<
        GetDidacticToolsForAppProgressTableFilterQuery,
        GetDidacticToolsForAppProgressTableFilterQueryVariables
    >;
export const GetDidacticToolsForModuleDocument = gql`
    query GetDidacticToolsForModule($id: UUID!) {
        getDidacticToolsForModule(id: $id) {
            moduleId
            didacticTools {
                id
                name
                order
                type
                label
            }
        }
    }
`;

/**
 * __useGetDidacticToolsForModuleQuery__
 *
 * To run a query within a React component, call `useGetDidacticToolsForModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDidacticToolsForModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDidacticToolsForModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDidacticToolsForModuleQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDidacticToolsForModuleQuery,
        GetDidacticToolsForModuleQueryVariables
    > &
        (
            | {
                  variables: GetDidacticToolsForModuleQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetDidacticToolsForModuleQuery,
        GetDidacticToolsForModuleQueryVariables
    >(GetDidacticToolsForModuleDocument, options);
}
export function useGetDidacticToolsForModuleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDidacticToolsForModuleQuery,
        GetDidacticToolsForModuleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDidacticToolsForModuleQuery,
        GetDidacticToolsForModuleQueryVariables
    >(GetDidacticToolsForModuleDocument, options);
}
export function useGetDidacticToolsForModuleSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetDidacticToolsForModuleQuery,
              GetDidacticToolsForModuleQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDidacticToolsForModuleQuery,
        GetDidacticToolsForModuleQueryVariables
    >(GetDidacticToolsForModuleDocument, options);
}
export type GetDidacticToolsForModuleQueryHookResult = ReturnType<
    typeof useGetDidacticToolsForModuleQuery
>;
export type GetDidacticToolsForModuleLazyQueryHookResult = ReturnType<
    typeof useGetDidacticToolsForModuleLazyQuery
>;
export type GetDidacticToolsForModuleSuspenseQueryHookResult = ReturnType<
    typeof useGetDidacticToolsForModuleSuspenseQuery
>;
export type GetDidacticToolsForModuleQueryResult = Apollo.QueryResult<
    GetDidacticToolsForModuleQuery,
    GetDidacticToolsForModuleQueryVariables
>;
export const GetEducationalPathDocument = gql`
    query GetEducationalPath($id: UUID!) {
        getEducationalPath(id: $id) {
            id
            name
            description
            dashboardImageId
            durationIndication
            headerImageId
            headerIntroText
        }
    }
`;

/**
 * __useGetEducationalPathQuery__
 *
 * To run a query within a React component, call `useGetEducationalPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalPathQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEducationalPathQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalPathQuery,
        GetEducationalPathQueryVariables
    > &
        (
            | { variables: GetEducationalPathQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalPathQuery,
        GetEducationalPathQueryVariables
    >(GetEducationalPathDocument, options);
}
export function useGetEducationalPathLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalPathQuery,
        GetEducationalPathQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalPathQuery,
        GetEducationalPathQueryVariables
    >(GetEducationalPathDocument, options);
}
export function useGetEducationalPathSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalPathQuery,
              GetEducationalPathQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalPathQuery,
        GetEducationalPathQueryVariables
    >(GetEducationalPathDocument, options);
}
export type GetEducationalPathQueryHookResult = ReturnType<
    typeof useGetEducationalPathQuery
>;
export type GetEducationalPathLazyQueryHookResult = ReturnType<
    typeof useGetEducationalPathLazyQuery
>;
export type GetEducationalPathSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalPathSuspenseQuery
>;
export type GetEducationalPathQueryResult = Apollo.QueryResult<
    GetEducationalPathQuery,
    GetEducationalPathQueryVariables
>;
export const GetEducationalPathForAppDocument = gql`
    query GetEducationalPathForApp($id: UUID!) {
        getEducationalPathForApp(id: $id) {
            id
            name
            headerIntroText
            headerImageId
            description
            dashboardImageId
            durationIndication
        }
    }
`;

/**
 * __useGetEducationalPathForAppQuery__
 *
 * To run a query within a React component, call `useGetEducationalPathForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalPathForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalPathForAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEducationalPathForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalPathForAppQuery,
        GetEducationalPathForAppQueryVariables
    > &
        (
            | {
                  variables: GetEducationalPathForAppQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalPathForAppQuery,
        GetEducationalPathForAppQueryVariables
    >(GetEducationalPathForAppDocument, options);
}
export function useGetEducationalPathForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalPathForAppQuery,
        GetEducationalPathForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalPathForAppQuery,
        GetEducationalPathForAppQueryVariables
    >(GetEducationalPathForAppDocument, options);
}
export function useGetEducationalPathForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalPathForAppQuery,
              GetEducationalPathForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalPathForAppQuery,
        GetEducationalPathForAppQueryVariables
    >(GetEducationalPathForAppDocument, options);
}
export type GetEducationalPathForAppQueryHookResult = ReturnType<
    typeof useGetEducationalPathForAppQuery
>;
export type GetEducationalPathForAppLazyQueryHookResult = ReturnType<
    typeof useGetEducationalPathForAppLazyQuery
>;
export type GetEducationalPathForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalPathForAppSuspenseQuery
>;
export type GetEducationalPathForAppQueryResult = Apollo.QueryResult<
    GetEducationalPathForAppQuery,
    GetEducationalPathForAppQueryVariables
>;
export const GetEducationalPathProgressDocument = gql`
    query GetEducationalPathProgress($ids: [UUID!]!) {
        getEducationalPathProgress(ids: $ids) {
            id
            progressPercentage
        }
    }
`;

/**
 * __useGetEducationalPathProgressQuery__
 *
 * To run a query within a React component, call `useGetEducationalPathProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalPathProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalPathProgressQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetEducationalPathProgressQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalPathProgressQuery,
        GetEducationalPathProgressQueryVariables
    > &
        (
            | {
                  variables: GetEducationalPathProgressQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalPathProgressQuery,
        GetEducationalPathProgressQueryVariables
    >(GetEducationalPathProgressDocument, options);
}
export function useGetEducationalPathProgressLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalPathProgressQuery,
        GetEducationalPathProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalPathProgressQuery,
        GetEducationalPathProgressQueryVariables
    >(GetEducationalPathProgressDocument, options);
}
export function useGetEducationalPathProgressSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalPathProgressQuery,
              GetEducationalPathProgressQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalPathProgressQuery,
        GetEducationalPathProgressQueryVariables
    >(GetEducationalPathProgressDocument, options);
}
export type GetEducationalPathProgressQueryHookResult = ReturnType<
    typeof useGetEducationalPathProgressQuery
>;
export type GetEducationalPathProgressLazyQueryHookResult = ReturnType<
    typeof useGetEducationalPathProgressLazyQuery
>;
export type GetEducationalPathProgressSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalPathProgressSuspenseQuery
>;
export type GetEducationalPathProgressQueryResult = Apollo.QueryResult<
    GetEducationalPathProgressQuery,
    GetEducationalPathProgressQueryVariables
>;
export const GetEducationalPathsForAppDocument = gql`
    query GetEducationalPathsForApp($educationalProgramId: UUID!) {
        getEducationalPathsForApp(educationalProgramId: $educationalProgramId) {
            id
            name
            description
            dashboardImageId
            headerImageId
            headerIntroText
        }
    }
`;

/**
 * __useGetEducationalPathsForAppQuery__
 *
 * To run a query within a React component, call `useGetEducationalPathsForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalPathsForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalPathsForAppQuery({
 *   variables: {
 *      educationalProgramId: // value for 'educationalProgramId'
 *   },
 * });
 */
export function useGetEducationalPathsForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalPathsForAppQuery,
        GetEducationalPathsForAppQueryVariables
    > &
        (
            | {
                  variables: GetEducationalPathsForAppQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalPathsForAppQuery,
        GetEducationalPathsForAppQueryVariables
    >(GetEducationalPathsForAppDocument, options);
}
export function useGetEducationalPathsForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalPathsForAppQuery,
        GetEducationalPathsForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalPathsForAppQuery,
        GetEducationalPathsForAppQueryVariables
    >(GetEducationalPathsForAppDocument, options);
}
export function useGetEducationalPathsForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalPathsForAppQuery,
              GetEducationalPathsForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalPathsForAppQuery,
        GetEducationalPathsForAppQueryVariables
    >(GetEducationalPathsForAppDocument, options);
}
export type GetEducationalPathsForAppQueryHookResult = ReturnType<
    typeof useGetEducationalPathsForAppQuery
>;
export type GetEducationalPathsForAppLazyQueryHookResult = ReturnType<
    typeof useGetEducationalPathsForAppLazyQuery
>;
export type GetEducationalPathsForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalPathsForAppSuspenseQuery
>;
export type GetEducationalPathsForAppQueryResult = Apollo.QueryResult<
    GetEducationalPathsForAppQuery,
    GetEducationalPathsForAppQueryVariables
>;
export const GetEducationalPathsForAppProgressTableFilterDialogDocument = gql`
    query GetEducationalPathsForAppProgressTableFilterDialog(
        $educationalProgramId: UUID!
    ) {
        educationalPaths: getEducationalPathsForApp(
            educationalProgramId: $educationalProgramId
        ) {
            id
            name
        }
    }
`;

/**
 * __useGetEducationalPathsForAppProgressTableFilterDialogQuery__
 *
 * To run a query within a React component, call `useGetEducationalPathsForAppProgressTableFilterDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalPathsForAppProgressTableFilterDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalPathsForAppProgressTableFilterDialogQuery({
 *   variables: {
 *      educationalProgramId: // value for 'educationalProgramId'
 *   },
 * });
 */
export function useGetEducationalPathsForAppProgressTableFilterDialogQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalPathsForAppProgressTableFilterDialogQuery,
        GetEducationalPathsForAppProgressTableFilterDialogQueryVariables
    > &
        (
            | {
                  variables: GetEducationalPathsForAppProgressTableFilterDialogQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalPathsForAppProgressTableFilterDialogQuery,
        GetEducationalPathsForAppProgressTableFilterDialogQueryVariables
    >(GetEducationalPathsForAppProgressTableFilterDialogDocument, options);
}
export function useGetEducationalPathsForAppProgressTableFilterDialogLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalPathsForAppProgressTableFilterDialogQuery,
        GetEducationalPathsForAppProgressTableFilterDialogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalPathsForAppProgressTableFilterDialogQuery,
        GetEducationalPathsForAppProgressTableFilterDialogQueryVariables
    >(GetEducationalPathsForAppProgressTableFilterDialogDocument, options);
}
export function useGetEducationalPathsForAppProgressTableFilterDialogSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalPathsForAppProgressTableFilterDialogQuery,
              GetEducationalPathsForAppProgressTableFilterDialogQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalPathsForAppProgressTableFilterDialogQuery,
        GetEducationalPathsForAppProgressTableFilterDialogQueryVariables
    >(GetEducationalPathsForAppProgressTableFilterDialogDocument, options);
}
export type GetEducationalPathsForAppProgressTableFilterDialogQueryHookResult =
    ReturnType<
        typeof useGetEducationalPathsForAppProgressTableFilterDialogQuery
    >;
export type GetEducationalPathsForAppProgressTableFilterDialogLazyQueryHookResult =
    ReturnType<
        typeof useGetEducationalPathsForAppProgressTableFilterDialogLazyQuery
    >;
export type GetEducationalPathsForAppProgressTableFilterDialogSuspenseQueryHookResult =
    ReturnType<
        typeof useGetEducationalPathsForAppProgressTableFilterDialogSuspenseQuery
    >;
export type GetEducationalPathsForAppProgressTableFilterDialogQueryResult =
    Apollo.QueryResult<
        GetEducationalPathsForAppProgressTableFilterDialogQuery,
        GetEducationalPathsForAppProgressTableFilterDialogQueryVariables
    >;
export const GetEducationalPathsForProgramDocument = gql`
    query GetEducationalPathsForProgram($programId: UUID!) {
        getEducationalPathsForProgram(id: $programId) {
            programId
            educationalPaths {
                id
                name
                order
            }
        }
    }
`;

/**
 * __useGetEducationalPathsForProgramQuery__
 *
 * To run a query within a React component, call `useGetEducationalPathsForProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalPathsForProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalPathsForProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetEducationalPathsForProgramQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalPathsForProgramQuery,
        GetEducationalPathsForProgramQueryVariables
    > &
        (
            | {
                  variables: GetEducationalPathsForProgramQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalPathsForProgramQuery,
        GetEducationalPathsForProgramQueryVariables
    >(GetEducationalPathsForProgramDocument, options);
}
export function useGetEducationalPathsForProgramLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalPathsForProgramQuery,
        GetEducationalPathsForProgramQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalPathsForProgramQuery,
        GetEducationalPathsForProgramQueryVariables
    >(GetEducationalPathsForProgramDocument, options);
}
export function useGetEducationalPathsForProgramSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalPathsForProgramQuery,
              GetEducationalPathsForProgramQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalPathsForProgramQuery,
        GetEducationalPathsForProgramQueryVariables
    >(GetEducationalPathsForProgramDocument, options);
}
export type GetEducationalPathsForProgramQueryHookResult = ReturnType<
    typeof useGetEducationalPathsForProgramQuery
>;
export type GetEducationalPathsForProgramLazyQueryHookResult = ReturnType<
    typeof useGetEducationalPathsForProgramLazyQuery
>;
export type GetEducationalPathsForProgramSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalPathsForProgramSuspenseQuery
>;
export type GetEducationalPathsForProgramQueryResult = Apollo.QueryResult<
    GetEducationalPathsForProgramQuery,
    GetEducationalPathsForProgramQueryVariables
>;
export const GetEducationalProgramDocument = gql`
    query GetEducationalProgram($id: UUID!) {
        getEducationalProgram(id: $id) {
            id
            name
            description
        }
    }
`;

/**
 * __useGetEducationalProgramQuery__
 *
 * To run a query within a React component, call `useGetEducationalProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEducationalProgramQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalProgramQuery,
        GetEducationalProgramQueryVariables
    > &
        (
            | { variables: GetEducationalProgramQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalProgramQuery,
        GetEducationalProgramQueryVariables
    >(GetEducationalProgramDocument, options);
}
export function useGetEducationalProgramLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalProgramQuery,
        GetEducationalProgramQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalProgramQuery,
        GetEducationalProgramQueryVariables
    >(GetEducationalProgramDocument, options);
}
export function useGetEducationalProgramSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalProgramQuery,
              GetEducationalProgramQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalProgramQuery,
        GetEducationalProgramQueryVariables
    >(GetEducationalProgramDocument, options);
}
export type GetEducationalProgramQueryHookResult = ReturnType<
    typeof useGetEducationalProgramQuery
>;
export type GetEducationalProgramLazyQueryHookResult = ReturnType<
    typeof useGetEducationalProgramLazyQuery
>;
export type GetEducationalProgramSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalProgramSuspenseQuery
>;
export type GetEducationalProgramQueryResult = Apollo.QueryResult<
    GetEducationalProgramQuery,
    GetEducationalProgramQueryVariables
>;
export const GetEducationalProgramForGroupDocument = gql`
    query GetEducationalProgramForGroup($groupId: UUID!, $programId: UUID!) {
        educationalProgram: getEducationalProgramForGroup(
            groupId: $groupId
            programId: $programId
        ) {
            id
            name
            description
        }
    }
`;

/**
 * __useGetEducationalProgramForGroupQuery__
 *
 * To run a query within a React component, call `useGetEducationalProgramForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalProgramForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalProgramForGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetEducationalProgramForGroupQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalProgramForGroupQuery,
        GetEducationalProgramForGroupQueryVariables
    > &
        (
            | {
                  variables: GetEducationalProgramForGroupQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalProgramForGroupQuery,
        GetEducationalProgramForGroupQueryVariables
    >(GetEducationalProgramForGroupDocument, options);
}
export function useGetEducationalProgramForGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalProgramForGroupQuery,
        GetEducationalProgramForGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalProgramForGroupQuery,
        GetEducationalProgramForGroupQueryVariables
    >(GetEducationalProgramForGroupDocument, options);
}
export function useGetEducationalProgramForGroupSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalProgramForGroupQuery,
              GetEducationalProgramForGroupQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalProgramForGroupQuery,
        GetEducationalProgramForGroupQueryVariables
    >(GetEducationalProgramForGroupDocument, options);
}
export type GetEducationalProgramForGroupQueryHookResult = ReturnType<
    typeof useGetEducationalProgramForGroupQuery
>;
export type GetEducationalProgramForGroupLazyQueryHookResult = ReturnType<
    typeof useGetEducationalProgramForGroupLazyQuery
>;
export type GetEducationalProgramForGroupSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalProgramForGroupSuspenseQuery
>;
export type GetEducationalProgramForGroupQueryResult = Apollo.QueryResult<
    GetEducationalProgramForGroupQuery,
    GetEducationalProgramForGroupQueryVariables
>;
export const GetEducationalProgramsForAppDocument = gql`
    query GetEducationalProgramsForApp {
        getEducationalProgramsForApp {
            id
            description
            name
        }
    }
`;

/**
 * __useGetEducationalProgramsForAppQuery__
 *
 * To run a query within a React component, call `useGetEducationalProgramsForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalProgramsForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalProgramsForAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEducationalProgramsForAppQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetEducationalProgramsForAppQuery,
        GetEducationalProgramsForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalProgramsForAppQuery,
        GetEducationalProgramsForAppQueryVariables
    >(GetEducationalProgramsForAppDocument, options);
}
export function useGetEducationalProgramsForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalProgramsForAppQuery,
        GetEducationalProgramsForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalProgramsForAppQuery,
        GetEducationalProgramsForAppQueryVariables
    >(GetEducationalProgramsForAppDocument, options);
}
export function useGetEducationalProgramsForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalProgramsForAppQuery,
              GetEducationalProgramsForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalProgramsForAppQuery,
        GetEducationalProgramsForAppQueryVariables
    >(GetEducationalProgramsForAppDocument, options);
}
export type GetEducationalProgramsForAppQueryHookResult = ReturnType<
    typeof useGetEducationalProgramsForAppQuery
>;
export type GetEducationalProgramsForAppLazyQueryHookResult = ReturnType<
    typeof useGetEducationalProgramsForAppLazyQuery
>;
export type GetEducationalProgramsForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalProgramsForAppSuspenseQuery
>;
export type GetEducationalProgramsForAppQueryResult = Apollo.QueryResult<
    GetEducationalProgramsForAppQuery,
    GetEducationalProgramsForAppQueryVariables
>;
export const GetEducationalProgramsForProgressTableDocument = gql`
    query GetEducationalProgramsForProgressTable($groupId: UUID!) {
        programs: getEducationalProgramsForGroup(groupId: $groupId) {
            id
            name
        }
    }
`;

/**
 * __useGetEducationalProgramsForProgressTableQuery__
 *
 * To run a query within a React component, call `useGetEducationalProgramsForProgressTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalProgramsForProgressTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalProgramsForProgressTableQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetEducationalProgramsForProgressTableQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalProgramsForProgressTableQuery,
        GetEducationalProgramsForProgressTableQueryVariables
    > &
        (
            | {
                  variables: GetEducationalProgramsForProgressTableQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalProgramsForProgressTableQuery,
        GetEducationalProgramsForProgressTableQueryVariables
    >(GetEducationalProgramsForProgressTableDocument, options);
}
export function useGetEducationalProgramsForProgressTableLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalProgramsForProgressTableQuery,
        GetEducationalProgramsForProgressTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalProgramsForProgressTableQuery,
        GetEducationalProgramsForProgressTableQueryVariables
    >(GetEducationalProgramsForProgressTableDocument, options);
}
export function useGetEducationalProgramsForProgressTableSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalProgramsForProgressTableQuery,
              GetEducationalProgramsForProgressTableQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalProgramsForProgressTableQuery,
        GetEducationalProgramsForProgressTableQueryVariables
    >(GetEducationalProgramsForProgressTableDocument, options);
}
export type GetEducationalProgramsForProgressTableQueryHookResult = ReturnType<
    typeof useGetEducationalProgramsForProgressTableQuery
>;
export type GetEducationalProgramsForProgressTableLazyQueryHookResult =
    ReturnType<typeof useGetEducationalProgramsForProgressTableLazyQuery>;
export type GetEducationalProgramsForProgressTableSuspenseQueryHookResult =
    ReturnType<typeof useGetEducationalProgramsForProgressTableSuspenseQuery>;
export type GetEducationalProgramsForProgressTableQueryResult =
    Apollo.QueryResult<
        GetEducationalProgramsForProgressTableQuery,
        GetEducationalProgramsForProgressTableQueryVariables
    >;
export const GetElementsForProgramDocument = gql`
    query GetElementsForProgram(
        $programId: UUID!
        $filters: AppEducationalProgramElementsFilterInput!
    ) {
        elements: getElementsForProgram(
            programId: $programId
            filters: $filters
        ) {
            educationalPaths {
                id
                name
            }
            modules {
                id
                name
            }
            didacticTools {
                id
                name
            }
        }
    }
`;

/**
 * __useGetElementsForProgramQuery__
 *
 * To run a query within a React component, call `useGetElementsForProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsForProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsForProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetElementsForProgramQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetElementsForProgramQuery,
        GetElementsForProgramQueryVariables
    > &
        (
            | { variables: GetElementsForProgramQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetElementsForProgramQuery,
        GetElementsForProgramQueryVariables
    >(GetElementsForProgramDocument, options);
}
export function useGetElementsForProgramLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetElementsForProgramQuery,
        GetElementsForProgramQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetElementsForProgramQuery,
        GetElementsForProgramQueryVariables
    >(GetElementsForProgramDocument, options);
}
export function useGetElementsForProgramSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetElementsForProgramQuery,
              GetElementsForProgramQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetElementsForProgramQuery,
        GetElementsForProgramQueryVariables
    >(GetElementsForProgramDocument, options);
}
export type GetElementsForProgramQueryHookResult = ReturnType<
    typeof useGetElementsForProgramQuery
>;
export type GetElementsForProgramLazyQueryHookResult = ReturnType<
    typeof useGetElementsForProgramLazyQuery
>;
export type GetElementsForProgramSuspenseQueryHookResult = ReturnType<
    typeof useGetElementsForProgramSuspenseQuery
>;
export type GetElementsForProgramQueryResult = Apollo.QueryResult<
    GetElementsForProgramQuery,
    GetElementsForProgramQueryVariables
>;
export const GetFiltersForReferenceCardDocument = gql`
    query GetFiltersForReferenceCard($programId: UUID!) {
        filters: getReferenceCardsFilterOptionsForApp(programId: $programId) {
            categories {
                id
                label
            }
            leads {
                id
                label
            }
        }
    }
`;

/**
 * __useGetFiltersForReferenceCardQuery__
 *
 * To run a query within a React component, call `useGetFiltersForReferenceCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersForReferenceCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersForReferenceCardQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetFiltersForReferenceCardQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetFiltersForReferenceCardQuery,
        GetFiltersForReferenceCardQueryVariables
    > &
        (
            | {
                  variables: GetFiltersForReferenceCardQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetFiltersForReferenceCardQuery,
        GetFiltersForReferenceCardQueryVariables
    >(GetFiltersForReferenceCardDocument, options);
}
export function useGetFiltersForReferenceCardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetFiltersForReferenceCardQuery,
        GetFiltersForReferenceCardQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetFiltersForReferenceCardQuery,
        GetFiltersForReferenceCardQueryVariables
    >(GetFiltersForReferenceCardDocument, options);
}
export function useGetFiltersForReferenceCardSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetFiltersForReferenceCardQuery,
              GetFiltersForReferenceCardQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetFiltersForReferenceCardQuery,
        GetFiltersForReferenceCardQueryVariables
    >(GetFiltersForReferenceCardDocument, options);
}
export type GetFiltersForReferenceCardQueryHookResult = ReturnType<
    typeof useGetFiltersForReferenceCardQuery
>;
export type GetFiltersForReferenceCardLazyQueryHookResult = ReturnType<
    typeof useGetFiltersForReferenceCardLazyQuery
>;
export type GetFiltersForReferenceCardSuspenseQueryHookResult = ReturnType<
    typeof useGetFiltersForReferenceCardSuspenseQuery
>;
export type GetFiltersForReferenceCardQueryResult = Apollo.QueryResult<
    GetFiltersForReferenceCardQuery,
    GetFiltersForReferenceCardQueryVariables
>;
export const GetGroupDocument = gql`
    query GetGroup($id: UUID!) {
        getGroup(id: $id) {
            id
            name
            productIds
            showGroupDuringRegistration
            parentId
            externalCode
        }
    }
`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetGroupQuery,
        GetGroupQueryVariables
    > &
        (
            | { variables: GetGroupQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(
        GetGroupDocument,
        options,
    );
}
export function useGetGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGroupQuery,
        GetGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(
        GetGroupDocument,
        options,
    );
}
export function useGetGroupSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetGroupQuery,
              GetGroupQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetGroupQuery, GetGroupQueryVariables>(
        GetGroupDocument,
        options,
    );
}
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<
    typeof useGetGroupLazyQuery
>;
export type GetGroupSuspenseQueryHookResult = ReturnType<
    typeof useGetGroupSuspenseQuery
>;
export type GetGroupQueryResult = Apollo.QueryResult<
    GetGroupQuery,
    GetGroupQueryVariables
>;
export const GetGroupForAppDocument = gql`
    query GetGroupForApp($id: UUID!) {
        getGroupForApp(id: $id) {
            ...AppGroup
            members {
                ...AppGroupMember
            }
        }
    }
    ${AppGroupFragmentDoc}
    ${AppGroupMemberFragmentDoc}
`;

/**
 * __useGetGroupForAppQuery__
 *
 * To run a query within a React component, call `useGetGroupForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupForAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetGroupForAppQuery,
        GetGroupForAppQueryVariables
    > &
        (
            | { variables: GetGroupForAppQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetGroupForAppQuery, GetGroupForAppQueryVariables>(
        GetGroupForAppDocument,
        options,
    );
}
export function useGetGroupForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGroupForAppQuery,
        GetGroupForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetGroupForAppQuery,
        GetGroupForAppQueryVariables
    >(GetGroupForAppDocument, options);
}
export function useGetGroupForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetGroupForAppQuery,
              GetGroupForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetGroupForAppQuery,
        GetGroupForAppQueryVariables
    >(GetGroupForAppDocument, options);
}
export type GetGroupForAppQueryHookResult = ReturnType<
    typeof useGetGroupForAppQuery
>;
export type GetGroupForAppLazyQueryHookResult = ReturnType<
    typeof useGetGroupForAppLazyQuery
>;
export type GetGroupForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetGroupForAppSuspenseQuery
>;
export type GetGroupForAppQueryResult = Apollo.QueryResult<
    GetGroupForAppQuery,
    GetGroupForAppQueryVariables
>;
export const GetGroupsForAuthenticatedUserDocument = gql`
    query GetGroupsForAuthenticatedUser {
        getGroupsForAuthenticatedUser {
            ...GroupFields
            ...SubgroupsRecursive
        }
    }
    ${GroupFieldsFragmentDoc}
    ${SubgroupsRecursiveFragmentDoc}
`;

/**
 * __useGetGroupsForAuthenticatedUserQuery__
 *
 * To run a query within a React component, call `useGetGroupsForAuthenticatedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsForAuthenticatedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsForAuthenticatedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsForAuthenticatedUserQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetGroupsForAuthenticatedUserQuery,
        GetGroupsForAuthenticatedUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetGroupsForAuthenticatedUserQuery,
        GetGroupsForAuthenticatedUserQueryVariables
    >(GetGroupsForAuthenticatedUserDocument, options);
}
export function useGetGroupsForAuthenticatedUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGroupsForAuthenticatedUserQuery,
        GetGroupsForAuthenticatedUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetGroupsForAuthenticatedUserQuery,
        GetGroupsForAuthenticatedUserQueryVariables
    >(GetGroupsForAuthenticatedUserDocument, options);
}
export function useGetGroupsForAuthenticatedUserSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetGroupsForAuthenticatedUserQuery,
              GetGroupsForAuthenticatedUserQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetGroupsForAuthenticatedUserQuery,
        GetGroupsForAuthenticatedUserQueryVariables
    >(GetGroupsForAuthenticatedUserDocument, options);
}
export type GetGroupsForAuthenticatedUserQueryHookResult = ReturnType<
    typeof useGetGroupsForAuthenticatedUserQuery
>;
export type GetGroupsForAuthenticatedUserLazyQueryHookResult = ReturnType<
    typeof useGetGroupsForAuthenticatedUserLazyQuery
>;
export type GetGroupsForAuthenticatedUserSuspenseQueryHookResult = ReturnType<
    typeof useGetGroupsForAuthenticatedUserSuspenseQuery
>;
export type GetGroupsForAuthenticatedUserQueryResult = Apollo.QueryResult<
    GetGroupsForAuthenticatedUserQuery,
    GetGroupsForAuthenticatedUserQueryVariables
>;
export const GetGroupsForProgressTableDocument = gql`
    query GetGroupsForProgressTable {
        getGroupsForAuthenticatedUser {
            ...GroupFields
            ...SubgroupsRecursive
        }
    }
    ${GroupFieldsFragmentDoc}
    ${SubgroupsRecursiveFragmentDoc}
`;

/**
 * __useGetGroupsForProgressTableQuery__
 *
 * To run a query within a React component, call `useGetGroupsForProgressTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsForProgressTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsForProgressTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsForProgressTableQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetGroupsForProgressTableQuery,
        GetGroupsForProgressTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetGroupsForProgressTableQuery,
        GetGroupsForProgressTableQueryVariables
    >(GetGroupsForProgressTableDocument, options);
}
export function useGetGroupsForProgressTableLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGroupsForProgressTableQuery,
        GetGroupsForProgressTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetGroupsForProgressTableQuery,
        GetGroupsForProgressTableQueryVariables
    >(GetGroupsForProgressTableDocument, options);
}
export function useGetGroupsForProgressTableSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetGroupsForProgressTableQuery,
              GetGroupsForProgressTableQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetGroupsForProgressTableQuery,
        GetGroupsForProgressTableQueryVariables
    >(GetGroupsForProgressTableDocument, options);
}
export type GetGroupsForProgressTableQueryHookResult = ReturnType<
    typeof useGetGroupsForProgressTableQuery
>;
export type GetGroupsForProgressTableLazyQueryHookResult = ReturnType<
    typeof useGetGroupsForProgressTableLazyQuery
>;
export type GetGroupsForProgressTableSuspenseQueryHookResult = ReturnType<
    typeof useGetGroupsForProgressTableSuspenseQuery
>;
export type GetGroupsForProgressTableQueryResult = Apollo.QueryResult<
    GetGroupsForProgressTableQuery,
    GetGroupsForProgressTableQueryVariables
>;
export const GetGroupsMarkedForRegistrationDocument = gql`
    query GetGroupsMarkedForRegistration {
        getGroupsMarkedForRegistration {
            id
            name
            parentId
            showGroupDuringRegistration
            subgroups {
                id
                name
                parentId
                showGroupDuringRegistration
                subgroups {
                    id
                    name
                    parentId
                    showGroupDuringRegistration
                }
            }
        }
    }
`;

/**
 * __useGetGroupsMarkedForRegistrationQuery__
 *
 * To run a query within a React component, call `useGetGroupsMarkedForRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsMarkedForRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsMarkedForRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsMarkedForRegistrationQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetGroupsMarkedForRegistrationQuery,
        GetGroupsMarkedForRegistrationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetGroupsMarkedForRegistrationQuery,
        GetGroupsMarkedForRegistrationQueryVariables
    >(GetGroupsMarkedForRegistrationDocument, options);
}
export function useGetGroupsMarkedForRegistrationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGroupsMarkedForRegistrationQuery,
        GetGroupsMarkedForRegistrationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetGroupsMarkedForRegistrationQuery,
        GetGroupsMarkedForRegistrationQueryVariables
    >(GetGroupsMarkedForRegistrationDocument, options);
}
export function useGetGroupsMarkedForRegistrationSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetGroupsMarkedForRegistrationQuery,
              GetGroupsMarkedForRegistrationQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetGroupsMarkedForRegistrationQuery,
        GetGroupsMarkedForRegistrationQueryVariables
    >(GetGroupsMarkedForRegistrationDocument, options);
}
export type GetGroupsMarkedForRegistrationQueryHookResult = ReturnType<
    typeof useGetGroupsMarkedForRegistrationQuery
>;
export type GetGroupsMarkedForRegistrationLazyQueryHookResult = ReturnType<
    typeof useGetGroupsMarkedForRegistrationLazyQuery
>;
export type GetGroupsMarkedForRegistrationSuspenseQueryHookResult = ReturnType<
    typeof useGetGroupsMarkedForRegistrationSuspenseQuery
>;
export type GetGroupsMarkedForRegistrationQueryResult = Apollo.QueryResult<
    GetGroupsMarkedForRegistrationQuery,
    GetGroupsMarkedForRegistrationQueryVariables
>;
export const GetLastViewedModuleDocument = gql`
    query GetLastViewedModule($programId: UUID!) {
        getLastViewedModule(programId: $programId) {
            module {
                id
                content
                dashboardImageId
                description
                headerImageId
                shortTitle
                title
            }
            educationalPathId
            programId
        }
    }
`;

/**
 * __useGetLastViewedModuleQuery__
 *
 * To run a query within a React component, call `useGetLastViewedModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastViewedModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastViewedModuleQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetLastViewedModuleQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLastViewedModuleQuery,
        GetLastViewedModuleQueryVariables
    > &
        (
            | { variables: GetLastViewedModuleQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetLastViewedModuleQuery,
        GetLastViewedModuleQueryVariables
    >(GetLastViewedModuleDocument, options);
}
export function useGetLastViewedModuleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLastViewedModuleQuery,
        GetLastViewedModuleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetLastViewedModuleQuery,
        GetLastViewedModuleQueryVariables
    >(GetLastViewedModuleDocument, options);
}
export function useGetLastViewedModuleSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetLastViewedModuleQuery,
              GetLastViewedModuleQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetLastViewedModuleQuery,
        GetLastViewedModuleQueryVariables
    >(GetLastViewedModuleDocument, options);
}
export type GetLastViewedModuleQueryHookResult = ReturnType<
    typeof useGetLastViewedModuleQuery
>;
export type GetLastViewedModuleLazyQueryHookResult = ReturnType<
    typeof useGetLastViewedModuleLazyQuery
>;
export type GetLastViewedModuleSuspenseQueryHookResult = ReturnType<
    typeof useGetLastViewedModuleSuspenseQuery
>;
export type GetLastViewedModuleQueryResult = Apollo.QueryResult<
    GetLastViewedModuleQuery,
    GetLastViewedModuleQueryVariables
>;
export const GetLicensesForUserInCmsDocument = gql`
    query GetLicensesForUserInCms($userId: String!) {
        getLicenses(
            paginationInput: { page: 0, pageSize: 999 }
            filters: [{ selector: UserId, operator: EQUALS, value: $userId }]
        ) {
            rows {
                id
                product {
                    id
                    name
                }
                validUntil
                startAt
                isActive
            }
        }
    }
`;

/**
 * __useGetLicensesForUserInCmsQuery__
 *
 * To run a query within a React component, call `useGetLicensesForUserInCmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensesForUserInCmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensesForUserInCmsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLicensesForUserInCmsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetLicensesForUserInCmsQuery,
        GetLicensesForUserInCmsQueryVariables
    > &
        (
            | {
                  variables: GetLicensesForUserInCmsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetLicensesForUserInCmsQuery,
        GetLicensesForUserInCmsQueryVariables
    >(GetLicensesForUserInCmsDocument, options);
}
export function useGetLicensesForUserInCmsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetLicensesForUserInCmsQuery,
        GetLicensesForUserInCmsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetLicensesForUserInCmsQuery,
        GetLicensesForUserInCmsQueryVariables
    >(GetLicensesForUserInCmsDocument, options);
}
export function useGetLicensesForUserInCmsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetLicensesForUserInCmsQuery,
              GetLicensesForUserInCmsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetLicensesForUserInCmsQuery,
        GetLicensesForUserInCmsQueryVariables
    >(GetLicensesForUserInCmsDocument, options);
}
export type GetLicensesForUserInCmsQueryHookResult = ReturnType<
    typeof useGetLicensesForUserInCmsQuery
>;
export type GetLicensesForUserInCmsLazyQueryHookResult = ReturnType<
    typeof useGetLicensesForUserInCmsLazyQuery
>;
export type GetLicensesForUserInCmsSuspenseQueryHookResult = ReturnType<
    typeof useGetLicensesForUserInCmsSuspenseQuery
>;
export type GetLicensesForUserInCmsQueryResult = Apollo.QueryResult<
    GetLicensesForUserInCmsQuery,
    GetLicensesForUserInCmsQueryVariables
>;
export const GetModuleDocument = gql`
    query GetModule($id: UUID!) {
        getModule(id: $id) {
            id
            content
            dashboardImageId
            durationIndication
            description
            headerImageId
            shortTitle
            title
            moduleBlocks: blocks {
                ...BaseModuleBlock
                ...DidacticToolsModuleBlock
                ...TitleModuleBlock
                ...VideoModuleBlock
            }
        }
    }
    ${BaseModuleBlockFragmentDoc}
    ${DidacticToolsModuleBlockFragmentDoc}
    ${TitleModuleBlockFragmentDoc}
    ${VideoModuleBlockFragmentDoc}
`;

/**
 * __useGetModuleQuery__
 *
 * To run a query within a React component, call `useGetModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModuleQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModuleQuery,
        GetModuleQueryVariables
    > &
        (
            | { variables: GetModuleQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetModuleQuery, GetModuleQueryVariables>(
        GetModuleDocument,
        options,
    );
}
export function useGetModuleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModuleQuery,
        GetModuleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetModuleQuery, GetModuleQueryVariables>(
        GetModuleDocument,
        options,
    );
}
export function useGetModuleSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModuleQuery,
              GetModuleQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetModuleQuery, GetModuleQueryVariables>(
        GetModuleDocument,
        options,
    );
}
export type GetModuleQueryHookResult = ReturnType<typeof useGetModuleQuery>;
export type GetModuleLazyQueryHookResult = ReturnType<
    typeof useGetModuleLazyQuery
>;
export type GetModuleSuspenseQueryHookResult = ReturnType<
    typeof useGetModuleSuspenseQuery
>;
export type GetModuleQueryResult = Apollo.QueryResult<
    GetModuleQuery,
    GetModuleQueryVariables
>;
export const GetModuleBlocksForAppDocument = gql`
    query GetModuleBlocksForApp($moduleId: UUID!) {
        getModuleBlocksForApp(moduleId: $moduleId) {
            blocks {
                ... on AppDidacticToolsModuleBlockDto {
                    id
                    didacticToolBlocks {
                        didacticToolId
                        order
                        variant
                        didacticTool {
                            altLabel
                            description
                            durationIndication
                            headerBackground
                            headerImageId
                            headerIntroText
                            id
                            label
                            learningAdviceDescription
                            learningAdviceTitle
                            name
                            type
                        }
                    }
                }
                ... on AppTitleModuleBlockDto {
                    id
                    title
                    description
                    order
                }
                ... on AppVideoModuleBlockDto {
                    assetId
                    durationIndication
                    title
                    id
                    order
                    type
                }
            }
            moduleId
        }
    }
`;

/**
 * __useGetModuleBlocksForAppQuery__
 *
 * To run a query within a React component, call `useGetModuleBlocksForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleBlocksForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleBlocksForAppQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetModuleBlocksForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModuleBlocksForAppQuery,
        GetModuleBlocksForAppQueryVariables
    > &
        (
            | { variables: GetModuleBlocksForAppQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetModuleBlocksForAppQuery,
        GetModuleBlocksForAppQueryVariables
    >(GetModuleBlocksForAppDocument, options);
}
export function useGetModuleBlocksForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModuleBlocksForAppQuery,
        GetModuleBlocksForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetModuleBlocksForAppQuery,
        GetModuleBlocksForAppQueryVariables
    >(GetModuleBlocksForAppDocument, options);
}
export function useGetModuleBlocksForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModuleBlocksForAppQuery,
              GetModuleBlocksForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetModuleBlocksForAppQuery,
        GetModuleBlocksForAppQueryVariables
    >(GetModuleBlocksForAppDocument, options);
}
export type GetModuleBlocksForAppQueryHookResult = ReturnType<
    typeof useGetModuleBlocksForAppQuery
>;
export type GetModuleBlocksForAppLazyQueryHookResult = ReturnType<
    typeof useGetModuleBlocksForAppLazyQuery
>;
export type GetModuleBlocksForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetModuleBlocksForAppSuspenseQuery
>;
export type GetModuleBlocksForAppQueryResult = Apollo.QueryResult<
    GetModuleBlocksForAppQuery,
    GetModuleBlocksForAppQueryVariables
>;
export const GetModuleForAppDocument = gql`
    query GetModuleForApp($moduleId: UUID!) {
        getModuleForApp(moduleId: $moduleId) {
            id
            content
            dashboardImageId
            description
            durationIndication
            headerImageId
            shortTitle
            title
        }
    }
`;

/**
 * __useGetModuleForAppQuery__
 *
 * To run a query within a React component, call `useGetModuleForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleForAppQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetModuleForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModuleForAppQuery,
        GetModuleForAppQueryVariables
    > &
        (
            | { variables: GetModuleForAppQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetModuleForAppQuery, GetModuleForAppQueryVariables>(
        GetModuleForAppDocument,
        options,
    );
}
export function useGetModuleForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModuleForAppQuery,
        GetModuleForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetModuleForAppQuery,
        GetModuleForAppQueryVariables
    >(GetModuleForAppDocument, options);
}
export function useGetModuleForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModuleForAppQuery,
              GetModuleForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetModuleForAppQuery,
        GetModuleForAppQueryVariables
    >(GetModuleForAppDocument, options);
}
export type GetModuleForAppQueryHookResult = ReturnType<
    typeof useGetModuleForAppQuery
>;
export type GetModuleForAppLazyQueryHookResult = ReturnType<
    typeof useGetModuleForAppLazyQuery
>;
export type GetModuleForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetModuleForAppSuspenseQuery
>;
export type GetModuleForAppQueryResult = Apollo.QueryResult<
    GetModuleForAppQuery,
    GetModuleForAppQueryVariables
>;
export const GetModuleProgressDocument = gql`
    query GetModuleProgress($ids: [UUID!]!) {
        getModuleProgress(ids: $ids) {
            id
            progressPercentage
        }
    }
`;

/**
 * __useGetModuleProgressQuery__
 *
 * To run a query within a React component, call `useGetModuleProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleProgressQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetModuleProgressQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModuleProgressQuery,
        GetModuleProgressQueryVariables
    > &
        (
            | { variables: GetModuleProgressQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetModuleProgressQuery,
        GetModuleProgressQueryVariables
    >(GetModuleProgressDocument, options);
}
export function useGetModuleProgressLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModuleProgressQuery,
        GetModuleProgressQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetModuleProgressQuery,
        GetModuleProgressQueryVariables
    >(GetModuleProgressDocument, options);
}
export function useGetModuleProgressSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModuleProgressQuery,
              GetModuleProgressQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetModuleProgressQuery,
        GetModuleProgressQueryVariables
    >(GetModuleProgressDocument, options);
}
export type GetModuleProgressQueryHookResult = ReturnType<
    typeof useGetModuleProgressQuery
>;
export type GetModuleProgressLazyQueryHookResult = ReturnType<
    typeof useGetModuleProgressLazyQuery
>;
export type GetModuleProgressSuspenseQueryHookResult = ReturnType<
    typeof useGetModuleProgressSuspenseQuery
>;
export type GetModuleProgressQueryResult = Apollo.QueryResult<
    GetModuleProgressQuery,
    GetModuleProgressQueryVariables
>;
export const GetModulesForEducationalPathForAppDocument = gql`
    query GetModulesForEducationalPathForApp($educationalPathId: UUID!) {
        getModulesForEducationalPathForApp(
            educationalPathId: $educationalPathId
        ) {
            content
            dashboardImageId
            description
            id
            headerImageId
            title
            shortTitle
        }
    }
`;

/**
 * __useGetModulesForEducationalPathForAppQuery__
 *
 * To run a query within a React component, call `useGetModulesForEducationalPathForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesForEducationalPathForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesForEducationalPathForAppQuery({
 *   variables: {
 *      educationalPathId: // value for 'educationalPathId'
 *   },
 * });
 */
export function useGetModulesForEducationalPathForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModulesForEducationalPathForAppQuery,
        GetModulesForEducationalPathForAppQueryVariables
    > &
        (
            | {
                  variables: GetModulesForEducationalPathForAppQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetModulesForEducationalPathForAppQuery,
        GetModulesForEducationalPathForAppQueryVariables
    >(GetModulesForEducationalPathForAppDocument, options);
}
export function useGetModulesForEducationalPathForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModulesForEducationalPathForAppQuery,
        GetModulesForEducationalPathForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetModulesForEducationalPathForAppQuery,
        GetModulesForEducationalPathForAppQueryVariables
    >(GetModulesForEducationalPathForAppDocument, options);
}
export function useGetModulesForEducationalPathForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModulesForEducationalPathForAppQuery,
              GetModulesForEducationalPathForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetModulesForEducationalPathForAppQuery,
        GetModulesForEducationalPathForAppQueryVariables
    >(GetModulesForEducationalPathForAppDocument, options);
}
export type GetModulesForEducationalPathForAppQueryHookResult = ReturnType<
    typeof useGetModulesForEducationalPathForAppQuery
>;
export type GetModulesForEducationalPathForAppLazyQueryHookResult = ReturnType<
    typeof useGetModulesForEducationalPathForAppLazyQuery
>;
export type GetModulesForEducationalPathForAppSuspenseQueryHookResult =
    ReturnType<typeof useGetModulesForEducationalPathForAppSuspenseQuery>;
export type GetModulesForEducationalPathForAppQueryResult = Apollo.QueryResult<
    GetModulesForEducationalPathForAppQuery,
    GetModulesForEducationalPathForAppQueryVariables
>;
export const GetModulesForAppProgressTableFilterDialogDocument = gql`
    query GetModulesForAppProgressTableFilterDialog($educationalPathId: UUID!) {
        modules: getModulesForEducationalPathForApp(
            educationalPathId: $educationalPathId
        ) {
            id
            name: title
        }
    }
`;

/**
 * __useGetModulesForAppProgressTableFilterDialogQuery__
 *
 * To run a query within a React component, call `useGetModulesForAppProgressTableFilterDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesForAppProgressTableFilterDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesForAppProgressTableFilterDialogQuery({
 *   variables: {
 *      educationalPathId: // value for 'educationalPathId'
 *   },
 * });
 */
export function useGetModulesForAppProgressTableFilterDialogQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModulesForAppProgressTableFilterDialogQuery,
        GetModulesForAppProgressTableFilterDialogQueryVariables
    > &
        (
            | {
                  variables: GetModulesForAppProgressTableFilterDialogQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetModulesForAppProgressTableFilterDialogQuery,
        GetModulesForAppProgressTableFilterDialogQueryVariables
    >(GetModulesForAppProgressTableFilterDialogDocument, options);
}
export function useGetModulesForAppProgressTableFilterDialogLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModulesForAppProgressTableFilterDialogQuery,
        GetModulesForAppProgressTableFilterDialogQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetModulesForAppProgressTableFilterDialogQuery,
        GetModulesForAppProgressTableFilterDialogQueryVariables
    >(GetModulesForAppProgressTableFilterDialogDocument, options);
}
export function useGetModulesForAppProgressTableFilterDialogSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModulesForAppProgressTableFilterDialogQuery,
              GetModulesForAppProgressTableFilterDialogQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetModulesForAppProgressTableFilterDialogQuery,
        GetModulesForAppProgressTableFilterDialogQueryVariables
    >(GetModulesForAppProgressTableFilterDialogDocument, options);
}
export type GetModulesForAppProgressTableFilterDialogQueryHookResult =
    ReturnType<typeof useGetModulesForAppProgressTableFilterDialogQuery>;
export type GetModulesForAppProgressTableFilterDialogLazyQueryHookResult =
    ReturnType<typeof useGetModulesForAppProgressTableFilterDialogLazyQuery>;
export type GetModulesForAppProgressTableFilterDialogSuspenseQueryHookResult =
    ReturnType<
        typeof useGetModulesForAppProgressTableFilterDialogSuspenseQuery
    >;
export type GetModulesForAppProgressTableFilterDialogQueryResult =
    Apollo.QueryResult<
        GetModulesForAppProgressTableFilterDialogQuery,
        GetModulesForAppProgressTableFilterDialogQueryVariables
    >;
export const GetModulesForEducationalPathDocument = gql`
    query GetModulesForEducationalPath($id: UUID!) {
        getModulesForEducationalPath(id: $id) {
            educationalPathId
            modules {
                id
                name
                order
            }
        }
    }
`;

/**
 * __useGetModulesForEducationalPathQuery__
 *
 * To run a query within a React component, call `useGetModulesForEducationalPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesForEducationalPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesForEducationalPathQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModulesForEducationalPathQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModulesForEducationalPathQuery,
        GetModulesForEducationalPathQueryVariables
    > &
        (
            | {
                  variables: GetModulesForEducationalPathQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetModulesForEducationalPathQuery,
        GetModulesForEducationalPathQueryVariables
    >(GetModulesForEducationalPathDocument, options);
}
export function useGetModulesForEducationalPathLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModulesForEducationalPathQuery,
        GetModulesForEducationalPathQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetModulesForEducationalPathQuery,
        GetModulesForEducationalPathQueryVariables
    >(GetModulesForEducationalPathDocument, options);
}
export function useGetModulesForEducationalPathSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModulesForEducationalPathQuery,
              GetModulesForEducationalPathQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetModulesForEducationalPathQuery,
        GetModulesForEducationalPathQueryVariables
    >(GetModulesForEducationalPathDocument, options);
}
export type GetModulesForEducationalPathQueryHookResult = ReturnType<
    typeof useGetModulesForEducationalPathQuery
>;
export type GetModulesForEducationalPathLazyQueryHookResult = ReturnType<
    typeof useGetModulesForEducationalPathLazyQuery
>;
export type GetModulesForEducationalPathSuspenseQueryHookResult = ReturnType<
    typeof useGetModulesForEducationalPathSuspenseQuery
>;
export type GetModulesForEducationalPathQueryResult = Apollo.QueryResult<
    GetModulesForEducationalPathQuery,
    GetModulesForEducationalPathQueryVariables
>;
export const GetParentGroupsForCreateChildGroupDocument = gql`
    query GetParentGroupsForCreateChildGroup {
        getGroupsForAuthenticatedUser {
            id
            name
            subgroups {
                id
                name
                subgroups {
                    id
                    name
                    subgroups {
                        id
                        name
                        subgroups {
                            id
                            name
                            subgroups {
                                id
                                name
                                subgroups {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useGetParentGroupsForCreateChildGroupQuery__
 *
 * To run a query within a React component, call `useGetParentGroupsForCreateChildGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentGroupsForCreateChildGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentGroupsForCreateChildGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentGroupsForCreateChildGroupQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetParentGroupsForCreateChildGroupQuery,
        GetParentGroupsForCreateChildGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetParentGroupsForCreateChildGroupQuery,
        GetParentGroupsForCreateChildGroupQueryVariables
    >(GetParentGroupsForCreateChildGroupDocument, options);
}
export function useGetParentGroupsForCreateChildGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetParentGroupsForCreateChildGroupQuery,
        GetParentGroupsForCreateChildGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetParentGroupsForCreateChildGroupQuery,
        GetParentGroupsForCreateChildGroupQueryVariables
    >(GetParentGroupsForCreateChildGroupDocument, options);
}
export function useGetParentGroupsForCreateChildGroupSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetParentGroupsForCreateChildGroupQuery,
              GetParentGroupsForCreateChildGroupQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetParentGroupsForCreateChildGroupQuery,
        GetParentGroupsForCreateChildGroupQueryVariables
    >(GetParentGroupsForCreateChildGroupDocument, options);
}
export type GetParentGroupsForCreateChildGroupQueryHookResult = ReturnType<
    typeof useGetParentGroupsForCreateChildGroupQuery
>;
export type GetParentGroupsForCreateChildGroupLazyQueryHookResult = ReturnType<
    typeof useGetParentGroupsForCreateChildGroupLazyQuery
>;
export type GetParentGroupsForCreateChildGroupSuspenseQueryHookResult =
    ReturnType<typeof useGetParentGroupsForCreateChildGroupSuspenseQuery>;
export type GetParentGroupsForCreateChildGroupQueryResult = Apollo.QueryResult<
    GetParentGroupsForCreateChildGroupQuery,
    GetParentGroupsForCreateChildGroupQueryVariables
>;
export const GetProductDocument = gql`
    query GetProduct($id: UUID!) {
        getProduct(id: $id) {
            id
            educationalProgramIds
            name
            isAvailable
            externalIdentifier
            validityPeriod
            prefix
        }
    }
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetProductQuery,
        GetProductQueryVariables
    > &
        (
            | { variables: GetProductQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
        GetProductDocument,
        options,
    );
}
export function useGetProductLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProductQuery,
        GetProductQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
        GetProductDocument,
        options,
    );
}
export function useGetProductSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetProductQuery,
              GetProductQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetProductQuery, GetProductQueryVariables>(
        GetProductDocument,
        options,
    );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<
    typeof useGetProductLazyQuery
>;
export type GetProductSuspenseQueryHookResult = ReturnType<
    typeof useGetProductSuspenseQuery
>;
export type GetProductQueryResult = Apollo.QueryResult<
    GetProductQuery,
    GetProductQueryVariables
>;
export const GetProductsForLicenseSelectionDocument = gql`
    query GetProductsForLicenseSelection {
        products: getProducts(
            paginationInput: { page: 0, pageSize: 999 }
            filters: [
                { selector: IsAvailable, operator: EQUALS, value: "true" }
            ]
        ) {
            rows {
                id
                name
                validityPeriod
            }
        }
    }
`;

/**
 * __useGetProductsForLicenseSelectionQuery__
 *
 * To run a query within a React component, call `useGetProductsForLicenseSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForLicenseSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForLicenseSelectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsForLicenseSelectionQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetProductsForLicenseSelectionQuery,
        GetProductsForLicenseSelectionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetProductsForLicenseSelectionQuery,
        GetProductsForLicenseSelectionQueryVariables
    >(GetProductsForLicenseSelectionDocument, options);
}
export function useGetProductsForLicenseSelectionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProductsForLicenseSelectionQuery,
        GetProductsForLicenseSelectionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetProductsForLicenseSelectionQuery,
        GetProductsForLicenseSelectionQueryVariables
    >(GetProductsForLicenseSelectionDocument, options);
}
export function useGetProductsForLicenseSelectionSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetProductsForLicenseSelectionQuery,
              GetProductsForLicenseSelectionQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetProductsForLicenseSelectionQuery,
        GetProductsForLicenseSelectionQueryVariables
    >(GetProductsForLicenseSelectionDocument, options);
}
export type GetProductsForLicenseSelectionQueryHookResult = ReturnType<
    typeof useGetProductsForLicenseSelectionQuery
>;
export type GetProductsForLicenseSelectionLazyQueryHookResult = ReturnType<
    typeof useGetProductsForLicenseSelectionLazyQuery
>;
export type GetProductsForLicenseSelectionSuspenseQueryHookResult = ReturnType<
    typeof useGetProductsForLicenseSelectionSuspenseQuery
>;
export type GetProductsForLicenseSelectionQueryResult = Apollo.QueryResult<
    GetProductsForLicenseSelectionQuery,
    GetProductsForLicenseSelectionQueryVariables
>;
export const GetReferenceCardDocument = gql`
    query GetReferenceCard($id: UUID!) {
        getReferenceCard(id: $id, language: null) {
            id
            __typename
            headerImage
            thumbnail
            lead {
                id
                label
            }
            title
            description
            didacticTools {
                id
                name
            }
            programs {
                id
                label: name
            }
            categories {
                id
                label
            }
            buildingBlocks {
                id
                background
                order
                ...ContentBuildingBlock
            }
        }
    }
    ${ContentBuildingBlockFragmentDoc}
`;

/**
 * __useGetReferenceCardQuery__
 *
 * To run a query within a React component, call `useGetReferenceCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferenceCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferenceCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReferenceCardQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetReferenceCardQuery,
        GetReferenceCardQueryVariables
    > &
        (
            | { variables: GetReferenceCardQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetReferenceCardQuery,
        GetReferenceCardQueryVariables
    >(GetReferenceCardDocument, options);
}
export function useGetReferenceCardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetReferenceCardQuery,
        GetReferenceCardQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetReferenceCardQuery,
        GetReferenceCardQueryVariables
    >(GetReferenceCardDocument, options);
}
export function useGetReferenceCardSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetReferenceCardQuery,
              GetReferenceCardQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetReferenceCardQuery,
        GetReferenceCardQueryVariables
    >(GetReferenceCardDocument, options);
}
export type GetReferenceCardQueryHookResult = ReturnType<
    typeof useGetReferenceCardQuery
>;
export type GetReferenceCardLazyQueryHookResult = ReturnType<
    typeof useGetReferenceCardLazyQuery
>;
export type GetReferenceCardSuspenseQueryHookResult = ReturnType<
    typeof useGetReferenceCardSuspenseQuery
>;
export type GetReferenceCardQueryResult = Apollo.QueryResult<
    GetReferenceCardQuery,
    GetReferenceCardQueryVariables
>;
export const GetReferenceCardCategoriesDocument = gql`
    query GetReferenceCardCategories($language: Language) {
        getReferenceCardCategories(language: $language) {
            id
            label
        }
    }
`;

/**
 * __useGetReferenceCardCategoriesQuery__
 *
 * To run a query within a React component, call `useGetReferenceCardCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferenceCardCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferenceCardCategoriesQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetReferenceCardCategoriesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetReferenceCardCategoriesQuery,
        GetReferenceCardCategoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetReferenceCardCategoriesQuery,
        GetReferenceCardCategoriesQueryVariables
    >(GetReferenceCardCategoriesDocument, options);
}
export function useGetReferenceCardCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetReferenceCardCategoriesQuery,
        GetReferenceCardCategoriesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetReferenceCardCategoriesQuery,
        GetReferenceCardCategoriesQueryVariables
    >(GetReferenceCardCategoriesDocument, options);
}
export function useGetReferenceCardCategoriesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetReferenceCardCategoriesQuery,
              GetReferenceCardCategoriesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetReferenceCardCategoriesQuery,
        GetReferenceCardCategoriesQueryVariables
    >(GetReferenceCardCategoriesDocument, options);
}
export type GetReferenceCardCategoriesQueryHookResult = ReturnType<
    typeof useGetReferenceCardCategoriesQuery
>;
export type GetReferenceCardCategoriesLazyQueryHookResult = ReturnType<
    typeof useGetReferenceCardCategoriesLazyQuery
>;
export type GetReferenceCardCategoriesSuspenseQueryHookResult = ReturnType<
    typeof useGetReferenceCardCategoriesSuspenseQuery
>;
export type GetReferenceCardCategoriesQueryResult = Apollo.QueryResult<
    GetReferenceCardCategoriesQuery,
    GetReferenceCardCategoriesQueryVariables
>;
export const GetReferenceCardLeadsDocument = gql`
    query GetReferenceCardLeads($language: Language) {
        getReferenceCardLeads(language: $language) {
            id
            label
        }
    }
`;

/**
 * __useGetReferenceCardLeadsQuery__
 *
 * To run a query within a React component, call `useGetReferenceCardLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferenceCardLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferenceCardLeadsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetReferenceCardLeadsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetReferenceCardLeadsQuery,
        GetReferenceCardLeadsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetReferenceCardLeadsQuery,
        GetReferenceCardLeadsQueryVariables
    >(GetReferenceCardLeadsDocument, options);
}
export function useGetReferenceCardLeadsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetReferenceCardLeadsQuery,
        GetReferenceCardLeadsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetReferenceCardLeadsQuery,
        GetReferenceCardLeadsQueryVariables
    >(GetReferenceCardLeadsDocument, options);
}
export function useGetReferenceCardLeadsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetReferenceCardLeadsQuery,
              GetReferenceCardLeadsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetReferenceCardLeadsQuery,
        GetReferenceCardLeadsQueryVariables
    >(GetReferenceCardLeadsDocument, options);
}
export type GetReferenceCardLeadsQueryHookResult = ReturnType<
    typeof useGetReferenceCardLeadsQuery
>;
export type GetReferenceCardLeadsLazyQueryHookResult = ReturnType<
    typeof useGetReferenceCardLeadsLazyQuery
>;
export type GetReferenceCardLeadsSuspenseQueryHookResult = ReturnType<
    typeof useGetReferenceCardLeadsSuspenseQuery
>;
export type GetReferenceCardLeadsQueryResult = Apollo.QueryResult<
    GetReferenceCardLeadsQuery,
    GetReferenceCardLeadsQueryVariables
>;
export const GetReferenceCardsForAppDocument = gql`
    query GetReferenceCardsForApp(
        $pagination: PaginationInput!
        $filters: [ReferenceCardsFilterInput!]
        $programId: UUID!
    ) {
        getReferenceCardsForApp(
            pagination: $pagination
            filters: $filters
            programId: $programId
        ) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                title
                description
                buildingBlocks {
                    id
                    ...ContentBuildingBlock
                }
                thumbnail
                headerImage
                lead {
                    id
                    label
                }
            }
        }
    }
    ${ContentBuildingBlockFragmentDoc}
`;

/**
 * __useGetReferenceCardsForAppQuery__
 *
 * To run a query within a React component, call `useGetReferenceCardsForAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferenceCardsForAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferenceCardsForAppQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetReferenceCardsForAppQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetReferenceCardsForAppQuery,
        GetReferenceCardsForAppQueryVariables
    > &
        (
            | {
                  variables: GetReferenceCardsForAppQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetReferenceCardsForAppQuery,
        GetReferenceCardsForAppQueryVariables
    >(GetReferenceCardsForAppDocument, options);
}
export function useGetReferenceCardsForAppLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetReferenceCardsForAppQuery,
        GetReferenceCardsForAppQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetReferenceCardsForAppQuery,
        GetReferenceCardsForAppQueryVariables
    >(GetReferenceCardsForAppDocument, options);
}
export function useGetReferenceCardsForAppSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetReferenceCardsForAppQuery,
              GetReferenceCardsForAppQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetReferenceCardsForAppQuery,
        GetReferenceCardsForAppQueryVariables
    >(GetReferenceCardsForAppDocument, options);
}
export type GetReferenceCardsForAppQueryHookResult = ReturnType<
    typeof useGetReferenceCardsForAppQuery
>;
export type GetReferenceCardsForAppLazyQueryHookResult = ReturnType<
    typeof useGetReferenceCardsForAppLazyQuery
>;
export type GetReferenceCardsForAppSuspenseQueryHookResult = ReturnType<
    typeof useGetReferenceCardsForAppSuspenseQuery
>;
export type GetReferenceCardsForAppQueryResult = Apollo.QueryResult<
    GetReferenceCardsForAppQuery,
    GetReferenceCardsForAppQueryVariables
>;
export const GetRoleDocument = gql`
    query getRole($id: UUID!) {
        getRole(id: $id) {
            id
            keycloakId
            name
            permissions
        }
    }
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(
    baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables> &
        (
            | { variables: GetRoleQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(
        GetRoleDocument,
        options,
    );
}
export function useGetRoleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetRoleQuery,
        GetRoleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(
        GetRoleDocument,
        options,
    );
}
export function useGetRoleSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetRoleQuery, GetRoleQueryVariables>(
        GetRoleDocument,
        options,
    );
}
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleSuspenseQueryHookResult = ReturnType<
    typeof useGetRoleSuspenseQuery
>;
export type GetRoleQueryResult = Apollo.QueryResult<
    GetRoleQuery,
    GetRoleQueryVariables
>;
export const GetShortcodeDocument = gql`
    query getShortcode($id: UUID!) {
        getShortcode(id: $id) {
            id
            shortcode
            educationalProgram {
                id
                name
            }
            educationalPath {
                id
                name
            }
            module {
                id
                title
            }
            didacticTool {
                id
                name
            }
        }
    }
`;

/**
 * __useGetShortcodeQuery__
 *
 * To run a query within a React component, call `useGetShortcodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortcodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortcodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShortcodeQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetShortcodeQuery,
        GetShortcodeQueryVariables
    > &
        (
            | { variables: GetShortcodeQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetShortcodeQuery, GetShortcodeQueryVariables>(
        GetShortcodeDocument,
        options,
    );
}
export function useGetShortcodeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetShortcodeQuery,
        GetShortcodeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetShortcodeQuery, GetShortcodeQueryVariables>(
        GetShortcodeDocument,
        options,
    );
}
export function useGetShortcodeSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetShortcodeQuery,
              GetShortcodeQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetShortcodeQuery,
        GetShortcodeQueryVariables
    >(GetShortcodeDocument, options);
}
export type GetShortcodeQueryHookResult = ReturnType<
    typeof useGetShortcodeQuery
>;
export type GetShortcodeLazyQueryHookResult = ReturnType<
    typeof useGetShortcodeLazyQuery
>;
export type GetShortcodeSuspenseQueryHookResult = ReturnType<
    typeof useGetShortcodeSuspenseQuery
>;
export type GetShortcodeQueryResult = Apollo.QueryResult<
    GetShortcodeQuery,
    GetShortcodeQueryVariables
>;
export const GetSignedAssetUrlDocument = gql`
    query GetSignedAssetUrl($id: UUID!) {
        getSignedAssetUrl(id: $id)
    }
`;

/**
 * __useGetSignedAssetUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedAssetUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedAssetUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedAssetUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSignedAssetUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetSignedAssetUrlQuery,
        GetSignedAssetUrlQueryVariables
    > &
        (
            | { variables: GetSignedAssetUrlQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetSignedAssetUrlQuery,
        GetSignedAssetUrlQueryVariables
    >(GetSignedAssetUrlDocument, options);
}
export function useGetSignedAssetUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSignedAssetUrlQuery,
        GetSignedAssetUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetSignedAssetUrlQuery,
        GetSignedAssetUrlQueryVariables
    >(GetSignedAssetUrlDocument, options);
}
export function useGetSignedAssetUrlSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetSignedAssetUrlQuery,
              GetSignedAssetUrlQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetSignedAssetUrlQuery,
        GetSignedAssetUrlQueryVariables
    >(GetSignedAssetUrlDocument, options);
}
export type GetSignedAssetUrlQueryHookResult = ReturnType<
    typeof useGetSignedAssetUrlQuery
>;
export type GetSignedAssetUrlLazyQueryHookResult = ReturnType<
    typeof useGetSignedAssetUrlLazyQuery
>;
export type GetSignedAssetUrlSuspenseQueryHookResult = ReturnType<
    typeof useGetSignedAssetUrlSuspenseQuery
>;
export type GetSignedAssetUrlQueryResult = Apollo.QueryResult<
    GetSignedAssetUrlQuery,
    GetSignedAssetUrlQueryVariables
>;
export const GetStudentsForProgressTableDocument = gql`
    query GetStudentsForProgressTable($groupId: UUID!, $userIds: [UUID!]) {
        getUsersForGroup(groupId: $groupId, userIds: $userIds) {
            id
            emailAddress
            firstName
            lastName
        }
    }
`;

/**
 * __useGetStudentsForProgressTableQuery__
 *
 * To run a query within a React component, call `useGetStudentsForProgressTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsForProgressTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsForProgressTableQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetStudentsForProgressTableQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStudentsForProgressTableQuery,
        GetStudentsForProgressTableQueryVariables
    > &
        (
            | {
                  variables: GetStudentsForProgressTableQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetStudentsForProgressTableQuery,
        GetStudentsForProgressTableQueryVariables
    >(GetStudentsForProgressTableDocument, options);
}
export function useGetStudentsForProgressTableLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStudentsForProgressTableQuery,
        GetStudentsForProgressTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetStudentsForProgressTableQuery,
        GetStudentsForProgressTableQueryVariables
    >(GetStudentsForProgressTableDocument, options);
}
export function useGetStudentsForProgressTableSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetStudentsForProgressTableQuery,
              GetStudentsForProgressTableQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetStudentsForProgressTableQuery,
        GetStudentsForProgressTableQueryVariables
    >(GetStudentsForProgressTableDocument, options);
}
export type GetStudentsForProgressTableQueryHookResult = ReturnType<
    typeof useGetStudentsForProgressTableQuery
>;
export type GetStudentsForProgressTableLazyQueryHookResult = ReturnType<
    typeof useGetStudentsForProgressTableLazyQuery
>;
export type GetStudentsForProgressTableSuspenseQueryHookResult = ReturnType<
    typeof useGetStudentsForProgressTableSuspenseQuery
>;
export type GetStudentsForProgressTableQueryResult = Apollo.QueryResult<
    GetStudentsForProgressTableQuery,
    GetStudentsForProgressTableQueryVariables
>;
export const GetStudentsWithProgressOverviewDocument = gql`
    query GetStudentsWithProgressOverview(
        $filters: UserProgressFilterInput!
        $sorting: [UsersSortingInput!]
        $pagination: PaginationInput!
    ) {
        getProgressOverview(
            filters: $filters
            sorting: $sorting
            paginationInput: $pagination
        ) {
            rows {
                id
                firstName
                lastName
                emailAddress
                learningPaths {
                    id
                    name
                    progress
                }
                modules {
                    id
                    name
                    progress
                }
                didacticTools {
                    type
                    id
                    name
                    progress
                    score
                    duration
                }
            }
            pagination {
                page
                pageSize
                totalCount
            }
        }
    }
`;

/**
 * __useGetStudentsWithProgressOverviewQuery__
 *
 * To run a query within a React component, call `useGetStudentsWithProgressOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsWithProgressOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsWithProgressOverviewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sorting: // value for 'sorting'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetStudentsWithProgressOverviewQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStudentsWithProgressOverviewQuery,
        GetStudentsWithProgressOverviewQueryVariables
    > &
        (
            | {
                  variables: GetStudentsWithProgressOverviewQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetStudentsWithProgressOverviewQuery,
        GetStudentsWithProgressOverviewQueryVariables
    >(GetStudentsWithProgressOverviewDocument, options);
}
export function useGetStudentsWithProgressOverviewLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStudentsWithProgressOverviewQuery,
        GetStudentsWithProgressOverviewQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetStudentsWithProgressOverviewQuery,
        GetStudentsWithProgressOverviewQueryVariables
    >(GetStudentsWithProgressOverviewDocument, options);
}
export function useGetStudentsWithProgressOverviewSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetStudentsWithProgressOverviewQuery,
              GetStudentsWithProgressOverviewQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetStudentsWithProgressOverviewQuery,
        GetStudentsWithProgressOverviewQueryVariables
    >(GetStudentsWithProgressOverviewDocument, options);
}
export type GetStudentsWithProgressOverviewQueryHookResult = ReturnType<
    typeof useGetStudentsWithProgressOverviewQuery
>;
export type GetStudentsWithProgressOverviewLazyQueryHookResult = ReturnType<
    typeof useGetStudentsWithProgressOverviewLazyQuery
>;
export type GetStudentsWithProgressOverviewSuspenseQueryHookResult = ReturnType<
    typeof useGetStudentsWithProgressOverviewSuspenseQuery
>;
export type GetStudentsWithProgressOverviewQueryResult = Apollo.QueryResult<
    GetStudentsWithProgressOverviewQuery,
    GetStudentsWithProgressOverviewQueryVariables
>;
export const GetTenantByNameDocument = gql`
    query GetTenantByName($name: String!) {
        getTenantByName(name: $name) {
            name
            defaultGroupId
            defaultRoleId
            defaultProductId
            allowRegistration
            theme {
                brandColors
                fonts
                neutralColorsVariant
                radiuses
            }
        }
    }
`;

/**
 * __useGetTenantByNameQuery__
 *
 * To run a query within a React component, call `useGetTenantByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetTenantByNameQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetTenantByNameQuery,
        GetTenantByNameQueryVariables
    > &
        (
            | { variables: GetTenantByNameQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTenantByNameQuery, GetTenantByNameQueryVariables>(
        GetTenantByNameDocument,
        options,
    );
}
export function useGetTenantByNameLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTenantByNameQuery,
        GetTenantByNameQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetTenantByNameQuery,
        GetTenantByNameQueryVariables
    >(GetTenantByNameDocument, options);
}
export function useGetTenantByNameSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetTenantByNameQuery,
              GetTenantByNameQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetTenantByNameQuery,
        GetTenantByNameQueryVariables
    >(GetTenantByNameDocument, options);
}
export type GetTenantByNameQueryHookResult = ReturnType<
    typeof useGetTenantByNameQuery
>;
export type GetTenantByNameLazyQueryHookResult = ReturnType<
    typeof useGetTenantByNameLazyQuery
>;
export type GetTenantByNameSuspenseQueryHookResult = ReturnType<
    typeof useGetTenantByNameSuspenseQuery
>;
export type GetTenantByNameQueryResult = Apollo.QueryResult<
    GetTenantByNameQuery,
    GetTenantByNameQueryVariables
>;
export const GetTenantsForUserDocument = gql`
    query GetTenantsForUser {
        getTenantsForUser {
            id
            name
            defaultGroupId
            defaultRoleId
        }
    }
`;

/**
 * __useGetTenantsForUserQuery__
 *
 * To run a query within a React component, call `useGetTenantsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsForUserQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetTenantsForUserQuery,
        GetTenantsForUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetTenantsForUserQuery,
        GetTenantsForUserQueryVariables
    >(GetTenantsForUserDocument, options);
}
export function useGetTenantsForUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTenantsForUserQuery,
        GetTenantsForUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetTenantsForUserQuery,
        GetTenantsForUserQueryVariables
    >(GetTenantsForUserDocument, options);
}
export function useGetTenantsForUserSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetTenantsForUserQuery,
              GetTenantsForUserQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetTenantsForUserQuery,
        GetTenantsForUserQueryVariables
    >(GetTenantsForUserDocument, options);
}
export type GetTenantsForUserQueryHookResult = ReturnType<
    typeof useGetTenantsForUserQuery
>;
export type GetTenantsForUserLazyQueryHookResult = ReturnType<
    typeof useGetTenantsForUserLazyQuery
>;
export type GetTenantsForUserSuspenseQueryHookResult = ReturnType<
    typeof useGetTenantsForUserSuspenseQuery
>;
export type GetTenantsForUserQueryResult = Apollo.QueryResult<
    GetTenantsForUserQuery,
    GetTenantsForUserQueryVariables
>;
export const GetThemeDocument = gql`
    query GetTheme($programId: UUID) {
        theme: getTheme(programId: $programId) {
            brandColors
            fonts
            neutralColorsVariant
            radiuses
        }
    }
`;

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetThemeQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetThemeQuery,
        GetThemeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetThemeQuery, GetThemeQueryVariables>(
        GetThemeDocument,
        options,
    );
}
export function useGetThemeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetThemeQuery,
        GetThemeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetThemeQuery, GetThemeQueryVariables>(
        GetThemeDocument,
        options,
    );
}
export function useGetThemeSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetThemeQuery,
              GetThemeQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetThemeQuery, GetThemeQueryVariables>(
        GetThemeDocument,
        options,
    );
}
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>;
export type GetThemeLazyQueryHookResult = ReturnType<
    typeof useGetThemeLazyQuery
>;
export type GetThemeSuspenseQueryHookResult = ReturnType<
    typeof useGetThemeSuspenseQuery
>;
export type GetThemeQueryResult = Apollo.QueryResult<
    GetThemeQuery,
    GetThemeQueryVariables
>;
export const GetThemeForEducationalProgramDocument = gql`
    query GetThemeForEducationalProgram($programId: UUID!) {
        theme: getThemeForEducationalProgram(programId: $programId) {
            brandColors
            fonts
            neutralColorsVariant
            programId
            radiuses
        }
    }
`;

/**
 * __useGetThemeForEducationalProgramQuery__
 *
 * To run a query within a React component, call `useGetThemeForEducationalProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeForEducationalProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeForEducationalProgramQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useGetThemeForEducationalProgramQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetThemeForEducationalProgramQuery,
        GetThemeForEducationalProgramQueryVariables
    > &
        (
            | {
                  variables: GetThemeForEducationalProgramQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetThemeForEducationalProgramQuery,
        GetThemeForEducationalProgramQueryVariables
    >(GetThemeForEducationalProgramDocument, options);
}
export function useGetThemeForEducationalProgramLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetThemeForEducationalProgramQuery,
        GetThemeForEducationalProgramQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetThemeForEducationalProgramQuery,
        GetThemeForEducationalProgramQueryVariables
    >(GetThemeForEducationalProgramDocument, options);
}
export function useGetThemeForEducationalProgramSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetThemeForEducationalProgramQuery,
              GetThemeForEducationalProgramQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetThemeForEducationalProgramQuery,
        GetThemeForEducationalProgramQueryVariables
    >(GetThemeForEducationalProgramDocument, options);
}
export type GetThemeForEducationalProgramQueryHookResult = ReturnType<
    typeof useGetThemeForEducationalProgramQuery
>;
export type GetThemeForEducationalProgramLazyQueryHookResult = ReturnType<
    typeof useGetThemeForEducationalProgramLazyQuery
>;
export type GetThemeForEducationalProgramSuspenseQueryHookResult = ReturnType<
    typeof useGetThemeForEducationalProgramSuspenseQuery
>;
export type GetThemeForEducationalProgramQueryResult = Apollo.QueryResult<
    GetThemeForEducationalProgramQuery,
    GetThemeForEducationalProgramQueryVariables
>;
export const GetUserDocument = gql`
    query GetUser($getUserId: UUID!) {
        getUser(id: $getUserId) {
            emailAddress
            firstName
            id
            isActive
            lastName
            roleId
            groupIds
            tenantIds
            modifiedAt
            modifiedBy
            createdAt
        }
    }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *   },
 * });
 */
export function useGetUserQuery(
    baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> &
        (
            | { variables: GetUserQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
        GetUserDocument,
        options,
    );
}
export function useGetUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserQuery,
        GetUserQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
        GetUserDocument,
        options,
    );
}
export function useGetUserSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(
        GetUserDocument,
        options,
    );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<
    typeof useGetUserSuspenseQuery
>;
export type GetUserQueryResult = Apollo.QueryResult<
    GetUserQuery,
    GetUserQueryVariables
>;
export const GetUsersApplicableForChildGroupDocument = gql`
    query GetUsersApplicableForChildGroup($parentGroupId: UUID!) {
        getUsersApplicableForChildGroup(parentGroupId: $parentGroupId) {
            ...UserApplicableForChildGroup
        }
    }
    ${UserApplicableForChildGroupFragmentDoc}
`;

/**
 * __useGetUsersApplicableForChildGroupQuery__
 *
 * To run a query within a React component, call `useGetUsersApplicableForChildGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersApplicableForChildGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersApplicableForChildGroupQuery({
 *   variables: {
 *      parentGroupId: // value for 'parentGroupId'
 *   },
 * });
 */
export function useGetUsersApplicableForChildGroupQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetUsersApplicableForChildGroupQuery,
        GetUsersApplicableForChildGroupQueryVariables
    > &
        (
            | {
                  variables: GetUsersApplicableForChildGroupQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetUsersApplicableForChildGroupQuery,
        GetUsersApplicableForChildGroupQueryVariables
    >(GetUsersApplicableForChildGroupDocument, options);
}
export function useGetUsersApplicableForChildGroupLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUsersApplicableForChildGroupQuery,
        GetUsersApplicableForChildGroupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetUsersApplicableForChildGroupQuery,
        GetUsersApplicableForChildGroupQueryVariables
    >(GetUsersApplicableForChildGroupDocument, options);
}
export function useGetUsersApplicableForChildGroupSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetUsersApplicableForChildGroupQuery,
              GetUsersApplicableForChildGroupQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetUsersApplicableForChildGroupQuery,
        GetUsersApplicableForChildGroupQueryVariables
    >(GetUsersApplicableForChildGroupDocument, options);
}
export type GetUsersApplicableForChildGroupQueryHookResult = ReturnType<
    typeof useGetUsersApplicableForChildGroupQuery
>;
export type GetUsersApplicableForChildGroupLazyQueryHookResult = ReturnType<
    typeof useGetUsersApplicableForChildGroupLazyQuery
>;
export type GetUsersApplicableForChildGroupSuspenseQueryHookResult = ReturnType<
    typeof useGetUsersApplicableForChildGroupSuspenseQuery
>;
export type GetUsersApplicableForChildGroupQueryResult = Apollo.QueryResult<
    GetUsersApplicableForChildGroupQuery,
    GetUsersApplicableForChildGroupQueryVariables
>;
export const IssueTenantTokenDocument = gql`
    mutation IssueTenantToken($tenant: String!) {
        issueTenantToken(tenant: $tenant) {
            tenant {
                name
            }
            token
        }
    }
`;
export type IssueTenantTokenMutationFn = Apollo.MutationFunction<
    IssueTenantTokenMutation,
    IssueTenantTokenMutationVariables
>;

/**
 * __useIssueTenantTokenMutation__
 *
 * To run a mutation, you first call `useIssueTenantTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueTenantTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueTenantTokenMutation, { data, loading, error }] = useIssueTenantTokenMutation({
 *   variables: {
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useIssueTenantTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        IssueTenantTokenMutation,
        IssueTenantTokenMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        IssueTenantTokenMutation,
        IssueTenantTokenMutationVariables
    >(IssueTenantTokenDocument, options);
}
export type IssueTenantTokenMutationHookResult = ReturnType<
    typeof useIssueTenantTokenMutation
>;
export type IssueTenantTokenMutationResult =
    Apollo.MutationResult<IssueTenantTokenMutation>;
export type IssueTenantTokenMutationOptions = Apollo.BaseMutationOptions<
    IssueTenantTokenMutation,
    IssueTenantTokenMutationVariables
>;
export const ListAppUsersDocument = gql`
    query listAppUsers(
        $pagination: PaginationInput!
        $filters: [UsersFilterInput!]
        $sorting: [UsersSortingInput!]
    ) {
        getUsers(
            paginationInput: $pagination
            filters: $filters
            sorting: $sorting
        ) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                emailAddress
                firstName
                id
                isActive
                lastName
                createdAt
            }
        }
    }
`;

/**
 * __useListAppUsersQuery__
 *
 * To run a query within a React component, call `useListAppUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAppUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAppUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useListAppUsersQuery(
    baseOptions: Apollo.QueryHookOptions<
        ListAppUsersQuery,
        ListAppUsersQueryVariables
    > &
        (
            | { variables: ListAppUsersQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ListAppUsersQuery, ListAppUsersQueryVariables>(
        ListAppUsersDocument,
        options,
    );
}
export function useListAppUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ListAppUsersQuery,
        ListAppUsersQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ListAppUsersQuery, ListAppUsersQueryVariables>(
        ListAppUsersDocument,
        options,
    );
}
export function useListAppUsersSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              ListAppUsersQuery,
              ListAppUsersQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        ListAppUsersQuery,
        ListAppUsersQueryVariables
    >(ListAppUsersDocument, options);
}
export type ListAppUsersQueryHookResult = ReturnType<
    typeof useListAppUsersQuery
>;
export type ListAppUsersLazyQueryHookResult = ReturnType<
    typeof useListAppUsersLazyQuery
>;
export type ListAppUsersSuspenseQueryHookResult = ReturnType<
    typeof useListAppUsersSuspenseQuery
>;
export type ListAppUsersQueryResult = Apollo.QueryResult<
    ListAppUsersQuery,
    ListAppUsersQueryVariables
>;
export const GetDidacticToolsDocument = gql`
    query GetDidacticTools(
        $pagination: PaginationInput!
        $filters: [DidacticToolsFilterInput!]
    ) {
        getDidacticTools(paginationInput: $pagination, filters: $filters) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                description
                headerImageId
                headerIntroText
                id
                name
                type
            }
        }
    }
`;

/**
 * __useGetDidacticToolsQuery__
 *
 * To run a query within a React component, call `useGetDidacticToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDidacticToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDidacticToolsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDidacticToolsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDidacticToolsQuery,
        GetDidacticToolsQueryVariables
    > &
        (
            | { variables: GetDidacticToolsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetDidacticToolsQuery,
        GetDidacticToolsQueryVariables
    >(GetDidacticToolsDocument, options);
}
export function useGetDidacticToolsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDidacticToolsQuery,
        GetDidacticToolsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetDidacticToolsQuery,
        GetDidacticToolsQueryVariables
    >(GetDidacticToolsDocument, options);
}
export function useGetDidacticToolsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetDidacticToolsQuery,
              GetDidacticToolsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetDidacticToolsQuery,
        GetDidacticToolsQueryVariables
    >(GetDidacticToolsDocument, options);
}
export type GetDidacticToolsQueryHookResult = ReturnType<
    typeof useGetDidacticToolsQuery
>;
export type GetDidacticToolsLazyQueryHookResult = ReturnType<
    typeof useGetDidacticToolsLazyQuery
>;
export type GetDidacticToolsSuspenseQueryHookResult = ReturnType<
    typeof useGetDidacticToolsSuspenseQuery
>;
export type GetDidacticToolsQueryResult = Apollo.QueryResult<
    GetDidacticToolsQuery,
    GetDidacticToolsQueryVariables
>;
export const GetEducationalPathsDocument = gql`
    query getEducationalPaths(
        $pagination: PaginationInput!
        $filters: [EducationalPathsFilterInput!]
    ) {
        getEducationalPaths(paginationInput: $pagination, filters: $filters) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                name
                description
            }
        }
    }
`;

/**
 * __useGetEducationalPathsQuery__
 *
 * To run a query within a React component, call `useGetEducationalPathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalPathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalPathsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetEducationalPathsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalPathsQuery,
        GetEducationalPathsQueryVariables
    > &
        (
            | { variables: GetEducationalPathsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalPathsQuery,
        GetEducationalPathsQueryVariables
    >(GetEducationalPathsDocument, options);
}
export function useGetEducationalPathsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalPathsQuery,
        GetEducationalPathsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalPathsQuery,
        GetEducationalPathsQueryVariables
    >(GetEducationalPathsDocument, options);
}
export function useGetEducationalPathsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalPathsQuery,
              GetEducationalPathsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalPathsQuery,
        GetEducationalPathsQueryVariables
    >(GetEducationalPathsDocument, options);
}
export type GetEducationalPathsQueryHookResult = ReturnType<
    typeof useGetEducationalPathsQuery
>;
export type GetEducationalPathsLazyQueryHookResult = ReturnType<
    typeof useGetEducationalPathsLazyQuery
>;
export type GetEducationalPathsSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalPathsSuspenseQuery
>;
export type GetEducationalPathsQueryResult = Apollo.QueryResult<
    GetEducationalPathsQuery,
    GetEducationalPathsQueryVariables
>;
export const GetEducationalProgramsDocument = gql`
    query getEducationalPrograms(
        $pagination: PaginationInput!
        $filters: [EducationalProgramsFilterInput!]
    ) {
        getEducationalPrograms(
            paginationInput: $pagination
            filters: $filters
        ) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                name
                description
            }
        }
    }
`;

/**
 * __useGetEducationalProgramsQuery__
 *
 * To run a query within a React component, call `useGetEducationalProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalProgramsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetEducationalProgramsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetEducationalProgramsQuery,
        GetEducationalProgramsQueryVariables
    > &
        (
            | {
                  variables: GetEducationalProgramsQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetEducationalProgramsQuery,
        GetEducationalProgramsQueryVariables
    >(GetEducationalProgramsDocument, options);
}
export function useGetEducationalProgramsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetEducationalProgramsQuery,
        GetEducationalProgramsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetEducationalProgramsQuery,
        GetEducationalProgramsQueryVariables
    >(GetEducationalProgramsDocument, options);
}
export function useGetEducationalProgramsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetEducationalProgramsQuery,
              GetEducationalProgramsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetEducationalProgramsQuery,
        GetEducationalProgramsQueryVariables
    >(GetEducationalProgramsDocument, options);
}
export type GetEducationalProgramsQueryHookResult = ReturnType<
    typeof useGetEducationalProgramsQuery
>;
export type GetEducationalProgramsLazyQueryHookResult = ReturnType<
    typeof useGetEducationalProgramsLazyQuery
>;
export type GetEducationalProgramsSuspenseQueryHookResult = ReturnType<
    typeof useGetEducationalProgramsSuspenseQuery
>;
export type GetEducationalProgramsQueryResult = Apollo.QueryResult<
    GetEducationalProgramsQuery,
    GetEducationalProgramsQueryVariables
>;
export const GetGroupsDocument = gql`
    query getGroups(
        $pagination: PaginationInput!
        $filters: [GroupsFilterInput!]
    ) {
        getGroups(paginationInput: $pagination, filters: $filters) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                ...GroupFields
                ...SubgroupsRecursive
            }
        }
    }
    ${GroupFieldsFragmentDoc}
    ${SubgroupsRecursiveFragmentDoc}
`;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetGroupsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetGroupsQuery,
        GetGroupsQueryVariables
    > &
        (
            | { variables: GetGroupsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(
        GetGroupsDocument,
        options,
    );
}
export function useGetGroupsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGroupsQuery,
        GetGroupsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(
        GetGroupsDocument,
        options,
    );
}
export function useGetGroupsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetGroupsQuery,
              GetGroupsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetGroupsQuery, GetGroupsQueryVariables>(
        GetGroupsDocument,
        options,
    );
}
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<
    typeof useGetGroupsLazyQuery
>;
export type GetGroupsSuspenseQueryHookResult = ReturnType<
    typeof useGetGroupsSuspenseQuery
>;
export type GetGroupsQueryResult = Apollo.QueryResult<
    GetGroupsQuery,
    GetGroupsQueryVariables
>;
export const GetModulesDocument = gql`
    query GetModules(
        $pagination: PaginationInput!
        $filters: [ModulesFilterInput!]
    ) {
        getModules(paginationInput: $pagination, filters: $filters) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                content
                dashboardImageId
                description
                headerImageId
                shortTitle
                title
            }
        }
    }
`;

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetModulesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetModulesQuery,
        GetModulesQueryVariables
    > &
        (
            | { variables: GetModulesQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetModulesQuery, GetModulesQueryVariables>(
        GetModulesDocument,
        options,
    );
}
export function useGetModulesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetModulesQuery,
        GetModulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(
        GetModulesDocument,
        options,
    );
}
export function useGetModulesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetModulesQuery,
              GetModulesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetModulesQuery, GetModulesQueryVariables>(
        GetModulesDocument,
        options,
    );
}
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>;
export type GetModulesLazyQueryHookResult = ReturnType<
    typeof useGetModulesLazyQuery
>;
export type GetModulesSuspenseQueryHookResult = ReturnType<
    typeof useGetModulesSuspenseQuery
>;
export type GetModulesQueryResult = Apollo.QueryResult<
    GetModulesQuery,
    GetModulesQueryVariables
>;
export const GetProductsDocument = gql`
    query getProducts(
        $pagination: PaginationInput!
        $filters: [ProductsFilterInput!]
    ) {
        getProducts(paginationInput: $pagination, filters: $filters) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                name
                educationalPrograms {
                    id
                    name
                    description
                }
                statistics {
                    total
                    valid
                    expired
                    inactive
                }
            }
        }
    }
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProductsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetProductsQuery,
        GetProductsQueryVariables
    > &
        (
            | { variables: GetProductsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(
        GetProductsDocument,
        options,
    );
}
export function useGetProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetProductsQuery,
        GetProductsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
        GetProductsDocument,
        options,
    );
}
export function useGetProductsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetProductsQuery,
              GetProductsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetProductsQuery, GetProductsQueryVariables>(
        GetProductsDocument,
        options,
    );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<
    typeof useGetProductsLazyQuery
>;
export type GetProductsSuspenseQueryHookResult = ReturnType<
    typeof useGetProductsSuspenseQuery
>;
export type GetProductsQueryResult = Apollo.QueryResult<
    GetProductsQuery,
    GetProductsQueryVariables
>;
export const GetReferenceCardsDocument = gql`
    query GetReferenceCards(
        $pagination: PaginationInput!
        $filters: [ReferenceCardsFilterInput!]
    ) {
        getReferenceCards(
            pagination: $pagination
            language: null
            filters: $filters
        ) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                title
                description
                body
                thumbnail
                headerImage
                programs {
                    id
                    name
                }
                didacticTools {
                    id
                    name
                }
                categories {
                    id
                    label
                }
                lead {
                    id
                    label
                }
            }
        }
    }
`;

/**
 * __useGetReferenceCardsQuery__
 *
 * To run a query within a React component, call `useGetReferenceCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferenceCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferenceCardsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetReferenceCardsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetReferenceCardsQuery,
        GetReferenceCardsQueryVariables
    > &
        (
            | { variables: GetReferenceCardsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetReferenceCardsQuery,
        GetReferenceCardsQueryVariables
    >(GetReferenceCardsDocument, options);
}
export function useGetReferenceCardsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetReferenceCardsQuery,
        GetReferenceCardsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetReferenceCardsQuery,
        GetReferenceCardsQueryVariables
    >(GetReferenceCardsDocument, options);
}
export function useGetReferenceCardsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetReferenceCardsQuery,
              GetReferenceCardsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetReferenceCardsQuery,
        GetReferenceCardsQueryVariables
    >(GetReferenceCardsDocument, options);
}
export type GetReferenceCardsQueryHookResult = ReturnType<
    typeof useGetReferenceCardsQuery
>;
export type GetReferenceCardsLazyQueryHookResult = ReturnType<
    typeof useGetReferenceCardsLazyQuery
>;
export type GetReferenceCardsSuspenseQueryHookResult = ReturnType<
    typeof useGetReferenceCardsSuspenseQuery
>;
export type GetReferenceCardsQueryResult = Apollo.QueryResult<
    GetReferenceCardsQuery,
    GetReferenceCardsQueryVariables
>;
export const ListRolesDocument = gql`
    query ListRoles(
        $paginationInput: PaginationInput!
        $filters: [RolesFilterInput!]
    ) {
        getRoles(paginationInput: $paginationInput, filters: $filters) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                keycloakId
                name
            }
        }
    }
`;

/**
 * __useListRolesQuery__
 *
 * To run a query within a React component, call `useListRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRolesQuery({
 *   variables: {
 *      paginationInput: // value for 'paginationInput'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListRolesQuery(
    baseOptions: Apollo.QueryHookOptions<
        ListRolesQuery,
        ListRolesQueryVariables
    > &
        (
            | { variables: ListRolesQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ListRolesQuery, ListRolesQueryVariables>(
        ListRolesDocument,
        options,
    );
}
export function useListRolesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ListRolesQuery,
        ListRolesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ListRolesQuery, ListRolesQueryVariables>(
        ListRolesDocument,
        options,
    );
}
export function useListRolesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              ListRolesQuery,
              ListRolesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<ListRolesQuery, ListRolesQueryVariables>(
        ListRolesDocument,
        options,
    );
}
export type ListRolesQueryHookResult = ReturnType<typeof useListRolesQuery>;
export type ListRolesLazyQueryHookResult = ReturnType<
    typeof useListRolesLazyQuery
>;
export type ListRolesSuspenseQueryHookResult = ReturnType<
    typeof useListRolesSuspenseQuery
>;
export type ListRolesQueryResult = Apollo.QueryResult<
    ListRolesQuery,
    ListRolesQueryVariables
>;
export const GetShortcodesDocument = gql`
    query GetShortcodes($pagination: PaginationInput!) {
        getShortcodes(paginationInput: $pagination) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                id
                shortcode
                educationalProgram {
                    id
                    name
                }
                educationalPath {
                    id
                    name
                }
                module {
                    id
                    title
                }
                didacticTool {
                    id
                    name
                }
            }
        }
    }
`;

/**
 * __useGetShortcodesQuery__
 *
 * To run a query within a React component, call `useGetShortcodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortcodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortcodesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetShortcodesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetShortcodesQuery,
        GetShortcodesQueryVariables
    > &
        (
            | { variables: GetShortcodesQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetShortcodesQuery, GetShortcodesQueryVariables>(
        GetShortcodesDocument,
        options,
    );
}
export function useGetShortcodesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetShortcodesQuery,
        GetShortcodesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetShortcodesQuery, GetShortcodesQueryVariables>(
        GetShortcodesDocument,
        options,
    );
}
export function useGetShortcodesSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetShortcodesQuery,
              GetShortcodesQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetShortcodesQuery,
        GetShortcodesQueryVariables
    >(GetShortcodesDocument, options);
}
export type GetShortcodesQueryHookResult = ReturnType<
    typeof useGetShortcodesQuery
>;
export type GetShortcodesLazyQueryHookResult = ReturnType<
    typeof useGetShortcodesLazyQuery
>;
export type GetShortcodesSuspenseQueryHookResult = ReturnType<
    typeof useGetShortcodesSuspenseQuery
>;
export type GetShortcodesQueryResult = Apollo.QueryResult<
    GetShortcodesQuery,
    GetShortcodesQueryVariables
>;
export const GetPermissionsDocument = gql`
    query getPermissions($pagination: PaginationInput!) {
        getPermissions(paginationInput: $pagination) {
            pagination {
                page
                pageSize
                totalCount
            }
            rows {
                enabled
                id
                key
                name
            }
        }
    }
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPermissionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetPermissionsQuery,
        GetPermissionsQueryVariables
    > &
        (
            | { variables: GetPermissionsQueryVariables; skip?: boolean }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
        GetPermissionsDocument,
        options,
    );
}
export function useGetPermissionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPermissionsQuery,
        GetPermissionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetPermissionsQuery,
        GetPermissionsQueryVariables
    >(GetPermissionsDocument, options);
}
export function useGetPermissionsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetPermissionsQuery,
              GetPermissionsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetPermissionsQuery,
        GetPermissionsQueryVariables
    >(GetPermissionsDocument, options);
}
export type GetPermissionsQueryHookResult = ReturnType<
    typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
    typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsSuspenseQueryHookResult = ReturnType<
    typeof useGetPermissionsSuspenseQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
>;
export const RegisterNewUserDocument = gql`
    mutation RegisterNewUser($input: RegisterNewUserInput!) {
        registerNewUser(input: $input) {
            educationalPathsProgress {
                id
                name
                progress
            }
            emailAddress
            firstName
            id
            lastName
        }
    }
`;
export type RegisterNewUserMutationFn = Apollo.MutationFunction<
    RegisterNewUserMutation,
    RegisterNewUserMutationVariables
>;

/**
 * __useRegisterNewUserMutation__
 *
 * To run a mutation, you first call `useRegisterNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNewUserMutation, { data, loading, error }] = useRegisterNewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterNewUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RegisterNewUserMutation,
        RegisterNewUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RegisterNewUserMutation,
        RegisterNewUserMutationVariables
    >(RegisterNewUserDocument, options);
}
export type RegisterNewUserMutationHookResult = ReturnType<
    typeof useRegisterNewUserMutation
>;
export type RegisterNewUserMutationResult =
    Apollo.MutationResult<RegisterNewUserMutation>;
export type RegisterNewUserMutationOptions = Apollo.BaseMutationOptions<
    RegisterNewUserMutation,
    RegisterNewUserMutationVariables
>;
export const RequestPasswordResetsDocument = gql`
    mutation RequestPasswordResets($ids: [UUID!]!) {
        requestPasswordResets(ids: $ids) {
            successful
            failed
        }
    }
`;
export type RequestPasswordResetsMutationFn = Apollo.MutationFunction<
    RequestPasswordResetsMutation,
    RequestPasswordResetsMutationVariables
>;

/**
 * __useRequestPasswordResetsMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetsMutation, { data, loading, error }] = useRequestPasswordResetsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRequestPasswordResetsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RequestPasswordResetsMutation,
        RequestPasswordResetsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RequestPasswordResetsMutation,
        RequestPasswordResetsMutationVariables
    >(RequestPasswordResetsDocument, options);
}
export type RequestPasswordResetsMutationHookResult = ReturnType<
    typeof useRequestPasswordResetsMutation
>;
export type RequestPasswordResetsMutationResult =
    Apollo.MutationResult<RequestPasswordResetsMutation>;
export type RequestPasswordResetsMutationOptions = Apollo.BaseMutationOptions<
    RequestPasswordResetsMutation,
    RequestPasswordResetsMutationVariables
>;
export const StartDidacticToolDocument = gql`
    mutation startDidacticTool($id: UUID!) {
        startDidacticTool(didacticToolId: $id)
    }
`;
export type StartDidacticToolMutationFn = Apollo.MutationFunction<
    StartDidacticToolMutation,
    StartDidacticToolMutationVariables
>;

/**
 * __useStartDidacticToolMutation__
 *
 * To run a mutation, you first call `useStartDidacticToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDidacticToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDidacticToolMutation, { data, loading, error }] = useStartDidacticToolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartDidacticToolMutation(
    baseOptions?: Apollo.MutationHookOptions<
        StartDidacticToolMutation,
        StartDidacticToolMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        StartDidacticToolMutation,
        StartDidacticToolMutationVariables
    >(StartDidacticToolDocument, options);
}
export type StartDidacticToolMutationHookResult = ReturnType<
    typeof useStartDidacticToolMutation
>;
export type StartDidacticToolMutationResult =
    Apollo.MutationResult<StartDidacticToolMutation>;
export type StartDidacticToolMutationOptions = Apollo.BaseMutationOptions<
    StartDidacticToolMutation,
    StartDidacticToolMutationVariables
>;
export const StartUsersWithProgressExportDocument = gql`
    mutation StartUsersWithProgressExport($filters: UserProgressFilterInput!) {
        startUsersWithProgressExport(filters: $filters) {
            id
            status
            __typename
        }
    }
`;
export type StartUsersWithProgressExportMutationFn = Apollo.MutationFunction<
    StartUsersWithProgressExportMutation,
    StartUsersWithProgressExportMutationVariables
>;

/**
 * __useStartUsersWithProgressExportMutation__
 *
 * To run a mutation, you first call `useStartUsersWithProgressExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartUsersWithProgressExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startUsersWithProgressExportMutation, { data, loading, error }] = useStartUsersWithProgressExportMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useStartUsersWithProgressExportMutation(
    baseOptions?: Apollo.MutationHookOptions<
        StartUsersWithProgressExportMutation,
        StartUsersWithProgressExportMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        StartUsersWithProgressExportMutation,
        StartUsersWithProgressExportMutationVariables
    >(StartUsersWithProgressExportDocument, options);
}
export type StartUsersWithProgressExportMutationHookResult = ReturnType<
    typeof useStartUsersWithProgressExportMutation
>;
export type StartUsersWithProgressExportMutationResult =
    Apollo.MutationResult<StartUsersWithProgressExportMutation>;
export type StartUsersWithProgressExportMutationOptions =
    Apollo.BaseMutationOptions<
        StartUsersWithProgressExportMutation,
        StartUsersWithProgressExportMutationVariables
    >;
export const ToegangOrgJwtDocument = gql`
    mutation ToegangOrgJwt($jwt: String!) {
        toegangOrgJwt(jwt: $jwt)
    }
`;
export type ToegangOrgJwtMutationFn = Apollo.MutationFunction<
    ToegangOrgJwtMutation,
    ToegangOrgJwtMutationVariables
>;

/**
 * __useToegangOrgJwtMutation__
 *
 * To run a mutation, you first call `useToegangOrgJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToegangOrgJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toegangOrgJwtMutation, { data, loading, error }] = useToegangOrgJwtMutation({
 *   variables: {
 *      jwt: // value for 'jwt'
 *   },
 * });
 */
export function useToegangOrgJwtMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ToegangOrgJwtMutation,
        ToegangOrgJwtMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        ToegangOrgJwtMutation,
        ToegangOrgJwtMutationVariables
    >(ToegangOrgJwtDocument, options);
}
export type ToegangOrgJwtMutationHookResult = ReturnType<
    typeof useToegangOrgJwtMutation
>;
export type ToegangOrgJwtMutationResult =
    Apollo.MutationResult<ToegangOrgJwtMutation>;
export type ToegangOrgJwtMutationOptions = Apollo.BaseMutationOptions<
    ToegangOrgJwtMutation,
    ToegangOrgJwtMutationVariables
>;
export const UpdateBuildingBlocksForDidacticToolDocument = gql`
    mutation UpdateBuildingBlocksForDidacticTool(
        $input: UpdateBuildingBlocksForDidacticToolInput!
    ) {
        updateBuildingBlocksForDidacticTool(input: $input) {
            buildingBlocks {
                id
                order
                type
            }
        }
    }
`;
export type UpdateBuildingBlocksForDidacticToolMutationFn =
    Apollo.MutationFunction<
        UpdateBuildingBlocksForDidacticToolMutation,
        UpdateBuildingBlocksForDidacticToolMutationVariables
    >;

/**
 * __useUpdateBuildingBlocksForDidacticToolMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingBlocksForDidacticToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingBlocksForDidacticToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingBlocksForDidacticToolMutation, { data, loading, error }] = useUpdateBuildingBlocksForDidacticToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuildingBlocksForDidacticToolMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBuildingBlocksForDidacticToolMutation,
        UpdateBuildingBlocksForDidacticToolMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateBuildingBlocksForDidacticToolMutation,
        UpdateBuildingBlocksForDidacticToolMutationVariables
    >(UpdateBuildingBlocksForDidacticToolDocument, options);
}
export type UpdateBuildingBlocksForDidacticToolMutationHookResult = ReturnType<
    typeof useUpdateBuildingBlocksForDidacticToolMutation
>;
export type UpdateBuildingBlocksForDidacticToolMutationResult =
    Apollo.MutationResult<UpdateBuildingBlocksForDidacticToolMutation>;
export type UpdateBuildingBlocksForDidacticToolMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateBuildingBlocksForDidacticToolMutation,
        UpdateBuildingBlocksForDidacticToolMutationVariables
    >;
export const UpdateChildGroupDocument = gql`
    mutation UpdateChildGroup($input: UpdateChildGroupInput!) {
        updateChildGroup(input: $input) {
            id
            name
            parentId
        }
    }
`;
export type UpdateChildGroupMutationFn = Apollo.MutationFunction<
    UpdateChildGroupMutation,
    UpdateChildGroupMutationVariables
>;

/**
 * __useUpdateChildGroupMutation__
 *
 * To run a mutation, you first call `useUpdateChildGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChildGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChildGroupMutation, { data, loading, error }] = useUpdateChildGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChildGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateChildGroupMutation,
        UpdateChildGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateChildGroupMutation,
        UpdateChildGroupMutationVariables
    >(UpdateChildGroupDocument, options);
}
export type UpdateChildGroupMutationHookResult = ReturnType<
    typeof useUpdateChildGroupMutation
>;
export type UpdateChildGroupMutationResult =
    Apollo.MutationResult<UpdateChildGroupMutation>;
export type UpdateChildGroupMutationOptions = Apollo.BaseMutationOptions<
    UpdateChildGroupMutation,
    UpdateChildGroupMutationVariables
>;
export const UpdateDidacticToolDocument = gql`
    mutation UpdateDidacticTool($input: UpdateDidacticToolInput!) {
        updateDidacticTool(input: $input) {
            description
            headerImageId
            headerIntroText
            durationIndication
            learningAdviceTitle
            learningAdviceDescription
            altLabel
            id
            name
            type
        }
    }
`;
export type UpdateDidacticToolMutationFn = Apollo.MutationFunction<
    UpdateDidacticToolMutation,
    UpdateDidacticToolMutationVariables
>;

/**
 * __useUpdateDidacticToolMutation__
 *
 * To run a mutation, you first call `useUpdateDidacticToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDidacticToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDidacticToolMutation, { data, loading, error }] = useUpdateDidacticToolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDidacticToolMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDidacticToolMutation,
        UpdateDidacticToolMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateDidacticToolMutation,
        UpdateDidacticToolMutationVariables
    >(UpdateDidacticToolDocument, options);
}
export type UpdateDidacticToolMutationHookResult = ReturnType<
    typeof useUpdateDidacticToolMutation
>;
export type UpdateDidacticToolMutationResult =
    Apollo.MutationResult<UpdateDidacticToolMutation>;
export type UpdateDidacticToolMutationOptions = Apollo.BaseMutationOptions<
    UpdateDidacticToolMutation,
    UpdateDidacticToolMutationVariables
>;
export const UpdateEducationalPathDocument = gql`
    mutation UpdateEducationalPath($input: UpdateEducationalPathInput!) {
        updateEducationalPath(input: $input) {
            id
            name
            description
            durationIndication
            headerIntroText
        }
    }
`;
export type UpdateEducationalPathMutationFn = Apollo.MutationFunction<
    UpdateEducationalPathMutation,
    UpdateEducationalPathMutationVariables
>;

/**
 * __useUpdateEducationalPathMutation__
 *
 * To run a mutation, you first call `useUpdateEducationalPathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationalPathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationalPathMutation, { data, loading, error }] = useUpdateEducationalPathMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEducationalPathMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateEducationalPathMutation,
        UpdateEducationalPathMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateEducationalPathMutation,
        UpdateEducationalPathMutationVariables
    >(UpdateEducationalPathDocument, options);
}
export type UpdateEducationalPathMutationHookResult = ReturnType<
    typeof useUpdateEducationalPathMutation
>;
export type UpdateEducationalPathMutationResult =
    Apollo.MutationResult<UpdateEducationalPathMutation>;
export type UpdateEducationalPathMutationOptions = Apollo.BaseMutationOptions<
    UpdateEducationalPathMutation,
    UpdateEducationalPathMutationVariables
>;
export const UpdateEducationalPathsForProgramDocument = gql`
    mutation UpdateEducationalPathsForProgram(
        $input: UpdateEducationalPathsForProgramInput!
    ) {
        updateEducationalPathsForProgram(input: $input) {
            programId
            educationalPaths {
                id
                name
                order
            }
        }
    }
`;
export type UpdateEducationalPathsForProgramMutationFn =
    Apollo.MutationFunction<
        UpdateEducationalPathsForProgramMutation,
        UpdateEducationalPathsForProgramMutationVariables
    >;

/**
 * __useUpdateEducationalPathsForProgramMutation__
 *
 * To run a mutation, you first call `useUpdateEducationalPathsForProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationalPathsForProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationalPathsForProgramMutation, { data, loading, error }] = useUpdateEducationalPathsForProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEducationalPathsForProgramMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateEducationalPathsForProgramMutation,
        UpdateEducationalPathsForProgramMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateEducationalPathsForProgramMutation,
        UpdateEducationalPathsForProgramMutationVariables
    >(UpdateEducationalPathsForProgramDocument, options);
}
export type UpdateEducationalPathsForProgramMutationHookResult = ReturnType<
    typeof useUpdateEducationalPathsForProgramMutation
>;
export type UpdateEducationalPathsForProgramMutationResult =
    Apollo.MutationResult<UpdateEducationalPathsForProgramMutation>;
export type UpdateEducationalPathsForProgramMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateEducationalPathsForProgramMutation,
        UpdateEducationalPathsForProgramMutationVariables
    >;
export const UpdateEducationalProgramDocument = gql`
    mutation UpdateEducationalProgram($input: UpdateEducationalProgramInput!) {
        updateEducationalProgram(input: $input) {
            id
            name
            description
        }
    }
`;
export type UpdateEducationalProgramMutationFn = Apollo.MutationFunction<
    UpdateEducationalProgramMutation,
    UpdateEducationalProgramMutationVariables
>;

/**
 * __useUpdateEducationalProgramMutation__
 *
 * To run a mutation, you first call `useUpdateEducationalProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationalProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationalProgramMutation, { data, loading, error }] = useUpdateEducationalProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEducationalProgramMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateEducationalProgramMutation,
        UpdateEducationalProgramMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateEducationalProgramMutation,
        UpdateEducationalProgramMutationVariables
    >(UpdateEducationalProgramDocument, options);
}
export type UpdateEducationalProgramMutationHookResult = ReturnType<
    typeof useUpdateEducationalProgramMutation
>;
export type UpdateEducationalProgramMutationResult =
    Apollo.MutationResult<UpdateEducationalProgramMutation>;
export type UpdateEducationalProgramMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateEducationalProgramMutation,
        UpdateEducationalProgramMutationVariables
    >;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($input: UpdateGroupInput!) {
        updateGroup(input: $input) {
            id
            name
            productIds
            showGroupDuringRegistration
            parentId
            externalCode
        }
    }
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateGroupMutation,
        UpdateGroupMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateGroupMutation,
        UpdateGroupMutationVariables
    >(UpdateGroupDocument, options);
}
export type UpdateGroupMutationHookResult = ReturnType<
    typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
    Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
>;
export const UpdateModuleDocument = gql`
    mutation UpdateModule($input: UpdateModuleInput!) {
        updateModule(input: $input) {
            id
            content
            dashboardImageId
            description
            durationIndication
            headerImageId
            shortTitle
            title
        }
    }
`;
export type UpdateModuleMutationFn = Apollo.MutationFunction<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateModuleMutation,
        UpdateModuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateModuleMutation,
        UpdateModuleMutationVariables
    >(UpdateModuleDocument, options);
}
export type UpdateModuleMutationHookResult = ReturnType<
    typeof useUpdateModuleMutation
>;
export type UpdateModuleMutationResult =
    Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
>;
export const UpdateModuleBlocksForModuleDocument = gql`
    mutation UpdateModuleBlocksForModule(
        $input: UpdateModuleBlocksForModuleInput!
    ) {
        updateModuleBlocksForModule(input: $input) {
            ... on BaseModuleBlock {
                id
                order
                type
            }
            ... on DidacticToolsModuleBlockDto {
                didacticToolBlocks {
                    didacticToolId
                    didacticTool {
                        id
                        name
                    }
                }
            }
            ... on TitleModuleBlockDto {
                title
                description
            }
            ... on VideoModuleBlockDto {
                assetId
                title
                durationIndication
            }
        }
    }
`;
export type UpdateModuleBlocksForModuleMutationFn = Apollo.MutationFunction<
    UpdateModuleBlocksForModuleMutation,
    UpdateModuleBlocksForModuleMutationVariables
>;

/**
 * __useUpdateModuleBlocksForModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleBlocksForModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleBlocksForModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleBlocksForModuleMutation, { data, loading, error }] = useUpdateModuleBlocksForModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleBlocksForModuleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateModuleBlocksForModuleMutation,
        UpdateModuleBlocksForModuleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateModuleBlocksForModuleMutation,
        UpdateModuleBlocksForModuleMutationVariables
    >(UpdateModuleBlocksForModuleDocument, options);
}
export type UpdateModuleBlocksForModuleMutationHookResult = ReturnType<
    typeof useUpdateModuleBlocksForModuleMutation
>;
export type UpdateModuleBlocksForModuleMutationResult =
    Apollo.MutationResult<UpdateModuleBlocksForModuleMutation>;
export type UpdateModuleBlocksForModuleMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateModuleBlocksForModuleMutation,
        UpdateModuleBlocksForModuleMutationVariables
    >;
export const UpdateModulesForEducationalPathDocument = gql`
    mutation UpdateModulesForEducationalPath(
        $input: UpdateModulesForEducationalPathInput!
    ) {
        updateModulesForEducationalPath(input: $input) {
            educationalPathId
            modules {
                id
                name
                order
            }
        }
    }
`;
export type UpdateModulesForEducationalPathMutationFn = Apollo.MutationFunction<
    UpdateModulesForEducationalPathMutation,
    UpdateModulesForEducationalPathMutationVariables
>;

/**
 * __useUpdateModulesForEducationalPathMutation__
 *
 * To run a mutation, you first call `useUpdateModulesForEducationalPathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModulesForEducationalPathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModulesForEducationalPathMutation, { data, loading, error }] = useUpdateModulesForEducationalPathMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModulesForEducationalPathMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateModulesForEducationalPathMutation,
        UpdateModulesForEducationalPathMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateModulesForEducationalPathMutation,
        UpdateModulesForEducationalPathMutationVariables
    >(UpdateModulesForEducationalPathDocument, options);
}
export type UpdateModulesForEducationalPathMutationHookResult = ReturnType<
    typeof useUpdateModulesForEducationalPathMutation
>;
export type UpdateModulesForEducationalPathMutationResult =
    Apollo.MutationResult<UpdateModulesForEducationalPathMutation>;
export type UpdateModulesForEducationalPathMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateModulesForEducationalPathMutation,
        UpdateModulesForEducationalPathMutationVariables
    >;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($input: UpdateProductInput!) {
        updateProduct(input: $input) {
            id
            educationalProgramIds
            name
        }
    }
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<
    UpdateProductMutation,
    UpdateProductMutationVariables
>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateProductMutation,
        UpdateProductMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateProductMutation,
        UpdateProductMutationVariables
    >(UpdateProductDocument, options);
}
export type UpdateProductMutationHookResult = ReturnType<
    typeof useUpdateProductMutation
>;
export type UpdateProductMutationResult =
    Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
    UpdateProductMutation,
    UpdateProductMutationVariables
>;
export const UpdateReferenceCardDocument = gql`
    mutation UpdateReferenceCard($data: ReferenceCardInput!) {
        updateReferenceCard(input: $data) {
            id
            __typename
            headerImage
            thumbnail
            lead {
                id
                label
            }
            title
            description
            didacticTools {
                id
                name
            }
            programs {
                id
                label: name
            }
            categories {
                id
                label
            }
            buildingBlocks {
                id
                background
                order
                ...ContentBuildingBlock
            }
        }
    }
    ${ContentBuildingBlockFragmentDoc}
`;
export type UpdateReferenceCardMutationFn = Apollo.MutationFunction<
    UpdateReferenceCardMutation,
    UpdateReferenceCardMutationVariables
>;

/**
 * __useUpdateReferenceCardMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceCardMutation, { data, loading, error }] = useUpdateReferenceCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReferenceCardMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateReferenceCardMutation,
        UpdateReferenceCardMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateReferenceCardMutation,
        UpdateReferenceCardMutationVariables
    >(UpdateReferenceCardDocument, options);
}
export type UpdateReferenceCardMutationHookResult = ReturnType<
    typeof useUpdateReferenceCardMutation
>;
export type UpdateReferenceCardMutationResult =
    Apollo.MutationResult<UpdateReferenceCardMutation>;
export type UpdateReferenceCardMutationOptions = Apollo.BaseMutationOptions<
    UpdateReferenceCardMutation,
    UpdateReferenceCardMutationVariables
>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
        updateRole(input: $input) {
            id
            keycloakId
            name
            permissions
        }
    }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateRoleMutation,
        UpdateRoleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
        UpdateRoleDocument,
        options,
    );
}
export type UpdateRoleMutationHookResult = ReturnType<
    typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
    Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
>;
export const UpdateShortcodeDocument = gql`
    mutation updateShortcode($input: UpdateShortcodeInput!) {
        updateShortcode(input: $input) {
            id
            shortcode
            educationalProgram {
                id
            }
            educationalPath {
                id
            }
            module {
                id
            }
            didacticTool {
                id
            }
        }
    }
`;
export type UpdateShortcodeMutationFn = Apollo.MutationFunction<
    UpdateShortcodeMutation,
    UpdateShortcodeMutationVariables
>;

/**
 * __useUpdateShortcodeMutation__
 *
 * To run a mutation, you first call `useUpdateShortcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShortcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShortcodeMutation, { data, loading, error }] = useUpdateShortcodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShortcodeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateShortcodeMutation,
        UpdateShortcodeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateShortcodeMutation,
        UpdateShortcodeMutationVariables
    >(UpdateShortcodeDocument, options);
}
export type UpdateShortcodeMutationHookResult = ReturnType<
    typeof useUpdateShortcodeMutation
>;
export type UpdateShortcodeMutationResult =
    Apollo.MutationResult<UpdateShortcodeMutation>;
export type UpdateShortcodeMutationOptions = Apollo.BaseMutationOptions<
    UpdateShortcodeMutation,
    UpdateShortcodeMutationVariables
>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($input: UpdateTenantInput!) {
        updateTenant(input: $input) {
            name
            defaultGroupId
            defaultRoleId
            allowRegistration
            defaultProductId
            theme {
                brandColors
                fonts
                neutralColorsVariant
                radiuses
            }
        }
    }
`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTenantMutation,
        UpdateTenantMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateTenantMutation,
        UpdateTenantMutationVariables
    >(UpdateTenantDocument, options);
}
export type UpdateTenantMutationHookResult = ReturnType<
    typeof useUpdateTenantMutation
>;
export type UpdateTenantMutationResult =
    Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
>;
export const UpdateThemeForEducationalProgramDocument = gql`
    mutation UpdateThemeForEducationalProgram(
        $input: UpdateThemeForEducationalProgramInput!
    ) {
        theme: updateThemeForEducationalProgram(input: $input) {
            brandColors
            fonts
            neutralColorsVariant
            programId
            radiuses
        }
    }
`;
export type UpdateThemeForEducationalProgramMutationFn =
    Apollo.MutationFunction<
        UpdateThemeForEducationalProgramMutation,
        UpdateThemeForEducationalProgramMutationVariables
    >;

/**
 * __useUpdateThemeForEducationalProgramMutation__
 *
 * To run a mutation, you first call `useUpdateThemeForEducationalProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemeForEducationalProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemeForEducationalProgramMutation, { data, loading, error }] = useUpdateThemeForEducationalProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThemeForEducationalProgramMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateThemeForEducationalProgramMutation,
        UpdateThemeForEducationalProgramMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateThemeForEducationalProgramMutation,
        UpdateThemeForEducationalProgramMutationVariables
    >(UpdateThemeForEducationalProgramDocument, options);
}
export type UpdateThemeForEducationalProgramMutationHookResult = ReturnType<
    typeof useUpdateThemeForEducationalProgramMutation
>;
export type UpdateThemeForEducationalProgramMutationResult =
    Apollo.MutationResult<UpdateThemeForEducationalProgramMutation>;
export type UpdateThemeForEducationalProgramMutationOptions =
    Apollo.BaseMutationOptions<
        UpdateThemeForEducationalProgramMutation,
        UpdateThemeForEducationalProgramMutationVariables
    >;
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UpdateUserInput!) {
        updateUser(input: $user) {
            id
            emailAddress
            firstName
            isActive
            lastName
            roleId
            modifiedAt
            modifiedBy
        }
    }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        options,
    );
}
export type UpdateUserMutationHookResult = ReturnType<
    typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
    Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;
export const GetGroupsForRegistrationDocument = gql`
    query GetGroupsForRegistration($groupId: UUID!) {
        getChildGroupsForRegistration(groupId: $groupId) {
            ...RootGroup
        }
    }
    ${RootGroupFragmentDoc}
`;

/**
 * __useGetGroupsForRegistrationQuery__
 *
 * To run a query within a React component, call `useGetGroupsForRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsForRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsForRegistrationQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupsForRegistrationQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetGroupsForRegistrationQuery,
        GetGroupsForRegistrationQueryVariables
    > &
        (
            | {
                  variables: GetGroupsForRegistrationQueryVariables;
                  skip?: boolean;
              }
            | { skip: boolean }
        ),
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetGroupsForRegistrationQuery,
        GetGroupsForRegistrationQueryVariables
    >(GetGroupsForRegistrationDocument, options);
}
export function useGetGroupsForRegistrationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetGroupsForRegistrationQuery,
        GetGroupsForRegistrationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetGroupsForRegistrationQuery,
        GetGroupsForRegistrationQueryVariables
    >(GetGroupsForRegistrationDocument, options);
}
export function useGetGroupsForRegistrationSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetGroupsForRegistrationQuery,
              GetGroupsForRegistrationQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetGroupsForRegistrationQuery,
        GetGroupsForRegistrationQueryVariables
    >(GetGroupsForRegistrationDocument, options);
}
export type GetGroupsForRegistrationQueryHookResult = ReturnType<
    typeof useGetGroupsForRegistrationQuery
>;
export type GetGroupsForRegistrationLazyQueryHookResult = ReturnType<
    typeof useGetGroupsForRegistrationLazyQuery
>;
export type GetGroupsForRegistrationSuspenseQueryHookResult = ReturnType<
    typeof useGetGroupsForRegistrationSuspenseQuery
>;
export type GetGroupsForRegistrationQueryResult = Apollo.QueryResult<
    GetGroupsForRegistrationQuery,
    GetGroupsForRegistrationQueryVariables
>;
export const GetUserConfigurationDocument = gql`
    query GetUserConfiguration($licenseSorting: [LicensesSortingInput!]) {
        getUserConfiguration(licensesSorting: $licenseSorting) {
            user {
                id
                firstName
                lastName
                emailAddress
            }
            authorities
            licenses {
                id
                validUntil
                startAt
                userExpirationSeenAt
                isActive
                product {
                    id
                    name
                }
                userExpirationSeenAt
            }
        }
    }
`;

/**
 * __useGetUserConfigurationQuery__
 *
 * To run a query within a React component, call `useGetUserConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserConfigurationQuery({
 *   variables: {
 *      licenseSorting: // value for 'licenseSorting'
 *   },
 * });
 */
export function useGetUserConfigurationQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetUserConfigurationQuery,
        GetUserConfigurationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetUserConfigurationQuery,
        GetUserConfigurationQueryVariables
    >(GetUserConfigurationDocument, options);
}
export function useGetUserConfigurationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetUserConfigurationQuery,
        GetUserConfigurationQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetUserConfigurationQuery,
        GetUserConfigurationQueryVariables
    >(GetUserConfigurationDocument, options);
}
export function useGetUserConfigurationSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetUserConfigurationQuery,
              GetUserConfigurationQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetUserConfigurationQuery,
        GetUserConfigurationQueryVariables
    >(GetUserConfigurationDocument, options);
}
export type GetUserConfigurationQueryHookResult = ReturnType<
    typeof useGetUserConfigurationQuery
>;
export type GetUserConfigurationLazyQueryHookResult = ReturnType<
    typeof useGetUserConfigurationLazyQuery
>;
export type GetUserConfigurationSuspenseQueryHookResult = ReturnType<
    typeof useGetUserConfigurationSuspenseQuery
>;
export type GetUserConfigurationQueryResult = Apollo.QueryResult<
    GetUserConfigurationQuery,
    GetUserConfigurationQueryVariables
>;
export const LicenseExpirationSeenDocument = gql`
    mutation LicenseExpirationSeen($id: UUID!) {
        licenseExpirationSeen(id: $id) {
            id
            product {
                name
            }
            user {
                firstName
                lastName
            }
            validUntil
            startAt
            userExpirationSeenAt
        }
    }
`;
export type LicenseExpirationSeenMutationFn = Apollo.MutationFunction<
    LicenseExpirationSeenMutation,
    LicenseExpirationSeenMutationVariables
>;

/**
 * __useLicenseExpirationSeenMutation__
 *
 * To run a mutation, you first call `useLicenseExpirationSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLicenseExpirationSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [licenseExpirationSeenMutation, { data, loading, error }] = useLicenseExpirationSeenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLicenseExpirationSeenMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LicenseExpirationSeenMutation,
        LicenseExpirationSeenMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        LicenseExpirationSeenMutation,
        LicenseExpirationSeenMutationVariables
    >(LicenseExpirationSeenDocument, options);
}
export type LicenseExpirationSeenMutationHookResult = ReturnType<
    typeof useLicenseExpirationSeenMutation
>;
export type LicenseExpirationSeenMutationResult =
    Apollo.MutationResult<LicenseExpirationSeenMutation>;
export type LicenseExpirationSeenMutationOptions = Apollo.BaseMutationOptions<
    LicenseExpirationSeenMutation,
    LicenseExpirationSeenMutationVariables
>;
export const PingDocument = gql`
    query Ping {
        ping
    }
`;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(
    baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PingQuery, PingQueryVariables>(
        PingDocument,
        options,
    );
}
export function usePingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(
        PingDocument,
        options,
    );
}
export function usePingSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<PingQuery, PingQueryVariables>,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<PingQuery, PingQueryVariables>(
        PingDocument,
        options,
    );
}
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingSuspenseQueryHookResult = ReturnType<
    typeof usePingSuspenseQuery
>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;
export const GetTenantsDocument = gql`
    query GetTenants {
        getTenants {
            id
            name
            defaultGroupId
            defaultRoleId
        }
    }
`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetTenantsQuery,
        GetTenantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(
        GetTenantsDocument,
        options,
    );
}
export function useGetTenantsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTenantsQuery,
        GetTenantsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(
        GetTenantsDocument,
        options,
    );
}
export function useGetTenantsSuspenseQuery(
    baseOptions?:
        | Apollo.SkipToken
        | Apollo.SuspenseQueryHookOptions<
              GetTenantsQuery,
              GetTenantsQueryVariables
          >,
) {
    const options =
        baseOptions === Apollo.skipToken
            ? baseOptions
            : { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetTenantsQuery, GetTenantsQueryVariables>(
        GetTenantsDocument,
        options,
    );
}
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<
    typeof useGetTenantsLazyQuery
>;
export type GetTenantsSuspenseQueryHookResult = ReturnType<
    typeof useGetTenantsSuspenseQuery
>;
export type GetTenantsQueryResult = Apollo.QueryResult<
    GetTenantsQuery,
    GetTenantsQueryVariables
>;
export const UpdateLicensesDocument = gql`
    mutation UpdateLicenses($input: [UpdateLicenseInput!]!) {
        updateLicenses(input: $input) {
            user {
                firstName
                lastName
            }
            validUntil
            startAt
            isActive
        }
    }
`;
export type UpdateLicensesMutationFn = Apollo.MutationFunction<
    UpdateLicensesMutation,
    UpdateLicensesMutationVariables
>;

/**
 * __useUpdateLicensesMutation__
 *
 * To run a mutation, you first call `useUpdateLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicensesMutation, { data, loading, error }] = useUpdateLicensesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLicensesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateLicensesMutation,
        UpdateLicensesMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateLicensesMutation,
        UpdateLicensesMutationVariables
    >(UpdateLicensesDocument, options);
}
export type UpdateLicensesMutationHookResult = ReturnType<
    typeof useUpdateLicensesMutation
>;
export type UpdateLicensesMutationResult =
    Apollo.MutationResult<UpdateLicensesMutation>;
export type UpdateLicensesMutationOptions = Apollo.BaseMutationOptions<
    UpdateLicensesMutation,
    UpdateLicensesMutationVariables
>;

type Properties<T> = Required<{
    [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
    v !== undefined && v !== null;

export const definedNonNullAnySchema = z
    .any()
    .refine((v) => isDefinedNonNullAny(v));

export const BorderRadiusSchema = z.nativeEnum(BorderRadius);

export const BuildingBlockBackgroundSchema = z.nativeEnum(
    BuildingBlockBackground,
);

export const CarouselBlockLayoutSchema = z.nativeEnum(CarouselBlockLayout);

export const ContentBlockLayoutSchema = z.nativeEnum(ContentBlockLayout);

export const ContentBlockListLayoutSchema = z.nativeEnum(
    ContentBlockListLayout,
);

export const ConversationBlockLayoutSchema = z.nativeEnum(
    ConversationBlockLayout,
);

export const DidacticToolBackgroundSchema = z.nativeEnum(
    DidacticToolBackground,
);

export const DidacticToolLabelTypeSchema = z.nativeEnum(DidacticToolLabelType);

export const DidacticToolTypeSchema = z.nativeEnum(DidacticToolType);

export const DidacticToolsFieldSelectorSchema = z.nativeEnum(
    DidacticToolsFieldSelector,
);

export const DirectionSchema = z.nativeEnum(Direction);

export const EducationalPathsFieldSelectorSchema = z.nativeEnum(
    EducationalPathsFieldSelector,
);

export const EducationalProgramsFieldSelectorSchema = z.nativeEnum(
    EducationalProgramsFieldSelector,
);

export const FontSetSchema = z.nativeEnum(FontSet);

export const GroupSortSelectorSchema = z.nativeEnum(GroupSortSelector);

export const GroupsFieldSelectorSchema = z.nativeEnum(GroupsFieldSelector);

export const HotspotBlockLayoutSchema = z.nativeEnum(HotspotBlockLayout);

export const InteractiveQuestionBlockLayoutSchema = z.nativeEnum(
    InteractiveQuestionBlockLayout,
);

export const LanguageSchema = z.nativeEnum(Language);

export const LicensesFieldSelectorSchema = z.nativeEnum(LicensesFieldSelector);

export const LicensesSortSelectorSchema = z.nativeEnum(LicensesSortSelector);

export const LinkBlockLayoutSchema = z.nativeEnum(LinkBlockLayout);

export const ModuleBlockVariantSchema = z.nativeEnum(ModuleBlockVariant);

export const ModulesFieldSelectorSchema = z.nativeEnum(ModulesFieldSelector);

export const MoodboardBlockLayoutSchema = z.nativeEnum(MoodboardBlockLayout);

export const MpcBlockLayoutSchema = z.nativeEnum(MpcBlockLayout);

export const MpcChoiceTypeSchema = z.nativeEnum(MpcChoiceType);

export const NeutralColorVariantSchema = z.nativeEnum(NeutralColorVariant);

export const ObjectFitSchema = z.nativeEnum(ObjectFit);

export const OperatorSchema = z.nativeEnum(Operator);

export const PollBlockLayoutSchema = z.nativeEnum(PollBlockLayout);

export const PollVoteOptionSchema = z.nativeEnum(PollVoteOption);

export const ProductsFieldSelectorSchema = z.nativeEnum(ProductsFieldSelector);

export const QuestionConnectBlockLayoutSchema = z.nativeEnum(
    QuestionConnectBlockLayout,
);

export const QuoteBlockLayoutSchema = z.nativeEnum(QuoteBlockLayout);

export const ReferenceCardsCarouselBlockLayoutSchema = z.nativeEnum(
    ReferenceCardsCarouselBlockLayout,
);

export const ReferenceCardsFieldSelectorSchema = z.nativeEnum(
    ReferenceCardsFieldSelector,
);

export const RolesFieldSelectorSchema = z.nativeEnum(RolesFieldSelector);

export const SceneBlockLayoutSchema = z.nativeEnum(SceneBlockLayout);

export const SortQuestionBlockLayoutSchema = z.nativeEnum(
    SortQuestionBlockLayout,
);

export const SortingSchema = z.nativeEnum(Sorting);

export const StandSchema = z.nativeEnum(Stand);

export const StandBlockLayoutSchema = z.nativeEnum(StandBlockLayout);

export const StatusSchema = z.nativeEnum(Status);

export const TypeSchema = z.nativeEnum(Type);

export const UserSortSelectorSchema = z.nativeEnum(UserSortSelector);

export const UsersFieldSelectorSchema = z.nativeEnum(UsersFieldSelector);

export function AnswerInputSchema(): z.ZodObject<Properties<AnswerInput>> {
    return z.object({
        assetId: z.string().nullish(),
        content: z.string().nullish(),
        correct: z.boolean(),
        id: z.string().nullish(),
        sortOrder: z.number().nullish(),
        text: z.string().nullish(),
    });
}

export function AppEducationalProgramElementsFilterInputSchema(): z.ZodObject<
    Properties<AppEducationalProgramElementsFilterInput>
> {
    return z.object({
        didacticTools: z.array(z.string()),
        educationalPaths: z.array(z.string()),
        modules: z.array(z.string()),
    });
}

export function BuildingBlocksLabelInputSchema(): z.ZodObject<
    Properties<BuildingBlocksLabelInput>
> {
    return z.object({
        id: z.string().nullish(),
        label: z.string(),
    });
}

export function CarouselBuildingBlockInputSchema(): z.ZodObject<
    Properties<CarouselBuildingBlockInput>
> {
    return z.object({
        background: BuildingBlockBackgroundSchema,
        blocks: z.array(z.lazy(() => definedNonNullAnySchema)),
        id: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: CarouselBlockLayoutSchema,
        order: z.number(),
        title: z.string(),
        type: z.string(),
    });
}

export function CheckAnswersForDidacticToolInputSchema(): z.ZodObject<
    Properties<CheckAnswersForDidacticToolInput>
> {
    return z.object({
        answers: z.array(definedNonNullAnySchema),
        didacticToolId: z.string(),
    });
}

export function ContentBlockListInputSchema(): z.ZodObject<
    Properties<ContentBlockListInput>
> {
    return z.object({
        items: z.array(z.lazy(() => ContentBlockListItemInputSchema())),
        layout: ContentBlockListLayoutSchema,
    });
}

export function ContentBlockListItemInputSchema(): z.ZodObject<
    Properties<ContentBlockListItemInput>
> {
    return z.object({
        assetId: z.string().nullish(),
        content: z.string(),
        id: z.string().nullish(),
        sortOrder: z.number(),
        subtitle: z.string(),
        title: z.string(),
    });
}

export function ContentBuildingBlockInputSchema(): z.ZodObject<
    Properties<ContentBuildingBlockInput>
> {
    return z.object({
        assetId: z.string().nullish(),
        background: BuildingBlockBackgroundSchema,
        contentColumnOne: z.string().nullish(),
        contentColumnTwo: z.string().nullish(),
        id: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: ContentBlockLayoutSchema,
        list: z.lazy(() => ContentBlockListInputSchema().nullish()),
        objectFit: ObjectFitSchema,
        order: z.number(),
        type: z.string(),
    });
}

export function ConversationBuildingBlockInputSchema(): z.ZodObject<
    Properties<ConversationBuildingBlockInput>
> {
    return z.object({
        background: BuildingBlockBackgroundSchema,
        id: z.string().nullish(),
        interval: z.number(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: ConversationBlockLayoutSchema,
        messages: z.array(z.lazy(() => MessageInputSchema())),
        order: z.number(),
        persons: z.array(z.lazy(() => PersonInputSchema())),
        type: z.string(),
    });
}

export function CreateChildGroupInputSchema(): z.ZodObject<
    Properties<CreateChildGroupInput>
> {
    return z.object({
        memberIds: z.array(z.string()),
        name: z.string(),
        parentId: z.string(),
    });
}

export function CreateDidacticToolInputSchema(): z.ZodObject<
    Properties<CreateDidacticToolInput>
> {
    return z.object({
        altLabel: z.string().nullish(),
        description: z.string(),
        durationIndication: z.string().nullish(),
        headerImageId: z.string().nullish(),
        headerIntroText: z.string().nullish(),
        label: DidacticToolLabelTypeSchema.nullish(),
        learningAdviceDescription: z.string().nullish(),
        learningAdviceTitle: z.string().nullish(),
        name: z.string(),
        type: DidacticToolTypeSchema,
    });
}

export function CreateEducationalPathInputSchema(): z.ZodObject<
    Properties<CreateEducationalPathInput>
> {
    return z.object({
        dashboardImageId: z.string().nullish(),
        description: z.string(),
        durationIndication: z.string().nullish(),
        headerImageId: z.string().nullish(),
        headerIntroText: z.string().nullish(),
        name: z.string(),
    });
}

export function CreateEducationalProgramInputSchema(): z.ZodObject<
    Properties<CreateEducationalProgramInput>
> {
    return z.object({
        description: z.string(),
        name: z.string(),
    });
}

export function CreateGroupInputSchema(): z.ZodObject<
    Properties<CreateGroupInput>
> {
    return z.object({
        externalCode: z.string().nullish(),
        name: z.string(),
        parentId: z.string().nullish(),
        productIds: z.array(z.string()),
        showGroupDuringRegistration: z.boolean(),
    });
}

export function CreateLicensesInputSchema(): z.ZodObject<
    Properties<CreateLicensesInput>
> {
    return z.object({
        productIds: z.array(z.string()),
        users: z.array(z.string()),
    });
}

export function CreateModuleInputSchema(): z.ZodObject<
    Properties<CreateModuleInput>
> {
    return z.object({
        content: z.string().nullish(),
        dashboardImageId: z.string().nullish(),
        description: z.string(),
        durationIndication: z.string().nullish(),
        headerImageId: z.string().nullish(),
        shortTitle: z.string().nullish(),
        title: z.string(),
    });
}

export function CreateProductInputSchema(): z.ZodObject<
    Properties<CreateProductInput>
> {
    return z.object({
        educationalProgramIds: z.array(z.string()),
        externalIdentifier: z.string().nullish(),
        isAvailable: z.boolean(),
        name: z.string(),
        prefix: z.string().nullish(),
        validityPeriod: z.number().nullish(),
    });
}

export function CreateRoleInputSchema(): z.ZodObject<
    Properties<CreateRoleInput>
> {
    return z.object({
        name: z.string(),
        permissions: z.array(z.string()),
    });
}

export function CreateShortcodeInputSchema(): z.ZodObject<
    Properties<CreateShortcodeInput>
> {
    return z.object({
        didacticToolId: z.string().nullish(),
        educationalPathId: z.string().nullish(),
        educationalProgramId: z.string(),
        moduleId: z.string().nullish(),
        shortCode: z.string(),
    });
}

export function CreateUserInputSchema(): z.ZodObject<
    Properties<CreateUserInput>
> {
    return z.object({
        dateOfBirth: definedNonNullAnySchema.nullish(),
        emailAddress: z.string(),
        firstName: z.string(),
        groupIds: z.array(z.string()).nullish(),
        lastName: z.string(),
        roleId: z.string(),
        sendInviteImmediately: z.boolean(),
        tenantIds: z.array(z.string()).nullish(),
    });
}

export function DidacticToolModuleBlockInputSchema(): z.ZodObject<
    Properties<DidacticToolModuleBlockInput>
> {
    return z.object({
        didacticToolId: z.string(),
        order: z.number(),
        variant: ModuleBlockVariantSchema,
    });
}

export function DidacticToolsFilterInputSchema(): z.ZodObject<
    Properties<DidacticToolsFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: DidacticToolsFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function DidacticToolsModuleBlockInputSchema(): z.ZodObject<
    Properties<DidacticToolsModuleBlockInput>
> {
    return z.object({
        didacticTools: z.array(
            z.lazy(() => DidacticToolModuleBlockInputSchema()),
        ),
        id: z.string().nullish(),
        order: z.number(),
        type: z.string(),
    });
}

export function EducationalPathsFilterInputSchema(): z.ZodObject<
    Properties<EducationalPathsFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: EducationalPathsFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function EducationalProgramsFilterInputSchema(): z.ZodObject<
    Properties<EducationalProgramsFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: EducationalProgramsFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function ElementFiltersInputSchema(): z.ZodObject<
    Properties<ElementFiltersInput>
> {
    return z.object({
        didacticTools: z.array(z.string()).nullish(),
        educationalPaths: z.array(z.string()).nullish(),
        modules: z.array(z.string()).nullish(),
    });
}

export function GroupsFilterInputSchema(): z.ZodObject<
    Properties<GroupsFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: GroupsFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function GroupsSortingInputSchema(): z.ZodObject<
    Properties<GroupsSortingInput>
> {
    return z.object({
        direction: DirectionSchema,
        selector: GroupSortSelectorSchema,
    });
}

export function HotspotBuildingBlockInputSchema(): z.ZodObject<
    Properties<HotspotBuildingBlockInput>
> {
    return z.object({
        assetId: z.string(),
        background: BuildingBlockBackgroundSchema,
        content: z.string().nullish(),
        id: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: HotspotBlockLayoutSchema,
        order: z.number(),
        spots: z.array(z.lazy(() => SpotDtoInputSchema())),
        title: z.string().nullish(),
        type: z.string(),
    });
}

export function InteractiveQuestionBuildingBlockInputSchema(): z.ZodObject<
    Properties<InteractiveQuestionBuildingBlockInput>
> {
    return z.object({
        background: BuildingBlockBackgroundSchema,
        id: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: InteractiveQuestionBlockLayoutSchema,
        order: z.number(),
        question: z.lazy(() => definedNonNullAnySchema),
        sceneWhenWrong: z.lazy(() => SceneBuildingBlockInputSchema()),
        type: z.string(),
    });
}

export function LicensesFilterInputSchema(): z.ZodObject<
    Properties<LicensesFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: LicensesFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function LicensesSortingInputSchema(): z.ZodObject<
    Properties<LicensesSortingInput>
> {
    return z.object({
        direction: DirectionSchema,
        selector: LicensesSortSelectorSchema,
    });
}

export function LinkBuildingBlockInputSchema(): z.ZodObject<
    Properties<LinkBuildingBlockInput>
> {
    return z.object({
        assetId: z.string().nullish(),
        background: BuildingBlockBackgroundSchema,
        id: z.string().nullish(),
        instruction: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: LinkBlockLayoutSchema,
        linkLabel: z.string().nullish(),
        openInNewWindow: z.boolean(),
        order: z.number(),
        title: z.string(),
        type: z.string(),
        url: z.string(),
    });
}

export function MessageInputSchema(): z.ZodObject<Properties<MessageInput>> {
    return z.object({
        id: z.string().nullish(),
        message: z.string(),
        order: z.number(),
        personIndex: z.number(),
    });
}

export function ModulesFilterInputSchema(): z.ZodObject<
    Properties<ModulesFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: ModulesFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function MoodboardBuildingBlockInputSchema(): z.ZodObject<
    Properties<MoodboardBuildingBlockInput>
> {
    return z.object({
        background: BuildingBlockBackgroundSchema,
        id: z.string().nullish(),
        images: z.array(z.lazy(() => MoodboardImageInputSchema())),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: MoodboardBlockLayoutSchema,
        order: z.number(),
        type: z.string(),
    });
}

export function MoodboardImageInputSchema(): z.ZodObject<
    Properties<MoodboardImageInput>
> {
    return z.object({
        assetId: z.string(),
        id: z.string().nullish(),
        sortOrder: z.number(),
        text: z.string().nullish(),
        title: z.string().nullish(),
    });
}

export function MpcBuildingBlockAnswerInputSchema(): z.ZodObject<
    Properties<MpcBuildingBlockAnswerInput>
> {
    return z.object({
        answerIds: z.array(z.string()),
        buildingBlockId: z.string(),
        type: z.string(),
    });
}

export function MpcBuildingBlockInputSchema(): z.ZodObject<
    Properties<MpcBuildingBlockInput>
> {
    return z.object({
        answers: z.array(z.lazy(() => AnswerInputSchema())),
        assetId: z.string().nullish(),
        background: BuildingBlockBackgroundSchema,
        correctFeedbackText: z.string().nullish(),
        helpText: z.string().nullish(),
        id: z.string().nullish(),
        incorrectFeedbackText: z.string().nullish(),
        instruction: z.string().nullish(),
        introduction: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: MpcBlockLayoutSchema,
        objectFit: ObjectFitSchema,
        order: z.number(),
        question: z.string(),
        sorting: SortingSchema,
        type: z.string(),
    });
}

export function PaginationInputSchema(): z.ZodObject<
    Properties<PaginationInput>
> {
    return z.object({
        page: z.number(),
        pageSize: z.number(),
    });
}

export function PersonInputSchema(): z.ZodObject<Properties<PersonInput>> {
    return z.object({
        assetId: z.string().nullish(),
        id: z.string().nullish(),
        name: z.string(),
        order: z.number(),
    });
}

export function PollAnswerInputSchema(): z.ZodObject<
    Properties<PollAnswerInput>
> {
    return z.object({
        id: z.string().nullish(),
        sortOrder: z.number(),
        text: z.string(),
    });
}

export function PollBuildingBlockAnswerInputSchema(): z.ZodObject<
    Properties<PollBuildingBlockAnswerInput>
> {
    return z.object({
        answers: z.array(z.string()),
        buildingBlockId: z.string(),
        type: z.string(),
    });
}

export function PollBuildingBlockInputSchema(): z.ZodObject<
    Properties<PollBuildingBlockInput>
> {
    return z.object({
        answers: z.array(z.lazy(() => PollAnswerInputSchema())),
        background: BuildingBlockBackgroundSchema,
        id: z.string().nullish(),
        instruction: z.string(),
        introduction: z.string(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: PollBlockLayoutSchema,
        order: z.number(),
        question: z.string(),
        type: z.string(),
        voteOption: PollVoteOptionSchema,
    });
}

export function ProductsFilterInputSchema(): z.ZodObject<
    Properties<ProductsFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: ProductsFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function QuestionConnectBuildingBlockAnswerInputSchema(): z.ZodObject<
    Properties<QuestionConnectBuildingBlockAnswerInput>
> {
    return z.object({
        answerIds: z.array(z.string()),
        buildingBlockId: z.string(),
        type: z.string(),
    });
}

export function QuestionConnectBuildingBlockInputSchema(): z.ZodObject<
    Properties<QuestionConnectBuildingBlockInput>
> {
    return z.object({
        background: BuildingBlockBackgroundSchema,
        correctFeedbackText: z.string().nullish(),
        id: z.string().nullish(),
        incorrectFeedbackText: z.string().nullish(),
        instruction: z.string(),
        introduction: z.string().nullish(),
        items: z.array(z.lazy(() => QuestionConnectItemInputSchema())),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: QuestionConnectBlockLayoutSchema,
        order: z.number(),
        question: z.string(),
        type: z.string(),
    });
}

export function QuestionConnectCorrectAnswerInputSchema(): z.ZodObject<
    Properties<QuestionConnectCorrectAnswerInput>
> {
    return z.object({
        content: z.string(),
        id: z.string().nullish(),
    });
}

export function QuestionConnectItemInputSchema(): z.ZodObject<
    Properties<QuestionConnectItemInput>
> {
    return z.object({
        assetId: z.string().nullish(),
        buildingBlockId: z.string().nullish(),
        content: z.string().nullish(),
        correctAnswer: z.lazy(() =>
            QuestionConnectCorrectAnswerInputSchema().nullish(),
        ),
        id: z.string().nullish(),
    });
}

export function QuoteBuildingBlockInputSchema(): z.ZodObject<
    Properties<QuoteBuildingBlockInput>
> {
    return z.object({
        assetId: z.string().nullish(),
        background: BuildingBlockBackgroundSchema,
        id: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: QuoteBlockLayoutSchema,
        name: z.string().nullish(),
        order: z.number(),
        quote: z.string(),
        type: z.string(),
    });
}

export function ReferenceCardCategoryInputSchema(): z.ZodObject<
    Properties<ReferenceCardCategoryInput>
> {
    return z.object({
        id: z.string().nullish(),
        label: z.string(),
        language: LanguageSchema.nullish(),
    });
}

export function ReferenceCardInputSchema(): z.ZodObject<
    Properties<ReferenceCardInput>
> {
    return z.object({
        buildingBlocks: z.array(z.lazy(() => definedNonNullAnySchema)),
        categories: z.array(z.lazy(() => ReferenceCardCategoryInputSchema())),
        description: z.string().nullish(),
        headerImage: z.string(),
        id: z.string().nullish(),
        language: LanguageSchema.nullish(),
        lead: z.lazy(() => ReferenceCardLeadInputSchema().nullish()),
        programs: z.array(z.string()),
        thumbnail: z.string(),
        title: z.string(),
    });
}

export function ReferenceCardLeadInputSchema(): z.ZodObject<
    Properties<ReferenceCardLeadInput>
> {
    return z.object({
        id: z.string().nullish(),
        label: z.string(),
        language: LanguageSchema.nullish(),
    });
}

export function ReferenceCardsCarouselBuildingBlockInputSchema(): z.ZodObject<
    Properties<ReferenceCardsCarouselBuildingBlockInput>
> {
    return z.object({
        background: BuildingBlockBackgroundSchema,
        cards: z.array(z.lazy(() => SortableReferenceCardIdInputSchema())),
        id: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: ReferenceCardsCarouselBlockLayoutSchema,
        order: z.number(),
        title: z.string(),
        type: z.string(),
    });
}

export function ReferenceCardsFilterInputSchema(): z.ZodObject<
    Properties<ReferenceCardsFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: ReferenceCardsFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function RegisterNewUserInputSchema(): z.ZodObject<
    Properties<RegisterNewUserInput>
> {
    return z.object({
        dateOfBirth: definedNonNullAnySchema.nullish(),
        emailAddress: z.string(),
        firstName: z.string(),
        groupId: z.string(),
        lastName: z.string(),
    });
}

export function RolesFilterInputSchema(): z.ZodObject<
    Properties<RolesFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: RolesFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function SceneBuildingBlockInputSchema(): z.ZodObject<
    Properties<SceneBuildingBlockInput>
> {
    return z.object({
        assetId: z.string(),
        background: BuildingBlockBackgroundSchema,
        description: z.string().nullish(),
        id: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: SceneBlockLayoutSchema,
        order: z.number(),
        title: z.string(),
        type: z.string(),
    });
}

export function SortQuestionAnswerInputSchema(): z.ZodObject<
    Properties<SortQuestionAnswerInput>
> {
    return z.object({
        id: z.string().nullish(),
        order: z.number().nullish(),
        text: z.string(),
    });
}

export function SortQuestionBuildingBlockAnswerInputSchema(): z.ZodObject<
    Properties<SortQuestionBuildingBlockAnswerInput>
> {
    return z.object({
        answerIds: z.array(z.string()),
        buildingBlockId: z.string(),
        type: z.string(),
    });
}

export function SortQuestionBuildingBlockInputSchema(): z.ZodObject<
    Properties<SortQuestionBuildingBlockInput>
> {
    return z.object({
        answers: z.array(z.lazy(() => SortQuestionAnswerInputSchema())),
        assetId: z.string().nullish(),
        background: BuildingBlockBackgroundSchema,
        correctFeedbackText: z.string().nullish(),
        id: z.string().nullish(),
        incorrectFeedbackText: z.string().nullish(),
        instruction: z.string().nullish(),
        introduction: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: SortQuestionBlockLayoutSchema,
        objectFit: ObjectFitSchema,
        order: z.number(),
        question: z.string(),
        type: z.string(),
    });
}

export function SortableEntryInputSchema(): z.ZodObject<
    Properties<SortableEntryInput>
> {
    return z.object({
        id: z.string(),
        order: z.number(),
    });
}

export function SortableReferenceCardIdInputSchema(): z.ZodObject<
    Properties<SortableReferenceCardIdInput>
> {
    return z.object({
        id: z.string(),
        order: z.number(),
    });
}

export function SpotDtoInputSchema(): z.ZodObject<Properties<SpotDtoInput>> {
    return z.object({
        content: z.string().nullish(),
        id: z.string().nullish(),
        title: z.string().nullish(),
        x: z.number(),
        y: z.number(),
    });
}

export function StandBuildingBlockAnswerInputSchema(): z.ZodObject<
    Properties<StandBuildingBlockAnswerInput>
> {
    return z.object({
        answer: StandSchema,
        buildingBlockId: z.string(),
        type: z.string(),
    });
}

export function StandBuildingBlockInputSchema(): z.ZodObject<
    Properties<StandBuildingBlockInput>
> {
    return z.object({
        agreeText: z.string(),
        assetId: z.string().nullish(),
        background: BuildingBlockBackgroundSchema,
        correctFeedbackText: z.string().nullish(),
        correctOption: StandSchema,
        disagreeText: z.string(),
        id: z.string().nullish(),
        incorrectFeedbackText: z.string().nullish(),
        instruction: z.string().nullish(),
        label: z.lazy(() => BuildingBlocksLabelInputSchema().nullish()),
        layout: StandBlockLayoutSchema,
        order: z.number(),
        stand: z.string(),
        text: z.string().nullish(),
        type: z.string(),
    });
}

export function ThemeDtoInputSchema(): z.ZodObject<Properties<ThemeDtoInput>> {
    return z.object({
        brandColors: z.string(),
        fonts: FontSetSchema,
        neutralColorsVariant: NeutralColorVariantSchema,
        radiuses: BorderRadiusSchema,
    });
}

export function TitleModuleBlockInputSchema(): z.ZodObject<
    Properties<TitleModuleBlockInput>
> {
    return z.object({
        description: z.string().nullish(),
        id: z.string().nullish(),
        order: z.number(),
        title: z.string().nullish(),
        type: z.string(),
    });
}

export function UpdateBuildingBlocksForDidacticToolInputSchema(): z.ZodObject<
    Properties<UpdateBuildingBlocksForDidacticToolInput>
> {
    return z.object({
        buildingBlocks: z.array(z.lazy(() => definedNonNullAnySchema)),
        didacticToolId: z.string(),
        language: LanguageSchema.nullish(),
    });
}

export function UpdateChildGroupInputSchema(): z.ZodObject<
    Properties<UpdateChildGroupInput>
> {
    return z.object({
        id: z.string(),
        memberIds: z.array(z.string()),
        name: z.string(),
    });
}

export function UpdateDidacticToolInputSchema(): z.ZodObject<
    Properties<UpdateDidacticToolInput>
> {
    return z.object({
        altLabel: z.string().nullish(),
        description: z.string(),
        durationIndication: z.string().nullish(),
        headerBackground: DidacticToolBackgroundSchema.nullish(),
        headerImageId: z.string().nullish(),
        headerIntroText: z.string().nullish(),
        id: z.string(),
        label: DidacticToolLabelTypeSchema.nullish(),
        language: LanguageSchema.nullish(),
        learningAdviceDescription: z.string().nullish(),
        learningAdviceTitle: z.string().nullish(),
        name: z.string(),
        type: DidacticToolTypeSchema,
    });
}

export function UpdateEducationalPathInputSchema(): z.ZodObject<
    Properties<UpdateEducationalPathInput>
> {
    return z.object({
        dashboardImageId: z.string().nullish(),
        description: z.string(),
        durationIndication: z.string().nullish(),
        headerImageId: z.string().nullish(),
        headerIntroText: z.string().nullish(),
        id: z.string(),
        language: LanguageSchema.nullish(),
        name: z.string(),
    });
}

export function UpdateEducationalPathsForProgramInputSchema(): z.ZodObject<
    Properties<UpdateEducationalPathsForProgramInput>
> {
    return z.object({
        educationalPaths: z.array(z.lazy(() => SortableEntryInputSchema())),
        programId: z.string(),
    });
}

export function UpdateEducationalProgramInputSchema(): z.ZodObject<
    Properties<UpdateEducationalProgramInput>
> {
    return z.object({
        description: z.string(),
        id: z.string(),
        name: z.string(),
    });
}

export function UpdateGroupInputSchema(): z.ZodObject<
    Properties<UpdateGroupInput>
> {
    return z.object({
        externalCode: z.string().nullish(),
        id: z.string(),
        name: z.string(),
        parentId: z.string().nullish(),
        productIds: z.array(z.string()),
        showGroupDuringRegistration: z.boolean(),
    });
}

export function UpdateLicenseInputSchema(): z.ZodObject<
    Properties<UpdateLicenseInput>
> {
    return z.object({
        id: z.string(),
        isActive: z.boolean(),
    });
}

export function UpdateModuleBlocksForModuleInputSchema(): z.ZodObject<
    Properties<UpdateModuleBlocksForModuleInput>
> {
    return z.object({
        language: LanguageSchema.nullish(),
        moduleBlocks: z.array(z.lazy(() => definedNonNullAnySchema)),
        moduleId: z.string(),
    });
}

export function UpdateModuleInputSchema(): z.ZodObject<
    Properties<UpdateModuleInput>
> {
    return z.object({
        content: z.string().nullish(),
        dashboardImageId: z.string().nullish(),
        description: z.string(),
        durationIndication: z.string().nullish(),
        headerImageId: z.string().nullish(),
        id: z.string(),
        language: LanguageSchema.nullish(),
        shortTitle: z.string().nullish(),
        title: z.string(),
    });
}

export function UpdateModulesForEducationalPathInputSchema(): z.ZodObject<
    Properties<UpdateModulesForEducationalPathInput>
> {
    return z.object({
        educationalPathId: z.string(),
        modules: z.array(z.lazy(() => SortableEntryInputSchema())),
    });
}

export function UpdateProductInputSchema(): z.ZodObject<
    Properties<UpdateProductInput>
> {
    return z.object({
        educationalProgramIds: z.array(z.string()),
        externalIdentifier: z.string().nullish(),
        id: z.string(),
        isAvailable: z.boolean(),
        name: z.string(),
        prefix: z.string().nullish(),
        validityPeriod: z.number().nullish(),
    });
}

export function UpdateRoleInputSchema(): z.ZodObject<
    Properties<UpdateRoleInput>
> {
    return z.object({
        id: z.string(),
        name: z.string(),
        permissions: z.array(z.string()),
    });
}

export function UpdateShortcodeInputSchema(): z.ZodObject<
    Properties<UpdateShortcodeInput>
> {
    return z.object({
        didacticToolId: z.string().nullish(),
        educationalPathId: z.string().nullish(),
        educationalProgramId: z.string(),
        id: z.string(),
        moduleId: z.string().nullish(),
        shortCode: z.string(),
    });
}

export function UpdateTenantInputSchema(): z.ZodObject<
    Properties<UpdateTenantInput>
> {
    return z.object({
        allowRegistration: z.boolean(),
        defaultGroupId: z.string(),
        defaultProductId: z.string().nullish(),
        defaultRoleId: z.string(),
        name: z.string(),
        theme: z.lazy(() => ThemeDtoInputSchema()),
    });
}

export function UpdateThemeForEducationalProgramInputSchema(): z.ZodObject<
    Properties<UpdateThemeForEducationalProgramInput>
> {
    return z.object({
        brandColors: z.string().nullish(),
        fonts: FontSetSchema.nullish(),
        neutralColorsVariant: NeutralColorVariantSchema.nullish(),
        programId: z.string(),
        radiuses: BorderRadiusSchema.nullish(),
    });
}

export function UpdateUserInputSchema(): z.ZodObject<
    Properties<UpdateUserInput>
> {
    return z.object({
        emailAddress: z.string(),
        firstName: z.string(),
        groupIds: z.array(z.string()).nullish(),
        id: z.string(),
        isActive: z.boolean(),
        lastName: z.string(),
        roleId: z.string(),
        tenantIds: z.array(z.string()).nullish(),
    });
}

export function UserProgressFilterInputSchema(): z.ZodObject<
    Properties<UserProgressFilterInput>
> {
    return z.object({
        elements: z.lazy(() => ElementFiltersInputSchema()),
        group: z.string(),
        program: z.string(),
        users: z.array(z.string()),
    });
}

export function UsersFilterInputSchema(): z.ZodObject<
    Properties<UsersFilterInput>
> {
    return z.object({
        operator: OperatorSchema,
        orNull: z.boolean().nullish(),
        selector: UsersFieldSelectorSchema,
        value: z.string().nullish(),
    });
}

export function UsersSortingInputSchema(): z.ZodObject<
    Properties<UsersSortingInput>
> {
    return z.object({
        direction: DirectionSchema,
        selector: UserSortSelectorSchema,
    });
}

export function VideoModuleBlockInputSchema(): z.ZodObject<
    Properties<VideoModuleBlockInput>
> {
    return z.object({
        assetId: z.string(),
        durationIndication: z.string().nullish(),
        id: z.string().nullish(),
        order: z.number(),
        title: z.string().nullish(),
        type: z.string(),
    });
}
