import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu } from "./menu.component";

export function Profile() {
    const { t } = useTranslation("translation", {
        keyPrefix: "templates.layout.profile",
    });

    return (
        <div className="container m-auto grid gap-12 px-4 pt-32 md:px-8">
            <h1 className="font-title text-2xl font-bold">
                {t("header.title")}
            </h1>
            <div className="grid gap-4 md:grid-cols-12">
                <div className="md:col-span-3">
                    <Menu />
                </div>
                <div className="md:col-span-1" />
                <div className="md:col-span-7 md:pt-2">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Profile;
