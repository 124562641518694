import classNames from "../../../../lib/class-names/class-names";
import type { ReactNode } from "react";

export interface SkeletonProps {
    className?: string;
    children?: ReactNode;
}

export const Skeleton = ({ className, children }: SkeletonProps) => {
    return (
        <div
            className={classNames(
                "relative overflow-hidden bg-neutral-200",
                className,
            )}
        >
            <div className="absolute inset-0 h-full w-full animate-shimmer bg-gradient-to-r from-neutral-200 via-neutral-100 to-neutral-200" />
            <div className="opacity-0">{children}</div>
        </div>
    );
};
