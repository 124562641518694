import { ClockIcon, Container, Divider, Grid } from "@bespeak/ui";
import css from "./DefaultHeader.module.scss";
import { Button, Figure, Typography } from "@bespeak/ui";
import ConvertedHTML from "@bespeak/ui/src/ConvertedHTML/ConvertedHTML";
import type { DidacticToolHeaderBtnProps } from "@/components/templates/DidacticToolHeader/DidacticToolHeader";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { HeaderShade } from "@/components/atoms/HeaderShade/HeaderShade.component";
import ImageById from "@/components/base/atoms/image-by-id/image-by-id";

export const HeaderHeight = {
    Sm: "Sm",
    Default: "Default",
} as const;

export type HeaderHeight = keyof typeof HeaderHeight;

export type DefaultHeaderProps = {
    imageId?: string;
    heading: string;
    preTitle?: string;
    cta?: DidacticToolHeaderBtnProps[];
    headerIntro?: string;
    headerLabel?: string;
    headerHeight?: HeaderHeight;
};

const DefaultHeaderRoot = forwardRef(function DefaultHeaderRoot(
    props: DefaultHeaderProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const {
        imageId,
        heading,
        preTitle,
        cta,
        headerIntro,
        headerLabel,
        headerHeight = HeaderHeight.Default,
    } = props;
    const isDesktop = useMediaQuery("(min-width: 992px)");

    return (
        <div
            className={clsx(css.DefaultHeader, {
                [css.DefaultHeaderDesktop]: isDesktop,
                [css.DefaultHeaderSm]: headerHeight === HeaderHeight.Sm,
            })}
            ref={ref}
        >
            {imageId ? (
                <Figure className={css.background}>
                    <ImageById
                        className={css.Image}
                        alt="heading"
                        id={imageId}
                    />
                </Figure>
            ) : null}
            <div className={css.content}>
                <Container>
                    <Grid>
                        <Grid.Row>
                            <Grid.Col
                                column={{
                                    xs: "span 12",
                                    md: HeaderHeight.Sm
                                        ? "2 / span 10"
                                        : "2 / span 8",
                                }}
                            >
                                <div>
                                    {preTitle || headerLabel ? (
                                        <div className={css.headerIntro}>
                                            <Typography
                                                variant="h6"
                                                className={css.preTitle}
                                            >
                                                {preTitle}
                                            </Typography>
                                            {headerLabel && preTitle ? (
                                                <Divider
                                                    size={Divider.Size.Sm}
                                                    direction={
                                                        Divider.Direction
                                                            .Vertical
                                                    }
                                                />
                                            ) : null}
                                            {headerLabel ? (
                                                <Typography
                                                    variant="body-bold"
                                                    className={css.headerLabel}
                                                    component={"span"}
                                                >
                                                    <ClockIcon />
                                                    {headerLabel}
                                                </Typography>
                                            ) : null}
                                        </div>
                                    ) : null}
                                    <Typography
                                        className={css.heading}
                                        variant="h3"
                                    >
                                        {heading}
                                    </Typography>
                                </div>

                                {headerIntro || cta ? (
                                    <div>
                                        {headerIntro && (
                                            <ConvertedHTML
                                                value={headerIntro}
                                            />
                                        )}
                                        {cta && (
                                            <div className={css.cta}>
                                                {cta.map((cta, index) => (
                                                    <Button
                                                        key={index}
                                                        color="primary"
                                                        onClick={cta.onClick}
                                                        startAdornment={
                                                            cta.startAdornment
                                                        }
                                                        variant={
                                                            index === 0
                                                                ? "filled"
                                                                : "ghost"
                                                        }
                                                        size="regular"
                                                    >
                                                        {cta.label}
                                                    </Button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </Grid.Col>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
            {imageId ? <HeaderShade /> : null}
        </div>
    );
});

export const DefaultHeader = Object.assign(DefaultHeaderRoot, {
    Height: HeaderHeight,
});
