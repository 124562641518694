export const GradiatuonIcon = ({ className }: { className?: string }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M5 10V16.0111C5 16.3701 5 16.5496 5.05465 16.708C5.10299 16.8482 5.18187 16.9758 5.28558 17.0817C5.40287 17.2014 5.5634 17.2817 5.88446 17.4422L11.2845 20.1422C11.5468 20.2734 11.678 20.339 11.8156 20.3648C11.9375 20.3877 12.0625 20.3877 12.1844 20.3648C12.322 20.339 12.4532 20.2734 12.7155 20.1422L18.1155 17.4422C18.4366 17.2817 18.5971 17.2014 18.7144 17.0817C18.8181 16.9758 18.897 16.8482 18.9453 16.708C19 16.5496 19 16.3701 19 16.0111V10M2 8.5L11.6422 3.67889C11.7734 3.6133 11.839 3.5805 11.9078 3.5676C11.9687 3.55616 12.0313 3.55616 12.0922 3.5676C12.161 3.5805 12.2266 3.6133 12.3578 3.67889L22 8.5L12.3578 13.3211C12.2266 13.3867 12.161 13.4195 12.0922 13.4324C12.0313 13.4438 11.9687 13.4438 11.9078 13.4324C11.839 13.4195 11.7734 13.3867 11.6422 13.3211L2 8.5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default GradiatuonIcon;
