import type { ContentBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import Block from "../../../atoms/Block";
import Stack from "@mui/material/Stack";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import { useState } from "react";
import clsx from "clsx";
import WysiwygEditor from "@/components/atoms/WysiwygEditor/WysiwygEditor";
import css from "./ContentBuildingBlockEditor.module.scss";
import { ContentBlockLayout, ObjectFit } from "@bespeak/apollo";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import { useTranslation } from "react-i18next";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import { BuildingBlockType } from "@/components/organisms/BuildingBlockMapper";
import ContentListItems from "@/components/molecules/ContentListItems/ContentListItems";
import { AssetUpload } from "src/components/organisms/AssetUpload";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";
import ObjectFitSelector from "@/components/molecules/ObjectFitSelector/ObjectFitSelector.component";

interface ContentBuildingBlockEditorComponentProps
    extends ContentBuildingBlock {
    elevate?: boolean;
    onChange?: (buildingBlock: ContentBuildingBlock) => void;
    /**
     * Whether block is rendered inside carousel (Stepper)
     */
    carousel?: boolean;
}

export function ContentBuildingBlockEditorComponent(
    props: ContentBuildingBlockEditorComponentProps,
) {
    const { type, list, background, onChange } = props;

    const { t } = useTranslation("common");
    const [bgColor, setBgColor] = useState(background || "DEFAULT");
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "link"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
        ],
    };

    const asset = useDetailedAsset(props.asset);
    const isFileAsset = useDetailedAsset.isFileAssetDto(asset);

    const isObjectFitLayout = [
        ContentBlockLayout.ImageLeft,
        ContentBlockLayout.ImageRight,
    ].includes(props.layout);

    const listElement =
        type === BuildingBlockType.CONTENT_LIST && list ? (
            <ContentListItems {...props} list={list} />
        ) : null;

    const imageBlock = (
        <AssetUpload
            availableModes={["file", "external_video"]}
            showExternalVideoSettings={true}
            asset={props.asset}
            onChange={(asset) => {
                onChange?.({
                    ...props,

                    ...(asset != null && {
                        asset: asset,
                    }),

                    ...(asset === null && {
                        // Allow clearing the asset
                        asset: undefined,
                    }),
                });
            }}
            error={resolveError({ errors: props.errors, path: "asset.id" })}
        />
    );

    const contentBlock = (value: string, prop: string) => {
        const error = resolveError({ errors: props.errors, path: prop });

        return (
            <WysiwygEditor
                customModules={modules}
                value={value}
                placeholder={t("start-typing", "Begin hier te typen...")}
                onChange={(jsonReply) => {
                    onChange?.({
                        ...props,
                        [prop]: jsonReply,
                    });
                }}
                error={error}
            />
        );
    };

    const layoutOptions = props.carousel
        ? [
              { type: ContentBlockLayout.TwoColumns },
              { type: ContentBlockLayout.ImageLeft },
              { type: ContentBlockLayout.ImageRight },
          ]
        : [
              { type: ContentBlockLayout.TwoColumns },
              { type: ContentBlockLayout.ImageLeft },
              { type: ContentBlockLayout.ImageRight },
              { type: ContentBlockLayout.TextCentered },
          ];

    return (
        <Block elevate={props.elevate}>
            <Block.Header>
                <LayoutSelector
                    type={BuildingBlockType.CONTENT}
                    value={props.layout || ContentBlockLayout.TwoColumns}
                    layoutOptions={layoutOptions}
                    onChange={(e) => {
                        onChange?.({
                            ...props,
                            layout: e as ContentBlockLayout,
                        });
                    }}
                />
                {isObjectFitLayout && isFileAsset && (
                    <ObjectFitSelector
                        value={isFileAsset ? props.objectFit : ObjectFit.Boxed}
                        onChange={(objectFit) => {
                            onChange?.({
                                ...props,
                                objectFit: isFileAsset
                                    ? objectFit
                                    : ObjectFit.Boxed,
                            });
                        }}
                    />
                )}
                {!props.carousel && (
                    <SelectBgColor
                        onChange={(background) => {
                            setBgColor(background);
                            onChange?.({
                                ...props,
                                background,
                            });
                        }}
                        value={bgColor}
                    />
                )}
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <Stack gap={4} direction="row">
                    {props.layout === ContentBlockLayout.TextCentered ? (
                        <>
                            <Stack
                                gap={1}
                                direction="column"
                                flexBasis={"100%"}
                            >
                                <div className={css.centerImage}>
                                    {imageBlock}
                                </div>
                                <Stack gap={1}>
                                    {contentBlock(
                                        props.contentColumnOne,
                                        "contentColumnOne",
                                    )}
                                </Stack>
                                {listElement}
                            </Stack>
                        </>
                    ) : null}
                    {props.layout === ContentBlockLayout.ImageRight ? (
                        <>
                            <Stack gap={1} flexBasis={"50%"}>
                                {contentBlock(
                                    props.contentColumnOne,
                                    "contentColumnOne",
                                )}
                                {listElement}
                            </Stack>
                            <Stack gap={1} flexBasis={"50%"}>
                                {imageBlock}
                            </Stack>
                        </>
                    ) : null}
                    {props.layout === ContentBlockLayout.ImageLeft ? (
                        <>
                            <Stack gap={1} flexBasis={"50%"}>
                                {imageBlock}
                            </Stack>
                            <Stack gap={1} flexBasis={"50%"}>
                                {contentBlock(
                                    props.contentColumnOne,
                                    "contentColumnOne",
                                )}
                                {listElement}
                            </Stack>
                        </>
                    ) : null}
                    {props.layout === ContentBlockLayout.TwoColumns ? (
                        <>
                            <Stack flexBasis={"100%"} gap={2}>
                                <Stack direction={"row"} gap={1}>
                                    <Stack gap={1} flexBasis={"50%"}>
                                        {contentBlock(
                                            props.contentColumnOne,
                                            "contentColumnOne",
                                        )}
                                    </Stack>
                                    <Stack gap={1} flexBasis={"50%"}>
                                        {contentBlock(
                                            props.contentColumnTwo ?? "",
                                            "contentColumnTwo",
                                        )}
                                    </Stack>
                                </Stack>
                                {listElement}
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default ContentBuildingBlockEditorComponent;
