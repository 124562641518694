import type { CarouselBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import Block from "@/components/atoms/Block";
import Stack from "@mui/material/Stack";
import SelectBgColor from "@/components/organisms/BuildingBlockMapper/blocks/common/SelectBgColor";
import CarouselEditor from "@/components/organisms/CarouselEditor";
import Input from "@/components/atoms/Input";
import { useTranslation } from "react-i18next";
import { BuildingBlockBackground } from "@bespeak/apollo";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import clsx from "clsx";
import css from "./CarouselBuildingBlock.module.scss";

const bgColors = [
    BuildingBlockBackground.BrandDark,
    BuildingBlockBackground.BrandLight,
    BuildingBlockBackground.NeutralLight,
];

interface CarouselBuildingBlockComponentBuildingBlockEditorComponentProps
    extends CarouselBuildingBlock {
    onChange?: (buildingBlock: CarouselBuildingBlock) => void;
}

export const CarouselBuildingBlockComponent = (
    props: CarouselBuildingBlockComponentBuildingBlockEditorComponentProps,
) => {
    const { t } = useTranslation("common", {
        keyPrefix: "carousel-building-block",
    });

    type Fields = Pick<CarouselBuildingBlock, "title" | "background">;

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <SelectBgColor
                        {...registerControl("background")}
                        filter={bgColors}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({
                    [css.primary]: props.background === "PRIMARY",
                })}
            >
                <Input
                    {...registerControl("title")}
                    placeholder={t("title")}
                    error={resolveError({
                        errors: props.errors,
                        path: "title",
                    })}
                />

                <ValidationErrorLabel
                    keyPrefix={"carousel-building-block"}
                    error={resolveError({
                        errors: props.errors,
                        path: "blocks",
                        type: "min",
                    })}
                />

                <CarouselEditor
                    blocks={props.blocks}
                    onChange={(blocks) => {
                        props.onChange
                            ? props.onChange({ ...props, blocks: blocks })
                            : null;
                    }}
                    errors={props.errors?.filter((error) =>
                        error.path?.startsWith("blocks."),
                    )}
                />
            </Block.Main>
        </Block>
    );
};

export default CarouselBuildingBlockComponent;
