import type { SearchResult } from "./search-result.component";
import { useGetLabel } from "@/lib/useGetLabelForDm/useGetLabel";
import { useTranslation } from "react-i18next";
import highlighString from "@/lib/HighlightString/HighlightString";
import Typography from "../Typography/typography";

export const SearchResultContent = ({
    type,
    title,
    searchString,
}: SearchResult.Content) => {
    const { t } = useTranslation("common", {
        keyPrefix: "search",
    });
    const { label } = useGetLabel(type);
    const mappedLabel =
        type === "reference-card" ? t(type, "Fact card") : label;

    return (
        <div className="flex w-full flex-col items-start justify-start">
            <Typography
                variant="span"
                color="very-dark"
                weight="medium"
                size="sm"
            >
                {mappedLabel}
            </Typography>
            <Typography variant="p" className="text-left" color="very-dark">
                {highlighString(title, searchString)}
            </Typography>
        </div>
    );
};
