import { DidacticToolType } from "@bespeak/apollo";
import { CarouselIcon, BlankIcon } from "@bespeak/ui";
import type { SearchResult } from "./search-result.component";
import DidacticIcon from "../../atoms/didactic-icon/didactic-icon";

export const SearchResultAsset = ({ type }: SearchResult.Asset) => {
    const dmTypes = Object.values(DidacticToolType);

    const typeIcon = () => {
        if (dmTypes.includes(type as DidacticToolType)) {
            return (
                <DidacticIcon type={type} className="size-6 justify-center" />
            );
        } else if (type === "reference-card") {
            return <CarouselIcon className="size-6" />;
        }

        return <BlankIcon className="size-6" />;
    };

    return (
        <div className="flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-full bg-primary text-white">
            {typeIcon()}
        </div>
    );
};
