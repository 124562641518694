import { DidacticToolType } from "@bespeak/apollo";
import {
    GradiatuonIcon,
    File02Icon,
    BookClosedIcon,
    Award03Icon,
    PlayCircleIcon,
    MessageCircle02Icon,
    PlaylistIcon,
    Trophy01Icon,
} from "@base/icons";

type DidacticToolIconProps = {
    type: DidacticToolType | string;
    className?: string;
};

export const DidacticIcon = ({ type, className }: DidacticToolIconProps) => {
    switch (type) {
        case DidacticToolType.Exam:
            return <GradiatuonIcon className={className} />;
        case DidacticToolType.Lesson:
            return <File02Icon className={className} />;
        case DidacticToolType.Book:
            return <BookClosedIcon className={className} />;
        case DidacticToolType.Assignment:
            return <Award03Icon className={className} />;
        case DidacticToolType.LinearVideo:
            return <PlayCircleIcon className={className} />;
        case DidacticToolType.Case:
            return <MessageCircle02Icon className={className} />;
        case DidacticToolType.VideoInteractive:
            return <PlaylistIcon className={className} />;
        case DidacticToolType.Quiz:
            return <Trophy01Icon className={className} />;
        default:
            return null;
    }
};

export default DidacticIcon;
