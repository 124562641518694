import type {
    ExternalVideoAssetDto,
    FileAssetDto,
} from "../../../packages/apollo";
import { isExternalVideoAsset, isFileAsset } from "@/helpers";
import Figure from "@/Figure";
import { memo, type ReactElement } from "react";
import ReactPlayer from "react-player";
import { useDetailedAsset } from "@/hooks";
import { useIntersectionObserver } from "usehooks-ts";

type AssetProps = {
    asset: FileAssetDto | ExternalVideoAssetDto | undefined;
    className?: string | undefined;
    aspectRatio?: string;
    playIcon?: ReactElement;
    cover?: boolean;
    focusPoint?: { x: number; y: number };
};

function ImageAsset(props: AssetProps & { asset: FileAssetDto }) {
    const { asset, className, cover } = props;

    return (
        <Figure cover={cover} className={className}>
            <Figure.Img
                src={asset.signedUrl ?? ""}
                aspectRatio={props.aspectRatio}
                focusPoint={props.focusPoint}
            />
        </Figure>
    );
}

function VideoAsset(props: AssetProps & { asset: ExternalVideoAssetDto }) {
    const { asset, className } = props;
    const { isIntersecting = false, ref } = useIntersectionObserver({
        threshold: 0.7,
        initialIsIntersecting: true,
    });
    const isLight = !asset.loop || !asset.autoplay;
    const hasNoOptions = !asset.loop && !asset.autoplay;
    const isAutoplay = asset.autoplay && !isLight;

    return (
        <Figure className={className}>
            <div ref={ref}>
                <ReactPlayer
                    light={(!isIntersecting && isLight) || hasNoOptions}
                    playing={asset.autoplay && isIntersecting}
                    loop={asset.loop}
                    url={asset.videoUrl}
                    volume={asset.autoplay ? 0 : undefined}
                    playIcon={props.playIcon}
                    config={{
                        youtube: {
                            playerVars: {
                                autoplay:
                                    isAutoplay && !isIntersecting
                                        ? 1
                                        : hasNoOptions // If no options are set, autoplay for light mode
                                          ? 1
                                          : 0,
                                loop: asset.loop ? 1 : 0,
                            },
                        },
                        vimeo: {
                            playerOptions: {
                                autoplay:
                                    isAutoplay && !isIntersecting
                                        ? 1
                                        : hasNoOptions // If no options are set, autoplay for light mode
                                          ? 1
                                          : 0,
                                loop: asset.loop,
                                texttrack: "nl",
                            },
                        },
                    }}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                    style={{ aspectRatio: "16/9" }}
                />
            </div>
        </Figure>
    );
}

export function AssetRoot(props: AssetProps) {
    const { asset } = props;

    if (!asset) {
        return null;
    } else if (isFileAsset(asset)) {
        return <ImageAsset {...props} asset={asset} />;
    } else if (isExternalVideoAsset(asset)) {
        return <VideoAsset {...props} asset={asset} />;
    }
}

export interface LazyAssetProps
    extends Pick<AssetProps, "aspectRatio" | "focusPoint" | "className"> {
    assetId: string;
}

function LazyAsset({ assetId, ...eagerAssetProps }: LazyAssetProps) {
    const media = useDetailedAsset(assetId);

    return <AssetRoot asset={media} {...eagerAssetProps} />;
}

export const Asset = Object.assign(AssetRoot, { Lazy: memo(LazyAsset) });

Asset.Lazy.displayName = "Asset.Lazy";

export default Asset;
