import { Search } from "@/components/base/organisms/search/search";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";
import { useContext } from "react";

export const SearchLayout = () => {
    const { tenantHasSearchKey, searchInstantiated } =
        useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);
    return <>{tenantHasSearchKey && searchInstantiated ? <Search /> : null}</>;
};

export default SearchLayout;
