import { cva } from "cva";

export const typographyStyles = cva({
    variants: {
        color: {
            "almost-black": "text-neutral-900",
            dark: "text-neutral-700",
            error: "text-error-700",
            neutral: "text-neutral-500",
            primary: "text-primary",
            "primary-dim": "text-primary-dim",
            success: "text-success-700",
            "very-dark": "text-neutral-800",
            warning: "text-warning-700",
            white: "text-white",
            inherit: "text-inherit",
        },
        italic: {
            true: "italic",
            false: "not-italic",
        },
        block: {
            true: "block",
            false: "inline",
        },
        size: {
            base: "text-base",
            xs: "text-xs",
            sm: "text-sm",
            lg: "text-lg",
            xl: "text-xl",
            "2xl": "text-2xl",
            "3xl": "text-3xl",
        },
        fontType: {
            title: "font-title",
            body: "font-body",
        },
        weight: {
            normal: "font-normal",
            medium: "font-medium",
            bold: "font-bold",
        },
    },
    defaultVariants: {
        size: "base",
    },
});
