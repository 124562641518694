import css from "./DialogBody.module.css";

export type DialogBodyProps = {
    children: React.ReactNode;
    maxWidth?: string;
    noPadding?: boolean;
    className?: string;
};

export const DialogBody = ({
    children,
    maxWidth,
    noPadding,
    className,
}: DialogBodyProps) => {
    return (
        <div
            className={`${css.DialogBody} ${className}`}
            style={{
                ...(maxWidth ? { [`--max-width`]: maxWidth } : null),
                ...(noPadding ? { [`--_DialogBody-padding`]: "0" } : null),
            }}
        >
            {children}
        </div>
    );
};

export default DialogBody;
