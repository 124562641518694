import classNames from "@/lib/class-names";
import getInitials from "@/lib/getInitials/getInitials";
import { Asset } from "@bespeak/ui";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";

interface AvatarProps {
    asset?: string | null;
    name: string;
    className?: string;
    size: "sm" | "lg" | "xl" | "xxl";
    outline?: "primary" | "neutral" | "none";
    isPrimaryBackground: boolean;
    hasBorder?: boolean;
}

const Avatar = ({
    asset,
    name,
    className,
    size = "sm",
    outline,
    isPrimaryBackground,
}: AvatarProps) => {
    const initials = getInitials(name);
    const detailedAsset = useDetailedAsset(asset || "");

    const width = {
        sm: "w-10 h-10 text-base",
        lg: "md:w-[72px] md:h-[72px] text-xl w-10 h-10",
        xl: "w-24 h-24 text-4xl",
        xxl: "w-[72px] h-[72px] md:w-40 md:h-40 text-4xl",
    };

    const outlineColor = (outline?: string) => {
        switch (outline) {
            case "primary":
                return isPrimaryBackground
                    ? "outline-white"
                    : "outline-primary";
            case "neutral":
                return "outline-neutral-300";
            case "none":
                return "outline-none";
            default:
                return "";
        }
    };

    return (
        <div
            className={classNames(
                "flex shrink-0 items-center justify-center overflow-hidden rounded-full",
                {
                    "bg-white": isPrimaryBackground,
                    "bg-primary": !isPrimaryBackground,
                    "outline outline-2 md:outline-4": outline,
                },
                width[size],
                outlineColor(outline),
                className,
            )}
        >
            {detailedAsset ? (
                <Asset asset={detailedAsset} />
            ) : (
                <p className="p-4 font-medium text-white">{initials}</p>
            )}
        </div>
    );
};

export default Avatar;
