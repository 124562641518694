import css from "./WysiwygEditor.module.scss";
import { Typography } from "@mui/material";
import type { ValidationError } from "yup";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import Editor, { isQuillEmpty } from "@/components/atoms/WysiwygEditor/Editor";
import log from "@/lib/log";

interface WysiwygEditorProps {
    value?: string | undefined;
    /* @deprecated use customModules instead. This broke in a previous version of de quill editor, so we enabled it
     *  component by component. Ticket: https://tribeagency.atlassian.net/browse/BW-2705 */
    modules?: Record<string, any>;
    customModules?: Record<string, any>;
    onChange?: (jsonReply: string) => void;
    placeholder?: string;
    label?: string;
    error?: ValidationError;
}

const WysiwygEditor = (props: WysiwygEditorProps) => {
    if (props.modules)
        log.warn({
            tag: "cms:WysiwygEditor",
            message: "modules prop is deprecated, use customModules instead.",
            stack: null,
        });
    return (
        <div className={css.Quill}>
            {props.label && (
                <Typography>
                    <strong>{props.label}</strong>:
                </Typography>
            )}
            <ValidationErrorLabel
                error={props.error}
                keyPrefix={"wysiwyg-editor"}
            />
            <Editor
                modules={props.customModules}
                defaultValue={props.value ? JSON.parse(props.value) : null}
                readOnly={false}
                onTextChange={(current, data, source, editor) => {
                    props.onChange?.(
                        isQuillEmpty(editor)
                            ? ""
                            : JSON.stringify(editor.getContents()),
                    );
                }}
            />
        </div>
    );
};

export default WysiwygEditor;
