import { z } from "zod";

import { FontSetEnum } from "@bespeak/schema/enums/font-set.enum";
import { BorderRadiusEnum } from "@bespeak/schema/enums/border-radius.enum";
import { NeutralColorVariantEnum } from "@bespeak/schema/enums/neutral-colors-variant.enum";

export const baseThemeSchema = z.object({
    brandColors: z.string(),
    fonts: z.nativeEnum(FontSetEnum),
    neutralColorsVariant: z.nativeEnum(NeutralColorVariantEnum),
    radiuses: z.nativeEnum(BorderRadiusEnum),
});
